import Api from "../service/Instance";
import { AxiosError } from "axios";

export class AxiosRequester {
	get(url: string, params?: any) {
		return Api.get(url, {
			params: params
		}).catch(this.handleHttpError);
	}
	post(url: string, bodyFormData: FormData) {
		return Api.post(url, bodyFormData)
			.then(function (response) {
				return response.data;
			})
			.catch(function (response) {
				console.log(response);
			});
	}
	handleHttpError(error: AxiosError) {
		console.error(error.response);
		return Promise.reject(error);
	}
}

export const HttpRequest = new AxiosRequester();
