import React from "react"
import classNames from "classnames";
import { Footer } from "components/atoms/CardFooter/CardFooter";
import { Header } from "components/atoms/CardHeader/CardHeader";
import { UnitValue } from "components/atoms/UnitValue/UnitValue";

type Props = {
	spinner?: boolean;
	headerLabel: string;
	valueLabel: string;
	unitLabel: string;
	footerLabel: Array<string>;
};

export default function CardMainHeadingTrendMolecule({ spinner, headerLabel, valueLabel, unitLabel, footerLabel }: Props) {
	return (
		<article
			className={classNames("item", "xs-itemspan-12", "dashboard-headerSection", spinner && "spinner")}
		>
			<Header label={headerLabel} />
			<div className={classNames("card-body", "trend-and-unit")}>
				<UnitValue unitLabel={unitLabel} valueLabel={valueLabel} />
			</div>
			<Footer label={footerLabel} />
		</article>
	)
}
