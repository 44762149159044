import React from "react"
import { AppStore } from "store"
import { AppStoreProps } from "store/app/app.store"
import { Properties } from "helpers/properties"
import { HeaderInfoPopup } from "components/molecules"

type Props = {
	children: any;
} & AppStoreProps

/*
	FUNCTION NAME: TopValueLeversTable
	DESCRIPTION: The function that renders Top value levers table used in Value Levers page for LNG value chain
*/
function TopValueLeversTable({ children, dictionary }: Props) {
	return (
		<div className="ValueLevers__tables">
			<div className="ValueLevers__tables__heading">
				<HeaderInfoPopup
					info={Properties.getDictionaryText(dictionary.get("dictionary"), `LNG_value_lever_top_value_lever`, "en")} type="subheader"
				>
					<h2>Top Value Levers</h2>
				</HeaderInfoPopup>
			</div>
			{children}
		</div>
	)
}
export default AppStore.withStores(TopValueLeversTable);
