import React from "react";
import classNames from "classnames";
import { BreadcrumbItem, BreadcrumbItemProps } from "../../molecules/Breadcrumbs/BreadcrumbItem";
import "./Breadcrumbs.scss";

interface Props {
	className?: string;
	children?: Array<React.ReactElement<BreadcrumbItemProps>>
}

/*
	FUNCTION NAME: Breadcrumb
	DESCRIPTION: The main function that creates the breadcrumb UI that is used on all pages
*/
export function Breadcrumb({ className, children }: Props) {
	return (
		<section className={classNames("breadcrumbs", className)}>
			<ul className="breadcrumbs__children">
				{children}
			</ul>
		</section>
	);
}

Breadcrumb.Item = BreadcrumbItem;