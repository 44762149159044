import React, { useRef } from "react"
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Icon } from "components/atoms/Icon/Icon";
import { Typography } from "components/atoms/Typography/Typography";
import { RouteType } from "types/RouteType";
import "./NavExpandedMenu.scss";

type Props = {
	expandedMenuArray: RouteType | null,
	header: string,
	selectedNavRoute?: string,
	onClose: () => void;
};

/*
	FUNCTION NAME: NavExpandedMenu
	DESCRIPTION: The function that renders the expanded navigation menu component for IVCI navigation menu
*/
export function NavExpandedMenu(props: Props) {
	const wrapperRef = useRef(null);

	React.useEffect(() => {
		let timer;
		// This function closes the sidebar, there is a delay added before close for good UX
		const onMouseOutsideWindow = (event) => {
			// Custom logic for the case of mouse leaving the browser window
			if (event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) {
				// Clear old timers if exists when user goes out of window.
				clearTimeout(timer);
				timer = null;
				// Set a new timer and run this function when user leaves window.
				timer = setTimeout(props.onClose, 500);
			} else {
				// if user is back in window clear timers.
				// eslint-disable-next-line
				if (timer) {
					clearTimeout(timer);
					timer = null;
				}
			}
		}

		window.addEventListener("mouseout", onMouseOutsideWindow);
		// This function is necessary in order to prevent memory leak as event listener might attach too many times if not removed
		return () => window.removeEventListener("mouseout", onMouseOutsideWindow);
		// eslint-disable-next-line
	}, []);

	return (
		<aside ref={wrapperRef} className={classNames("navExpandedMenu", { "expanded": props.expandedMenuArray })} onMouseLeave={props.onClose}>
			<p className={"navExpandedMenu__title"}>
				<Typography.ExtraLargeText>{props.header}</Typography.ExtraLargeText>
				<Icon name="close" className={classNames("navExpandedMenu__title__expandedClose", "icon-close")} onClick={props.onClose} />
			</p>
			<ul className={"navExpandedMenu__navExpandedItems"} >
				{props.expandedMenuArray?.subpages?.map((navItem, index) => {
					const navPath = `${props.expandedMenuArray?.path}/${navItem.path}`;
					return <li key={index} className={classNames("navExpandedMenu__navExpandedItems__navExpandedItem", { "selected": props.selectedNavRoute === navPath })} >
						<Link key={navPath || ""} to={navPath || ""} onClick={props.onClose} >
							<Icon name={navItem.icon || ""} className={classNames("icon_color", `icon-${navItem.icon}`)} onClick={props.onClose} />
							<Typography.LargeText>{navItem.label}</Typography.LargeText>
						</Link>
					</li>
				})}
			</ul>
		</aside>
	)
}
