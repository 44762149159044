import { API_ROUTE } from "config/PageApiConfig";
import { FilterStateType } from "store/app/filters.store";
import { HttpRequest } from "helpers/request-helper";
import { SubSegmentType } from "./types/SubSegmentType";
import { VLSegmentType } from "types/VLSegmentType";

export function GetValueLeverSummary(
	segment: string,
	subsegments: string,
	params: Pick<FilterStateType, "showBy"> & {
		includeCashPayment: boolean;
		includePSCCosts: boolean;
		includeAccountingCosts: boolean;

	}
): Promise<VLSegmentType> {
	return HttpRequest.get(`${API_ROUTE.CCNG.valueLeversChart}/${segment}/${subsegments.split("/").join("|")}/costs/detail`, params).then(
		(data) => data.data as VLSegmentType
	);
}


export function GetSubSegments(): Promise<SubSegmentType> {
	return HttpRequest.get(API_ROUTE.LNG.valueLeversSubsegment).then(
		(data) => data.data as SubSegmentType
	);
}

export function LoadProductType() {
	return HttpRequest.get(API_ROUTE.CCNG.productType).then(
		(data) => data.data)
}

export function LoadSubSegment() {
	return HttpRequest.get(API_ROUTE.CCNG.subsegments).then(
		(data) => data.data)
}

export function LoadRegion() {
	return HttpRequest.get(API_ROUTE.CCNG.region).then(
		(data) => data.data)
}

export function LoadYears() {
	return HttpRequest.get(API_ROUTE.CCNG.years).then(
		(data) => data.data)
}
