import React from "react"
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Switch, Route, Redirect } from "react-router"
import { CreateConnectedStoreAs } from "undux/dist/src/react/createConnectedStoreAs";
import { AppHeader, Typography, Breadcrumb } from "components/molecules"
import { AppHistory } from "pages/app"
import { processLogout } from "helpers/auth"
import { SidebarContentLayout } from "layouts"
import { RouteType } from "types/RouteType";
import "./SubNavigationWrapper.scss";

/**
 * Use this component only for Value Chain/Home level components.
 * This component wraps subroutes into SidebarLayout.
 * @export
 * @param {string} [appTitle="Home"] = Page Title
 * @param {string} title = Html Title
 * @param {RouteType} route = Route Info for the selected page only.
 * @param {CreateConnectedStoreAs<any>} Store = Store for the VC
 * @param {string} lastUpdated = Last updated stamp
 * @param {Array<RouteType>} ParentLevelRoute = This is AppRoute info. Since this app wasn't ready for page level routing for now. You need this to work.
 * @param {...Array<RouteType>} additionalRoutes = These are other value chain routes.
 * @returns
 */
export function SubNavigationWrapper(appTitle: string = "Home", title: string, route: RouteType, Store: CreateConnectedStoreAs<any>, lastUpdated: string, ParentLevelRoute: Array<RouteType>, ...additionalRoutes: Array<RouteType>) {
	const hasSubpages = route.subpages && (route.subpages.length > 0);
	const subPages = (hasSubpages && route.subpages) || [];
	const defaultSubPage = (hasSubpages && subPages[0]) || null;
	const currentSelectedRoute = AppHistory.location.pathname;
	const parentRoute: string = route.path || "/"
	const pageTitle = route.label !== title ? title : route.label;
	const MINIMIZED_HEIGHT_SCROLL_THRESHOLD = 2.9125;

	const PageRoutes = <Switch>
		{subPages
			.map((page) => <Route
				key={page.path}
				path={`${parentRoute}/${page.path}`}
				render={() => {

					window.scroll(0, 0);
					return <>
						<Helmet>
							<title>{appTitle} {page.label && `| ${title} | ${page.label}`}</title>
						</Helmet>
						<AppHeader title={page.label} sticky={MINIMIZED_HEIGHT_SCROLL_THRESHOLD} ><Typography.TextNormal color={"Grey"}>{lastUpdated}</Typography.TextNormal></AppHeader>
						<Breadcrumb className={"subNavigationWrapperBreadcrumbs"}>
							<Breadcrumb.Item><Link className={"link-item"} to="/">{appTitle} Dashboard</Link></Breadcrumb.Item>
							<Breadcrumb.Item><Link className={"link-item"} to={parentRoute}>{pageTitle}</Link></Breadcrumb.Item>
							<Breadcrumb.Item selected>{page.label}</Breadcrumb.Item>
						</Breadcrumb>
						<page.component></page.component>
					</>
				}}
			/>)}
		<Redirect from="**" to={`${parentRoute}/${defaultSubPage && defaultSubPage.path}`} />
	</Switch>

	// Routes transformation for sidebar.
	const allSideBarRoutes = ParentLevelRoute.map((r) => {
		const currentParentRouteIndex = additionalRoutes.find((x) => r.path === x.path);
		if (currentParentRouteIndex)
			return currentParentRouteIndex;
		else return r;
	});

	return <SidebarContentLayout onLogout={processLogout} pages={allSideBarRoutes} selected={currentSelectedRoute}>
		{hasSubpages &&
			((Store && <Store.Container>
				{PageRoutes}
			</Store.Container>) || PageRoutes)
		}
		{!hasSubpages && <>You can render a default view because there are no subpages.</>}
	</SidebarContentLayout>
}
