import React from "react";
import { NavItem } from "components/molecules";
import "../../../../layouts/SidebarContentLayout/SidebarContentLayout.scss";
import { RouteType } from "types/RouteType";

type Props = {
	selected?: string;
	onLogout?: () => void;
	pages: Array<RouteType>;
	open: RouteType | null;
	setOpen: (newState) => void;
};


/*
	FUNCTION NAME: NavItems
	DESCRIPTION: The function that creates the  navigation menu UI for IVCI
*/
export function NavItems({ pages, onLogout, selected, open, setOpen }: Props) {
	const isNavSelected = (navItemSelected, page) => {
		return navItemSelected && (navItemSelected.includes(page.path || "") || false)
	}
	const handleNavClick = (newState) => {
		setOpen(newState)
	};

	return (
		<nav className="sidebarContentLayout__appNav">
			<ul className="sidebarContentLayout__appNav__navList">
				{pages
					.filter((r) => !r.hidden)
					.map((page) => {
						return <NavItem
							key={`${page.label}-${page.path}`}
							icon={page.icon}
							hovered={open?.path === page.path}
							selected={isNavSelected(selected, page)}
							label={page.label}
							onHover={() => handleNavClick(page)}
							onTouchStart={() => handleNavClick(page)}
						/>
					})
				}
				<NavItem className="sidebarContentLayout__appNav__navList__logout"
					icon="exit"
					label="Logout"
					onClick={onLogout}
				></NavItem>
			</ul>
		</nav>
	)
}
