import classNames from "classnames";
import React from "react";
import { Typography } from "components/atoms/Typography/Typography";
import "./BoxAndLabel.scss";

interface Props {
	label: string;
	box: "box" | "line";
	boxColor?:
	| "teal"
	| "lightaqua"
	| "darkaqua"
	| "sand"
	| "twilight"
	| "indigo"
	| "lightsand"
	| "cranberry"
	| "cobalt"
	| "white";
	className?: string;
}

export function BoxAndLabel(props: Props) {
	const { className, label, boxColor, box } = props;
	return (
		<div className={classNames("box-and-label", className)}>
			<div className={classNames("box-and-label__left", boxColor, box)} />
			<Typography.SmallText className="box-and-label__right">{label}</Typography.SmallText>
		</div>
	);
}
