import React, { useEffect } from "react";
import { AppStore } from "store"
import { AppStoreProps } from "store/app/app.store";
import { ComparativeType } from "types/ComparativeType";
import { isNullOrUndefined } from "util";
import { Properties } from "helpers/properties";
import { SubSegmentType } from "./types/SubSegmentType";
import TopValueLeversTable from "./components/TopValueLeversTable";
import { LNGUtil, Util } from "helpers/util/util";
import { VLFilters } from "./components/VLFilters";
import { VLSegmentType } from "types/VLSegmentType";
import { DropdownItemType } from "components/organisms/Dropdown/Dropdown";
import { Dropdown, HeaderInfoPopup, HeaderSubtitle, StickyElement, WaterfallChart } from "components/molecules";
import { GetSubSegments, GetValueLeverSummary, LoadYears } from "./ValueLevers.service";
import { LNGStore, LNGStoreProps } from "store/lng/lng.store";
import TopVLTableAccordion from "components/organisms/TopVLTableAccordion/TopVLTableAccordion";
import "./ValueLevers.scss";
import { CostDetailTable } from "components/organisms/CostDetailTable/CostDetailTable";

type Props = AppStoreProps & LNGStoreProps;

type State = {
	subsegments: string;
	loading: boolean;
	data: {
		summary: VLSegmentType | null;
		subsegment: SubSegmentType | null;
	}
	yearList: Array<DropdownItemType>;
	costBreakdownType: "fixed-variable" | "cash-non_cash" | "controllable-non_ontrollable";
}

/**
 * This should always return new state.
 * **/
function reducer(state: State, action: { type: string, data?: any, key?: string, [key: string]: any }) {
	switch (action.type) {
		case "UPDATE_FILTER":
			if (!action["key"]) {
				throw new Error("You must pass 'key' in action for 'UPDATE_FILTER' to work");
			}
			if (!action["data"]) {
				throw new Error("You must pass 'data' in action for 'UPDATE_FILTER' to work");
			}
			return { ...state, [action.key]: action.data };

		case "UPDATE_SUMMARY_DATA":
			if (!action["data"]) {
				throw new Error("You must pass 'data' in action for 'UPDATE_SUMMARY_DATA' to work");
			}
			return {
				...state,
				data: {
					...state.data,
					summary: action.data as VLSegmentType
				}
			}

		case "UPDATE_LOADING":
			return { ...state, loading: action.data };

		case "UPDATE_SUBSEGMENTS_DATA":
			return {
				...state,
				data: {
					...state.data,
					subsegment: action.data as SubSegmentType
				}
			}

		default:
			throw new Error();
	}
}

/*
	FUNCTION NAME: ValueLevers
	DESCRIPTION: The function that renders the LNG Value Levers screen
*/
let waterfallChart;
function ValueLevers({ filters, dictionary, lng }: Props) {
	const [state, dispatch] = React.useReducer(reducer, {
		subsegments: "all",
		loading: true,
		data: {
			summary: null,
			subsegment: null
		},
		yearList: [],
		costBreakdownType: "fixed-variable"
	});

	const showBy = filters.get("showBy");
	const currency = lng.get("currency");
	// CustomLogic: Custom logic here.
	const comparative = lng.get("comparative") === "yoy" ? "actual_preceding" : lng.get("comparative");
	const valueChain = lng.get("valueChain");
	const segment = lng.get("segment");
	const period = filters.get("period");
	const month = filters.get("month");
	const quarter = filters.get("quarter");
	const year = filters.get("year");
	const dictionaryList = dictionary.get("dictionary");
	const ESTIMATED_APP_HEADER_HEIGHT = 2.9125;
	const deviationThreshold = 100;
	const tableHeaderLabels = {
		left: Util.getActualOrPlanText("Actual", comparative, month, quarter, year, period),
		right: Util.getActualOrPlanText("Plan", comparative, month, quarter, year, period, true)
	}
	let comparative_param: ComparativeType = "plan";
	let subSegmentList = [
		{ id: "all", label: "All" }
	];
	let typeList = [
		{
			label: "Monthly",
			id: "monthly"
		},
		{
			label: "Quarterly",
			id: "quarterly"
		},
		{
			label: "YTD",
			id: "ytd"
		}
	];

	if (comparative === "actual_preceding") {
		comparative_param = "actual_preceding";
	}
	else if (comparative === "actual_corresponding") {
		comparative_param = "yoy";
	}

	if (comparative === "actual_preceding") {
		typeList = [
			{
				label: "Monthly",
				id: "monthly"
			},
			{
				label: "Quarterly",
				id: "quarterly"
			}
		];
	}

	const handleFilterChange = (key: string, value: any) => {
		switch (key) {
			case "comparative":
				// Do this special logic provided by clients before you update local state.
				// intentionally skipped break statement. NOT A MISTAKE.
				if (value.id === "actual_preceding" && period === "ytd") {
					filters.set("period")("monthly");
				}
			// eslint-disable-next-line
			case "segment":
			case "valueChain":
				lng.set(key)(value.id);
				break;
			case "showBy":
			case "month":
			case "quarter":
			case "period":
			case "year":
				// this will trigger re-render too but the values are global to component.
				filters.set(key)(value.id);
				break;
			default:
				// this will trigger re-render too but the values are local to component.
				dispatch({ type: "UPDATE_FILTER", key: key, data: value.id });
		}
	};
	const handleSubsegmentDataChange = (value: SubSegmentType) => dispatch({ type: "UPDATE_SUBSEGMENTS_DATA", data: value });
	const handleSummaryDataChange = (value: VLSegmentType) => dispatch({ type: "UPDATE_SUMMARY_DATA", data: value });
	const toggleLoadingChange = (value: boolean) => dispatch({ type: "UPDATE_LOADING", data: value });



	const getAPIParams = () => {
		const isQuarterly = period === "quarterly";
		const isMonthly = period === "monthly";

		return {
			comparative: comparative_param,
			currency: currency.name,
			periodType: period,
			valueChain: valueChain,
			period: (isQuarterly && quarter) || (isMonthly && month) || `ytd-${month}`,
			year: year,
			showBy: showBy,
		};
	}

	const _initChart = (data: VLSegmentType) => {
		waterfallChart = new window["WaterfallChart"](
			".waterfallchart-ivcr",
			{ data: data },
			500,
			1170,
			"lng",
			showBy,
			currency,
			tableHeaderLabels
		);

		waterfallChart.init();
	}

	const _updateChart = (data: VLSegmentType, showByParam, currencyParam) => {
		waterfallChart.update({ data: data }, showByParam, currencyParam, tableHeaderLabels);
	}

	React.useEffect(() => {
		LoadYears().then((data) => {
			handleFilterChange("yearList", { id: LNGUtil.loadYears(data) });
		});
		// eslint-disable-next-line
	}, [state.data])

	useEffect(() => {
		GetSubSegments(valueChain.split("-")[0])
			.then((values) => {
				handleSubsegmentDataChange(values);
			})
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const params = getAPIParams();
		toggleLoadingChange(true);

		GetValueLeverSummary(segment, state.subsegments, params)
			.then((values) => {
				handleSummaryDataChange(values);
				toggleLoadingChange(false);
			})
		// eslint-disable-next-line
	}, [month, year, quarter, segment, state.subsegments, comparative, period, showBy, currency, valueChain]);


	useEffect(() => {
		if (isNullOrUndefined(state.data.summary)) {
			return;
		}

		if (isNullOrUndefined(waterfallChart)) {
			_initChart(state.data.summary);
		} else {
			_updateChart(state.data.summary, showBy, currency);
		}
		// eslint-disable-next-line
	}, [state.data.summary])

	// Dynamically construct dropdown list for subsegments.
	if (state.data.subsegment && state.data.summary) {
		const currentSegmentItem = state.data.subsegment.segments.find((s) => s.name === segment);
		if (!isNullOrUndefined(currentSegmentItem)) {
			subSegmentList = currentSegmentItem.subSegments.map((sub) => {
				return { id: sub, label: Util.capitalize(sub) }
			});
		}
	}

	const valueLeverTables = () => {
		if (state.data.summary && state.data.summary.costs)
			if (state.costBreakdownType === "fixed-variable") {
				return (
					(state.data.summary.costs.fixedCosts?.costs?.length > 0 && state.data.summary.costs.variableCosts?.costs?.length > 0) &&
					<>
						<CostDetailTable id={"LNG_value_levers_fixed_costs"} category={"Fixed Costs"} valueLevelTable={state.data.summary.costs.fixedCosts} tableCurrency={currency.name} actual={state.data.summary["actual"]} plan={state.data.summary["compare"]} childNodeName="details" tableHeaderLabel={tableHeaderLabels} showBy="" />
						<CostDetailTable id={"LNG_value_levers_variable_costs"} category={"Variable Costs"} valueLevelTable={state.data.summary.costs.variableCosts} tableCurrency={currency.name} actual={state.data.summary["actual"]} plan={state.data.summary["compare"]} childNodeName="details" headerLabel="Variable Costs" tableHeaderLabel={tableHeaderLabels} showBy="" />
					</>
				)
			}
			else if (state.costBreakdownType === "cash-non_cash") {
				return (
					(state.data.summary.costs.cashCosts?.costs?.length > 0 && state.data.summary.costs.nonCashCosts?.costs?.length > 0) &&
					<>
						<CostDetailTable id={"LNG_value_levers_cash_costs"} category={"Cash Costs"} valueLevelTable={state.data.summary.costs.cashCosts} tableCurrency={currency.name} actual={state.data.summary["actual"]} plan={state.data.summary["compare"]} childNodeName="details" tableHeaderLabel={tableHeaderLabels} showBy="" />
						<CostDetailTable id={"LNG_value_levers_non_cash_costs"} category={"Non-Cash Costs"} valueLevelTable={state.data.summary.costs.nonCashCosts} tableCurrency={currency.name} actual={state.data.summary["actual"]} plan={state.data.summary["compare"]} childNodeName="details" headerLabel="Non-Cash Costs" tableHeaderLabel={tableHeaderLabels} showBy="" />
					</>
				)

			}
			else if (state.costBreakdownType === "controllable-non_ontrollable") {
				return (
					(state.data.summary.costs.controllableCosts?.costs?.length > 0 && state.data.summary.costs.nonControllableCosts?.costs?.length > 0) &&
					<>
						<CostDetailTable id={"LNG_value_levers_controllable_costs"} category={"Controllable Costs"} valueLevelTable={state.data.summary.costs.controllableCosts} tableCurrency={currency.name} actual={state.data.summary["actual"]} plan={state.data.summary["compare"]} childNodeName="details" tableHeaderLabel={tableHeaderLabels} showBy="" />
						<CostDetailTable id={"LNG_value_levers_non_controllable_costs"} category={"Non-Controllable Costs"} valueLevelTable={state.data.summary.costs.nonControllableCosts} tableCurrency={currency.name} actual={state.data.summary["actual"]} plan={state.data.summary["compare"]} childNodeName="details" headerLabel="Non-Controllable Costs" tableHeaderLabel={tableHeaderLabels} showBy="" />
					</>
				)

			}
		return [];
	}

	return (
		<section className="homepage__article">
			<HeaderSubtitle heading="Key Value Levers" subtitle={Properties.getDictionaryText(dictionaryList, `LNG_value_lever_subheader`, "en")} className="value-levers-header-with-subtitles" />
			<StickyElement to="top" floatBy={ESTIMATED_APP_HEADER_HEIGHT} className="value-levers-filters" stickyClassName="sticky">
				<VLFilters
					period={period}
					comparative={comparative}
					month={month}
					quarter={quarter}
					year={year}
					yearList={state.yearList}
					segment={segment}
					subsegment={state.subsegments}
					onEvent={handleFilterChange}
					showBy={showBy}
					currency={currency}
					valueChain={valueChain}
					subSegmentList={subSegmentList}
					periodType={typeList}
					hideMonth={period === "quarterly"}
					hideQuarterly={period === "monthly" || period === "ytd"}
					disableSubsegment={segment === "all"}
					stickyClassName="value-levers-filters" //This stickyClassName is meant for the JS for scrolling
				></VLFilters>
			</StickyElement>

			<div className="homepage__article__segment">
				<div className="ValueLevers__chart">
					<WaterfallChart loading={state.loading} hasData={(state.data?.summary?.valueLevers && state.data?.summary?.valueLevers.length > 0) || false} />
				</div>
				{!state.loading && state.data.summary && state.data.summary.valueLevers.length > 0 &&

					<TopValueLeversTable>
						<TopVLTableAccordion
							tableCurrency={state.data.summary.currency}
							valueLevers={state.data.summary.valueLevers}
							tableHeaderLabel={tableHeaderLabels}
							deviationThreshold={deviationThreshold}
						/>
					</TopValueLeversTable>
				}
			</div>
			{!state.loading && state.data.summary && state.data.summary.costs.fixedCosts?.costs?.length > 0 && state.data.summary.costs.variableCosts?.costs?.length > 0 &&
				<>
					<div className="ValueLevers__tables__heading">
						<HeaderInfoPopup info={Properties.getDictionaryText(dictionary.get("dictionary"), "LNG_value_lever_cost_breakdown", "en")} type="subheader">
							<h2>Cost Breakdown</h2>
						</HeaderInfoPopup>
						<Dropdown
							border
							title="Cost"
							key="costBreakdownType"
							onChange={(value) => handleFilterChange("costBreakdownType", value)}
							className="costBreakdownTypeDropdown"
							selected={state.costBreakdownType}
							list={[
								{ label: "Fixed & Variable", id: "fixed-variable" },
								{ label: "Cash & Non-cash", id: "cash-non_cash" },
								{ label: "Controllable & Non-controllable", id: "controllable-non_ontrollable" }
							]}
						/>
					</div>
					{valueLeverTables()}
				</>
			}
		</section>
	)
}

export default AppStore.withStores(LNGStore.withStores(ValueLevers));
