import "./StickyElement.scss";
import React from "react";
import classNames from "classnames";
import { useActivatingStickyEvent } from "helpers/hooks/useActivatingStickyEvent";

interface Props {
	// Has to be one child else it'll fail
	children: React.ReactElement;
	className?: string;
	stickyClassName?: string;
	onSticky?: (state: "normal" | "sticky") => void;
	to: "top" | "right" | "left" | "bottom";
	floatBy: number;
}

export function StickyElement({ children, className, to, floatBy = 0, stickyClassName = "", onSticky }: Props) {
	const isMultipleChildren = React.Children.toArray(children).length > 1;

	// Addition of the sticky class based on the function logic.
	useActivatingStickyEvent("sticky-element", stickyClassName);
	if (isMultipleChildren) {
		throw new Error("StickyElement must only hold one child for it to work properly. Upgrade it's functionality if you wish to support more elements");
	}

	return (
		<div style={
			{
				[to]: `${floatBy}rem`,
			}
		} className={classNames(className, "sticky-element")} >
			{children}
		</div >
	)
}
