import "./ItemGroup.scss";
import classNames from "classnames";
import React from "react";

interface Props {
	// Has to be multiple children else there's no need for it.
	children: React.ReactNodeArray;
	className?: string;
}

export function ItemGroup({ children, className }: Props) {
	const childs = React.Children.toArray(children);
	let lastChild;
	let everythingInBetween;
	let firstChild;

	if (childs.length > 1) {
		firstChild = React.cloneElement(childs[0] as React.ReactElement, {
			buttonClassName: "item-start",
			expandedClassName: "item-start-expanded",
			rounded: false,
			border: false
		});

		lastChild = React.cloneElement(childs[childs.length - 1] as React.ReactElement, {
			buttonClassName: "item-end",
			expandedClassName: "item-end-expanded",
			rounded: false
		});

		everythingInBetween = childs.splice(1, childs.length - 2).map((child) => {
			return React.cloneElement(child as React.ReactElement, {
				buttonClassName: "item-middle",
				rounded: false,
				border: false
			});
		})
	}

	return (
		<div className={classNames("item-group", className)}>
			{childs.length <= 1 && childs}
			{childs.length > 1 && <>
				{firstChild}
				{everythingInBetween}
				{lastChild}
			</>}

		</div>
	)
}
