import { API_ROUTE } from "config/PageApiConfig";
import { HttpRequest } from "helpers/request-helper";

export function GetUserLastFeedback(upn: string): Promise<any> {
	const params = {
		upn: upn
	}
	return HttpRequest.get(API_ROUTE.CSAT.userFeedback, params).then(
		(data) => data.data);
}

export function SubmitUserFeedback(upn: string, rating: number, feedback: string, category: string): Promise<any> {
	const bodyFormData = new FormData();
	bodyFormData.append("upn", upn);
	bodyFormData.append("rating", rating.toString());
	bodyFormData.append("feedback", feedback);
	bodyFormData.append("category", category);
	return HttpRequest.post(API_ROUTE.CSAT.userFeedback, bodyFormData).then(
		(data) => data.data
	);
}