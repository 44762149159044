import { appStoreEffects } from "./effects/app.effects";
import { createConnectedStoreAs } from "undux";
import { initialDictionaryState, DictionaryStateType } from "./dictionary.store";
import { initialFiltersState, FilterStateType } from "./filters.store";
import { initialGlobalState, GlobalStateType } from "./global.store";
import { Store, EffectsAs } from "undux";
import { initialPermissionsState } from "./permissions.store";
import { UserPermissions } from "types/UserPermissions";

export const AppStore = createConnectedStoreAs(
	{
		filters: initialFiltersState,
		global: initialGlobalState,
		dictionary: initialDictionaryState,
		permissions: initialPermissionsState
	},
	appStoreEffects
);

export type AppStoreProps = {
	filters: Store<FilterStateType>;
	global: Store<GlobalStateType>;
	dictionary: Store<DictionaryStateType>;
	permissions: Store<UserPermissions>
};

export type AppStoreEffects = EffectsAs<{
	filters: FilterStateType;
	global: GlobalStateType;
	dictionary: DictionaryStateType;
	permissions: UserPermissions;
}>;