import React from "react";
import classNames from "classnames";
import { ClickOutsideBoundary, Icon } from "components/molecules";
import { useRef, useState } from "react";
import "./HeaderInfoPopup.scss";

type Props = {
	info?: string;
	className?: string;
	children: React.ReactElement;
	type?: "subheader" | "popup"
}

export function HeaderInfoPopup(props: Props) {
	const { className, type = "popup", children, info } = props;
	const [open, setOpen] = useState(false);
	const headerInfoPopUp = useRef(null);
	return (<div className={classNames("header-div", className, type)}>
		{children}
		{type === "popup" &&
			<>
				<Icon
					className={classNames("info-icon", (open && "active"))}
					name="info"
					size="medium"
					onClick={(e: MouseEvent) => {
						setOpen(!open);
					}}
				/>
				<div className="header-div__info" >
					<ClickOutsideBoundary connectTo={headerInfoPopUp} listen={true} onOutClick={(e) => setOpen(false)} >
						<div className={classNames("info-popup", (open && "show-popup"))} ref={headerInfoPopUp}>
							<p>{info}</p>
							<Icon name="close" onClick={(e) => {
								e.stopPropagation();
								setOpen(false)
							}} />
						</div>
					</ClickOutsideBoundary>
				</div>
			</>
		}
		{type === "subheader" &&
			<p>{info}</p>
		}
	</div>)
}