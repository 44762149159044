import i18Config from "../i18.json";
import { isNullOrUndefined } from "util";
import propertiesJson from "../properties.json";


interface ListType {
	label: string;
	id: any;
}
export class PropertiesFile {
	properties;
	i18Config;

	constructor(properties, i18ConfigParam) {
		this.properties = properties;
		this.i18Config = i18ConfigParam;
	}

	build() {
		return this;
	}
	getDictionary(key: string, filter?: string, valueChain?: string) {
		if (filter !== undefined && this.properties["dictionary"][filter] !== undefined && valueChain !== undefined && this.properties["dictionary"][filter][valueChain] !== undefined && this.properties["dictionary"][filter][valueChain][key] !== undefined) {
			return this.properties["dictionary"][filter][valueChain][key];
		} else if (filter !== undefined && this.properties["dictionary"][filter] !== undefined && this.properties["dictionary"][filter][key] !== undefined) {
			return this.properties["dictionary"][filter][key]
		} else if (this.properties["dictionary"][key] !== undefined) {
			return this.properties["dictionary"][key]
		}
		return key;
	}

	getCurrentMonth(key: number) {
		if (this.properties["months"][key] !== undefined) {
			return this.properties["months"][key]
		}
		return key;
	}

	getPreviousMonth(key: string, year = 2000) {
		const time = { month: key, year: year }
		const currentMonthKey = this.getAllMonths().findIndex((val) => val.label === key);
		if (currentMonthKey !== undefined) {
			if (currentMonthKey === 0) {
				time.month = this.properties["months"][this.properties["months"].length - 1];
				time.year = year - 1;
			} else {
				time.month = this.properties["months"][currentMonthKey - 1];
			}
		}
		return time;
	}

	getCurrentQuarter(key: number) {
		const month = this.getCurrentMonth(key);
		if (this.properties["quartersMonth"][month] !== undefined) {
			return this.properties["quartersMonth"][month]
		}
		return month;
	}

	getPreviousQuarter(key: string, year = 2000) {
		const time = { quarter: key, year: year }
		const currentQuarterKey = this.getAllQuarters().findIndex((val) => val.label === key);
		if (currentQuarterKey !== undefined) {
			if (currentQuarterKey === 0) {
				time.quarter = this.properties["quarters"][this.properties["quarters"].length - 1];
				time.year = year - 1;
			} else {
				time.quarter = this.properties["quarters"][currentQuarterKey - 1];
			}
		}
		return time;
	}

	getAllMonths() {
		const allMonths: Array<ListType> = [];
		if (this.properties["months"] !== undefined) {

			for (let i = 0; i < this.properties["months"].length; i++) {
				const oneMonth: ListType = { label: "", id: null };

				oneMonth.label = this.properties["months"][i];
				oneMonth.id = i.toString();

				allMonths.push(oneMonth);
			}
		}
		return allMonths;
	}


	getAllQuarters() {
		const allQuarters: Array<ListType> = [];
		if (this.properties["quarters"] !== undefined) {
			for (let i = 0; i < this.properties["quarters"].length; i++) {
				const oneMonth: ListType = { label: "", id: null };
				oneMonth.label = this.properties["quarters"][i];
				oneMonth.id = i.toString();
				allQuarters.push(oneMonth);
			}
		}
		return allQuarters;
	}

	getAllYears(numberOfYears?: number) {
		const arraySize = numberOfYears !== undefined ? numberOfYears : this.properties["years"].length;
		const allYears: Array<ListType> = [];
		if (this.properties["years"] !== undefined) {

			for (let i = 0; i < this.properties["years"].length; i++) {
				if (i < arraySize) {
					const oneYear: ListType = { label: "", id: null };
					oneYear.label = this.properties["years"][i];
					oneYear.id = this.properties["years"][i];

					allYears.push(oneYear);
				}
			}
		}
		return allYears;
	}

	getAllSegments(key?: string) {
		const allSegment: Array<ListType> = [];
		key = key ? key : "monthly";
		if (this.properties["contributionTrendSegments"] !== undefined) {

			for (let i = 0; i < this.properties["contributionTrendSegments"][key].length; i++) {
				const oneSegment: ListType = { label: "", id: null };

				oneSegment.label = this.properties["contributionTrendSegments"][key][i];
				oneSegment.id = this.properties["contributionTrendSegments"][key][i];

				allSegment.push(oneSegment);
			}
		}
		return allSegment;
	}

	getSegmentLabelContributionTrend(key?: string) {
		let defaultSegmentLabel: string = "";
		key = key ? key : "monthly";
		if (this.properties["contributionTrendSegments"] !== undefined) {
			for (let i = 0; i < this.properties["contributionTrendSegments"][key].length; i++) {
				defaultSegmentLabel = this.properties["contributionTrendSegments"][key][i]
			}
		}
		return defaultSegmentLabel;
	}

	getIcons(key: string) {
		if (this.properties["icons"][key] !== undefined) {
			return this.properties["icons"][key]
		}
		return key;
	}
	getCurrencySymbol(key: string) {
		if (this.properties["currencySymbol"][key] !== undefined) {
			return this.properties["currencySymbol"][key];
		}
		return key;
	}

	showCurrency(key: string) {
		if (this.properties["showCurrency"][key] !== undefined) {
			return this.properties["showCurrency"][key];
		}
		return false;
	}
	showDecimal(key: string) {
		if (this.properties["showDecimal"][key] !== undefined) {
			return this.properties["showDecimal"][key];
		}
		return false;
	}

	showOppositeTrend(key: string) {
		if (this.properties["oppositeTrend"][key] !== undefined) {
			return this.properties["oppositeTrend"][key];
		}
		return false;
	}
	getFooterLabelForContribution(key: string, showBy: string, segment: string) {
		let unit;
		if (showBy !== "absolute") {
			if (this.properties["footerLabelContribution"][key] !== undefined) {
				unit = this.properties["footerLabelContribution"][key];
			}
		}
		else {
			// eslint-disable-next-line
			if (this.properties["footerLabelContribution"]["absolute"][segment][key] !== undefined) {
				unit = this.properties["footerLabelContribution"]["absolute"][segment][key];
			}
		}
		return this.pushUnit(unit)

	}

	getUnitForSubSegmentDetails(key: string, showBy: string, segment: string, currency?: string) {
		let unit = "";
		if (showBy !== "absolute") {
			if (this.properties["subsegmentLabelContribution"][key] !== undefined) {
				unit = this.properties["subsegmentLabelContribution"][key];
			}
		}
		else {
			// eslint-disable-next-line
			if (currency && currency === "MYR") {
				if (this.properties["subsegmentLabelContribution"]["absolute-myr"][segment][key] !== undefined) {
					unit = this.properties["subsegmentLabelContribution"]["absolute-myr"][segment][key];
				}
			}
			else {
				// eslint-disable-next-line
				if (this.properties["subsegmentLabelContribution"]["absolute"][segment][key] !== undefined) {
					unit = this.properties["subsegmentLabelContribution"]["absolute"][segment][key];
				}
			}
		}
		return this.pushUnit(unit)
	}
	pushUnit(unit) {
		if (Array.isArray(unit)) {
			const units: any[] = [];
			for (let i = 0; i < unit.length; i++) {
				units.push(this.properties["footerUnits"][unit[i]]);
			}
			return units;
		}
		return unit ? this.properties["footerUnits"][unit] : "";
	}
	getSectorsForContribution() {
		if (this.properties["contributionTrendSectorName"] !== undefined) {
			return this.properties["contributionTrendSectorName"];
		}
		return "";
	}
	get(key: string) {
		if (this.properties[key] !== undefined) {
			return this.properties[key]
		}
		return null;
	}
	getErrorMessage(key: string) {
		console.info(`INFO : ${key}`);
	}
	isTileGrey(tab: string, contributor: string) {
		if (this.properties[tab] && this.properties[tab][contributor]) {
			return this.properties[tab][contributor];
		}
		return "";
	}
	getI18Text(text: string, countryCode: string) {
		if (this.properties["i18"][countryCode][text] !== undefined) {
			return this.properties["i18"][countryCode][text]
		}
		return text;
	}

	getDictionaryText(dictionaryList, text: string, countryCode: string) {
		const key = this.i18Config[countryCode][text];
		if (!isNullOrUndefined(dictionaryList) && !isNullOrUndefined(key)) {
			for (let i = 0; i < dictionaryList.length; i++) {
				if (dictionaryList[i].lang === countryCode && dictionaryList[i].values[key]) {
					return dictionaryList[i].values[key];
				}
			}
		}
		return text;
	}

	// While using this function in the front end, use dangerouslySetInnerHTML
	// an example of using it would be as follows:
	// <p dangerouslySetInnerHTML={ { __html: Properties.convertStringToLink('THIS_IS_THE_STRING_INPUT_OR_DICTIONARY')} }/>
	// This function identifies links within the string and wraps it in a link tag
	// It would identify links that starts with www and a email format
	// www.google.com, https://www.google.com, john@doe.com
	convertStringToLink(inputText) {
		let replacedText;

		//URLs starting with http://, https://, or ftp://
		const replacePattern1 = new RegExp(/(\b(https?|ftp):\/\/[-A-Z0-9+&@#%?=~_|!:,.;]*[-A-Z0-9+&@#%=~_|])/gim);
		replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

		//URLs starting with "www." (without // before it, or it'd re-link the ones done above).
		const replacePattern2 = new RegExp(/(^|[^])(www\.[\S]+(\b|$))/gim);
		replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

		//Change email addresses to mailto:: links.
		const replacePattern3 = new RegExp(/(([a-zA-Z0-9])+@[a-zA-Z]+?(\.[a-zA-Z]{2,6})+)/gim);
		replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

		return replacedText;
	}

}

export const Properties = new PropertiesFile(propertiesJson, i18Config).build();
