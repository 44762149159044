import React, { ReactElement, ReactNode } from "react";
import classNames from "classnames";
import "./TabTable.scss";

type Props = {
	defaultPage: number;
	className?: string;
	headerClassName?: string;
	contentClassName?: string;
	children?: React.ReactNode | React.ReactNodeArray;
	onTabChange?: (id: number) => void;
}

export function TabTable({ defaultPage, children, contentClassName, className, headerClassName, onTabChange }: Props) {
	const [currentPage, setCurrentPage] = React.useState(defaultPage);
	const [isMenuOpen, setOpen] = React.useState(false);

	const onMenuToggle = (): void => {
		setOpen(!isMenuOpen);
	}

	const onTabSelected = (id: number): void => {
		if (currentPage === id) {
			onMenuToggle();
			return;
		}
		onTabChange && onTabChange(id);
		setCurrentPage(id);
		setOpen(false);
	}



	let tabsWithProps = children;
	const selectedTabChildrens: Array<{
		view: any;
		isSelected: boolean;
	}> = [];

	if (children && Array.isArray(children)) {
		tabsWithProps =
			children.map((child: ReactElement | ReactNode | any, index) => {
				const isSelected: boolean = currentPage === child.props.id;
				selectedTabChildrens.push({
					view: (
						<div
							className={classNames({ "tabsTableContainer__tab-panel--content-hidden": !isSelected })}
							data-id={child.props.id}
						>
							{child.props.children}
						</div>
					),
					isSelected: isSelected
				});
				return React.cloneElement(child, {
					key: index,
					selected: isSelected,
					id: child.props.id,
					className: isSelected ? "tabTable-selected" : "tabTable-unselected",
					onClick: () => onTabSelected(child.props.id)
				});
			});
	}
	return (
		<div className={classNames("tabsTableContainer", className)}>
			<div className={classNames("tabsTableContainer__header", headerClassName, { "tabs-expanded": isMenuOpen })}>
				{tabsWithProps}
			</div>
			<div className={classNames("tabsTableContainer__tab-panel", contentClassName)}>
				<>
					{selectedTabChildrens.map((child, index) => {
						return <React.Fragment key={index}>{child.view}</React.Fragment>;
					})}
				</>
			</div>
		</div>
	);
}
