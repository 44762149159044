import React from "react"
import classNames from "classnames";
import { Dropdown, SortableTable, Typography } from "components/molecules";
import { isNullOrUndefined } from "util";
import { PriceDifference } from "../types/ValueChainSummary";
import { Util } from "helpers/util/util";

type Props = {
	priceDifference?: PriceDifference;
}

/*
	FUNCTION NAME: CCNGPriceDifference
	DESCRIPTION: The function that renders all cards used in Price Difference section of CCLNG Overview page
*/
export function CCNGPriceDifference({ priceDifference }: Props) {
	const [floorPrice, setFloorPrice] = React.useState<"Crude" | "Condensate">("Crude");
	const [marketIndex, setMarketIndex] = React.useState<"MCO" | "Brent">("Brent");
	const handleOnEvent = (key, val) => {
		switch (key) {
			case "Floorprice":
				setFloorPrice(val.id);
				break;
			case "Market":
				setMarketIndex(val.id)
				break;
			default:
				break;
		}
	}
	const priceDifferenceArray = priceDifference?.[floorPrice]?.[marketIndex] || [];
	if (!priceDifference) {
		return <></>
	}

	return (
		<div>
			<div className="ccng__overview__footer__cards__floorPrices__priceDifference">
				<Typography.Heading2 weight={500}>Price Difference</Typography.Heading2>
				<Dropdown
					selected={floorPrice}
					border
					className="spaced"
					title="Product"
					onChange={(value) => handleOnEvent("Floorprice", value)}
					list={[
						{
							label: "Crude",
							id: "Crude",
						},
						{
							label: "Condensate",
							id: "Condensate",
						}
					]}
				/>

				<Dropdown
					selected={marketIndex}
					border
					className="spaced"
					title="Marker"
					onChange={(value) => handleOnEvent("Market", value)}
					list={[
						{
							label: "Brent",
							id: "Brent",
						},
						{
							label: "MCO",
							id: "MCO",
						}
					]}
				/>
			</div>
			<SortableTable
				id={`CCNG_price_difference`}
				header={[
					{ label: `${floorPrice} Type`, id: "crudeType", render: (val) => <>{val.crudeType || "-"}</> },
					{
						label: "WAP",
						id: "wap",
						render: (val) => <div className="currency-wrapper"><span className="super-currency">{val.currency} </span><span className="value">{Util.formatDigits(val.wap, "", 2)} </span></div>
					},
					{
						label: "Premium/Disc",
						id: "premiumDisc",
						render: (val) =>
							<div className={classNames("currency-wrapper", "right-align", val.premiumDisc, isNullOrUndefined(val.premiumDisc) || val.premiumDisc === 0 ? "neutral" : (val.premiumDisc < 0 ? "minus" : "plus"))}>
								<span className="value">{(isNullOrUndefined(val.premiumDisc) || val.premiumDisc === 0 ? "" : (val.premiumDisc < 0 ? "-" : "+"))}</span>
								<span className="value">{Util.formatDigits((val.premiumDisc < 0 ? -1 * val.premiumDisc : val.premiumDisc), "", 2)}</span>
							</div>
					},
				]}
				data={priceDifferenceArray}
				showOnly={priceDifferenceArray.length}
			/>
		</div>
	)
}
