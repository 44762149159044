import { CurrencyType } from "types/CurrencyType";
import { DropdownItemType } from "components/organisms/Dropdown/Dropdown";
import { MonthType } from "types/MonthType";
import { Properties } from "helpers/properties";
import { PeriodType } from "types/PeriodType";
import { QuarterType } from "types/QuarterType";
import React from "react"
import { ShowByType } from "types/ShowByType";
import { Dropdown, ItemGroup, WrapContainer } from "components/molecules";

type Props = {
	onEvent?: (type: string, value: any) => void;
	segment?: string;
	subsegment?: string;
	currentSubsegmentList?: Array<DropdownItemType>;
	ctsComponent?: string;
	showBy?: ShowByType | string;
	currency: CurrencyType;
	cts?: string;
	fixedOrVariable?: string;
	controllableOrNonControllable?: string;
	period?: PeriodType;
	month?: MonthType;
	quarter?: QuarterType;
	year?: string;
	hideMonth?: boolean;
	hideQuarter?: boolean;
	disableMonth?: boolean;
	disableYear?: boolean;
	disableQuarterly?: boolean;
	product: string;
	productType: string;
	region: string;
	regionList: Array<DropdownItemType>;
	yearList: Array<DropdownItemType>;
	productTypeList: Array<DropdownItemType>;
	profitability: string;
	stickyClassName: string;
}

/*
	FUNCTION NAME: CostMarginFilters
	DESCRIPTION: The function that renders all filter dropdowns used in CCNG Cost Margin Buildup page
*/
export function CostMarginFilters({ onEvent, showBy, currency, segment, subsegment, profitability, product, productType, productTypeList, region, regionList, cts, fixedOrVariable, controllableOrNonControllable, currentSubsegmentList = [{ label: "All", id: "all" }], hideMonth = false, hideQuarter = false, period, month, quarter, year, yearList, disableMonth, disableYear, disableQuarterly, stickyClassName }: Props) {
	const handleOnEvent = (eventType: string, value: any) => {
		if (!onEvent) return;
		onEvent(eventType, value)
	}
	const unit = product === "Natural Gas" ? "/MMBtu" : "/BOE";
	return (
		<WrapContainer className="cost-margin-filter-component ccng-filters-container" stickyClassName={stickyClassName} >
			<Dropdown
				border
				selected={currency.name}
				className="spaced"
				title="Currency"
				onChange={(value) => handleOnEvent("currency", value)}
				list={[
					{
						label: "USD",
						id: "USD",
					},
					{
						label: "MYR",
						id: "MYR",
					}
				]}
			/>
			<Dropdown
				selected={showBy}
				border
				className="spaced"
				title="Show By"
				onChange={(value) => handleOnEvent("showBy", value)}
				list={[
					{
						label: `${currency.currencySymbol}${unit}`,
						id: "mmbtu",
					},
					{
						label: `Absolute(${currency.currencySymbol})`,
						id: "absolute",
					}
				]}
			/>
			<Dropdown
				border
				title="Profitability"
				onChange={(value) => handleOnEvent("profitability", value)}
				className="spaced"
				selected={profitability}
				list={Properties.get("CCNG_profitablity_filter")}
			/>
			<Dropdown
				selected={period}
				border
				className="spaced"
				title="Period"
				onChange={(value) => handleOnEvent("period", value)}
				list={[{
					label: "Monthly",
					id: "monthly"
				},
				{
					label: "Quarterly",
					id: "quarterly"
				},
				{
					label: "YTD",
					id: "ytd"
				}]}
			/>

			<ItemGroup className="spaced">
				{!hideMonth && <Dropdown
					selected={month}
					border
					title="Month"
					onChange={(value) => handleOnEvent("month", value)}
					list={Properties.getAllMonths().map((v) => {
						return { ...v, id: v.label }
					})}
				/>}


				{!hideQuarter && <Dropdown
					selected={quarter}
					border
					title="Quarter"
					onChange={(value) => handleOnEvent("quarter", value)}
					list={Properties.getAllQuarters().map((v) => {
						return { ...v, id: v.label }
					})}
				/>}

				<Dropdown
					selected={year}
					border
					title="Year"
					onChange={(value) => handleOnEvent("year", value)}
					list={yearList}
				/>
			</ItemGroup>
			<Dropdown
				border
				selected={product}
				className="spaced"
				title="Product"
				onChange={(value) => handleOnEvent("product", value)}
				list={[
					{
						label: "All",
						id: "all",
					},
					{
						label: "Crude",
						id: "Crude",
					},
					{
						label: "Condensate",
						id: "Condensate",
					},
					{
						label: "Natural  Gas",
						id: "Natural Gas",
					}
				]}
			/>


			<Dropdown
				border
				selected={productType}
				className="spaced"
				title="Product Type"
				onChange={(value) => handleOnEvent("productType", value)}
				list={productTypeList}
				disabled={productTypeList.length === 1}
			/>

			<Dropdown
				border
				selected={region}
				className="spaced"
				title="Region"
				onChange={(value) => handleOnEvent("region", value)}
				list={regionList}
			/>
			<Dropdown
				selected={segment}
				border
				className="spaced"
				title="Segment"
				onChange={(value) => handleOnEvent("segment", value)}
				list={[
					{ label: "All", id: "all" },
					{ label: "Carigali Group", id: "carigaliGroup" },
					{ label: "MPM", id: "mpm" },
					{ label: "Marketing", id: "marketing" }
				]}
			/>

			<Dropdown
				selected={subsegment}
				border
				className="spaced"
				title="Sub Segment"
				onChange={(value) => handleOnEvent("subsegment", value)}
				list={currentSubsegmentList}
			/>

			<Dropdown
				selected={cts}
				className="spaced"
				border
				onChange={(value) => handleOnEvent("cts", value)}
				title="Cost Item 1"
				list={[
					{ label: "All", id: "all" },
					{ label: "Cash", id: "cash" },
					{ label: "Non-Cash", id: "non-cash" },
				]}
			/>

			<Dropdown
				selected={fixedOrVariable}
				className="spaced"
				border
				onChange={(value) => handleOnEvent("fixedOrVariable", value)}
				title="Cost Item 2"
				list={[
					{ label: "All", id: "all" },
					{ label: "Fixed", id: "fixed" },
					{ label: "Variable", id: "variable" }
				]}
			/>

			<Dropdown
				selected={controllableOrNonControllable}
				className="spaced"
				border
				onChange={(value) => handleOnEvent("controllableOrNonControllable", value)}
				title="Cost Item 3"
				list={[
					{ label: "All", id: "all" },
					{ label: "Controllable", id: "controllable" },
					{ label: "Non-Controllable", id: "non-controllable" }
				]}
			/>
		</WrapContainer>
	)
}