import { AppStore } from "store";
import { AppStoreProps } from "store/app/app.store";
import { CardSplitNoIcon } from "components/organisms/CardSplitNoIcon/CardSplitNoIcon";
import { ComparativeType } from "types/ComparativeType";
import { Contributors } from "../types/ValueChainDetailResult";
import { MonthType } from "types/MonthType";
import { Properties } from "helpers/properties";
import { QuarterType } from "types/QuarterType";
import React from "react"
import { ShowByType } from "types/ShowByType";
import { Util } from "helpers/util/util";
import { SkeletonContext } from "helpers/hooks/Loader";

type Props = {
	onEvent?: (type: string, value: any) => void;
	contributions?: { [key: string]: Contributors };
	comparative: ComparativeType;
	year: string;
	showBy: ShowByType | string;
	currencySymbol: string;
	selectedValueChain: string;
	month?: MonthType;
	quarter?: QuarterType;
	periodType: string;
	lng?: any;
} & AppStoreProps

/*
	FUNCTION NAME: LNGContributors
	DESCRIPTION: The function that renders the Top Margin Variance Contributors section
	of the LNG Overview page including all the card components used within it
*/
function LNGContributors({ contributions, comparative, year, showBy, currencySymbol, selectedValueChain, dictionary, month, quarter, periodType, lng }: Props) {
	const skeletonState = React.useContext(SkeletonContext);
	const loading = skeletonState?.loading;

	if (!loading && contributions && Object.keys(contributions).length) {
		const sortedContribution = [
			"revenue",
			"salesVolume",
			"price",
			"cost",
			"productionVolume",
			"thirdPartyPurchase",
			"purchasePrice",
			"shippingRelatedCost",
			"upc",
			"umc"
		];
		const contributionDiv: any = [];
		let k = 0;
		while (k < Object.keys(contributions).length) {
			if (sortedContribution.length >= k) {
				const CardSplitNoIconDivs: any = [];
				for (let i = k; i < sortedContribution.length; i++) {
					const key = sortedContribution[i];
					if (((lng.get("valueChain")).includes("all")) || key !== "productionVolume") {
						const tab = contributions[key];
						const decimalPlace = Properties.showDecimal(key) ? 2 : Util.getDecimalPlace(showBy);
						tab &&
							CardSplitNoIconDivs.push(
								<CardSplitNoIcon
									id={`LNG_contributor_${i}`}
									key={i}
									mainheaderLabel={Properties.getDictionary(key, `${selectedValueChain}_tab`, lng.get("valueChain"))}

									headerLabel={Util.getActualOrPlanText("Actual", comparative, month, quarter, year, periodType)}

									footerLabel={_getContributionFooter(
										showBy,
										currencySymbol,
										selectedValueChain,
										tab.actualThirdPartyPurchasePrice || 0,
										key,
										decimalPlace
									)}
									footerLabel1={_getContributionFooter(
										showBy,
										currencySymbol,
										selectedValueChain,
										tab.compareThirdPartyPurchasePrice || 0,
										key,
										decimalPlace
									)}
									unitLabel={
										Properties.showCurrency(key)
											? Properties.getCurrencySymbol(currencySymbol)
											: ""
									}
									valueLabel={Util.formatDigits(tab.actual, showBy, decimalPlace)}
									bordered
									oppositeTrend={Properties.showOppositeTrend(key)}
									trend={
										tab.deviation > 0
											? "up"
											: tab.deviation < 0
												? "down"
												: "neutral"
									}
									trendLabel1={Util.formatDigits(tab.deviation, showBy, decimalPlace)}
									indicatorLabel1={Util.formatDigits(tab.deviationPercentage, "", 0)}
									headerLabel1={Util.getActualOrPlanText("Plan", comparative, month, quarter, year, periodType, true)}
									unitLabel1={
										Properties.showCurrency(key)
											? Properties.getCurrencySymbol(currencySymbol)
											: ""
									}
									valueLabel1={Util.formatDigits(tab.compare, showBy, decimalPlace)}
									withTrend1
									className={Properties.isTileGrey(
										selectedValueChain,
										key
									)}
									infotiptool
									info={Properties.getDictionaryText(dictionary.get("dictionary"), `LNG_contributers_${selectedValueChain}_${key}`, "en")}
								/>
							);
					}
				}
				contributionDiv.push(
					<div className="homepage__article__tab-content__container__group"
						key={k}>
						{CardSplitNoIconDivs[0]}
						{/* <div className="homepage__article__tab-content__container__group__child"> */}
						{CardSplitNoIconDivs[1]}
						{CardSplitNoIconDivs[2]}
						{/* </div> */}
					</div>
				);
			}
			k += 3
		}
		return contributionDiv;
	} else {
		const spinnerDivs: any = [];
		for (let k = 0; k < 3; k++) {
			spinnerDivs.push(
				<CardSplitNoIcon
					key={k}
					mainheaderLabel=""
					headerLabel=""
					footerLabel={[""]}
					trendLabel=""
					unitLabel=""
					valueLabel=""
					indicatorLabel=""
					bordered
					headerLabel1=""
					unitLabel1=""
					valueLabel1=""
					spinner
				/>
			);
		}
		return (
			<div className="homepage__article__tab-content__container__group">
				{spinnerDivs[0]}
				<div className="homepage__article__tab-content__container__group__child">
					{spinnerDivs[1]}
					{spinnerDivs[2]}
				</div>
			</div>
		);
	}
}

function _getContributionFooter(showBy, currencySymbol, selectedValueChain, key, contributionKey, decimalPlace) {
	let decimal = decimalPlace;
	if (contributionKey === "thirdPartyPurchase") {
		decimal = Properties.showDecimal(`${key}_footer`) ? 2 : Util.getDecimalPlace(showBy);
	}
	const footerLabel = Array<string>();
	footerLabel.push(
		String(
			Properties.getFooterLabelForContribution(
				contributionKey,
				showBy,
				selectedValueChain
			)
		)
	);
	if (
		showBy !== "absolute" &&
		selectedValueChain !== "upstream" &&
		contributionKey === "thirdPartyPurchase"
	) {
		footerLabel.push(`${Properties.getCurrencySymbol(currencySymbol)}${Util.formatDigits(key, showBy, decimal)} / MMBtu`);
	} else if (
		showBy === "absolute" &&
		selectedValueChain !== "upstream" &&
		contributionKey === "thirdPartyPurchase"
	) {
		footerLabel.push(
			Properties.getCurrencySymbol(currencySymbol) +
			Util.formatDigits(key, showBy, decimal)
		);
	} else {
		footerLabel.push("");
	}
	return footerLabel;
}


export default AppStore.withStores(LNGContributors);