import "./UnitValue.scss";
import classNames from "classnames";
import React from "react";

interface Props {
	unitLabel: string;
	valueLabel: string;
	className?: string;
}

export function UnitValue(props: Props) {
	return (
		<p className={classNames("unit-value", props.className)}>
			<span className="amount">
				{parseFloat(props.valueLabel) < 0 && <span>-</span>}
			</span>
			<span className="currency">{props.unitLabel}</span>
			<span className="amount">
				{parseFloat(props.valueLabel) < 0 ? props.valueLabel.replace("-", "") : props.valueLabel}
			</span>
		</p>
	);
}
