import { ComparativeType } from "types/ComparativeType";
import { CurrencyType } from "types/CurrencyType";
import { DropdownItemType } from "components/organisms/Dropdown/Dropdown";
import { MonthType } from "types/MonthType";
import { PeriodType } from "types/PeriodType";
import { ProductType } from "types/CCNGtype";
import { ProfitabilityType } from "types/ProfitabilityType";
import { Properties } from "helpers/properties";
import { QuarterType } from "types/QuarterType";
import React from "react"
import { ShowByType } from "types/ShowByType";
import { Dropdown, ItemGroup, WrapContainer } from "components/molecules";

type Props = {
	onEvent?: (type: string, value: any) => void;
	comparative?: ComparativeType;
	profitability: ProfitabilityType;
	period?: PeriodType;
	showBy?: ShowByType | string;
	currency: CurrencyType;
	periodType: DropdownItemType[];
	month?: MonthType;
	quarter?: QuarterType;
	yearList?: Array<DropdownItemType>
	year?: string;
	hideMonth?: boolean;
	hideQuarter?: boolean;
	product: ProductType
	productTypeList?: Array<DropdownItemType>
	regionList?: Array<DropdownItemType>
	productType: string;
	region: string;
	stickyClassName: string;
}

/*
	FUNCTION NAME: CCNGFilters
	DESCRIPTION: The function that renders all filter dropdowns used in CCNG Overview page
*/
export function CCNGFilters({ onEvent, showBy, currency, periodType, product, hideMonth = false, hideQuarter = false, comparative, profitability, period, month, quarter, yearList = [], year, productTypeList = [], productType, regionList = [], region, stickyClassName }: Props) {
	const handleOnEvent = (type: string, value: any) => {
		if (!onEvent) return;
		onEvent(type, value)
	}
	const unit = product === "Natural Gas" ? "/MMBtu" : "/BOE";
	return (
		<WrapContainer className="ccng-filters-container" stickyClassName={stickyClassName}>
			<Dropdown
				selected={currency.name}
				border
				className="spaced"
				title="Currency"
				onChange={(value) => handleOnEvent("currency", value)}
				list={[
					{
						label: "USD",
						id: "USD",
					},
					{
						label: "MYR",
						id: "MYR",
					}
				]}
			/>
			<Dropdown
				selected={showBy}
				border
				className="spaced"
				title="Show By"
				onChange={(value) => handleOnEvent("showBy", value)}
				list={[
					{
						label: `${currency.currencySymbol}${unit}`,
						id: "mmbtu",
					},
					{
						label: `Absolute(${currency.currencySymbol})`,
						id: "absolute",
					}
				]}
			/>
			<Dropdown
				border
				title="Profitability"
				onChange={(value) => handleOnEvent("profitability", value)}
				className="spaced"
				selected={profitability}
				list={Properties.get("CCNG_profitablity_filter")}
			/>
			<Dropdown
				selected={comparative}
				border
				className="spaced"
				title="Comparative"
				onChange={(value) => handleOnEvent("comparative", value)}
				list={[
					{
						label: "Plan",
						id: "plan"
					},
					{
						label: "Actual - Preceding",
						id: "actual_preceding"
					},

					{
						label: "Actual - Corresponding",
						id: "actual_corresponding"
					}
				]}
			/>

			<Dropdown
				selected={period}
				border
				className="spaced"
				title="Period"
				onChange={(value) => handleOnEvent("period", value)}
				list={periodType}
			/>

			<ItemGroup className="spaced">
				{!hideMonth && <Dropdown
					selected={month}
					border
					title="Month"
					onChange={(value) => handleOnEvent("month", value)}
					list={Properties.getAllMonths().map((v) => {
						return { ...v, id: v.label }
					})}
				/>}


				{!hideQuarter && <Dropdown
					selected={quarter}
					border
					title="Quarter"
					onChange={(value) => handleOnEvent("quarter", value)}
					list={Properties.getAllQuarters().map((v) => {
						return { ...v, id: v.label }
					})}
				/>}

				<Dropdown
					selected={year}
					border
					title="Year"
					onChange={(value) => handleOnEvent("year", value)}
					list={yearList}
				/>
			</ItemGroup>

			<Dropdown
				border
				selected={product}
				className="spaced"
				title="Product"
				onChange={(value) => handleOnEvent("product", value)}
				list={[
					{
						label: "All",
						id: "all",
					},
					{
						label: "Crude",
						id: "Crude",
					},
					{
						label: "Condensate",
						id: "Condensate",
					},
					{
						label: "Natural  Gas",
						id: "Natural Gas",
					}
				]}
			/>

			<Dropdown
				border
				selected={productType}
				className="spaced"
				title="Product Type"
				onChange={(value) => handleOnEvent("productType", value)}
				list={productTypeList}
				disabled={productTypeList.length === 1}
			/>

			<Dropdown
				border
				selected={region}
				className="spaced"
				title="Region"
				onChange={(value) => handleOnEvent("region", value)}
				list={regionList}
			/>
		</WrapContainer>
	)
}