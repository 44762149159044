import { ExtraLargeText } from "./ExtraLargeText";
import { ExtraSmallText } from "./ExtraSmallText";
import { Heading1 } from "./Heading1";
import { Heading2 } from "./Heading2";
import { Heading3 } from "./Heading3";
import { Heading4 } from "./Heading4";
import { Heading5 } from "./Heading5";
import { Heading6 } from "./Heading6";
import { LargeText } from "./LargeText";
import { Paragraph } from "./Paragraph";
import { SmallText } from "./SmallText";
import { TextNormal } from "./TextNormal";

export interface TypographyProps {
	children?: string | any;
	className?: string;
	color?: string | "inherit";
	weight?: 300 | 500 | 700 | 900;
	uppercase?: boolean
	wrap?: boolean
}

export const Typography = {
	Heading1: Heading1,
	Heading2: Heading2,
	Heading3: Heading3,
	Heading4: Heading4,
	Heading5: Heading5,
	Heading6: Heading6,
	TextNormal: TextNormal,
	SmallText: SmallText,
	LargeText: LargeText,
	ExtraLargeText: ExtraLargeText,
	ExtraSmallText: ExtraSmallText,
	Paragraph: Paragraph
};

