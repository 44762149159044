import "./Buttons.scss";
import { ButtonsProps } from "./Buttons";
import classNames from "classnames";
import React from "react";
import { Icon, Typography } from "components/molecules";

type Props = ButtonsProps;

export function TextButton({ children, className, color = "Teal", icon, disabled, onClick, renderText, iconClassName }: Props) {

	const applyColor = disabled ? "MediumGrey" : color;
	return (
		<button
			className={classNames("text-button", { "disabled": disabled }, className, `color-${applyColor}`)}
			onClick={onClick}
		>
			{renderText ? renderText(children) : <Typography.ExtraLargeText
				weight={900}
				color={disabled ? "MediumGrey" : color}
				uppercase>{children}</Typography.ExtraLargeText>}
			{icon && <Icon
				name={icon}
				size="medium"
				color={!disabled ? color : applyColor}
				className={classNames("icon-color", iconClassName)}
			/>}
		</button>
	)
}
