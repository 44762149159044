import "./Maintenance.scss";
import { AppHeader } from "components/molecules";
import { Properties } from "helpers/properties";
import React from "react"
import { AppStore, AppStoreProps } from "store/app/app.store";

type Props = AppStoreProps

/*
	FUNCTION NAME: Maintenance
	DESCRIPTION: The function that renders the maintanance screen for IVCI 
*/
function Maintenance({ dictionary }: Props) {
	return (
		<>
			<AppHeader sticky={1200} />
			<section className={"maintenance"}>
				<img alt="logo" className={"logo"} src="/assets/images/503.svg" ></img>
				<h1 className={"pageTitle"}>{Properties.getDictionaryText(dictionary.get("dictionary"), `maintenance_header`, "en")}</h1>
				<p className={"information"} dangerouslySetInnerHTML={{ __html: Properties.convertStringToLink(Properties.getDictionaryText(dictionary.get("dictionary"), `maintenance_subheader`, "en")) }} />

				{/* commented translation
        <p className={"informationMY"} dangerouslySetInnerHTML={{ __html: Properties.convertStringToLink(Properties.getDictionaryText(dictionary.get("dictionary"), `maintenance_subheader`, "my")) }} />
        */}

			</section>
		</>
	)
}


export default AppStore.withStores(Maintenance)