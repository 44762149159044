import { ContributorsType } from "../types/ContributorsType";
import { DropdownItemType } from "components/organisms/Dropdown/Dropdown";
import { CurrencyType } from "store/app/global.store";
import { MonthType } from "types/MonthType";
import { PeriodType } from "types/PeriodType";
import { QuarterType } from "types/QuarterType";
import { Properties } from "helpers/properties";
import React from "react"
import { ShowByType } from "types/ShowByType";
import { Checkbox, Dropdown, ItemGroup, WrapContainer } from "components/molecules";

type Props = {
	onEvent?: (type: string, value: any) => void;
	contributor: ContributorsType;
	period: PeriodType;
	month?: MonthType;
	quarter?: QuarterType;
	year: string;
	yearList?: Array<DropdownItemType>;
	sector: string;
	trailLength: string;
	showTrend: boolean;
	showLegend: boolean;
	showBy?: ShowByType | string;
	currency: CurrencyType;
	// disable
	disableContributor?: boolean;
	disableType?: boolean;
	disableMonth?: boolean;
	disableQuarter?: boolean;
	disableYear?: boolean;
	disableShowTrend?: boolean;
	disableShowLegend?: boolean;
	// hide
	hideContributor?: boolean;
	hideType?: boolean;
	hideMonth?: boolean;
	hideQuarter?: boolean;
	hideYear?: boolean;
	hideShowTrend?: boolean;
	hideShowLegend?: boolean;
	stickyClassName: string;
}

/*
	FUNCTION NAME: CCNGVLFilters
	DESCRIPTION: The function that renders all filter dropdowns used in Contribution Trend page
*/
export function CTFilters({ showBy, currency, onEvent, contributor, period, month, quarter, year, yearList = [], sector, trailLength, showTrend, showLegend, hideContributor = false, hideType = false, hideMonth = false, hideQuarter = false, hideYear = false, hideShowTrend = false, hideShowLegend = false, disableContributor = false, disableType = false, disableMonth = false, disableQuarter = false, disableYear = false, disableShowTrend = false, disableShowLegend = false, stickyClassName }: Props) {
	const handleOnEvent = (eventType: string, value: any) => {
		if (!onEvent) return;
		onEvent(eventType, value)
	}

	const sectors = { all: "All", ...Properties.getSectorsForContribution() }
	const mappedSectors = Object.keys(sectors).map((s) => {
		return { id: s, label: sectors[s] }
	})

	return (
		<WrapContainer className="contribution-trends-filters-container" stickyClassName={stickyClassName}>
			<Dropdown
				selected={showBy}
				border
				className="spaced"
				title="Show By"
				disabled
				key="showBy"
				onChange={(value) => handleOnEvent("showBy", value)}
				list={[
					{
						label: `${currency.currencySymbol}/MMBtu`,
						id: "mmbtu",
					},
					{
						label: `Absolute(${currency.currencySymbol})`,
						id: "absolute",
					}
				]}
			/>

			{!hideType && <Dropdown
				selected={period}
				border
				className="spaced"
				title="Period"
				key="period"
				onChange={(value) => handleOnEvent("period", value)}
				disabled={disableType}
				list={[
					{
						label: "Monthly",
						id: "monthly"
					},
					{
						label: "Quarterly",
						id: "quarterly"
					},
					{
						label: "YTD",
						id: "ytd"
					}
				]}
			/>}

			<ItemGroup className="spaced" key="month-quarter-year">
				{!hideMonth && <Dropdown
					selected={month}
					border
					title="Month"
					key="month"
					onChange={(value) => handleOnEvent("month", value)}
					disabled={disableMonth}
					list={Properties.getAllMonths().map((v) => {
						return { ...v, id: v.label }
					})}
				/>}


				{!hideQuarter && <Dropdown
					selected={quarter}
					border
					title="Quarter"
					key="quarter"
					onChange={(value) => handleOnEvent("quarter", value)}
					disabled={disableQuarter}
					list={Properties.getAllQuarters().map((v) => {
						return { ...v, id: v.label }
					})}
				/>}

				{!hideYear && <Dropdown
					selected={year}
					border
					title="Year"
					key="year"
					onChange={(value) => handleOnEvent("year", value)}
					disabled={disableYear}
					list={yearList}
				/>}
			</ItemGroup>

			{!hideContributor && <Dropdown
				selected={contributor.toString()}
				border
				className="spaced"
				title="Show"
				key="contributor"
				onChange={(value) => handleOnEvent("contributor", value)}
				disabled={disableContributor}
				list={[
					{ label: "Customer", id: "companies" },
					{ label: "Portfolio", id: "sectors" }
				]}
			/>}

			{!hideType && <Dropdown
				selected={sector}
				border
				className="spaced"
				title="Sector"
				key="sector"
				onChange={(value) => handleOnEvent("sector", value)}
				disabled={disableType}
				list={mappedSectors}
			/>}
			{!hideType && <Dropdown
				selected={trailLength}
				border
				className="spaced"
				title="Trail Length"
				key="trailLength"
				onChange={(value) => handleOnEvent("trailLength", value)}
				disabled={disableType}
				// Custom logic for obtaining the different lists based on the period
				list={Properties.getAllSegments(period)}
			/>}

			<div className="contribution-trends-filter-checkboxes"
				key="showTrend-showLegend"
			>
				<Checkbox
					className="spaced"
					key="showTrend"
					htmlFor="showTrend"
					label="Show Trend"
					checked={showTrend}
					onChange={(key, value) => handleOnEvent(key, value)}
				/>
				<Checkbox
					className="spaced"
					key="showLegend"
					htmlFor="showLegend"
					label="Show Label"
					checked={showLegend}
					onChange={(key, value) => handleOnEvent(key, value)}
				/>
			</div>
		</WrapContainer>
	)
}
