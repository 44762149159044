import { API_ROUTE } from "config/PageApiConfig";
import { ComparativeType } from "types/ComparativeType";
import { FilterStateType } from "store/app/filters.store";
import { HttpRequest } from "helpers/request-helper";
import { ValueChainDetailResult } from "./types/ValueChainDetailResult";
import { ValueChainSummary } from "./types/ValueChainSummary";
import { ValueChainType } from "types/ValueChainType";


export function getCCNGValueChainsSummary(params: Pick<FilterStateType, "showBy"> & {
	currency: string;
	comparative: ComparativeType;
	period: string;
	year: string;
}): Promise<ValueChainSummary> {
	return HttpRequest.get(API_ROUTE.CCNG.dashboardSummary, params).then(
		(data) => data.data as ValueChainSummary

	);
}

export function getCCNGValueChainsDetails(path: ValueChainType,
	params: Pick<FilterStateType, "showBy"> & {
		currency: string;
		comparative: ComparativeType;
		period: string;
		year: string;
	}): Promise<ValueChainDetailResult> {
	return HttpRequest.get(
		`${API_ROUTE.CCNG.dashboardDetail}/${path}/detail`, params
	).then((data) => data.data as ValueChainDetailResult);


}

export function getCCNGValueChainsSubSegmentDetails(path: ValueChainType, subSegment: ValueChainType,
	params: Pick<FilterStateType, "showBy"> & {
		currency: string;
		comparative: ComparativeType;
		period: string;
		year: string;
	}): Promise<ValueChainDetailResult> {
	return HttpRequest.get(
		`${API_ROUTE.CCNG.dashboardDetail}/${path}/${subSegment.split("/").join("|")}/detail`, params
	).then((data) => data.data as ValueChainDetailResult);
}

export function LoadProductType() {
	return HttpRequest.get(API_ROUTE.CCNG.productType).then(
		(data) => data.data)
}

export function LoadRegion() {
	return HttpRequest.get(API_ROUTE.CCNG.region).then(
		(data) => data.data)
}

export function LoadYears() {
	return HttpRequest.get(API_ROUTE.CCNG.years).then(
		(data) => data.data)
}

export function LoadEnities() {
	return HttpRequest.get(API_ROUTE.CCNG.entities).then(
		(data) => data.data)
}