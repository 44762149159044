import "./AccordionTable.scss";
import classNames from "classnames";
import { Icon } from "components/molecules";
import React from "react";
import { Util } from "helpers/util/util";
import { Page_Util } from "helpers/util/pageUtil";

interface Props {
	className?: string;
	header: Array<HeaderType>;
	data: Array<DataType>;
	childNodeName?: string;
	fixedColumnId?: string;
	expandCoumnNumber?: number
	footer?: Array<FooterType>;
	id?: string;
}

interface DataType {
	[key: string]: any;
}

interface HeaderType {
	id: string;
	label?: string;
	render?: (value: DataType) => React.ReactElement;
	renderHeader?: (value: DataType) => React.ReactElement;
}

interface FooterType {
	value?: string;
	render?: (value: string) => React.ReactElement;
}

interface State {
	showChildren: string
	dataTable: Array<DataType>
}

export class AccordionTable extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = { showChildren: "", dataTable: this.props.data }
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.data !== prevProps.data) {
			this.setState({ dataTable: this.props.data });
		}
	}

	_showChildren = (id: string) => {
		const tableId = id;
		if (tableId) {
			this.setState((prevState) => ({
				showChildren: prevState.showChildren === tableId ? "" : tableId
			}))
		}
	}

	sortBy = (key: any, e: any) => {
		const type = e["target"]["dataset"]["sort"];
		e["target"]["dataset"]["sort"] = type === "asc" ? "desc" : "asc";
		const arrayCopy = this.state.dataTable;
		arrayCopy.sort(Util.compareBy(key, type));
		this.setState({ dataTable: arrayCopy });
	}

	render() {
		return (
			<div className={classNames("table-holder accordion-table", this.props.className)} id={this.props.id}>
				<table className={classNames("table", this.props.fixedColumnId && "fixed-table")}>
					{Page_Util.getTableHeader(this.props.header, this.props.fixedColumnId)}

					<tbody>
						{this.state.dataTable.map((row, ind) => {
							return (<React.Fragment key={ind}>
								<tr className={classNames((this.state.showChildren === ind.toString()) && "expanded")}>
									{this.props.header.map((head, k) => {
										return (
											<td className={classNames("table__body", head.id === this.props.fixedColumnId && "fixed-column")} key={k} id={ind.toString()} onClick={(e) => this._showChildren(ind.toString())}>
												{k === this.props.expandCoumnNumber && <Icon color="#cccdcf" name={this.state.showChildren === ind.toString() ? "table-arrow_down" : "table-arrow_right"}></Icon>}
												{head.render && head.render(row)}
												{!head.render && row[head.id]}
											</td>)
									})}
								</tr>

								{(this.props.childNodeName && this.state.showChildren === ind.toString()) && row[this.props.childNodeName].map((val, j) =>
									<tr key={j} className={classNames("child-row", j === 0 && "first-child")}>
										{this.props.header.map((head, k) => {
											return (<td className={classNames("table__body", head.id === this.props.fixedColumnId && "fixed-column")} key={k}>
												{head.render && head.render(val)}
												{!head.render && val[head.id]}
											</td>)

										})}
									</tr>
								)}
							</React.Fragment>
							);
						})}
						<tr>
							{this.props.footer?.map((footer, i) => {
								return (
									<th className={classNames("table__footer")}
										key={i} >
										{footer.render && footer.render(footer.value || "")}
									</th>
								);
							})}
						</tr>
					</tbody>
				</table>
			</div>
		);
	}
}