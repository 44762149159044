import "./TrailChart.scss";
import classNames from "classnames";
import { Placeholders } from "components/molecules";
import React from "react";

interface Props {
	children?: any;
	loading: boolean;
	hasData: boolean;
}

export function TrailChart(props: Props) {
	return (
		<div className={classNames("trail-ivcr", { "--no-data-overlay": !props.loading && !props.hasData })}>
			{props.loading && <Placeholders.Loader className={classNames("trail-ivcr__loader")}></Placeholders.Loader>}
			{!props.loading && !props.hasData && <Placeholders.NoData className={classNames("trail-ivcr__no-data")}></Placeholders.NoData>}
			<div className={classNames("Xchart trail-chart", { "--has-overlay": props.loading || !props.hasData })}></div>
			{!props.loading && props.children}
		</div>
	)
}
