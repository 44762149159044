import { LNGEffects } from "../lng.store";

export const lngEffects: LNGEffects = ({ lng }) => {
	if (process.env.NODE_ENV !== "production") {
		lng.onAll().subscribe(({ key, previousValue, value }) => {
			console.info(
				`%c LNG ⥁ ${key}`,
				"background-color: rgb(96, 125, 139); color: #fff; padding: 2px 8px 2px 0;",
				previousValue,
				"→",
				value
			);
		});


	}

	return { lng };
};
