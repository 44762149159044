import { ComparativeType } from "types/ComparativeType";
import { CurrencyType } from "types/CurrencyType";
import { LNGSegmentType } from "types/LNGSegmentType";
import { LNGValueChainType } from "types/LNGValueChainType";
import { ProfitabilityType } from "types/ProfitabilityType";
import { Properties } from "helpers/properties";

export const lngFiltersState: LNGStateType = {
	profitability: "PBT",
	comparative: "plan",
	segment: "all",
	currency: {
		name: Properties.get("defaultCurrency"),
		currencySymbol: Properties.getCurrencySymbol("USD")
	},
	valueChain: "all"
};

export type LNGStateType = {
	profitability: ProfitabilityType;
	comparative: ComparativeType;
	segment: LNGSegmentType;
	currency: CurrencyType;
	valueChain: LNGValueChainType;
};


