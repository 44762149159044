import { isNullOrUndefined } from "util";
import React from "react"
import { useComponentSize } from "react-use-size";

// Magic. Do not touch.
export const DimensionsWrapper = ({ children, onDimensionChange, id }) => {
	const { ref, width } = useComponentSize();

	React.useEffect(() => {
		if (!isNullOrUndefined(ref.current) && width !== 0) {
			onDimensionChange(width, id, ref);
		}
		// eslint-disable-next-line
	}, [width])

	return (
		<div ref={ref}
			id={id}>
			{children}
		</div>
	);
};

export const MemoizedDimensionsWrapper = React.memo(DimensionsWrapper)
