import { AppStore } from "store";
import { AppStoreProps } from "store/app/app.store"
import { BoxAndLabel } from "components/molecules/Legend/BoxAndLabel/BoxAndLabel";
import { CostMarginFilters } from "./components/CostMarginFilter";
import { DropdownItemType } from "components/organisms/Dropdown/Dropdown";
import { LegendContainer } from "components/organisms/LegendContainer/LegendContainer";
import { ProductListType } from "types/ProductListType";
import { Properties } from "helpers/properties";
import React from "react"
import { SubSegmentType } from "pages/LNG/ValueLevers/types/SubSegmentType";
import { BuildupChart, HeaderSubtitle, PopupBox, StickyElement } from "components/molecules";
import { CCNGStore, CCNGStoreProps } from "store/ccng/ccng.store";
import { CCNGUtil, Util } from "helpers/util/util";
import { CostItem1Type, CostItem2Type, CostItem3Type, CostMarginDetailType } from "types/CCNGtype";
import { LoadChartData, LoadProductType, LoadRegion, LoadSubSegment, LoadYears } from "./CostMargin.service";
import { useEffect, useReducer } from "react";
import "./CostMargin.scss";

type Props = AppStoreProps & CCNGStoreProps;

type State = {
	subsegment: string;
	cts: CostItem1Type;
	fixedOrVariable: CostItem2Type;
	controllableOrNonControllable: CostItem3Type;
	data: CostMarginDetailType;
	subsegmentsList: SubSegmentType[];
	productTypesList: ProductListType[];
	productType: string;
	region: string;
	loading: boolean;
	yearList: Array<DropdownItemType>;
	regionList: Array<DropdownItemType>;
}


function reducer(state: State, action: { type: string, data?: any, key?: string, [key: string]: any }) {
	switch (action.type) {
		case "UPDATE_FILTER":
			if (!action["key"]) {
				throw new Error("You must pass 'key' in action for 'UPDATE_FILTER' to work");
			}
			if (!action["data"]) {
				throw new Error("You must pass 'data' in action for 'UPDATE_FILTER' to work");
			}

			return {
				...state, [action.key]: action.data.id
			};

		case "UPDATE_LOADING":
			return { ...state, loading: action.data };

		case "UPDATE_CHART_DATA":
			if (!action["data"]) {
				throw new Error("You must pass 'data' in action for 'UPDATE_CHART_DATA' to work");
			}
			return {
				...state, data: action.data
			};

		default:
			throw new Error();
	}
}


let buildUpChart;

/*
	FUNCTION NAME: CCNGCostMargin
	DESCRIPTION: The function that renders the CCNG Cost Margin Buildup screen
*/
function CCNGCostMargin({ filters, dictionary, ccng }: Props) {
	const [state, dispatch] = useReducer(reducer, {
		data: {},
		subsegment: "all",
		cts: "all",
		loading: false,
		fixedOrVariable: "all",
		controllableOrNonControllable: "all",
		subsegmentsList: [],
		productTypesList: [],
		productType: "all",
		yearList: [],
		region: "all",
		regionList: [{ label: "All", id: "all" }],
	});

	const hideMarginBar = false;
	const showBy = filters.get("showBy");
	const dictionaryList = dictionary.get("dictionary");
	const currency = filters.get("currency");
	const month = filters.get("month");
	const quarter = filters.get("quarter");
	const year = filters.get("year");
	const period = filters.get("period");
	const currencySymbol = currency.currencySymbol;
	const ESTIMATED_APP_HEADER_HEIGHT = 2.9125;
	const product = ccng.get("product");
	const segment = ccng.get("segment");
	const profitability = ccng.get("profitability");


	const handleFilterChange = (key: string, value: any) => {
		switch (key) {
			case "showBy":
			case "month":
			case "quarter":
			case "year":
			case "period":
				filters.set(key)(value.id);
				break;
			case "currency":
				filters.set(key)({ name: value.label, currencySymbol: Properties.getCurrencySymbol(value.id) })
				break;
			case "product":
			case "segment":
			case "profitability":
				ccng.set(key)(value.id);
				break;
			default:
				dispatch({ type: "UPDATE_FILTER", key: key, data: value });
		}
	};
	const toggleLoadingChange = (value: boolean) => dispatch({ type: "UPDATE_LOADING", data: value });

	const setChartData = (value: any) => {
		dispatch({ type: "UPDATE_CHART_DATA", data: value });
		toggleLoadingChange(false);
	}


	let currentSubsegment = [{ label: "All", id: "all" }];

	const getAPIParams = () => {
		const isQuarterly = period === "quarterly";
		const isMonthly = period === "monthly";

		return {
			showBy: showBy,
			currency: currency.name,
			periodType: period,
			period: (isQuarterly && quarter) || (isMonthly && month) || `ytd-${month}`,
			year: year,
			product: product,
			productType: state.productType,
			region: state.region,
			cts: state.cts,
			fixedOrVariable: state.fixedOrVariable,
			controllableOrNonControllable: state.controllableOrNonControllable,
			profitability: profitability
		};
	}

	const _loadChart = (
		data: any,
		create: boolean,
		subsegment: string,
		hideMargin: boolean,
		updateViewOnly?: boolean,
	) => {
		const hideSegmentMargin =
			segment.toLowerCase() === "all" && subsegment === "all";

		if (!updateViewOnly) {
			if (create) {
				buildUpChart = new window["BuildUpChart"](
					".build-up-chart",
					data,
					"subSegmentL2Details",
					500,
					currencySymbol,
					hideMargin,
					hideSegmentMargin,
					"CCNG"
				);
				if (buildUpChart.data) {
					buildUpChart.init();
				}
			} else {
				buildUpChart.update(
					data,
					currencySymbol,
					hideMargin,
					hideSegmentMargin,
					showBy,
					product
				);
			}
		} else {
			buildUpChart.update(
				data,
				currencySymbol,
				hideMargin,
				hideSegmentMargin,
				showBy
			);
		}
	}
	let currentProductTypeList = [{ label: "All", id: "all" }];

	React.useEffect(() => {
		LoadProductType().then((data) => {
			handleFilterChange("productTypesList", { id: data });
		});

		LoadRegion().then((data) => {
			handleFilterChange("regionList", { id: CCNGUtil.loadRegion(data) });
		});

		LoadYears().then((data) => {
			handleFilterChange("yearList", { id: CCNGUtil.loadYears(data) });
		});

		LoadSubSegment().then((data) => {
			handleFilterChange("subsegmentsList", { id: data });
		});
		// eslint-disable-next-line
	}, [])

	currentProductTypeList = CCNGUtil.loadCurrentProductType(product, state.productTypesList, currentProductTypeList)

	currentSubsegment = CCNGUtil.loadCurrentSubSegment(segment, state.subsegmentsList, currentSubsegment);

	useEffect(() => {
		_loadChart(null, true, state.subsegment.toLowerCase(), hideMarginBar);
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		const params = getAPIParams();
		toggleLoadingChange(true);
		LoadChartData(segment, state.subsegment, params).then((data) => {
			setChartData(data);
			_loadChart(data, false, state.subsegment.toLowerCase(), hideMarginBar);
		})
		// eslint-disable-next-line
	}, [showBy, currency, period, period, profitability, month, year, quarter, product, state.productType, state.region, segment, state.subsegment, state.cts, state.controllableOrNonControllable, state.fixedOrVariable])

	return (
		<section className="cost-margin">
			<HeaderSubtitle heading="Integrated Cost/Margin By Product" subtitle={Properties.getDictionaryText(dictionaryList, "CCNG_costmargin_sub_heading", "en")} className="ccng-cost-margin-header-with-subtitles" />
			<StickyElement to="top" floatBy={ESTIMATED_APP_HEADER_HEIGHT} className="TrendAnalysis-filters" stickyClassName="sticky">
				<CostMarginFilters
					onEvent={handleFilterChange}
					period={period}
					month={month}
					quarter={quarter}
					year={year}
					yearList={state.yearList}
					showBy={showBy}
					currency={currency}
					hideMonth={period === "quarterly"}
					hideQuarter={period !== "quarterly"}
					segment={segment}
					subsegment={state.subsegment}
					cts={state.cts}
					fixedOrVariable={state.fixedOrVariable}
					controllableOrNonControllable={state.controllableOrNonControllable}
					currentSubsegmentList={currentSubsegment}
					product={product}
					productType={state.productType}
					region={state.region}
					regionList={state.regionList}
					productTypeList={currentProductTypeList}
					profitability={profitability}
					stickyClassName="TrendAnalysis-filters" //This stickyClassName is meant for the JS for scrolling
				/>
			</StickyElement>
			<div className="cost-margin__filter-chart">
				{/* Need this new class to make it flex grow */}
				<BuildupChart loading={state.loading} hasData={(state.data && state.data["subSegmentL2Details"]?.length > 0) || false} className="cost-margin-buildup-chart-parent">
					<LegendContainer className="buildup-chart-legends">
						<LegendContainer className="unit-margin__legends">
							<BoxAndLabel label="Cost" boxColor="teal" box="box" />
							{!hideMarginBar && (
								<BoxAndLabel label="Margin" boxColor="sand" box="box" />
							)}
							<BoxAndLabel
								label={
									showBy === "absolute"
										? "Revenue"
										: "WAP"
								}
								boxColor="twilight"
								box="box"
							/>
						</LegendContainer>
					</LegendContainer>
					<PopupBox id="buildupchart_Selected-details" className="--hidden" />
					<div id="ccng__cost__margin__market-index">
						<table >
							<thead>
								<tr>
									<th colSpan={3}>Market Index</th>
								</tr>
							</thead>
							<tbody>
								{getmarketIndex(state.data["marketPrices"], currency, showBy)}
							</tbody>
						</table>
						<i className="icon icon-close"></i>
					</div>
				</BuildupChart>
			</div>
		</section>
	);
}

function getmarketIndex(data, currency, showBy) {
	if (data === undefined) {
		return;
	}
	return data.map((item, key) =>
		(key < 3) &&
		<tr key={key}>
			<td>{item.name}:</td>
			<td className="marketIndex-row">
				<span className="super-currency">{currency.currencySymbol === "MYR" ? "MYR" : "USD"}</span><span className="value">{currency.currencySymbol === "MYR" ? Util.formatDigits(item.priceInMYR, showBy, 2) : Util.formatDigits(item.priceInUSD, showBy, 2)}</span>
			</td>
			<td className="marketIndex-row">(<span className="super-currency">{currency.currencySymbol === "MYR" ? "USD" : "MYR"}</span><span className="value">{currency.currencySymbol === "MYR" ? Util.formatDigits(item.priceInUSD, showBy, 2) : Util.formatDigits(item.priceInMYR, showBy, 2)}</span>)</td>
		</tr>

	)

}

export default AppStore.withStores(CCNGStore.withStores(CCNGCostMargin));
