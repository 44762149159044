import React from "react"
import classNames from "classnames"
import { AccordionTable, Icon } from "components/molecules"
import { Indicator } from "components/atoms/Indicator/Indicator"
import { isNullOrUndefined } from "util"
import { Properties } from "helpers/properties"
import { TrendAmt } from "components/atoms/TrendAmt/TrendAmt"
import { useState } from "react"
import { Util } from "helpers/util/util"
import { FixedAndVariableCost } from "types/VLSegmentType"

type Props = {
	valueLevelTable: FixedAndVariableCost;
	tableCurrency: string;
	tableHeaderLabel: {
		left: string,
		right: string
	};
	actual: number;
	plan: number;
	childNodeName: string;
	category: string;
	headerLabel?: string;
	id?: string;
	showBy: string;
}

/*
	FUNCTION NAME: CostDetailTable
	DESCRIPTION: The function that creates the UI for Cost breakdown tables used in Value Levers page
*/
export function CostDetailTable({ valueLevelTable, tableCurrency, actual, plan, childNodeName, headerLabel, category, tableHeaderLabel, id, showBy }: Props) {

	const [sortBy, setSortBy] = useState<{ key: string | null, type: "asc" | "desc" }>({ key: "deviation", type: (actual < plan) ? "asc" : "desc" })

	const fixedCostArray = valueLevelTable?.costs?.sort(Util.compareBy(sortBy.key, sortBy.type)) || [];

	const sortByCost = (key: any, e: any) => {
		const type = e["target"]["dataset"]["sort"];
		e["target"]["dataset"]["sort"] = type === "asc" ? "desc" : "asc";
		fixedCostArray.sort(Util.compareBy(key, type));
		setSortBy({ key, type });
	};

	const accordionCostTable = <AccordionTable
		id={id}
		header={[
			{
				label: category,
				id: "name",
				render: (val) => <span className="name-attr">{val.name}</span>,
				renderHeader: (val) => (
					<>
						{val.label}
						<Icon
							name="sort_updown"
							size="medium"
							onClick={(e) => sortByCost(val.id, e)}
							data-sort="asc"
						/>
					</>
				)
			},
			{
				label: tableHeaderLabel.left,
				id: "actual",
				render: (val) => (
					<div className="currency-wrapper">
						{(val.actual < 0 ? "-" : "")}
						<span className="super-currency">
							{Properties.getCurrencySymbol(tableCurrency)}
						</span><span className="value">
							{Util.formatDigits((val.actual < 0 ? -1 * val.actual : val.actual), showBy, 2)}</span>
					</div>
				),
				renderHeader: (val) => (
					<>
						{val.label}
						<Icon
							name="sort_updown"
							size="medium"
							onClick={(e) => sortByCost(val.id, e)}
							data-sort="asc"
						/>
					</>
				)
			},
			{
				label: tableHeaderLabel.right,
				id: "compare",
				render: (val) => (
					<div>
						{(val.compare < 0 ? "-" : "")}
						<span className="super-currency">
							{!isNullOrUndefined(val.compare) && Properties.getCurrencySymbol(tableCurrency)}
						</span><span className="value">
							{(isNullOrUndefined(val.compare) && "-") || Util.formatDigits((val.compare < 0 ? -1 * val.compare : val.compare), showBy, 2)}</span>
					</div>
				),
				renderHeader: (val) => (
					<>
						{val.label}
						<Icon
							name="sort_updown"
							size="medium"
							onClick={(e) => sortByCost(val.id, e)}
							data-sort="asc"
						/>
					</>
				)
			},
			{
				label: "Difference",
				id: "deviation",
				render: (val) => (
					<p
						className={classNames(
							"right-align",
							val.deviation > 0
						)}
					>
						<TrendAmt
							negativeSymbol={val.deviation < 0 ? "-" : ""}
							currencySymbol={Properties.getCurrencySymbol(tableCurrency)}
							numberValue={Util.formatDigits((val.deviation < 0 ? -1 * val.deviation : val.deviation), "", 2)}
							label={""}
							up={val.deviation === 0 ? "neutral" : (val.deviation > 0 ? "up" : "down")}
							oppositeTrend
						/>
					</p>
				),
				renderHeader: (val) => (
					<p className="right-align">
						{val.label}
						<Icon
							name="sort_updown"
							size="medium"
							onClick={(e) => sortByCost(val.id, e)}
						/>
					</p>
				)
			},
			{
				label: "Difference(%)",
				id: "deviationPercentage",
				render: (val) => (
					<p
						className={classNames(
							"right-align",
							val.deviation > 0 && "positiveDeviation"
						)}
					>
						<Indicator
							label={Util.formatDigits(val.deviationPercentage, "", 0)}
							down={val.deviation > 0 ? "down" : "up"}
							oppositeTrend
						/>
					</p>
				),
				renderHeader: (val) => (
					<p className="right-align">
						{val.label}
						<Icon
							name="sort_updown"
							size="medium"
							onClick={(e) => sortByCost(val.id, e)}
						/>
					</p>
				)
			}
		]}
		data={fixedCostArray}
		childNodeName={childNodeName}
		expandCoumnNumber={0}
		footer={valueLevelTable.total && [
			{
				value: "Total",
				render: (val) => <b>{val}</b>
			},
			{
				value: valueLevelTable.total?.actual.toString(),
				render: (val) => {
					const valueParsed = Number.parseFloat(val);
					return <div className="currency-wrapper">
						{(valueParsed < 0 ? "-" : "")}
						<span className="super-currency">
							{Properties.getCurrencySymbol(tableCurrency)}
						</span>
						<span className="value">{Util.formatDigits((valueParsed < 0 ? -1 * valueParsed : valueParsed), "", 2)}</span>
					</div>
				}
			},
			{
				value: valueLevelTable.total?.compare.toString(),
				render: (val: string) => {
					const valueParsed = Number.parseFloat(val);
					return <div>
						{(valueParsed < 0 ? "-" : "")}
						<span className="super-currency">
							{!isNullOrUndefined(valueParsed) && Properties.getCurrencySymbol(tableCurrency)}
						</span><span className="value">
							{(isNullOrUndefined(valueParsed) && "-") || Util.formatDigits((valueParsed < 0 ? -1 * valueParsed : valueParsed), "", 2)}</span>
					</div>
				},
			},
			{
				value: valueLevelTable.total?.deviation.toString(),
				render: (val: string) => {
					const valueParsed = Number.parseFloat(val);
					return <p
						className={classNames(
							"right-align",
							valueParsed > 0
						)}
					>
						<TrendAmt
							negativeSymbol={valueParsed < 0 ? "-" : ""}
							currencySymbol={Properties.getCurrencySymbol(tableCurrency)}
							numberValue={Util.formatDigits((valueParsed < 0 ? -1 * valueParsed : valueParsed), "", 2)}
							label={""}
							up={false ? "neutral" : (valueParsed > 0 ? "up" : "down")}
							oppositeTrend
						/>
					</p>
				},
			},
			{
				value: valueLevelTable.total?.deviationPercentage.toString(),
				render: (val: string) => {
					const valueParsed = Number.parseFloat(val);
					return <p
						className={classNames(
							"right-align",
							valueParsed > 0 && "positiveDeviation"
						)}
					>
						<Indicator
							label={Util.formatDigits(valueParsed, "", 0)}
							down={valueParsed > 0 ? "down" : "up"}
							oppositeTrend
						/>
					</p>
				},
			}
		]}
	/>;

	const table = () => {
		if (headerLabel !== undefined) {
			return (<div className="CCNGValueLevers__cost-detail">
				{accordionCostTable}
			</div>)
		}
		else {
			return (accordionCostTable);
		}
	};

	return (table())
}
