import React from "react";
import classNames from "classnames";
import { Header } from "components/atoms/CardHeader/CardHeader";
import { Trend } from "components/molecules/CardTrend/CardTrend";
import { UnitValue } from "components/atoms/UnitValue/UnitValue";
import { Footer } from "components/atoms/CardFooter/CardFooter";
import "./CardHeadingTrendMolecule.scss";

interface Props {
	headerLabel: string;
	footerLabel: Array<string>;
	trendLabel?: string;
	indicatorLabel?: string;
	unitLabel: string;
	valueLabel: string;
	withTrend?: boolean;
	bordered?: boolean;
	trend?: "up" | "down" | "neutral";
	indicatorDown?: boolean;
	className?: string;
	oppositeTrend?: boolean;
	spinner?: boolean;
}

export function CardHeadingTrendMolecule(props: Props) {
	const {
		headerLabel,
		footerLabel,
		trendLabel,
		indicatorLabel,
		unitLabel,
		valueLabel,
		bordered,
		trend = "down",
		withTrend = true,
		className,
		oppositeTrend,
		spinner

	} = props;
	return (
		<article
			className={classNames("cmp-card", className, bordered && "bordered", spinner && "spinner")}
		>
			<Header label={headerLabel} />
			<div className="card-body">
				<div className="trend-and-unit">
					{withTrend && (
						<Trend
							trendAmountLabel={trendLabel || ""}
							indicatorLabel={indicatorLabel || ""}
							trend={trend}
							oppositeTrend={oppositeTrend}
						/>
					)}
					<UnitValue unitLabel={unitLabel} valueLabel={valueLabel} />
				</div>
			</div>
			<Footer label={footerLabel} />
		</article>
	);
}
