
import React from "react"
import { CardWithIcon } from "components/organisms/CardWithIcon/CardWithIcon";
import classNames from "classnames";

class IVCI_Page_Util {
	getOverviewSegmentLoadingCard = () => {
		const spinnerDivs: any = [];
		for (let k = 0; k < 4; k++) {
			spinnerDivs.push(
				<CardWithIcon
					key={k}
					spinner
					iconName=""
					className="homepage__article__segment-tabs__tab"
					mainheaderLabel=""
					headerLabel=""
					footerLabel={[""]}
					trendLabel=""
					unitLabel=""
					valueLabel=""
					indicatorLabel=""
					bordered
					headerLabel1=""
					unitLabel1=""
					valueLabel1=""
				/>
			);
		}
		return spinnerDivs;
	}

	getTableHeader = (header, fixedColumnId) => {
		return (<thead>
			<tr>
				{header.map((head, i) => {
					return (
						<th className={classNames("table__header", head.id === fixedColumnId && "fixed-column")}
							key={i}>
							{head.renderHeader && head.renderHeader(head)}
							{!head.renderHeader && head.label}
						</th>
					);
				})}
			</tr>
		</thead>)
	}

	getPermissionList = (appStore, AppRoutes, CCNGRoutes, LNGRoutes) => {
		const permissions = appStore.permissions.get("permissions");

		let allowedAppRoute = [...AppRoutes];

		const isAllowedCCNGPSC = permissions.find((page) => page.permissionName === `/ccng`);
		if (isAllowedCCNGPSC === undefined) {
			allowedAppRoute = allowedAppRoute.filter((ele) => ele.path !== "/ccng");
		}
		const allowedCCNGRoutes = isAllowedCCNGPSC ? { ...CCNGRoutes } : {};
		allowedCCNGRoutes.subpages = [];
		isAllowedCCNGPSC && CCNGRoutes && CCNGRoutes.subpages && CCNGRoutes.subpages.forEach((ele) => {
			const isAllowed = permissions.find((page) => page.permissionName === `/ccng/${ele.path}`);
			if (isAllowed !== undefined) {
				allowedCCNGRoutes.subpages.push(ele);
			}
		})

		const isAllowedLNGPSC = permissions.find((page) => page.permissionName === `/lng`);
		const allowedLNGRoutes = isAllowedLNGPSC ? { ...LNGRoutes } : {};

		if (isAllowedLNGPSC === undefined) {
			allowedAppRoute = allowedAppRoute.filter((ele) => ele.path !== "/lng");
		}
		allowedLNGRoutes.subpages = [];
		isAllowedLNGPSC && LNGRoutes && LNGRoutes.subpages && LNGRoutes.subpages.forEach((ele) => {
			const isAllowed = permissions.find((page) => page.permissionName === `/lng/${ele.path}`);
			if (isAllowed !== undefined) {
				allowedLNGRoutes.subpages.push(ele);
			}
		})

		return { allowedLNGRoutes: allowedLNGRoutes, allowedAppRoute: allowedAppRoute, allowedCCNGRoutes: allowedCCNGRoutes, }
	}
}

export const Page_Util = new IVCI_Page_Util();