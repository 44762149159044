import { NormalButton } from "./NormalButton";
import { TextButton } from "./TextButton";

export interface ButtonsProps {
	children: string;
	className?: string;
	color?: string;
	icon?: string;
	iconClassName?: string;
	disabled?: boolean;
	renderText?: (string) => React.ReactNode;
	onClick?: () => void;
}

export const Buttons = {
	TextButton: TextButton,
	NormalButton: NormalButton
};

