import { AppStore } from "store";
import { AppStoreProps } from "store/app/app.store"
import classNames from "classnames";
import { CardSplitNoIcon } from "components/organisms/CardSplitNoIcon/CardSplitNoIcon";
import { Contributors } from "../types/ValueChainDetailResult";
import { Properties } from "helpers/properties";
import React from "react"
import { ShowByType } from "types/ShowByType";
import { Util } from "helpers/util/util";
import { CCNGSegmentType, ProductType } from "types/CCNGtype";
import { SkeletonContext } from "helpers/hooks/Loader";

type Props = {
	onEvent?: (type: string, value: any) => void;
	contributions?: { [key: string]: Contributors };
	showBy: ShowByType | string;
	currencySymbol: string;
	selectedValueChain: CCNGSegmentType;
	product: ProductType;
	entity: string;
	headerLabels: {
		left: string,
		right: string
	}
} & AppStoreProps


function getContributorList(selectedValueChain) {
	let sortedContribution;

	switch (selectedValueChain) {
		case "carigaliGroup":
			sortedContribution = [
				"revenue",
				"entitlementVol",
				"liftedVol",
				"price",
				"cost",
				"productionVol",
				//"stockVol",
				"upc"
			];
			break;
		case "mpm":
			sortedContribution = [
				"revenue",
				"entitlementVol",
				"salesVol",
				"price",
				"cost",
				"productionVol",
				"purchaseVol"
				//"stockVol"
			];
			break;
		case "marketing":
			sortedContribution = [
				"revenue",
				"liftedVol",
				"price",
				"cost",
				"intercoVol",
				"exportVol"
			];
			break;
		case "integrated":
			sortedContribution = [
				"revenue",
				"entitlementVol",
				"salesVol",
				"price",
				"cost",
				"productionVol"
				//"stockVol"
			];
			break;
		default:
			sortedContribution = [
				"revenue",
				"entitlementVol",
				"liftedVol",
				"salesVol",
				"price",
				"cost",
				"productionVol",
				"purchaseVol",
				//"stockVol",
				"upc"
			];
			break;

	}
	return sortedContribution

}

/*
	FUNCTION NAME: CCNGContributors
	DESCRIPTION: The function that renders the Top Margin Variance Contributors section
	of the CCNG Overview page including all the card components used within it
*/
function CCNGContributors({ contributions, showBy, product, currencySymbol, selectedValueChain, dictionary, entity, headerLabels }: Props) {
	const skeletonState = React.useContext(SkeletonContext);
	const loading = skeletonState?.loading;

	if (!loading && contributions && Object.keys(contributions).length) {
		const sortedContribution = getContributorList(selectedValueChain);

		const contributionDiv: any = [];
		let increment = 4;
		increment = selectedValueChain === "marketing" ? 3 : increment;
		for (let k = 0; k < Object.keys(contributions).length; k += increment) {

			if (sortedContribution.length >= k) {
				const CardSplitNoIconDivs: any = [];
				for (let i = k; i < sortedContribution.length; i++) {
					const tab = contributions[sortedContribution[i]];
					const isNeutralTrend = selectedValueChain === "mpm" && sortedContribution[i] === "purchaseVol";
					const decimalPlace = Properties.showDecimal(sortedContribution[i]) ? 2 : Util.getDecimalPlace(showBy);
					tab &&
						CardSplitNoIconDivs.push(
							<CardSplitNoIcon
								id={`CCNG_contributor_${i}`}
								key={i}
								mainheaderLabel={_getContributorHeader(sortedContribution[i], selectedValueChain, entity)}
								headerLabel={headerLabels.left}
								footerLabel={
									_getContributionFooter(
										showBy,
										selectedValueChain,
										sortedContribution[i], product
									)
								}
								footerLabel1={
									_getContributionFooter(
										showBy,
										selectedValueChain,
										sortedContribution[i], product
									)
								}
								unitLabel={
									Properties.showCurrency(sortedContribution[i])
										? Properties.getCurrencySymbol(currencySymbol)
										: ""
								}
								valueLabel={Util.formatDigits(tab["actual"], showBy, decimalPlace)}
								bordered
								oppositeTrend={
									Properties.showOppositeTrend(sortedContribution[i])
								}
								trend={isNeutralTrend ? "neutral" : (tab["deviation"] > 0 ? "up" : tab["deviation"] < 0 ? "down" : "neutral")}

								trendLabel1={Util.formatDigits(tab["deviation"], showBy, decimalPlace)}
								indicatorLabel1={Util.formatDigits(tab["deviationPercentage"], "", 0)}
								headerLabel1={headerLabels.right}
								unitLabel1={
									Properties.showCurrency(sortedContribution[i])
										? Properties.getCurrencySymbol(currencySymbol)
										: ""
								}
								valueLabel1={Util.formatDigits(tab["compare"], showBy, decimalPlace)}
								withTrend1
								className={
									Properties.isTileGrey(
										selectedValueChain,
										sortedContribution[i]
									)
								}
								infotiptool
								info={Properties.getDictionaryText(dictionary.get("dictionary"), `CCNG_contributers_${selectedValueChain}_${sortedContribution[i]}`, "en")}
							/>
						);
				}
				if (selectedValueChain === "marketing") {
					contributionDiv.push(
						<div
							className={classNames("homepage__article__tab-content__container__group ccng__contributor__group", `CCNG__${selectedValueChain}`)}
							key={k}
						>
							{CardSplitNoIconDivs[0]}
							{/* <div className="homepage__article__tab-content__container__group__child"> */}
							{CardSplitNoIconDivs[1]}
							{CardSplitNoIconDivs[2]}
							{/* </div> */}
						</div>
					);
				}
				else {
					contributionDiv.push(
						<div
							className={classNames("homepage__article__tab-content__container__group ccng__contributor__group", `CCNG__${selectedValueChain}`)}
							key={k}
						>
							{CardSplitNoIconDivs[0]}
							{CardSplitNoIconDivs[1]}
							{CardSplitNoIconDivs[2]}
							{CardSplitNoIconDivs[3] ? CardSplitNoIconDivs[3] : 
							// <CardSplitNoIcon
							// 	className="no-visible"
							// 	mainheaderLabel=""
							// 	headerLabel=""
							// 	footerLabel={[""]}
							// 	unitLabel=""
							// 	valueLabel=""
							// 	headerLabel1=""
							// 	unitLabel1=""
							// 	valueLabel1=""
							// 	bordered
							// />
							<div className="homepage__article__tab-content__container__group__child">
							</div>
							} 
							<div className="homepage__article__tab-content__container__group__child">
							</div>
						</div>
					);
				}
			}
		}
		return contributionDiv;
	} else {
		const spinnerDivs: any = <CardSplitNoIcon
			mainheaderLabel=""
			headerLabel=""
			footerLabel={[""]}
			trendLabel=""
			unitLabel=""
			valueLabel=""
			indicatorLabel=""
			bordered
			headerLabel1=""
			unitLabel1=""
			valueLabel1=""
			spinner
		/>;

		return (
			<div className="homepage__article__tab-content__container__group">
				{spinnerDivs}
				{spinnerDivs}
				{spinnerDivs}
				{spinnerDivs}
				<div className="homepage__article__tab-content__container__group__child">
				</div>
			</div>
		);
	}
}

function _getContributionFooter(showBy, selectedValueChain, contributionKey, product) {
	const footerLabel = Array<string>();
	const labelIndex = product === "Natural Gas" ? 1 : 0;
	const footerLabelText = Properties.getFooterLabelForContribution(`CCNG_${contributionKey}`, showBy, `CCNG_${selectedValueChain}`);

	footerLabel.push(
		String(
			footerLabelText === "" ? "" : footerLabelText[labelIndex]
		)
	);
	return footerLabel;
}

function _getContributorHeader(key, valueChain, entity) {
	if (valueChain === "carigaliGroup" && key === "productionVol" && entity === "all") {
		return Properties.getDictionary(`${key}Gross`, `CCNG_${valueChain}_tab`);
	}
	return Properties.getDictionary(key, `CCNG_${valueChain}_tab`);
}

export default AppStore.withStores(CCNGContributors);