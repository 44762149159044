import { AppStore } from "store";
import { AppStoreProps } from "store/app/app.store";
import { BoxAndLabel } from "components/molecules/Legend/BoxAndLabel/BoxAndLabel";
import { CostMarginFilters } from "./components/CostMarginFilter";
import { CostMarginResult } from "./type/CostMarginResult";
import { LegendContainer } from "components/organisms/LegendContainer/LegendContainer";
import { LNGCostType } from "./type/LNGCostType";
import { DropdownItemType } from "components/organisms/Dropdown/Dropdown";
import { PopupBox } from "components/molecules/PopupBox/PopupBox";
import { Properties } from "helpers/properties";
import { SegmentBreakdown } from "./type/CostMarginSubSegmentResult";
import React from "react"
import { BuildupChart, HeaderSubtitle, StickyElement } from "components/molecules";
import { LNGStore, LNGStoreProps } from "store/lng/lng.store";
import { LNGUtil, Util } from "helpers/util/util";
import { LoadChartData, LoadSubSegment, LoadYears } from "./CostMargin.service";
import { useEffect, useReducer } from "react"
import "./CostMargin.scss";

type Props = AppStoreProps & LNGStoreProps;

type State = {
	subsegment: string;
	ctsComponent: string;
	loading: boolean;
	cost: LNGCostType;
	data: CostMarginResult | null;
	yearList: Array<DropdownItemType>;
	subsegments: Array<SegmentBreakdown> | null;
}

/**
 * This should always return new state.
 * **/
function reducer(state: State, action: { type: string, data?: any, key?: string, [key: string]: any }) {
	switch (action.type) {
		case "UPDATE_FILTER":
			if (!action["key"]) {
				throw new Error("You must pass 'key' in action for 'UPDATE_FILTER' to work");
			}
			if (!action["data"]) {
				throw new Error("You must pass 'data' in action for 'UPDATE_FILTER' to work");
			}

			return {
				...state, [action.key]: action.data.id
			};

		case "UPDATE_LOADING":
			return { ...state, loading: action.data };

		case "UPDATE_SUBSEGMENT":
			if (!action["data"]) {
				throw new Error("You must pass 'data' in action for 'UPDATE_SUBSEGMENT' to work");
			}
			return {
				...state, subsegments: action.data as Array<SegmentBreakdown>
			};
		case "UPDATE_CHART_DATA":
			if (!action["data"]) {
				throw new Error("You must pass 'data' in action for 'UPDATE_CHART_DATA' to work");
			}
			return {
				...state, data: action.data as CostMarginResult
			};

		default:
			throw new Error();
	}
}

/*
	FUNCTION NAME: CostMarginPage
	DESCRIPTION: The function that renders the LNG Cost Margin Buildup screen
*/
let buildUpChart;
function CostMarginPage({ filters, lng, dictionary }: Props) {
	const [state, dispatch] = useReducer(reducer, {
		data: null,
		subsegment: "All",
		ctsComponent: "All",
		cost: "all",
		subsegments: [],
		yearList: [],
		loading: true,
	});

	/**
		 * Toggle hideMarginBar to show and hide margin from build up chart
		 * **/
	const hideMarginBar = false;
	const showBy = filters.get("showBy");
	const dictionaryList = dictionary.get("dictionary");
	const currency = lng.get("currency");
	const period = filters.get("period");
	const month = filters.get("month");
	const quarter = filters.get("quarter");
	const year = filters.get("year");
	const currencySymbol = lng.get("currency").currencySymbol;
	const profitability = lng.get("profitability");
	const segment = lng.get("segment");
	const valueChain = lng.get("valueChain");
	const costMarginPopupArray = state.data ? [
		{ label: "JCC", onScreen: Util.formatDigits(state.data?.totalCostWithJCC, "", 0), comparedData: state.data?.totalCost },
		{ label: "JCC", onScreen: Util.formatDigits(state.data?.totalRevenueWithJCC, "", 0), comparedData: state.data?.totalRevenue },
		{ label: "Japan Spot", onScreen: state.data?.JKM, comparedData: state.data?.JKM }
	] : undefined;

	costMarginPopupArray?.sort((a, b) => (a.comparedData < b.comparedData) ? 1 : -1);

	const ESTIMATED_APP_HEADER_HEIGHT = 2.9125;

	const handleFilterChange = (key: string, value: any) => {
		switch (key) {
			case "profitability":
			case "segment":
			case "valueChain":
				lng.set(key)(value.id);
				break;

			case "showBy":
			case "period":
			case "month":
			case "quarter":
			case "year":
				// this will trigger re-render too but the values are global to component.
				filters.set(key)(value.id);
				break;
			default:
				// this will trigger re-render too but the values are local to component.
				dispatch({ type: "UPDATE_FILTER", key: key, data: value });
		}
	};
	const toggleLoadingChange = (value: boolean) => dispatch({ type: "UPDATE_LOADING", data: value });
	const setSubsegment = (value: any) => {
		dispatch({ type: "UPDATE_SUBSEGMENT", data: value });
	}

	const setChartData = (value: any) => {
		dispatch({ type: "UPDATE_CHART_DATA", data: value });
		toggleLoadingChange(false);
	}


	const getAPIParams = () => {
		const isQuarterly = period === "quarterly";
		const isMonthly = period === "monthly";

		return {
			currency: currency.name,
			showBy: showBy,
			year: year,
			periodType: period,
			period: (isQuarterly && quarter) || (isMonthly && month) || `ytd-${month}`,
			profitability: profitability,
			valueChain: valueChain
		};
	}

	const _loadChart = (
		data: any,
		create: boolean,
		currentSegment: string,
		subsegment: string,
		hideMargin: boolean,
		updateViewOnly?: boolean,
	) => {
		const hideSegmentMargin =
			currentSegment === "all" && subsegment === "all";

		if (!updateViewOnly) {
			if (create) {
				buildUpChart = new window["BuildUpChart"](
					".build-up-chart",
					data,
					"segments",
					600,
					currencySymbol,
					hideMargin,
					hideSegmentMargin,
					"LNG"
				);
				if (buildUpChart.data) {
					buildUpChart.init();
				}
			} else {
				buildUpChart.update(
					data,
					currencySymbol,
					hideMargin,
					hideSegmentMargin,
					showBy
				);
			}
		} else {
			buildUpChart.update(
				data,
				currencySymbol,
				hideMargin,
				hideSegmentMargin,
				showBy
			);
		}
	}

	// Do not show the Japan spot label in the popup if it's in absolute view
	const showPopupItem = costMarginPopupArray?.map((element, index) =>
		<React.Fragment key={index}>
			{showBy === "absolute" && element.label === "Japan Spot" ? "" : <tr key={index}>
				<td>{element.label}</td>
				<td className="marketIndex-row">
					{/* This is to render the super currency based on Japan Spot and show the % if its present */}
					{element.label === "Japan Spot" ? <span className="super-currency">{currency.currencySymbol === "MYR" ? "MYR" : "USD"}</span> : ""}
					<span className="value">{element.onScreen} {element.label === "Japan Spot" ? "" : "%"}</span>
				</td>
			</tr>}
		</React.Fragment >
	)

	useEffect(() => {
		LoadSubSegment(valueChain.split("-")[0]).then((data: Array<SegmentBreakdown>) => {
			setSubsegment(data);
		});
		_loadChart(null, true, segment.toLowerCase(), state.subsegment.toLowerCase(), hideMarginBar);
		// eslint-disable-next-line
	}, [valueChain])

	React.useEffect(() => {
		LoadYears().then((data) => {
			handleFilterChange("yearList", { id: LNGUtil.loadYears(data) });
		});
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		const params = getAPIParams();
		toggleLoadingChange(true);

		// Adding values
		if (state.cost.toLowerCase() !== "all") {
			params["cost"] = state.cost.toLowerCase();
		}

		if (state.ctsComponent.toLowerCase() !== "all") {
			params["cts"] = state.ctsComponent.toLowerCase();
		}


		LoadChartData(segment.toLowerCase(), state.subsegment.toLowerCase(), profitability, params)
			.then((data: CostMarginResult) => {
				setChartData(data);
				_loadChart(data, false, segment.toLowerCase(), state.subsegment.toLowerCase(), hideMarginBar);
			})
		// eslint-disable-next-line
	}, [period, month, quarter, year, segment, state.subsegment, profitability, state.ctsComponent, state.cost, hideMarginBar, showBy, valueChain])


	const currentSubsegment = LNGUtil.loadCurrentSubSegment(segment, state.subsegments);

	// If list is empty.
	if (currentSubsegment.length === 0) {
		currentSubsegment.push({
			id: "all",
			label: "All"
		})
	}

	return (
		<section className="cost-margin">
			<HeaderSubtitle heading="Integrated Cost/Margin Build Up" subtitle={Properties.getDictionaryText(dictionaryList, "LNG_cost_margin_sub_header", "en")} className="cost-margin-header-with-subtitles" />
			<StickyElement to="top" floatBy={ESTIMATED_APP_HEADER_HEIGHT} className="cost-margin-filters" stickyClassName="sticky">
				<CostMarginFilters
					onEvent={handleFilterChange}
					period={period}
					month={month}
					quarter={quarter}
					year={year}
					yearList={state.yearList}
					showBy={showBy}
					currency={currency}
					hideMonth={period === "quarterly"}
					hideQuarter={period !== "quarterly"}
					segment={segment}
					valueChain={valueChain}
					subsegment={state.subsegment}
					profitability={profitability}
					ctsComponent={state.ctsComponent}
					cost={state.cost}
					currentSubsegmentList={currentSubsegment}
					disableSubsegment={segment === "all"}
					stickyClassName="cost-margin-filters" //This stickyClassName is meant for the JS for scrolling
				/>
			</StickyElement>

			<div className="cost-margin__filter-chart">
				{/* Need this new class to make it flex grow */}
				<BuildupChart loading={state.loading} hasData={(state.data && state.data.segments?.length > 0) || false} className="cost-margin-buildup-chart-parent">
					<LegendContainer className="buildup-chart-legends">
						<LegendContainer className="unit-margin__legends">
							<BoxAndLabel label="Cost" boxColor="teal" box="box" />
							{!hideMarginBar && (
								<BoxAndLabel label="Margin" boxColor="sand" box="box" />
							)}
							<BoxAndLabel
								label={
									showBy === "absolute"
										? "Revenue"
										: "WAP"
								}
								boxColor="twilight"
								box="box"
							/>
							{state.data && <>
								<BoxAndLabel
									label={`JCC: ${Util.formatDigits(state.data.totalCostWithJCC, "", 0)}%`}
									boxColor="teal"
									box="line"
								/>

								{showBy !== "absolute" && <BoxAndLabel
									label={`Japan Spot: ${Properties.getCurrencySymbol(state.data.currency)}${state.data.JKM}`}
									boxColor="sand"
									box="line"
								/>}
								<BoxAndLabel
									label={`JCC: ${Util.formatDigits(state.data.totalRevenueWithJCC, "", 0)}%`}
									boxColor="twilight"
									box="line"
								/>
							</>}

						</LegendContainer>
					</LegendContainer>
					<PopupBox id="buildupchart_Selected-details" className="--hidden" />
					{state.data !== null ?
						<div id="lng__cost__margin__market-index">
							<table >
								<thead>
									<tr>
										<th colSpan={3}>Market Index</th>
									</tr>
								</thead>
								<tbody>
									{showPopupItem}
								</tbody>
							</table>
							<i className="icon icon-close"></i>
						</div> : <></>
					}
				</BuildupChart>
			</div>
		</section>
	);
}

export default AppStore.withStores(LNGStore.withStores(CostMarginPage));
