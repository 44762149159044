import React from "react";
import ReactDOM from "react-dom";
import AdalConfig from "./config/AdalConfig";
import AuthContext from "./service/Auth";
import App from "pages/app";
import "./index.scss";

AuthContext.handleWindowCallback();
// Extra callback logic, only in the actual application, not in iframes in the app
if (
	window === window.parent &&
	window === window.top &&
	!AuthContext.isCallback(window.location.hash)
) {
	// Having both of these checks is to prevent having a token in localstorage, but no user.
	if (
		!AuthContext.getCachedToken(AdalConfig.clientId) ||
		!AuthContext.getCachedUser()
	) {
		AuthContext.login();
	} else {
		AuthContext.acquireToken(AdalConfig.endpoints.api, async (message, token) => {
			if (token) {
				ReactDOM.render(<App />, document.getElementById("root"));
			}
		});
	}
}
