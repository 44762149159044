import { ComparativeType } from "types/ComparativeType";
import { CurrencyType } from "types/CurrencyType";
import { DropdownItemType } from "components/organisms/Dropdown/Dropdown";
import { MonthType } from "types/MonthType";
import { PeriodType } from "types/PeriodType";
import { Properties } from "helpers/properties";
import { QuarterType } from "types/QuarterType";
import React from "react"
import { ShowByType } from "types/ShowByType";
import { CCNGSegmentType, ProductType } from "types/CCNGtype";
import { Dropdown, ItemGroup, WrapContainer } from "components/molecules";

type Props = {
	onEvent?: (type: string, value: any) => void;
	period?: PeriodType;
	comparative?: ComparativeType;
	month?: MonthType;
	quarter?: QuarterType;
	year?: string;
	yearList: DropdownItemType[];
	periodType: DropdownItemType[];
	segment?: CCNGSegmentType;
	subsegment?: string;
	product?: ProductType;
	productType?: string;
	region: string;
	regionList: DropdownItemType[];
	productTypeList: DropdownItemType[];
	subSegmentList: DropdownItemType[];
	hideMonth?: boolean;
	hideQuarterly?: boolean;
	showBy?: ShowByType | string;
	currency: CurrencyType;
	children?: React.ReactNode;
	stickyClassName: string;
}

/*
	FUNCTION NAME: CCNGVLFilters
	DESCRIPTION: The function that renders all filter dropdowns used in CCNG Value Levers page
*/
export function CCNGVLFilters({ children, onEvent, showBy, currency, hideMonth = false, periodType, region, regionList, comparative, period, month, quarter, year, yearList, segment, subsegment, subSegmentList, product, productType, productTypeList, hideQuarterly = false, stickyClassName }: Props) {
	const handleOnEvent = (key: string, value: any) => {
		if (!onEvent) return;
		onEvent(key, value)
	}
	const unit = product === "Natural Gas" ? "/MMBtu" : "/BOE";

	return (
		<WrapContainer className="vl-filters-container" stickyClassName={stickyClassName}>
			<Dropdown
				className="spaced"
				selected={currency.name}
				border
				title="Currency"
				onChange={(value) => handleOnEvent("currency", value)}
				list={[
					{ label: "USD", id: "USD" },
					{ label: "MYR", id: "MYR" }
				]}
			/>
			<Dropdown
				selected={showBy}
				border
				className="spaced"
				title="Show By"
				onChange={(value) => handleOnEvent("showBy", value)}
				list={[
					{
						label: `${currency.currencySymbol}${unit}`,
						id: "mmbtu",
					},
					{
						label: `Absolute(${currency.currencySymbol})`,
						id: "absolute",
					}
				]}
			/>
			<Dropdown
				selected={comparative}
				border
				className="spaced"
				title="Comparative"
				onChange={(value) => handleOnEvent("comparative", value)}
				list={[
					{ label: "Plan", id: "plan" },
					{ label: "Actual - Preceding", id: "actual_preceding" },
					{ label: "Actual - Corresponding", id: "actual_corresponding" }
				]}
			/>
			<Dropdown
				selected={period}
				border
				className="spaced"
				title="Period"
				onChange={(value) => handleOnEvent("period", value)}
				list={periodType}
			/>

			<ItemGroup className="spaced">
				{!hideMonth && <Dropdown
					selected={month}
					border
					title="Month"
					onChange={(value) => handleOnEvent("month", value)}
					list={Properties.getAllMonths().map((v) => {
						return { ...v, id: v.label }
					})}
				/>}


				{!hideQuarterly && <Dropdown
					selected={quarter}
					border
					title="Quarter"
					onChange={(value) => handleOnEvent("quarter", value)}
					list={Properties.getAllQuarters().map((v) => {
						return { ...v, id: v.label }
					})}
				/>}

				{<Dropdown
					selected={year}
					border
					title="Year"
					onChange={(value) => handleOnEvent("year", value)}
					list={yearList}
				/>}
			</ItemGroup>

			<Dropdown
				className="spaced"
				selected={product}
				border
				title="Product"
				onChange={(value) => handleOnEvent("product", value)}
				list={[
					{ label: "All", id: "all" },
					{ label: "Crude", id: "Crude" },
					{ label: "Condensate", id: "Condensate" },
					{ label: "Natural Gas", id: "Natural Gas" }
				]}
			/>
			<Dropdown
				className="spaced"
				selected={productType}
				border
				title="Product Type"
				onChange={(value) => handleOnEvent("productType", value)}
				list={productTypeList}
				disabled={productTypeList.length === 1}
			/>
			<Dropdown
				border
				selected={region}
				className="spaced"
				title="Region"
				onChange={(value) => handleOnEvent("region", value)}
				list={regionList}
			/>
			<Dropdown
				className="spaced"
				selected={segment}
				border
				title="Segment"
				onChange={(value) => handleOnEvent("segment", value)}
				list={[
					{ label: "All", id: "all" },
					{ label: "Carigali Group", id: "carigaliGroup" },
					{ label: "MPM", id: "mpm" },
					{ label: "Marketing", id: "marketing" }
				]}
			/>
			<Dropdown
				className="spaced"
				selected={subsegment}
				border
				title="Sub-Segment"
				onChange={(value) => handleOnEvent("subsegments", value)}
				list={subSegmentList}
			/>
			{children}
		</WrapContainer>
	)
}