import React from "react"
import classNames from "classnames";
import "./HeaderSubtitle.scss";
import { Typography } from "components/molecules";

type Props = {
	className?: string;
	heading: string;
	subtitle: string;
}

export function HeaderSubtitle({ className, heading, subtitle }: Props) {
	return (
		<div className={classNames("header-subtitle", className)}>
			<Typography.Heading1 className={"heading"} weight={500} color={"Grey"}>{heading}</Typography.Heading1>
			<Typography.TextNormal color={"Silver"} weight={500}>{subtitle}</Typography.TextNormal>
		</div>
	)
}
