import React from "react";
import classNames from "classnames";
import { Icon, Typography } from "components/molecules";
import { ButtonsProps } from "./Buttons";
import "./Buttons.scss";

type Props = {
	fullWidth?: boolean;
	rounded?: boolean;
	background?: string | "transparent";
	ghost?: boolean;
} & ButtonsProps


export function NormalButton({ children, className, color = "Teal", background = "transparent", icon, fullWidth, ghost, rounded, disabled, onClick, renderText, iconClassName }: Props) {

	let applyColor = ghost ? background : color;
	let applyBGColor = ghost ? "transparent" : background;
	let applyBorderColor = ghost ? background : "transparent";

	if (disabled) {
		applyBGColor = ghost ? "transparent" : "MediumGrey";
		applyColor = ghost ? "MediumGrey" : "White";
		applyBorderColor = ghost ? "MediumGrey" : applyBorderColor;
	}

	return (
		<button
			className={classNames("normal-button", { "block": fullWidth, "rounded": rounded, "disabled": disabled }, className,
				`color-${applyColor}`, `bg-color-${applyBGColor}`, `border-color-${applyBorderColor}`, background === "transparent" ? "padding-0" : "padding-2")}
			onClick={onClick}
		>
			{icon && <Icon
				name={icon}
				size="medium"
				color={!disabled ? color : "White"}
				className={classNames("icon-color", iconClassName)}
			/>}
			{renderText ? renderText(children) : <Typography.ExtraLargeText
				weight={900}
				color={disabled ? "White" : color}
				uppercase>{children}</Typography.ExtraLargeText>}
		</button>
	)
}
