import React from "react";
import classNames from "classnames";
import { isNullOrUndefined } from "util";
import { ClickOutsideBoundary, Typography, Icon } from "components/molecules";
import { DropDownListItem } from "components/molecules/Dropdown/DropdownListItem";
import "./Dropdown.scss";

export type DropdownItemType = {
	label: string;
	id: string;
	children?: Array<DropdownItemType>;
};

type Props = {
	title: string;
	list: Array<DropdownItemType>;
	className?: string;
	rowClassName?: string;
	optionsClassName?: string;
	buttonClassName?: string;
	expandedClassName?: string;
	border?: boolean;
	rounded?: boolean;
	disabled?: boolean;
	// To set selected pass the id of item you wish to select.
	selected?: string;
	onChange?: (item: DropdownItemType) => void;
};
/*
	FUNCTION NAME: Dropdown
	DESCRIPTION: The function that creates the UI for Dropdown component used on all screens for IVCI
*/
export function Dropdown({ list, className, rowClassName, buttonClassName, optionsClassName, title, border = false, onChange, selected = "", disabled, rounded = true, expandedClassName }: Props) {
	const [open, setOpen] = React.useState(false);

	const toggleDropDown = () => {
		list.length > 0 && setOpen(!open);
	};

	const findId = (searchList: Array<DropdownItemType>, toSearch: string) => {

		const flattenList = (arr: Array<DropdownItemType>, searchId: string) => {
			return arr.reduce((reducedArray: Array<DropdownItemType>, nextItem: DropdownItemType) => {
				if (nextItem[searchId]) return [...reducedArray, { id: nextItem.id, label: nextItem.label }, ...flattenList(nextItem[searchId], searchId)]
				else return reducedArray.concat([nextItem])
			}, []);
		}

		// First flat the list and get all children from the nested list
		const flatList = flattenList(searchList, "children");

		// Now search
		return flatList.find((i) => {
			return i.id === toSearch
		})
	}

	let selectedItem: DropdownItemType = findId(list, selected);

	// Used this to select default selected item
	if (isNullOrUndefined(selectedItem)) {
		// If list exists then choose first item by default
		if (list?.length > 0) {
			if (window.location.href.includes("srmc")) {
				selectedItem = list[1];
			} else {
				selectedItem = list[0];
			}
		} else {
			// Else create a dummy item.
			// Discussed it with backend already. They will handle it gracefully.
			selectedItem = {
				id: "all",
				label: "All"
			}
		}
	}

	const dropdown = React.useRef(null);
	return (
		<ClickOutsideBoundary connectTo={dropdown} listen={open} onOutClick={(e) => toggleDropDown()} >
			<div ref={dropdown} className={classNames("dropdown", className, { "bordered": border }, { "rounded": rounded })}>
				<button
					className={classNames("dropdown__button", buttonClassName, { [expandedClassName || "menuExpanded"]: open }, { "disabled": disabled })}
					type="button"
					disabled={disabled}
					onClick={toggleDropDown}
				>
					<Typography.Heading6 className="dropdown__button__heading" color={"Silver"} weight={900} uppercase>{title}</Typography.Heading6>
					<div className="dropdown__button__headingBottom">
						<Typography.Heading5 className="dropdown__button__headingBottom__label">{selectedItem.label}</Typography.Heading5>
						{!disabled && <Icon name="chevron" className="icon-chevron" />}
					</div>
				</button>

				<div className="dropdown__options">
					<ul
						className={classNames(
							"dropdown__options__menu",
							"dropdown__options__menuParent",
							optionsClassName,
							{ "hidden": !open }
						)}
					>
						{list.map((item, index) => {
							return <DropDownListItem
								key={index}
								item={item}
								onSelect={(value) => {
									onChange && onChange(value);
									toggleDropDown();
								}}
								rowClassName={rowClassName}
								selected={selectedItem.id}
							/>
						})}
					</ul>
				</div>
			</div>
		</ClickOutsideBoundary >
	);
}