import { RouteType } from "types/RouteType";
import Unauthorised from "./Unauthorised/Unauthorised";
import Maintenance from "./Maintenance/Maintenance";
import LNGHome from "./LNG/LNGHome";
import CCNGHome from "./CCNG/CCNGHome";

export default [
	{
		label: "CCNG",
		header: "Crude Condensate Natural Gas",
		icon: "psc",
		path: "/ccng",
		component: CCNGHome,
	},
	{
		label: "LNG",
		header: "Liquified Natural Gas",
		icon: "lng-ship",
		path: "/lng",
		component: LNGHome,
	},
	{
		label: "No Access",
		path: "/401",
		hidden: true,
		component: Unauthorised
	},
	{
		label: "Maintenance",
		path: "/503",
		hidden: true,
		component: Maintenance
	}
] as Array<RouteType>
