import { AppStore } from "store"
import { AppStoreProps } from "store/app/app.store";
import { BoxAndLabel } from "components/molecules/Legend/BoxAndLabel/BoxAndLabel";
import classNames from "classnames";
import { ContributorsType } from "./types/ContributorsType";
import { CTFilters } from "./components/CTFilters";
import { getTrendsData, LoadYears } from "./ContributionTrends.service";
import { isNullOrUndefined } from "util";
import { DropdownItemType } from "components/organisms/Dropdown/Dropdown";
import { LegendContainer } from "components/organisms/LegendContainer/LegendContainer";
import { Properties } from "helpers/properties";
import React from "react"
import ReactDOM from "react-dom";
import { LNGUtil, Util } from "helpers/util/util";
import { ContributionTrendResult, ContributorBreakdown } from "./types/ContributionTrendResult";
import { FixedColumnTable, HeaderSubtitle, Icon, StickyElement, TrailChart } from "components/molecules";
import { LNGStore, LNGStoreProps } from "store/lng/lng.store";
import "./ContributionTrends.scss";

type Props = AppStoreProps & LNGStoreProps;

type State = {
	contributor: ContributorsType;
	sector: string;
	trailLength: string;
	showTrend: boolean;
	showLegend: boolean;
	loading: boolean;
	yearList: Array<DropdownItemType>;
	trends: ContributionTrendResult | null;
	// Set this when user clicks on line to show popup.
	trailInformationPopup?: {
		itemno: string;
		children: string;
		name: string;
		color: string;
	};
}

/**
 * This should always return new state.
 * **/
function reducer(state: State, action: { type: string, data?: any, key?: string, [key: string]: any }) {
	switch (action.type) {
		case "UPDATE_FILTER":
			if (isNullOrUndefined(action["key"])) {
				throw new Error("You must pass 'key' in action for 'UPDATE_FILTER' to work");
			}
			if (isNullOrUndefined(action["data"])) {
				throw new Error("You must pass 'data' in action for 'UPDATE_FILTER' to work");
			}
			return { ...state, [action.key]: action.data };
		case "UPDATE_LOADING":
			return { ...state, loading: action.data };
		case "UPDATE_TRENDS_DATA":
			return { ...state, trends: action.data as ContributionTrendResult };
		case "UPDATE_INFORMATION_POPUP":
			return { ...state, trailInformationPopup: action.data };
		default:
			throw new Error();
	}
}

/*
	FUNCTION NAME: ContributionTrends
	DESCRIPTION: The function that renders LNG Contribution Trend screen
*/
let contributionTrendChart;
function ContributionTrends({ filters, lng, dictionary }: Props) {
	const showBy = "mmbtu"; // showBy is removed in here as it is forced to only show mmbtu. it is decoupled from the global filter of showby
	const currency = lng.get("currency");
	const period = filters.get("period");
	const quarter = filters.get("quarter");
	const month = filters.get("month");
	const year = filters.get("year");
	const ESTIMATED_APP_HEADER_HEIGHT = 2.9125;
	const [state, dispatch] = React.useReducer(reducer, {
		contributor: "sectors",
		sector: "all",
		yearList: [],
		trailLength: Properties.getSegmentLabelContributionTrend(period),
		showTrend: false,
		showLegend: false,
		loading: true,
		trends: null,
		trailInformationPopup: false,
	});
	const handleTrailPopupOpen = (event: CustomEventInit) => { dispatch({ type: "UPDATE_INFORMATION_POPUP", data: event.detail }) }
	const handleTrailPopupClose = () => { dispatch({ type: "UPDATE_INFORMATION_POPUP", data: null }) }
	const handleFilterChange = (key: string, value: any) => {
		switch (key) {
			case "period":
				dispatch({ type: "UPDATE_FILTER", key: "trailLength", data: Properties.getSegmentLabelContributionTrend(value.id) });
			// Do not add a break statement over here
			// Custom logic for this portion here
			// So that when period changes, trailLength changes based on period ^^^
			// eslint-disable-next-line
			case "month":
			case "quarter":
			case "year":
				// this will trigger re-render too but the values are global to component.
				filters.set(key)(value.id);
				break;
			default:
				// this will trigger re-render too but the values are local to component.
				dispatch({ type: "UPDATE_FILTER", key: key, data: value.id || value });
		}
	};
	const toggleLoadingChange = (value: boolean) => dispatch({ type: "UPDATE_LOADING", data: value });
	const handleContributionsChange = (data: ContributionTrendResult) => {
		dispatch({ type: "UPDATE_TRENDS_DATA", data: data })
		toggleLoadingChange(false);
	}
	const getAPIParams = () => {
		const isQuarterly = period === "quarterly";
		const isMonthly = period === "monthly";

		return {
			currency: currency.name,
			periodType: period,
			period: (isQuarterly && quarter) || (isMonthly && month) || `ytd-${month}`,
			year: year,
			showBy: showBy,
		};
	}

	const initChart = (data: ContributionTrendResult) => {
		contributionTrendChart = new window["ContributionTrendChart"](
			".trail-chart",
			data,
			600
		);
		contributionTrendChart.init();
		contributionTrendChart.setSectorNames(
			Properties.getSectorsForContribution()
		);
	}

	const updateChart = (dataUpdate?: boolean) => {
		if (isNullOrUndefined(contributionTrendChart)) return;

		const filtersParams = {
			...state,
			reportType: state.contributor,
			contractType: "all",
			segments: state.trailLength,
		};

		if (dataUpdate) {
			contributionTrendChart.update(filtersParams, state.trends);
		} else {
			contributionTrendChart.update(filtersParams);
		}
	}

	// Run this to update the API.
	React.useEffect(() => {
		const params = getAPIParams();
		toggleLoadingChange(true);
		getTrendsData(params)
			.then((data) => handleContributionsChange(data))
		// eslint-disable-next-line
	}, [showBy, currency, period, month, quarter, year])


	React.useEffect(() => {
		return () => {
			// Clearing up the contributionTrendChart upon leaving the page so that it would reload the chart back into the page
			contributionTrendChart = null;
		};
	}, [])

	React.useEffect(() => {
		LoadYears().then((data) => {
			handleFilterChange("yearList", { id: LNGUtil.loadYears(data) });
		});
		// eslint-disable-next-line
	}, [])

	// Run this to init
	React.useEffect(() => {
		// If there's no data, just ignore this effect.
		if (isNullOrUndefined(state.trends)) {
			return;
		}

		document.addEventListener("onTrailClick", handleTrailPopupOpen);

		// If 'contributionTrendChart' variable is null then just init the chart else update it with data.
		if (isNullOrUndefined(contributionTrendChart)) {
			initChart(state.trends);
		} else if (!isNullOrUndefined(state.trends)) {
			updateChart(true)
		}

		return () => {
			document.removeEventListener("onTrailClick", handleTrailPopupOpen);
		}
		// eslint-disable-next-line
	}, [state.trends])

	// Update params but don't re-render entire thing because data stays the same..
	React.useEffect(() => {
		if (!isNullOrUndefined(state.trends))
			updateChart(false);
		// eslint-disable-next-line
	}, [state.sector, state.contributor, state.showLegend, state.showTrend, state.trailLength])

	const processTabledata = (dataArr, header, tabledata, monthArray, text) => {
		if (dataArr && dataArr.length) {
			const tabledataMargin = { contractType: "Margin" };
			const tabledataPrice = { contractType: "Price" };
			const tabledataVoulume = { contractType: "Volume" };
			let count = 0;
			for (let j = 0; j < dataArr.length; j++) {
				if (j <= parseInt(state.trailLength)) {
					const currentMonth = dataArr[j].period;
					const currentYear = dataArr[j].year;
					const id = currentMonth.toLowerCase() + currentYear;
					const periodYear = `${currentMonth} ${currentYear}`;
					const label = `${currentMonth.substring(0, 3).toUpperCase()} ${currentYear}`;
					if (!header.find((ele) => id === ele.id))
						header.push({ label: label, id: id, periodYear: periodYear });

					tabledataMargin[id] = Util.formatDigits(dataArr[j].margin, showBy);

					tabledataPrice[id] = Util.formatDigits(dataArr[j].price, showBy);

					tabledataVoulume[id] = Util.formatDigits(dataArr[j].volume, showBy);
					count++;
					//check next month and year and add values
					if (
						j < dataArr.length - 1 &&
						j !== parseInt(state.trailLength)
					) {
						const previousMonth = dataArr[j + 1].period;
						let difference = 1;
						let currentMonthIndex = 0;
						let previousMonthIndex = 1;
						for (let i = 0; i < monthArray.length; i++) {
							if (monthArray[i] === currentMonth) {
								currentMonthIndex = i;
							}
							if (monthArray[i] === previousMonth) {
								previousMonthIndex = i;
							}
						}
						difference = previousMonthIndex - currentMonthIndex;
						if (Math.abs(difference) !== 1) {
							const gap =
								difference > 0
									? difference - 1
									: monthArray.length - 1 - Math.abs(difference);
							let gapMonthIndex = currentMonthIndex;
							let gapYear = currentYear;
							for (let i = 0; i < gap; i++) {
								gapMonthIndex++;
								gapYear =
									gapMonthIndex < monthArray.length ? gapYear : gapYear - 1;
								gapMonthIndex =
									gapMonthIndex < monthArray.length ? gapMonthIndex : 0;
								const gapMonth = monthArray[gapMonthIndex];
								const gapId = gapMonth.toLowerCase() + gapYear;
								const gapPeriod = `${gapMonth} ${gapYear}`;
								const gapLabel = `${gapMonth.substring(0, 3).toUpperCase()} ${gapYear}`;
								if (!header.find((ele) => gapId === ele.id))
									header.push({ label: gapLabel, id: gapId, periodYear: gapPeriod });
								tabledataMargin[gapId] = "";
								tabledataPrice[gapId] = "";
								tabledataVoulume[gapId] = "";
								count++;
							}
						}
					}
					// This is added to splice the array regardless of how much data there is according to the trail length
					// + 2 is because splice doesnt start from 0. it starts from 1. and compensating the additional trail + 1 business logic
					// would therefore end up with +2
					header.splice(state.trailLength + 2);
				}
			}
			if (
				monthArray.length &&
				count < parseInt(state.trailLength)
			) {
				let lastMonthIndex = 0;
				let lastYear = dataArr[dataArr.length - 1].year;
				for (let i = 0; i < monthArray.length; i++) {
					if (
						monthArray[i] === dataArr[dataArr.length - 1].period
					) {
						lastMonthIndex = i;
						break;
					}
				}
				for (
					let i = count;
					i < parseInt(state.trailLength);
					i++
				) {
					lastMonthIndex++;
					lastYear = lastMonthIndex < monthArray.length ? lastYear : lastYear - 1;
					lastMonthIndex =
						lastMonthIndex < monthArray.length ? lastMonthIndex : 0;
					const gapMonth = monthArray[lastMonthIndex];
					const gapId = gapMonth.toLowerCase() + lastYear;
					const gapPeriod = `${gapMonth} ${lastYear}`;
					const gapLabel = `${gapMonth.substring(0, 3).toUpperCase()} ${lastYear}`;
					if (!header.find((ele) => gapId === ele.id))
						header.push({ label: gapLabel, id: gapId, periodYear: gapPeriod });
					tabledataMargin[gapId] = "";
					tabledataPrice[gapId] = "";
					tabledataVoulume[gapId] = "";
				}
			}
			const childItemTableData = [
				tabledataMargin,
				tabledataPrice,
				tabledataVoulume
			];

			// tabledata[1]["child"] = childTermTableData;
			if (childItemTableData.length) {
				tabledata.push({ contractType: text, child: childItemTableData });
			}
		}

	}

	const processDataForPopup = (data: ContributorBreakdown) => {
		const header = [
			{
				label: "Type",
				id: "contractType",
				periodYear: "period Year"
			}
		];
		const monthArrayInitial =
			period === "monthly"
				? Properties.get("months")
				: period === "quarterly"
					? Properties.get("quarters")
					: ["YTD"];
		const tabledata: any = [];

		// this will create new ref in pointer...
		const monthArray = [...monthArrayInitial];
		monthArray.reverse()

		processTabledata(data.all, header, tabledata, monthArray, "Average");
		processTabledata(data.spot, header, tabledata, monthArray, "Spot");
		processTabledata(data.term, header, tabledata, monthArray, "Term");

		header.sort((a, b) => {
			if (a.label === "Type" || b.label === "Type") { return 0; }
			const monthYearA = a.periodYear.split(" ");
			const monthA = monthYearA[0];
			const yearA = monthYearA[1];
			const monthYearB = b.periodYear.split(" ");
			const monthB = monthYearB[0];
			const yearB = monthYearB[1];

			if (yearA > yearB) {
				return -1
			}
			if (yearA < yearB) {
				return 1;
			}
			if (yearB === yearA) {
				if (monthArray.indexOf(monthA) > monthArray.indexOf(monthB)) {
					return 1;
				}
				if (monthArray.indexOf(monthA) < monthArray.indexOf(monthB)) {
					return -1;
				}
			}
			return 0;
		});
		return {
			tabledata, header
		}
	}

	// This function will only run when state.showInfoPopup is true
	const renderPopupPortal = () => {
		if (!state.trends) return;
		if (!state.contributor) return;
		if (!state.trends.contributors) return;
		if (!state.trends.contributors[state.contributor]) return;

		const reportData = state.trends.contributors[state.contributor];

		if (!reportData) return;

		const index = reportData.findIndex((element) => {
			return element.name === state.trailInformationPopup.name;
		});

		const chartViewMetadata = processDataForPopup(reportData[index]);
		const tableHeading = Properties.getSectorsForContribution()[state.trailInformationPopup.name] || state.trailInformationPopup.name;
		// Recommended: Use Portal API provided by React to render on any specific dom tree location.
		// Best suited for popups/alerts/modals
		const popup = <div
			className={classNames(
				"contributor-popup",
				"show-popup"
			)}
		>
			<>
				<div className="contributor-popup__mask"
					onClick={handleTrailPopupClose}
				/>
				<div className="contributor-popup__content">
					<div
						className="contributor-popup__content__details"
					>
						<div className="contributor-popup__content__details__table">
							<Icon
								name="close"
								size="extra-large"
								onClick={handleTrailPopupClose}
							/>
							<h2>{tableHeading}</h2>
						</div>
						<FixedColumnTable
							heading={tableHeading}
							header={chartViewMetadata.header}
							data={chartViewMetadata.tabledata}
							childNodeName="child"
							fixedColumnId="contractType"
							expandCoumnNumber={0}
						/>
					</div>
				</div>

			</>
		</div>;
		const renderLocation = document.getElementById("root")!;
		return ReactDOM.createPortal(popup, renderLocation);
	}

	return (
		<>
			<section className="homepage__article">
				<HeaderSubtitle heading="Contributor Performance" subtitle={Properties.getDictionaryText(dictionary.get("dictionary"), `LNG_contribution_trend_subheader`, "en")} className="contribution-trends-header-with-subtitles" />
				<StickyElement to="top" floatBy={ESTIMATED_APP_HEADER_HEIGHT} className="contribution-trends-filters" stickyClassName="sticky">
					<CTFilters
						contributor={state.contributor}
						period={period}
						month={month}
						quarter={quarter}
						year={year}
						yearList={state.yearList}
						sector={state.sector}
						trailLength={state.trailLength}
						showTrend={state.showTrend}
						showBy={showBy}
						currency={currency}
						showLegend={state.showLegend}
						hideMonth={period !== "monthly" && period !== "ytd"}
						hideQuarter={period !== "quarterly"}
						stickyClassName="contribution-trends-filters" //This stickyClassName is meant for the JS for scrolling
						onEvent={handleFilterChange} />
				</StickyElement>

				<div className="homepage__article__segment">
					<div className="contribution-page trailchart__filter-chart">
						<TrailChart loading={state.loading} hasData={(state.trends?.contributors?.sectors && state.trends?.contributors.sectors.length > 0) || false}>
							<LegendContainer className="contribution-page__legends">
								<BoxAndLabel label="Southeast Asia" boxColor="twilight" box="box" />
								<BoxAndLabel label="Middle-east" boxColor="sand" box="box" />
								<BoxAndLabel label="Korean, Taiwan China" boxColor="darkaqua" box="box" />
								<BoxAndLabel label="Japan" boxColor="cranberry" box="box" />
								<BoxAndLabel label="West of Suez" boxColor="cobalt" box="box" />
							</LegendContainer>
						</TrailChart>
					</div>
				</div>
			</section>
			{state.trailInformationPopup && renderPopupPortal()}
		</>
	)
}

export default AppStore.withStores(LNGStore.withStores(ContributionTrends));

