import classNames from "classnames";
import React from "react";
import { Typography } from "components/molecules";
import "./Legend.scss";

interface Props {
	year: string;
	children: React.ReactNode | React.ReactNodeArray;
	className?: string;
}

export function Legend(props: Props) {
	const { className, year, children } = props;
	return (
		<div className={classNames("legend", className)}>
			<Typography.Heading6 className="legend__left">{year}</Typography.Heading6>
			<div className="legend__right">{children}</div>
		</div>
	);
}
