import "./Indicator.scss";
import classNames from "classnames";
import { Icon } from "components/molecules";
import React from "react";

interface Props {
	label: string;
	className?: string;
	enclosed?: boolean;
	down?: "down" | "up" | "neutral" | "disabled";
	oppositeTrend?: boolean;
	size?: "small" | "medium" | "large" | "extra-large";
}

export function Indicator(props: Props) {
	return (
		<>
			<span
				className={classNames(
					"indicator",
					props.className,
					props.down,
					props.oppositeTrend && "opp"
				)}
			>
				{props.down !== "neutral" && <Icon name="chevron" size={props.size || "small"} />}
			</span>
			<span className={classNames("indicator variance", props.down,
				props.oppositeTrend && "opp", props.enclosed && "enclosed")}>
				{props.label}
			</span>
		</>
	);
}
