import { AppStore } from "store"
import { AppStoreProps } from "store/app/app.store";
import { GetLNGTrendAnalysisStackedBarChart, LoadYears } from "./TrendAnalysis.service";
import { isNullOrUndefined } from "util";
import { Legend } from "components/organisms/LegendContainer/Legend/Legend";
import { Properties } from "helpers/properties";
import React from "react"
import { TrendAnalysisFilter } from "./components/TrendAnalysisFilter";
import { TrendAnalysisBarType } from "types/TrendAnalysisType";
import { TrendAnalysisResult } from "./types/TrendAnalysisResult";
import { useEffect } from "react"
import { LNGUtil, Util } from "helpers/util/util";
import { LNGStore, LNGStoreProps } from "store/lng/lng.store";
import { DropdownItemType } from "components/organisms/Dropdown/Dropdown";
import { HeaderSubtitle, OverlayPopUp, StickyElement, LegendContainer, BoxAndLabel } from "components/molecules";
import { StackedbarChart } from "components/molecules/StackedbarChart/StackedbarChart";
import "./TrendAnalysis.scss";

type Props = AppStoreProps & LNGStoreProps;
type State = {
	type: TrendAnalysisBarType;
	loading: boolean;
	data: TrendAnalysisResult | null;
	segment: string;
	informationPopupType?: {
		type: "upstream" | "asset" | "lmt" | "period",
		period: string,
		breakdownType: string
	};
	yearList: Array<DropdownItemType>;
}

/**
 * This should always return new state.
 * **/
function reducer(state: State, action: { type: string, data?: any, key?: string, [key: string]: any }) {
	switch (action.type) {
		case "UPDATE_FILTER":
			if (!action["key"]) {
				throw new Error("You must pass 'key' in action for 'UPDATE_FILTER' to work");
			}
			if (!action["data"]) {
				throw new Error("You must pass 'data' in action for 'UPDATE_FILTER' to work");
			}
			return { ...state, [action.key]: action.data };

		case "UPDATE_TREND_ANALYSIS_DATA":
			if (!action["data"]) {
				throw new Error("You must pass 'data' in action for 'UPDATE_TREND_ANALYSIS_DATA' to work");
			}
			return { ...state, data: action.data as TrendAnalysisResult };

		case "UPDATE_LOADING":
			return { ...state, loading: action.data };

		case "UPDATE_INFORMATION_POPUP":
			return {
				...state, informationPopupType: action.data as {
					type: "upstream" | "asset" | "lmt" | "period",
					period: string,
					breakdownType: string
				}
			};
		default:
			throw new Error();
	}
}

/*
	FUNCTION NAME: TrendAnalysis
	DESCRIPTION: The function that renders the LNG Trend Analysis screen
*/
function TrendAnalysis({ filters, dictionary, lng }: Props) {
	const [state, dispatch] = React.useReducer(reducer, {
		type: "margin",
		data: null,
		informationPopupType: undefined,
		loading: true,
		segment: "all",
		yearList: [],
	});

	const showBy = filters.get("showBy");
	const currency = lng.get("currency");
	const year = filters.get("year");
	const dictionaryList = dictionary.get("dictionary");
	const ESTIMATED_APP_HEADER_HEIGHT = 2.9125;
	let period = filters.get("period");
	// CustomLogic: Custom logic here.
	// Since comparative is global to LNG dropdowns on different pages have different value for some reason.
	const comparative = lng.get("comparative") === "plan" ? "plan" : "yoy";
	const profitability = lng.get("profitability");
	const segment = lng.get("segment");
	const valueChain = lng.get("valueChain");

	// CustomLogic: Custom logic here.
	switch (period) {
		case "monthly":
		case "quarterly":
			break;
		default:
			period = "monthly"
	}

	const handleFilterChange = (key: string, value: any) => {
		switch (key) {
			case "comparative":
			case "profitability":
			case "segment":
			case "valueChain":
				lng.set(key)(value.id);
				break
			case "showBy":
			case "year":
			case "period":
				// this will trigger re-render too but the values are global to component.
				filters.set(key)(value.id);
				break;
			default:
				// this will trigger re-render too but the values are local to component.
				dispatch({ type: "UPDATE_FILTER", key: key, data: value.id });
		}
	};
	const toggleLoadingChange = (value: boolean) => dispatch({ type: "UPDATE_LOADING", data: value });
	const handleData = (value: any) => {
		dispatch({ type: "UPDATE_TREND_ANALYSIS_DATA", data: value });
		toggleLoadingChange(false);
	}
	const handleBarPopupOpenClick = (event: CustomEventInit | null) => { dispatch({ type: "UPDATE_INFORMATION_POPUP", data: event?.detail || null }) }

	const renderPopupPortal = () => {
		const typeName: string = state.informationPopupType?.type || "";
		let monthValue;

		if (state.informationPopupType?.breakdownType === "lastYear") {
			monthValue = state.data?.previous.find((x) => x.period === state.informationPopupType?.period);
		} else {
			monthValue = state.data?.actual.find((x) => x.period === state.informationPopupType?.period);
		}

		if (!monthValue) return; //This is for error checks

		let popupHeaderName = typeName;
		switch (state.informationPopupType?.type) {
			case "asset":
				if (state.informationPopupType?.breakdownType === "lastYear") {
					popupHeaderName = `Plan  ${state.informationPopupType?.period} ${year} , Asset`;
				} else {
					popupHeaderName = `${((state.informationPopupType?.breakdownType).charAt(0).toUpperCase() + (state.informationPopupType?.breakdownType).slice(1))}  ${state.informationPopupType?.period}  ${year} , Asset`;
				}
				break
			case "lmt":
				if (state.informationPopupType?.breakdownType === "lastYear") {
					popupHeaderName = `Plan  ${state.informationPopupType?.period}  ${year} ,  LMT`;
				} else {
					popupHeaderName = `${((state.informationPopupType?.breakdownType).charAt(0).toUpperCase() + (state.informationPopupType?.breakdownType).slice(1))}  ${state.informationPopupType?.period}  ${year} ,  LMT`;
				}
				break
			case "upstream":
				if (state.informationPopupType?.breakdownType === "lastYear") {
					popupHeaderName = `Plan  ${state.informationPopupType?.period}  ${year} ,  Upstream`;
				} else {
					popupHeaderName = `${((state.informationPopupType?.breakdownType).charAt(0).toUpperCase() + (state.informationPopupType?.breakdownType).slice(1))}  ${state.informationPopupType?.period}  ${year} ,  Upstream`;
				}
				break
			case "period":
				popupHeaderName = "Period";
				break
			default:
				break

		}
		Util.sortArray(monthValue[typeName]?.subSegment, "margin", "DSC");
		return <OverlayPopUp headerTitle={popupHeaderName} onPopup={handleBarPopupOpenClick}>
			<table cellSpacing={0}
				cellPadding={0} >
				<tbody>
					{monthValue[typeName]?.subSegment?.map((item, i) => <tr key={i}>
						<td className="table__body" >
							{item.name}
						</td>
						<td className="table__body" >
							{Util.formatDigits(item[state.type], showBy)}
						</td>
					</tr>)}
				</tbody>
			</table>
		</OverlayPopUp>
	}


	const _legendLabel = () => {
		if (!state.data) return {
			left: "",
			right: ""
		};

		if (Object.keys(state.data).length) {
			if (comparative === "plan") {
				return {
					left: `Actual ${year}`,
					right: `Plan ${year}`
				};
			} else {
				// eslint-disable-next-line
				if (state.data.previous.length) {
					return {
						left: `Actual ${year}`,
						right: `Actual ${parseInt(year) - 1}`
					};
				} else {
					return { left: "", right: `Actual ${year}` };
				}
			}
		}
		return {
			left: "",
			right: ""
		};
	};

	useEffect(() => {
		toggleLoadingChange(true);
		GetLNGTrendAnalysisStackedBarChart({
			showBy: showBy,
			currency: currency.name,
			comparative: comparative,
			periodType: period,
			year: year,
			profitability: profitability,
			valueChain: valueChain
		}).then((result) => {
			handleData(result);
		});
		// eslint-disable-next-line
	}, [currency, showBy, year, comparative, state.type, period, profitability, valueChain]);

	useEffect(() => {
		dispatch({ type: "UPDATE_FILTER", key: "segment", data: segment });
		// eslint-disable-next-line
	}, [segment])
	// Run this to init
	React.useEffect(() => {
		// If there's no data, just ignore this effect.
		if (isNullOrUndefined(state.data)) {
			return;
		}

		LoadYears().then((data) => {
			handleFilterChange("yearList", { id: LNGUtil.loadYears(data) });
		});

		document.addEventListener("onLineBarClick", handleBarPopupOpenClick);

		// // If 'contributionTrendChart' variable is null then just init the chart else update it with data.
		// if (isNullOrUndefined(contributionTrendChart)) {
		//   initChart(state.trends);
		// } else if (!isNullOrUndefined(state.trends)) {
		//   updateChart(true)
		// }

		return () => {
			document.removeEventListener("onLineBarClick", handleBarPopupOpenClick);
		}
		// eslint-disable-next-line
	}, [state.data])

	const isDataAvailable = (state.data?.actual && state.data?.actual.length > 0) || false;

	return (
		<section className="TrendAnalysis">
			<HeaderSubtitle heading="Trend Analysis" subtitle={Properties.getDictionaryText(dictionaryList, `LNG_trend_analysis_subheader`, "en")} className="TrendAnalysis-header-with-subtitles" />
			<StickyElement to="top" floatBy={ESTIMATED_APP_HEADER_HEIGHT} className="TrendAnalysis-filters" stickyClassName="sticky">
				<TrendAnalysisFilter
					onEvent={handleFilterChange}
					showBy={showBy}
					currency={currency}
					type={state.type}
					year={year}
					yearList={state.yearList}
					period={period}
					comparative={comparative}
					segment={segment}
					valueChain={valueChain}
					profitability={profitability}
					stickyClassName="TrendAnalysis-filters" //This stickyClassName is meant for the JS for scrolling
				/>
			</StickyElement>


			<div className="TrendAnalysis__chart">
				{segment === "all" && isDataAvailable && <p className="label-text">*All the figures in the Trend Analysis are pre-consolidation</p>}
				<StackedbarChart
					loading={state.loading}
					hasData={(state.data?.actual && state.data?.actual.length > 0) || false}
					data={state.data}
					height={600}
					valuechain="LNG"
					currency={currency.name}
					showBySelectedValue={showBy}
					comparative={comparative || "plan"}
					type={state.type}
					previous="previous"
					period={period}
					year={year}
					segment={state.segment}
				></StackedbarChart>
				{isDataAvailable &&
					<div className="legend-container-wrap">
						<LegendContainer className="TrendAnalysis-legend">
							{_legendLabel().left && (
								<Legend year={_legendLabel().left}>
									<BoxAndLabel label="Upstream" boxColor="indigo" box="box" />
									<BoxAndLabel label="Asset" boxColor="sand" box="box" />
									<BoxAndLabel label="LMT" boxColor="teal" box="box" />
								</Legend>
							)}
							{_legendLabel().right && (
								<Legend year={_legendLabel().right}>
									<BoxAndLabel label="Upstream" boxColor="twilight" box="box" />
									<BoxAndLabel label="Asset" boxColor="lightsand" box="box" />
									<BoxAndLabel label="LMT" boxColor="lightaqua" box="box" />
								</Legend>
							)}
						</LegendContainer>
					</div>
				}
			</div>
			{state.informationPopupType && renderPopupPortal()}
		</section>
	)
}

export default AppStore.withStores(LNGStore.withStores(TrendAnalysis));
