
export const initialDictionaryState: DictionaryStateType = {
	dictionary: null
};

export type DictionaryStateType = {
	dictionary: DictionaryType | null;
};

export type DictionaryType = Array<{
	values: {
		[key: string]: string;
	};
	lang: LangType;
}>;

export type LangType = "en" | "my";
