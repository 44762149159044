import React from "react"
import { AppStore } from "store"
import { AppStoreProps } from "store/app/app.store"
import { Indicator } from "components/atoms/Indicator/Indicator"
import { Properties } from "helpers/properties"
import { TrendAmt } from "components/atoms/TrendAmt/TrendAmt"
import { Util } from "helpers/util/util"
import { TableAccordion, WithChild } from "components/molecules/TableAccordion/TableAccordion"
import { SubSegment } from "pages/LNG/Overview/types/ValueChainDetailResult"

type Props = {
	valueLevers: Array<any>;
	tableCurrency: string;
	tableHeaderLabel: {
		left: string,
		right: string
	};
	deviationThreshold: number;
} & AppStoreProps

function TopVLTableAccordion({ tableCurrency, valueLevers, tableHeaderLabel, deviationThreshold }: Props) {
	const newArray = new Array<WithChild<SubSegment>>();
	const sortedArray = new Array<WithChild<SubSegment>>();



	const topValueLeversNumber = valueLevers.length < 5 ? valueLevers.length : 5;
	const valueData = () => {
		// Business requirements : Data now sorted first by absolute deviation in descending order
		for (let i = 0; i < valueLevers.length; i++) {
			newArray.push(valueLevers[i]);
			newArray.sort(Util.compareByAbsolute("deviation", "desc"));
		}


		// Business requirement: to only show the top 5 of the value levers as top value levers
		// Sort this top 5 in descending
		for (let i = 0; i < topValueLeversNumber; i++) {
			if (Math.abs(newArray[i]["deviationPercentage"]) >= deviationThreshold) {
				sortedArray.push(newArray[i]);
			}
			sortedArray.sort(Util.compareBy("deviation", "desc"));
		}
		return sortedArray;
	}

	return (
		<TableAccordion<SubSegment>
			id={`CCNG_value_levers_top_value_lever`}
			className="CCNGValueLevers__tables__sortable"
			sortBackToDefault={true}
			sortType="all"
			defaultSortKey={{
				key: "deviation",
				sortBy: "DSC"
			}}
			header={[
				{
					key: "name",
					label: "Category",
					align: "left",
					sortable: true,
					renderRow: (val) => <span className="name-attr">{val}</span>
				},
				{

					key: "actual",
					label: tableHeaderLabel.left,
					align: "right",
					sortable: true,
					renderRow: (val) => (
						<div className="currency-wrapper">
							{(val < 0 ? "-" : "")}
							<span className="super-currency">{Properties.getCurrencySymbol(tableCurrency)}</span>
							<span className="value"> {Util.formatDigits((val < 0 ? -1 * val : val), "", 2)}</span>
						</div>
					)
				},
				{
					key: "compare",
					label: tableHeaderLabel.right,
					align: "right",
					sortable: true,
					renderRow: (val) => (
						<div className="currency-wrapper">
							{(val < 0 ? "-" : "")}
							<span className="super-currency">{Properties.getCurrencySymbol(tableCurrency)}</span>
							<span className="value"> {Util.formatDigits((val < 0 ? -1 * val : val), "", 2)}</span>
						</div>
					)
				},
				{
					key: "deviation",
					label: `Difference (${Properties.getCurrencySymbol(tableCurrency)})`,
					align: "right",
					sortable: true,
					className: "diff-header",
					renderRow: (val) => (
						<p className="diffUSD">
							<TrendAmt
								negativeSymbol={val < 0 ? "-" : ""}
								currencySymbol={Properties.getCurrencySymbol(tableCurrency)}
								numberValue={Util.formatDigits((val < 0 ? -1 * val : val), "", 2)}
								label={""}
								up={val === 0 ? "neutral" : (val > 0 ? "up" : "down")}
								oppositeTrend
								className="currency-wrapper"
							/>
						</p>
					)
				},
				{
					key: "deviationPercentage",
					label: "Difference(%)",
					align: "right",
					sortable: true,
					className: "diff-percent",
					renderRow: (val) => (
						<p className="diffpercent">
							<Indicator
								label={Util.formatDigits(val, "", 0)}
								down={val > 0 ? "down" : "up"}
								oppositeTrend
								className="currency-wrapper"
							/>
						</p>
					)
				}
			]}
			data={valueData() || []}
		/>
	)
}

export default AppStore.withStores(TopVLTableAccordion);
