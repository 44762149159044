import { TypographyProps } from "./Typography";
import classNames from "classnames";
import React from "react";

type Props = TypographyProps;

export function ExtraLargeText({ children, className, color = "inherit", weight = 500, uppercase = false, wrap = false }: Props) {
	return (
		<span className={classNames("extra-large-text", className, { [`color-${color}`]: color }, { [`weight-${weight}`]: weight }, { "text-uppercase": uppercase }, { "text-wrap": wrap })}>
			{children}
		</span>
	);
}
