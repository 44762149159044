import React from "react";
import classNames from "classnames";
import { isNullOrUndefined } from "util";
import { CSSTransition } from "react-transition-group";
import { NavExpandedMenu } from "components/organisms/Navigation/NavExpandedMenu/NavExpandedMenu";
import { NavItems } from "components/organisms/Navigation/NavItems/NavItems";
import "./SidebarContentLayout.scss";
import { RouteType } from "types/RouteType";


type Props = {
	children: React.ReactNode;
	selected?: string;
	className?: string;
	pages: Array<RouteType>;
	onLogout?: () => void;
};

export function SidebarContentLayout({ className, children, onLogout, pages, selected }: Props) {
	const [open, setOpen] = React.useState<RouteType | null>(null);
	const handleNavClick = (newState) => {
		setOpen(newState)
	};

	return (
		<main className={classNames("sidebarContentLayout", className)}>
			<NavItems selected={selected} pages={pages} onLogout={onLogout} open={open} setOpen={setOpen} />
			<CSSTransition
				in={!isNullOrUndefined(open)}
				out={isNullOrUndefined(open)}
				timeout={500}
				classNames="expanded-nav-menu"
				unmountOnExit
			>
				<NavExpandedMenu
					expandedMenuArray={open}
					onClose={() => handleNavClick(null)}
					header={(open && open.header) || ""}
					selectedNavRoute={selected}
				/>
			</CSSTransition>

			{/* For ipad. Close when clicked outside. */}
			<article className="sidebarContentLayout__bodyContent" onClick={() => open && handleNavClick(null)}>
				{children}
			</article>
		</main>
	);
}
