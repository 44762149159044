import React from "react";
import classNames from "classnames";
import { Icon } from "components/molecules";
import { Properties } from "helpers/properties";
import "./DynamicAccordionTable.scss";
import { Page_Util } from "helpers/util/pageUtil";

interface Props {
	className?: string;
	header: Array<HeaderType>;
	data: Array<DataType>;
	fixedColumnId?: string;
	expandCoumnNumber?: number;
	onRowIndexClick?: any;
	childDetail?: any[];
	reset?: string;
	expandedChildId?: string;
	id?: string;
}

interface DataType {
	[key: string]: any;
}

interface HeaderType {
	id: string;
	label?: string;
	renderParent?: (value: DataType) => React.ReactElement;
	render?: (value: DataType) => React.ReactElement;
	renderHeader?: (value: DataType) => React.ReactElement;
}

interface State {
	dataTable: Array<DataType>
	currentExpandedRowItem: string
}

export class DynamicAccordionTable extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = { dataTable: this.props.data, currentExpandedRowItem: "" }
		this.createChildRow = this.createChildRow.bind(this);
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.data !== prevProps.data) {
			this.setState({
				dataTable: this.props.data,
			});
		}
		// if reset value is changed...
		if (this.props.reset !== prevProps.reset) {
			// Clear old value and close the row by sending same rowid up with null data.
			this.setState({ currentExpandedRowItem: "" })
			this.props.onRowIndexClick && this.props.onRowIndexClick(null, this.state.currentExpandedRowItem);
		}

	}

	_showChildren = (e: any) => {
		const closestTrElement = e?.target?.closest("tr");
		const tableId = closestTrElement?.id
		let parentElementText = closestTrElement?.childNodes[0]?.innerText;
		parentElementText && parentElementText.toLowerCase() === "all" && (parentElementText = parentElementText.toLowerCase())
		parentElementText && this.props.onRowIndexClick && this.props.onRowIndexClick(parentElementText, tableId);
		// Maintain a local state for reset to work.
		this.setState({ currentExpandedRowItem: tableId })
	}

	render() {
		return (
			<div className={classNames("table-holder accordion-table dynamic-table", this.props.className)} id={this.props.id}>
				<table className={classNames("table", this.props.fixedColumnId && "fixed-table")}>
					{Page_Util.getTableHeader(this.props.header, this.props.fixedColumnId)}

					<tbody>
						{this.state.dataTable.map((row, ind) => {
							return (<React.Fragment key={ind}>
								<tr className={classNames((this.props.expandedChildId === ind.toString()) && "expanded")} onClick={this._showChildren} id={ind.toString()}>
									{this.props.header.map((head, k) => {
										return (
											<td className={classNames("table__body", head.id === this.props.fixedColumnId && "fixed-column")} key={k} id={ind.toString()}>
												{k === this.props.expandCoumnNumber && <Icon color="#cccdcf" name={this.props.expandedChildId === ind.toString() ? "table-arrow_down" : "table-arrow_right"}></Icon>}
												{head.renderParent && head.renderParent(row)}
												{!head.renderParent && head.render && head.render(row)}
												{!head.renderParent && !head.render && row[head.id]}
											</td>)
									})}
								</tr>
								{(this.props.expandedChildId === ind.toString()) && this.props.childDetail && this.props.childDetail.map(this.createChildRow)}
							</React.Fragment>
							);
						})}
					</tbody>
				</table>
			</div>
		);
	}

	createChildRow(val: any, j: number) {
		const constructRow = (value, keyNumber, isIndentedParent = false) => <tr key={keyNumber} className={classNames("child-row", { "first-child": keyNumber === 0 && !isIndentedParent }, { "sub-child": isIndentedParent })}>
			{this.props.header.map((head, k) => {
				return (<td className={classNames("table__body", head.id === this.props.fixedColumnId && "fixed-column")} key={k}>
					{head.render && head.render(value)}
					{!head.render && value[head.id]}
				</td>);
			})}
		</tr>;

		if (val?.subSegmentDetails) {
			return <React.Fragment key={j}>
				{constructRow(val, j)}
				{Object.keys(val.subSegmentDetails).map((i) => {
					return { ...val.subSegmentDetails[i], name: `\t${Properties.getDictionary(i)}` };
				}).map((value, keyNumber) => constructRow(value, keyNumber, true))}
			</React.Fragment>
		}
		else {
			return constructRow(val, j);
		}
	}
}