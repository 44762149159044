import "./CostMarginFilter.scss";
import { CurrencyType } from "store/app/global.store";
import { DropdownItemType } from "components/organisms/Dropdown/Dropdown";
import { LNGCostType } from "../type/LNGCostType";
import { LNGSegmentType } from "types/LNGSegmentType";
import { LNGValueChainType } from "types/LNGValueChainType";
import { MonthType } from "types/MonthType";
import { PeriodType } from "types/PeriodType";
import { ProfitabilityType } from "types/ProfitabilityType";
import { Properties } from "helpers/properties";
import { QuarterType } from "types/QuarterType";
import React from "react"
import { ShowByType } from "types/ShowByType";
import { Dropdown, ItemGroup, WrapContainer } from "components/molecules";

type Props = {
	onEvent?: (type: string, value: any) => void;
	segment?: LNGSegmentType;
	subsegment?: string;
	currentSubsegmentList?: Array<DropdownItemType>;
	profitability?: ProfitabilityType;
	ctsComponent?: string;
	showBy?: ShowByType | string;
	currency: CurrencyType;
	cost?: LNGCostType;
	period?: PeriodType;
	month?: MonthType;
	quarter?: QuarterType;
	year?: string;
	yearList?: Array<DropdownItemType>;
	valueChain?: LNGValueChainType,
	hideMonth?: boolean;
	hideQuarter?: boolean;
	disableMonth?: boolean;
	disableYear?: boolean;
	disableQuarterly?: boolean;
	disableSubsegment?: boolean;
	stickyClassName: string;
}

/*
	FUNCTION NAME: CostMarginFilters
	DESCRIPTION: The function that renders all filter dropdowns used in LNG Cost Margin Buildup page
*/
export function CostMarginFilters({ onEvent, valueChain, showBy, currency, segment, subsegment, profitability, ctsComponent, cost, currentSubsegmentList = [{ label: "All", id: "all" }], hideMonth = false, hideQuarter = false, period, month, quarter, year, yearList = [], disableMonth, disableYear, disableQuarterly, disableSubsegment, stickyClassName }: Props) {
	const handleOnEvent = (eventType: string, value: any) => {
		if (!onEvent) return;
		onEvent(eventType, value)
	}
	return (
		<WrapContainer className="cost-margin-filter-component" stickyClassName={stickyClassName} >
			<Dropdown
				selected={showBy}
				border
				className="spaced"
				title="Show By"
				key="showBy"
				onChange={(value) => handleOnEvent("showBy", value)}
				list={[
					{
						label: `${currency.currencySymbol}/MMBtu`,
						id: "mmbtu",
					},
					{
						label: `Absolute(${currency.currencySymbol})`,
						id: "absolute",
					}
				]}
			/>
			<Dropdown
				border
				title="Profitability"
				key="profitability"
				onChange={(value) => handleOnEvent("profitability", value)}
				className="spaced"
				selected={profitability}
				list={[
					{ label: "PBT", id: "PBT" },
					{ label: "EBITDA", id: "EBITDA" },
					{ label: "Gross Profit", id: "GP" }
				]}
			/>
			<Dropdown
				selected={period}
				border
				className="spaced"
				title="Period"
				key="period"
				onChange={(value) => handleOnEvent("period", value)}
				list={[
					{
						label: "Monthly",
						id: "monthly"
					},
					{
						label: "Quarterly",
						id: "quarterly"
					},
					{
						label: "YTD",
						id: "ytd"
					}
				]}
			/>
			<ItemGroup className="spaced" key="cost-margin-filter-component__filter">
				{!hideMonth && <Dropdown
					selected={month}
					border
					title="Month"
					onChange={(value) => handleOnEvent("month", value)}
					disabled={disableMonth}
					list={Properties.getAllMonths().map((v) => {
						return { ...v, id: v.label }
					})}
				/>}
				{!hideQuarter && <Dropdown
					selected={quarter}
					border
					title="Quarter"
					onChange={(value) => handleOnEvent("quarter", value)}
					disabled={disableQuarterly}
					list={Properties.getAllQuarters().map((v) => {
						return { ...v, id: v.label }
					})}
				/>}

				{<Dropdown
					selected={year}
					border
					title="Year"
					onChange={(value) => handleOnEvent("year", value)}
					disabled={disableYear}
					list={yearList}
				/>}
			</ItemGroup>
			<Dropdown
				key="valueChain"
				selected={valueChain}
				border
				className="spaced"
				title="Value Chain"
				onChange={(value) => handleOnEvent("valueChain", value)}
				list={[
					{ label: "All", id: "all" },
					{
						label: "Malaysia", id: "malaysia-all", children: [
							{ label: "MLNG1", id: "malaysia-mlng1" },
							{ label: "MLNG2", id: "malaysia-mlng2" },
							{ label: "MLNG3", id: "malaysia-mlng3" },
							{ label: "T9", id: "malaysia-t9" },
							{ label: "PFLNG", id: "malaysia-pflng" },
							{ label: "PFLNG1", id: "malaysia-pflng1" },
							{ label: "PFLNG2", id: "malaysia-pflng2" },
							{ label: "PLC (MLNG1,2,3 & T9)", id: "malaysia-plc" },]
					},
					{ label: "Egypt", id: "egypt-all" },
					{ label: "Australia", id: "australia-all" },
				]}
			/>
			<Dropdown
				selected={segment}
				border
				className="spaced"
				title="Segment"
				key="segment"
				onChange={(value) => handleOnEvent("segment", value)}
				list={[
					{ label: "All", id: "all" },
					{ label: "Upstream", id: "upstream" },
					{ label: "Asset", id: "asset" },
					{ label: "LMT", id: "lmt" }
				]}
			/>
			<Dropdown
				selected={subsegment}
				border
				className="spaced"
				title="Sub Segment"
				key="subsegment"
				onChange={(value) => handleOnEvent("subsegment", value)}
				list={currentSubsegmentList}
				disabled={disableSubsegment}
			/>

			<Dropdown
				selected={ctsComponent}
				border
				className="spaced"
				title="CTS Component"
				key="ctsComponent"
				onChange={(value) => handleOnEvent("ctsComponent", value)}
				list={[
					{ label: "All", id: "all" },
					{ label: "Cash", id: "cash" },
					{ label: "Non-Cash", id: "non-cash" }
				]}
			/>
			<Dropdown
				selected={cost}
				className="spaced"
				border
				key="cost"
				onChange={(value) => handleOnEvent("cost", value)}
				title="Cost"
				list={[
					{ label: "All", id: "all" },
					{ label: "Fixed", id: "fixed" },
					{ label: "Variable", id: "variable" }
				]}
			/>
		</WrapContainer>
	)
}












