import "./TrendAmt.scss";
import classNames from "classnames";
import React from "react";

interface Props {
	label: string;
	className?: string;
	negativeSymbol?: string;
	currencySymbol?: string;
	numberValue?: string;
	up?: "up" | "down" | "neutral";
	oppositeTrend?: boolean;
}

export function TrendAmt(props: Props) {
	return (
		<span
			className={classNames(
				"trend-amt",
				props.className,
				props.up,
				props.oppositeTrend && "opposite"
			)}
		>
			{(props.up === "up" && "+") || ""}
			{props.negativeSymbol}
			{props.currencySymbol && <span className="super-currency">{props.currencySymbol}</span>}
			{props.numberValue && <span className="value">{props.numberValue}</span>}
			{props.label}
		</span >
	);
}
