import { API_ROUTE } from "config/PageApiConfig";
import { CCNGSegmentType } from "types/CCNGtype";
import { HttpRequest } from "helpers/request-helper";

export function LoadSubSegment() {
	return HttpRequest.get(API_ROUTE.CCNG.subsegments).then(
		(data) => data.data)
}

export function LoadChartData<ParamType = any>(
	segment: CCNGSegmentType,
	subsegment: string,
	params: ParamType,
) {
	return HttpRequest.get(`${API_ROUTE.CCNG.costMarginChart}/${segment}/${subsegment.split("/").join("|")}/l2/detail`, params).then((data) => data.data);
}

export function LoadProductType() {
	return HttpRequest.get(API_ROUTE.CCNG.productType).then(
		(data) => data.data)
}

export function LoadRegion() {
	return HttpRequest.get(API_ROUTE.CCNG.region).then(
		(data) => data.data)
}

export function LoadYears() {
	return HttpRequest.get(API_ROUTE.CCNG.years).then(
		(data) => data.data)
}