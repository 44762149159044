import { AppStore } from "store";
import { AppStoreProps } from "store/app/app.store"
import { CardSplitNoIcon } from "components/organisms/CardSplitNoIcon/CardSplitNoIcon";
import { Contributors } from "../types/ValueChainDetailResult";
import { ProductType } from "types/CCNGtype";
import { Properties } from "helpers/properties";
import React from "react"
import { ShowByType } from "types/ShowByType";
import { Util } from "helpers/util/util";
import { SkeletonContext } from "helpers/hooks/Loader";

type Props = {
	onEvent?: (type: string, value: any) => void;
	corporateCost?: { [key: string]: Contributors };
	showBy: ShowByType | string;
	currencySymbol: string;
	selectedValueChain: string;
	product: ProductType;
	headerLabels: { left: string, right: string }
} & AppStoreProps

/*
	FUNCTION NAME: CCNGCorporateCost
	DESCRIPTION: The function that renders the Corporate Cost section of CCNG Overview page
	including all the card components used within it
*/
function CCNGCorporateCost({ corporateCost, showBy, currencySymbol, product, selectedValueChain, dictionary, headerLabels }: Props) {
	const skeletonState = React.useContext(SkeletonContext);
	const loading = skeletonState?.loading;

	if (!loading && corporateCost && corporateCost.length) {
		const corporateCostDiv: any = [];
		for (let k = 0; k < corporateCost.length; k++) {
			const decimalPlace = Properties.showDecimal(corporateCost[k]["name"]) ? 2 : Util.getDecimalPlace(showBy);
			corporateCostDiv.push(
				<CardSplitNoIcon
					id={`CCNG_corporate_cost_${k}`}
					key={k}
					mainheaderLabel={Properties.getDictionary(
						corporateCost[k]["name"],
						`${selectedValueChain}_tab`
					)}
					headerLabel={headerLabels.left}
					footerLabel={_getContributionFooter(
						showBy,
						selectedValueChain,
						corporateCost[k]["name"],
						product
					)}
					footerLabel1={_getContributionFooter(
						showBy,
						selectedValueChain,
						corporateCost[k]["name"],
						product
					)}
					unitLabel={
						Properties.showCurrency(corporateCost[k]["name"])
							? Properties.getCurrencySymbol(currencySymbol)
							: ""
					}
					valueLabel={Util.formatDigits(corporateCost[k]["actual"], showBy, decimalPlace)}
					bordered
					oppositeTrend={Properties.showOppositeTrend(
						corporateCost[k]["name"]
					)}
					trend={
						corporateCost[k]["deviation"] > 0
							? "up"
							: corporateCost[k]["deviation"] < 0
								? "down"
								: "neutral"
					}
					trendLabel1={Util.formatDigits(corporateCost[k]["deviation"], showBy, decimalPlace)}
					indicatorLabel1={Util.formatDigits(corporateCost[k]["deviationPercentage"], "", 0)}
					headerLabel1={headerLabels.right}
					unitLabel1={
						Properties.showCurrency(corporateCost[k]["name"])
							? Properties.getCurrencySymbol(currencySymbol)
							: ""
					}
					valueLabel1={Util.formatDigits(corporateCost[k]["compare"], showBy, decimalPlace)}
					withTrend1

					infotiptool
					info={Properties.getDictionaryText(dictionary.get("dictionary"), `CCNG_corporate_cost_${selectedValueChain}_${corporateCost[k]["name"]}`, "en")}
				/>
			);
		}
		return corporateCostDiv;

	}
	else {
		const spinnerDivs: any = [];
		for (let k = 0; k < 3; k++) {
			spinnerDivs.push(
				<CardSplitNoIcon
					key={k}
					mainheaderLabel=""
					headerLabel=""
					footerLabel={[""]}
					trendLabel=""
					unitLabel=""
					valueLabel=""
					indicatorLabel=""
					bordered
					headerLabel1=""
					unitLabel1=""
					valueLabel1=""
					spinner
				/>
			);
		}
		return (
			<>
				{spinnerDivs[0]}
				{spinnerDivs[1]}
				{spinnerDivs[2]}
			</>

		);
	}
}


function _getContributionFooter(showBy, selectedValueChain, contributionKey, product) {
	const footerLabel = Array<string>();
	const labelIndex = product === "Natural Gas" ? 1 : 0;
	const footerLabelText = Properties.getFooterLabelForContribution(`CCNG_Corporate_Cost_ ${contributionKey}`, showBy, `CCNG_${selectedValueChain}`);

	footerLabel.push(
		String(
			footerLabelText === "" ? "" : footerLabelText[labelIndex]
		)
	);
	return footerLabel;
}


export default AppStore.withStores(CCNGCorporateCost);