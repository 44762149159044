import "./SortableTable.scss";
import classNames from "classnames";
import { Icon } from "components/molecules";
import React from "react";
import { Util } from "helpers/util/util";

interface Props {
	className?: string;
	header: Array<HeaderType>;
	data: Array<DataType>;
	deviationThreshold?: string;
	showOnly?: number;
	id?: string;
}

interface DataType {
	[key: string]: any;
}
interface State {
	data: Array<DataType>;
	filteredData: Array<DataType>;
}
interface HeaderType {
	id: string;
	label?: string;
	className?: string;
	render?: (value: DataType) => React.ReactElement;
	renderHeader?: (value: DataType) => React.ReactElement;
}

export class SortableTable extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = { data: [], filteredData: [] };
		Util.compareBy.bind(this);
		this.sortBy.bind(this);
	}


	sortBy = (key: any, e: any) => {
		const type = e["target"]["dataset"]["sort"];
		e["target"]["dataset"]["sort"] = type === "asc" ? "desc" : "asc";

		const arrayCopy = this.state.filteredData;
		arrayCopy.sort(Util.compareBy(key, type));
		this.setState({ data: arrayCopy });
	};

	dataSort = (data) => {
		const newArray = new Array<DataType>();
		const sortedArray = new Array<DataType>();
		const dataLength = data.length;

		// Business requirements : Data now sorted first by absolute deviation in descending order
		for (let i = 0; i < dataLength; i++) {
			newArray.push(data[i]);
			newArray.sort(Util.compareByAbsolute("deviation", "desc"));
		}

		const topValueLeversNumber = dataLength < 5 ? dataLength : (this.props.showOnly || 5);

		// Business requirement: to only show the top 5 of the value levers as top value levers
		// Sort this top 5 in descending
		for (let i = 0; i < topValueLeversNumber; i++) {
			sortedArray.push(newArray[i]);
			sortedArray.sort(Util.compareBy("deviation", "desc"));
		}
		return sortedArray;
	}
	componentDidMount() {
		const data = this.props.data;
		const sortedArray = this.dataSort(data);
		const headerIDs = this.props.header.map((label) => label.id);

		// A generic check for no data in sortable table
		// Sets all header ID's to be none
		if (sortedArray.length === 0) {
			const noDataArray = {
				name: "No qualifying data"
			}

			headerIDs.forEach((e) => {
				noDataArray[e] = null
			})

			sortedArray.push(noDataArray)
		}

		this.setState({ filteredData: sortedArray });
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.data !== this.props.data) {
			const data = this.props.data;
			const sortedArray = this.dataSort(data);
			if (sortedArray.length === 0) {
				sortedArray.push({
					name: "No qualifying value levers",
					actual: null,
					compare: null,
					deviation: null,
					deviationPercentage: null
				});
			}
			this.setState({ filteredData: sortedArray });
		}
	}

	render() {
		return (
			<div
				className={classNames(
					"sortable-table table-holder",
					this.props.className
				)}
				id={this.props.id}
			>
				<table className={classNames("table")}>
					<thead>
						<tr>
							{this.props.header.map((head, i) => {
								return (
									<React.Fragment key={i}>
										<th
											className={classNames("table__header", head.className)}
											key={i}
										>
											{head.renderHeader && head.renderHeader(head)}
											{!head.renderHeader && head.label}
											{!head.renderHeader && <Icon
												name="sort_updown"
												onClick={(e) => this.sortBy(head.id, e)}
												size="medium"
											/>
											}
										</th>
									</React.Fragment>
								);
							})}
						</tr>
					</thead>
					<tbody>
						{this.state.filteredData.map((row, j) => {
							return (
								<tr key={j}>
									{this.props.header.map((head, k) => {
										return (
											<td className="table__body" key={k}>
												{head.render && head.render(row)}
												{!head.render && row[head.id]}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		);
	}
}
