import "./LegendContainer.scss";
import classNames from "classnames";
import React from "react";

interface Props {
	children: React.ReactNode | React.ReactNodeArray;
	className?: string;
	hidden?: boolean;
}

/*
	FUNCTION NAME: LegendContainer
	DESCRIPTION: The function that creates the legend box UI which is used on pages with charts
*/
export function LegendContainer(props: Props) {
	const { className, children, hidden } = props;
	return (
		<div
			className={classNames("legend-container", className, hidden && "hidden")}
		>
			{children}
		</div>
	);
}