import { API_ROUTE } from "config/PageApiConfig";
import { ComparativeType } from "types/ComparativeType";
import { HttpRequest } from "helpers/request-helper";
import { FilterStateType } from "store/app/filters.store";
import { PeriodType } from "types/PeriodType";
import { ProductType } from "../../../types/CCNGtype";
import { ProfitabilityType } from "types/ProfitabilityType";
import { TrendAnalysisBarType } from "types/TrendAnalysisType";


export function GetCCNGTrendAnalysisStackedBarChart(
	params: Pick<FilterStateType, "showBy"> & {
		currency: string;
		comparative: ComparativeType;
		periodType: PeriodType;
		year: string;
		product: ProductType;
		productType: string;
		profitability: ProfitabilityType;
		region: string;
		type: TrendAnalysisBarType
	}
) {
	return HttpRequest.get(API_ROUTE.CCNG.trendAnalysisChart, params).then(
		(data) => data.data
	);
}

export function LoadRegion() {
	return HttpRequest.get(API_ROUTE.CCNG.region).then(
		(data) => data.data)
}

export function LoadYears() {
	return HttpRequest.get(API_ROUTE.CCNG.years).then(
		(data) => data.data)
}
