import React from "react";
import classNames from "classnames";
import { isNullOrUndefined } from "util";
import { Icon } from "..";
import { Util } from "helpers/util/util";
import "./TableAccordion.scss";

export type WithChild<DataType> = DataType & {
	children?: Array<WithChild<DataType>>
}

type HeadAlignType = "left" | "center" | "right";
type SortType = "ASC" | "DSC";

type HeaderType<DataType> = {
	className?: string;
	// This is a must for head to show
	key: keyof DataType;
	// Text to show. Just a label. Always return a string
	label: string | ((header: Omit<HeaderType<DataType>, "label" | "render">) => string);
	// Default alignment
	align?: HeadAlignType;
	// Allow sorting?
	sortable?: boolean;
	// Render head UI using lable above
	render?: (label: any, header: Omit<HeaderType<DataType>, "render" | "label">) => React.ReactElement;
	// Render Row/Child/Sub child UI
	renderRow?: (value, row: WithChild<DataType>, level: number) => React.ReactElement;
	// Custom Sorting function
	sortFn?: (key: string, data: Array<WithChild<DataType>>, sortBy: SortType) => Array<WithChild<DataType>>
}

type Props<DataType> = {
	className?: string;
	header: Array<HeaderType<DataType>>;
	data: Array<WithChild<DataType>>;
	// default Sort by only
	defaultSortKey?: {
		key: keyof DataType;
		sortBy: SortType
	}
	// Show all rows? or only show a slice of rows. Use this for pagination
	showOnly?: number | "all";
	// Allow expanding of multiple child items at same time?
	expandActionType?: "single" | "multiple";
	// Sort only from the items shown or all items in list?
	sortType?: "filtered" | "all";
	// sort to default dataset after descending sort is pressed?
	sortBackToDefault?: boolean;
	// Event for expand button
	onRowExpand?: (item: WithChild<DataType>) => void;
	id?: string;
}

export function TableAccordion<DataType>(props: Props<DataType>) {
	const showOnly: number = props?.showOnly === "all" ? props.data.length : props?.showOnly as number;
	const [sortInfo, setSortInfo] = React.useState<{
		sortKey: string | undefined,
		sortData: Array<WithChild<DataType>>,
		sortBy: SortType | undefined
	}>({
		sortKey: props.defaultSortKey?.key.toString(),
		sortData: props.data,
		sortBy: props.defaultSortKey?.sortBy || undefined
	})

	const [expandedRows, setExpandedRows] = React.useState<Array<WithChild<DataType>>>([]);

	const handleRowExpand = (item: WithChild<DataType>) => {
		const exists = expandedRows.find((r) => Util.areObjectsEqual(item, r));
		switch (props.expandActionType) {
			case "multiple":
				if (exists) {
					const filteredRows = expandedRows.filter((r) => !Util.areObjectsEqual(item, r));
					setExpandedRows(filteredRows);
				} else {
					props?.onRowExpand?.(item);
					setExpandedRows(expandedRows.concat([item]));
				}
				break;

			case "single":
			default:
				if (exists) {
					setExpandedRows([])
				} else {
					setExpandedRows([item]);
					props?.onRowExpand?.(item);
				}
				break;
		}
	}

	React.useEffect(() => {
		if (props.defaultSortKey) {
			// Need this to create a new ref or array else it'll override the same instance of array
			let rowData: Array<WithChild<DataType>> = [];
			rowData = rowData.concat(props.sortType === "all" ? props.data : sortInfo.sortData);

			// default Sort
			setSortInfo({
				sortKey: props.defaultSortKey.key.toString(),
				sortData: Util.sortArray(rowData, props.defaultSortKey.key, props.defaultSortKey.sortBy),
				sortBy: props.defaultSortKey.sortBy
			})
		}
		// eslint-disable-next-line
	}, [props.defaultSortKey?.key, props.defaultSortKey?.sortBy])

	React.useEffect(() => {
		setExpandedRows([]);
	}, [sortInfo])

	const onColumnSort = (key: string, head?: HeaderType<DataType>) => {
		// Just to be safe we can enable/disable it when we want to.
		if (!head?.sortable) {
			return;
		}

		// Need this to create a new ref or array else it'll override the same instance of array
		let rowData: Array<WithChild<DataType>> = [];
		rowData = rowData.concat(props.sortType === "all" ? props.data : sortInfo.sortData);

		if (!isNullOrUndefined(head.sortFn)) {
			const newSortBy = sortInfo.sortBy === "ASC" ? "DSC" : "ASC";
			setSortInfo({
				sortKey: key,
				sortData: head
					.sortFn(key, rowData, newSortBy),
				sortBy: newSortBy
			})
		}
		else if (sortInfo.sortKey === key) {
			if (sortInfo.sortBy === "DSC" && (props?.sortBackToDefault)) {
				// Sort none
				setSortInfo({
					sortKey: undefined,
					sortData: props.data,
					sortBy: undefined
				})
			} else if (sortInfo.sortBy === "ASC") {
				// Sort desc
				setSortInfo({
					sortKey: key,
					sortData: Util.sortArray(rowData, key, "DSC"),
					sortBy: "DSC"
				})
			} else {
				// Sort asc
				setSortInfo({
					sortKey: key,
					sortData: Util.sortArray(rowData, key, "ASC"),
					sortBy: "ASC"
				})
			}
		} else {
			// Sort asc
			setSortInfo({
				sortKey: key,
				sortData: Util.sortArray(rowData, key, "ASC"),
				sortBy: "ASC"
			})
		}
	}

	return (
		<div className={classNames(props.className, "TableAccordion")} id={props.id}>
			<table className={classNames("TableAccordion__table")}>
				<thead className={classNames("TableAccordion__table__head")}>
					<tr className={classNames("TableAccordion__table__head__row")}>
						{props.header.map((head, k) => {
							return <TableHeader<DataType>
								key={k}
								head={head}
								sortInfo={sortInfo}
								onSort={(key) => onColumnSort(key, head)}
							/>
						})}
					</tr>
				</thead>
				<tbody className={classNames("TableAccordion__table__body")}>
					{RenderTableRows<DataType>(sortInfo.sortData.slice(0, showOnly), props.header, 0, handleRowExpand, expandedRows)}
				</tbody>
			</table>
		</div>
	)
}

export function RenderTableRows<DataType>(data: Array<WithChild<DataType>>, header: Array<HeaderType<DataType>>, level: number = 0, onRowExpand?: (item: WithChild<DataType>) => void, expandedRows: Array<WithChild<DataType>> = []) {
	return data.map((row, index) => {
		return <TableRow<DataType>
			key={index}
			keyIndex={index}
			data={row}
			header={header}
			level={level}
			isExpanded={expandedRows.find((r) => Util.areObjectsEqual(row, r)) ? true : false}
			onRowExpand={onRowExpand}
		/>
	})
}

type TableRowProps<DataType> = {
	keyIndex: number;
	data: WithChild<DataType>,
	header: Array<HeaderType<DataType>>;
	level: number;
	isExpanded: boolean;
	onRowExpand?: (data: WithChild<DataType>) => void
}

export function TableRow<DataType>({ keyIndex, data, header, level, onRowExpand, isExpanded }: TableRowProps<DataType>) {
	const handleExpand = () => {
		onRowExpand?.(data);
	}

	return (
		<React.Fragment key={keyIndex}>
			<tr
				className={classNames("TableAccordion__table__body__row", { "expandable": data?.children }, { "expanded": isExpanded }, { "child-row": level > 0 })}
				key={keyIndex}
				onClick={() => data.children && handleExpand()}
			>
				{header.map((head, index) => {
					const value = data[head.key];
					return (
						<td
							key={head.key.toString()}
							className={classNames("TableAccordion__table__body__row__data")}
							align={head.align || "center"}
						>
							{isExpanded && index === 0 && data.children?.length === 0 && <div className="TableAccordion__table__body__row__data__loader" />}
							{isExpanded && index === 0 && data.children && data.children?.length > 0 && <Icon
								color="#cccdcf"
								className={classNames("--expanded-icon")}
								name={isExpanded ? "table-arrow_down" : "table-arrow_right"} />}

							{!isExpanded && data?.children && index === 0 &&
								<Icon
									color="#cccdcf"
									className={classNames("--expanded-icon")}
									name={isExpanded ? "table-arrow_down" : "table-arrow_right"} />}
							{head.renderRow?.(value, data, level) || value}
						</td>
					)
				})}
			</tr>
			{data?.children && isExpanded && RenderTableRows(data.children, header, level + 1)}
		</React.Fragment>)
}

type TableHeaderProps<DataType> = {
	head: HeaderType<DataType>;
	sortInfo: {
		sortKey: string | undefined,
		sortData: Array<WithChild<DataType>>,
		sortBy: SortType | undefined
	};
	onSort: (key: string) => void;
}

export function TableHeader<DataType>({ head, onSort, sortInfo }: TableHeaderProps<DataType>) {
	const label = typeof head.label === "string" ? head.label : head.label(head);
	const isHeadSorted = sortInfo?.sortKey === head.key;

	return (
		<th
			key={head.key.toString()}
			className={classNames("TableAccordion__table__head__row__head", head.className, { "--sortable": head.sortable }, { "--sorted": isHeadSorted })}
			align={head.align}
			onClick={(e) => head.sortable && onSort(head.key.toString())}
		>
			{head.render?.(label, head) || label}
			{head.sortable && <Icon
				name={sortInfo.sortKey ? (isHeadSorted ? (sortInfo.sortBy === "ASC" ? "sort_up" : "sort_down") : "sort_updown") : "sort_updown"}
				size="medium"
				className={classNames("TableAccordion__table__head__row__head__sort", { "sort-asc": isHeadSorted })}
				color={isHeadSorted ? "#00a19c" : "#cccdcf"}
			/>}
		</th>
	)
}