import React from "react";
import classNames from "classnames";
import { CardHeadingTrendMolecule } from "../../molecules/CardHeadingTrendMolecule/CardHeadingTrendMolecule";
import { HeaderInfoPopup } from "components/molecules";
import "./CardSplitNoIcon.scss";

interface Props {
	mainheaderLabel: string;
	headerLabel: string;
	footerLabel: Array<string>;
	footerLabel1?: Array<string>;
	unitLabel: string;
	valueLabel: string;
	headerLabel1: string;
	unitLabel1: string;
	valueLabel1: string;
	trendLabel?: string;
	indicatorLabel?: string;
	withTrend?: boolean;
	bordered?: boolean;
	trend?: "up" | "down" | "neutral";
	trendLabel1?: string;
	indicatorLabel1?: string;
	withTrend1?: boolean;
	className?: string;
	oppositeTrend?: boolean;
	spinner?: boolean;
	infotiptool?: boolean;
	info?: string;
	id?: string;

}

/*
	FUNCTION NAME: CardSplitNoIcon
	DESCRIPTION: The function that creates the card component which has a left and right
	segment but no icon on the top and is used primarily at sub segment level on IVCI application
*/
export function CardSplitNoIcon(props: Props) {
	const {
		mainheaderLabel,
		headerLabel,
		footerLabel,
		footerLabel1,
		trendLabel,
		indicatorLabel,
		unitLabel,
		valueLabel,
		bordered,
		withTrend = true,
		headerLabel1,
		trendLabel1,
		indicatorLabel1,
		unitLabel1,
		valueLabel1,
		withTrend1 = false,
		trend = "down",
		className,
		oppositeTrend,
		spinner,
		infotiptool,
		info,
		id
	} = props;
	return (
		<article
			className={classNames("cmp-card", className, bordered && "bordered")} id={id}
		>

			{infotiptool && <HeaderInfoPopup info={info} className="header-info-pop-up">
				<h1 className={classNames("card-title--header", spinner && "spinner")}>
					<span>{mainheaderLabel}</span>
				</h1>
			</HeaderInfoPopup>
			}
			{!infotiptool &&
				<h1 className={classNames("card-title--header", spinner && "spinner")}>
					<span>{mainheaderLabel}</span>
				</h1>
			}
			<div className="card-body">
				<div className="card-group">
					<CardHeadingTrendMolecule
						headerLabel={headerLabel}
						footerLabel={footerLabel}
						trendLabel={trendLabel}
						unitLabel={unitLabel}
						valueLabel={valueLabel}
						indicatorLabel={indicatorLabel}
						trend={trend}
						withTrend={withTrend}
						className="item"
						oppositeTrend={oppositeTrend}
						spinner={spinner}
					/>
					<CardHeadingTrendMolecule
						headerLabel={headerLabel1}
						footerLabel={footerLabel1 || footerLabel}
						trendLabel={trendLabel1}
						unitLabel={unitLabel1}
						valueLabel={valueLabel1}
						indicatorLabel={indicatorLabel1}
						trend={trend}
						withTrend={withTrend1}
						className="item"
						oppositeTrend={oppositeTrend}
						spinner={spinner}
					/>
				</div>
			</div>
		</article>
	);
}
