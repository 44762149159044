import { API_ROUTE } from "config/PageApiConfig";
import { ComparativeType } from "types/ComparativeType";
import { FilterStateType } from "store/app/filters.store";
import { HttpRequest } from "helpers/request-helper";
import { LNGValueChainType } from "types/LNGValueChainType";
import { PeriodType } from "types/PeriodType";
import { ProfitabilityType } from "types/ProfitabilityType";
import { ValueChainDetailResult } from "./types/ValueChainDetailResult";
import { ValueChainSummary } from "./types/ValueChainSummary";
import { ValueChainType } from "types/ValueChainType";

export function getLNGValueChainsSummary(
	params: Pick<FilterStateType, "showBy"> & {
		currency: string;
		periodType: PeriodType,
		valueChain: LNGValueChainType,
		comparative: ComparativeType;
		period: string;
		year: string;
		profitability: ProfitabilityType
	}
): Promise<ValueChainSummary> {
	return HttpRequest.get(API_ROUTE.LNG.dashboardSummary, params).then(
		(data) => data.data as ValueChainSummary
	);
}

export function getValueChainDetails(
	path: ValueChainType,
	/** @see https://www.typescriptlang.org/docs/handbook/utility-types.html#picktk **/
	params: Pick<FilterStateType, "showBy"> & {
		currency: string;
		comparative: ComparativeType;
		periodType: PeriodType,
		valueChain: LNGValueChainType,
		period: string;
		year: string;
		profitability: ProfitabilityType
	}
): Promise<ValueChainDetailResult> {
	return HttpRequest.get(
		`${API_ROUTE.LNG.dashboardDetail}/${path}/detail`,
		params
	).then((data) => data.data as ValueChainDetailResult);
}

export function getLNGValueChainsSubSegmentDetails(path: ValueChainType, subSegment: string,
	params: Pick<FilterStateType, "showBy"> & {
		currency: string;
		comparative: ComparativeType;
		period: string;
		year: string;
	}): Promise<ValueChainDetailResult> {
	return HttpRequest.get(
		`${API_ROUTE.LNG.dashboardDetail}/${path}/${subSegment.split("/").join("|")}/detail`, params
	).then((data) => data.data as ValueChainDetailResult);
}

export function LoadYears() {
	return HttpRequest.get(API_ROUTE.LNG.years).then(
		(data) => data.data)
}
