import { AppStore } from "store";
import { AppStoreProps } from "store/app/app.store"
import { CardSplitNoIcon } from "components/organisms/CardSplitNoIcon/CardSplitNoIcon";
import { CCNGPriceDifference } from "./PriceDifference";
import { Properties } from "helpers/properties";
import React from "react"
import { Util } from "helpers/util/util";
import { HeaderInfoPopup, Table } from "components/molecules";
import { PriceBenchMark, PriceDifference } from "../types/ValueChainSummary";

type Props = {
	benchmark?: PriceBenchMark
	priceDifference?: PriceDifference;
	headerLabels: { left: string, right: string }
} & AppStoreProps

/*
	FUNCTION NAME: CCNGPriceBenchmark
	DESCRIPTION: The function that renders all cards used in Price Benchmark section of CCNG Overview page
*/
function CCNGPriceBenchmark({ benchmark, dictionary, headerLabels, priceDifference }: Props) {
	if (benchmark) {
		return (
			<section className="homepage__footer ccng__overview__footer">
				<HeaderInfoPopup type="subheader"
					info={Properties.getDictionaryText(dictionary.get("dictionary"), "CCNG_Price_Benchmark", "en")}
				>
					<h2 className="homepage__footer__header">Price Benchmark</h2>
				</HeaderInfoPopup>
				<div className="homepage__footer__cards ccng__overview__footer__cards">
					<div className="homepage__footer__cards__cards-and-table">

						<div className="homepage__footer__cards__market ccng__overview__footer__cards__floorPrices">
							<Table
								id={`CCNG_price_benchmark_floor_price`}
								header={getHeader(headerLabels, "Floor Price")}
								data={_filterMarketTable(benchmark?.floorPrices)}
							/>
							<CCNGPriceDifference priceDifference={priceDifference}></CCNGPriceDifference>
						</div>

						<Table
							id={`CCNG_price_benchmark_market_price`}
							className="homepage__footer__cards__market ccng__overview__footer__cards__market"
							header={getHeader(headerLabels, "Market")}
							data={_filterMarketTable(benchmark?.marketPrices)}
						/>
					</div>
				</div>
			</section>
		);
	} else {
		const spinnerDivs: any = [];
		for (let k = 0; k < 4; k++) {
			spinnerDivs.push(
				<CardSplitNoIcon
					key={k}
					mainheaderLabel=""
					headerLabel=""
					footerLabel={[""]}
					unitLabel=""
					valueLabel=""
					bordered
					headerLabel1=""
					unitLabel1=""
					valueLabel1=""
					className="homepage__footer__cards__spot-term"
					spinner
				/>
			);
		}
		return (
			<div className="homepage__footer">
				<div className="homepage__footer__cards">
					<div className="homepage__footer__cards__cards-and-table">
						{spinnerDivs}
					</div>
				</div>
			</div>
		);
	}
}

function _filterMarketTable(dataArray) {
	const footerMarketArray: Array<any> = [];
	const footerMarket = Properties.get("CCNG_footerMarket");
	for (let j = 0; j < footerMarket.length; j++) {
		for (let i = 0; i < dataArray?.length; i++) {
			if (dataArray[i].name === footerMarket[j]) {
				footerMarketArray.push(dataArray[i]);
			}
		}
	}
	return footerMarketArray;
}
function getHeader(headerLabels, label) {
	return (
		[
			{ label: label, id: "name" },
			{
				label: headerLabels.left,
				id: "actual",
				render: (val) => <div className="currency-wrapper"><span className="super-currency">{val.currency} </span><span className="value">{Util.formatDigits(val.actual, "", 2)}</span> </div>
			},
			{
				label: headerLabels.right,
				id: "plan",
				render: (val) => <div className="currency-wrapper"> <span className="super-currency">{val.currency} </span><span className="value">{Util.formatDigits(val.plan, "", 2)} </span></div>
			},
			{ label: "Unit", id: "unit" }
		]);
}

export default AppStore.withStores(CCNGPriceBenchmark);
