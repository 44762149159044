import React from "react";
import classNames from "classnames";
import { Placeholders } from "components/molecules";
import { isNullOrUndefined } from "util";
import * as d3 from "d3";
import "./StackedbarChart.scss";

interface Props {
	loading: boolean;
	hasData: boolean;
	data: any;
	height: number;
	valuechain: string;
	currency: string;
	showBySelectedValue: string;
	comparative: string;
	type: string;
	previous: string
	period: string;
	year: string;
	segment: string;
}

const bandwidth = 100;
const barWidth = 15;
const width = bandwidth * 12;
const negativeAreaGap = 20;
const yAxisSize = 40;
// Graph colours
const colors = {
	"upstream": "#2f1a48", //indigo
	"asset": "#ffbe4f", //sand
	"lmt": "#00a19c", //teal
	"carigaliGroup": "#2f1a48", //indigo
	"marketing": "#ffbe4f", //sand
	"mpm": "#00a19c" //teal
};
const colors2 = {
	"upstream": "#58478d", //twilight
	"asset": "#f5c981", //sand ???
	"lmt": "#9ad8d2", //teal ???
	"carigaliGroup": "#58478d", //twilight
	"marketing": "#f5c981", //sand ???
	"mpm": "#9ad8d2" //teal ???
};
const margin = {
	top: 40,
	right: 60,
	bottom: 40,
	left: 0,
};

const svgWidth = width + margin.right;

export class StackedbarChart extends React.Component<Props>  {
	totalHeight: number;
	height: number;
	isQuarterly: boolean;
	gridNode: React.RefObject<SVGGElement>;
	dataActual: React.RefObject<SVGGElement>;
	dataLastYear: React.RefObject<SVGGElement>;
	yAxisNode: React.RefObject<SVGGElement>;
	xAxisNode: React.RefObject<SVGGElement>;
	negativeTileNode: React.RefObject<SVGRectElement>;
	scroller: React.RefObject<HTMLDivElement>;
	shadow: React.RefObject<HTMLDivElement>;

	constructor(props) {
		super(props);
		this.totalHeight = this.props.height || 500;
		this.height = this.totalHeight - margin.top - margin.bottom;
		this.isQuarterly = this.props.period === "quarterly";
		this.gridNode = React.createRef<SVGGElement>();
		this.dataActual = React.createRef<SVGGElement>();
		this.dataLastYear = React.createRef<SVGGElement>();
		this.yAxisNode = React.createRef<SVGGElement>();
		this.xAxisNode = React.createRef<SVGGElement>();
		this.negativeTileNode = React.createRef<SVGRectElement>();
		this.scroller = React.createRef<HTMLDivElement>();
		this.shadow = React.createRef<HTMLDivElement>();

		this.createGridNode = this.createGridNode.bind(this);
		this.createDataActual = this.createDataActual.bind(this);
		this.createDataLastYear = this.createDataLastYear.bind(this);
		this.createYAxisNode = this.createYAxisNode.bind(this);
		this.createXAxisNode = this.createXAxisNode.bind(this);
		this.createNegativeTileNode = this.createNegativeTileNode.bind(this);
		this.createScroller = this.createScroller.bind(this);
		this.scrollLeft = this.scrollLeft.bind(this)
		this.scrollRight = this.scrollRight.bind(this)
		this.handleScroll = this.handleScroll.bind(this);
	}

	componentDidMount() {
		this.createXAxisNode(null);
		this.createYAxisNode(null);
		this.createGridNode(null)
		this.createNegativeTileNode(null);
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.data !== prevProps.data || this.props.segment !== prevProps.segment) {
			this.isQuarterly = this.props.period === "quarterly";
			let stackedBarChart: any = {}
			stackedBarChart.data = this.props.data;
			stackedBarChart.type = this.props.type;
			stackedBarChart.segments = this.props.valuechain === "CCNG" ? ["carigaliGroup", "marketing", "mpm"] : ["upstream", "asset", "lmt"];
			stackedBarChart.selectedSegment = this.props.segment;
			stackedBarChart = processData(stackedBarChart, this.isQuarterly);
			stackedBarChart = this.createXAxisNode(stackedBarChart);
			stackedBarChart = this.createYAxisNode(stackedBarChart);
			this.createGridNode(stackedBarChart.y)
			this.createNegativeTileNode(stackedBarChart.y);
			this.createDataActual(stackedBarChart.y, stackedBarChart.x, stackedBarChart.processedData, stackedBarChart.processedDataLastYear, true, this.props.segment)
			this.createScroller();
		}
	}


	render() {
		return (
			<div className={classNames("stackedbarchart-ivcr", { "--no-data-overlay": !this.props.loading && !this.props.hasData })} >
				{this.props.loading && <Placeholders.Loader className={classNames("stackedbarchart-ivcr__loader")}></Placeholders.Loader>}
				{!this.props.loading && !this.props.hasData && <Placeholders.NoData className={classNames("stackedbarchart-ivcr__no-data")}></Placeholders.NoData>}
				{this.props.hasData &&
					<div className={classNames("chart stacked-bar-chart", { "--has-overlay": this.props.loading || !this.props.hasData })}>
						<div ref={this.shadow} className="chart__shadow">
							<div ref={this.scroller} className="chart__scroll ">
								<svg
									width={1260} height={this.height} className="chart__main">
									<g className="data" transform={`translate(${yAxisSize},${margin.top})`}>
										<rect ref={this.negativeTileNode} className="data__negative-zone" width={1200} x="0px"></rect>
										<g ref={this.gridNode} className="data__grid" fill="none" fontSize="10" fontFamily="sans-serif" textAnchor="end"></g>
										<g ref={this.dataActual} className="data__actual"></g>
										<g ref={this.dataLastYear} className="data__lastYear"></g>
									</g>
									<g ref={this.yAxisNode} className="y-axis" transform={`translate(${yAxisSize},${margin.top})`} fill="none" fontSize="10" fontFamily="sans-serif"
										textAnchor="end">
									</g>
									<g ref={this.xAxisNode} className="x-axis" transform={`translate(${yAxisSize} , ${this.height + margin.top})`} fill="none" fontSize="10"
										fontFamily="sans-serif" textAnchor="middle">
									</g>
								</svg>
							</div>
						</div>
					</div>
				}
			</div >
		);
	}

	createDataActual(y, x, processedData, processedDataLastYear, includeLastYear, segment) {
		const actual = d3.select(this.dataActual.current);
		actual.selectAll("*").remove();
		const props = this.props;
		const typeText = props.valuechain === "CCNG" ? props.type.charAt(0).toUpperCase() + props.type.substring(1) : "";
		const unitText = props.valuechain === "CCNG" ? (props.data.product === "Natural Gas" ? "MMBtu" : "BOE") : "MMBtu";
		const showby = props.showBySelectedValue === "absolute" ? `${typeText} (${props.currency} million)` : `${typeText} (${props.currency}/${unitText})`
		includeLastYear = (typeof includeLastYear === "undefined") ? true : includeLastYear;

		if (!y || isNullOrUndefined(processedData)) {
			if (includeLastYear) { //Draw Last Year if required
				this.createDataLastYear(y, x, processedDataLastYear, segment);
				!isNullOrUndefined(processedDataLastYear) && this.makeTransition(showby, processedDataLastYear);
			}
			return;
		}
		const _this = this;
		let lasty = this.height - y(0);
		let lastyBtm = this.height - y(0);
		let lastBtmHeight = 0;
		let barTotal = 0;
		//check if data for last year is populated
		if (props.data[props.previous].length === 0) includeLastYear = false;
		segment = (typeof segment === "undefined") ? "all" : segment;
		const stack = actual.selectAll(".data__stack")
			.data(processedData)
			.enter()
			.append("g")
			.attr("class", (d) => {
				return `data__stack actual_"${(_this.isQuarterly ? d.date : getMonth(d.date))}`;
			})
			.attr("data-period", (d) => {
				//This would check if it"s quarterly
				return _this.isQuarterly ? d.date : d.date.toLocaleString("default", { month: "long" })
			})
			.attr("data-breakdown-type", () => {
				return "actual"
			})
			.attr("transform", (d) => {
				return `translate(${x(d.date)}, 0)`;
			});

		const barEnter = stack.selectAll(".data__stack__bar")
			.data((d) => {
				return d.data;
			})
			.enter();

		//Draw
		barEnter
			.append("rect")
			.attr("class", "data__stack__bar")
			// eslint-disable-next-line
			.attr("x", function (this: SVGRectElement) {
				const barX = (bandwidth - barWidth) / 2;
				d3.select(this.parentNode).attr("lastx", barX);
				return barX;
			})
			// eslint-disable-next-line
			.attr("y", function (this: SVGRectElement, d, i) {
				if (i === 0) {
					lasty = _this.height - y(0);
					lastyBtm = _this.height - y(0);
					lastBtmHeight = 0;
					barTotal = 0;
					d3.select(this.parentNode).attr("lasty", Math.round(lasty));
					d3.select(this.parentNode).attr("bar-total", Math.round(barTotal * 100) / 100);
				}

				if (d.value > 0) {
					let itemheight = y(d.value) - y(0);
					itemheight = segment === d.type && itemheight < 5 ? 5 : itemheight;
					if (segment === "all" || segment === d.type) {
						lasty -= itemheight;
						d3.select(this.parentNode).attr("lasty", Math.round(lasty));
						barTotal += d.value;
						d3.select(this.parentNode).attr("bar-total", Math.round(barTotal * 100) / 100);
						return lasty;
					} else {
						return -_this.height * 2; //hide if unclassified
					}
				} else {
					let itemheight = y(0) - y(d.value);
					itemheight = segment === d.type && itemheight > -5 ? -5 : itemheight;
					if (segment === "all" || segment === d.type) {
						lastyBtm += lastBtmHeight;
						lastBtmHeight = itemheight;
						barTotal += d.value;
						d3.select(this.parentNode).attr("bar-total", Math.round(barTotal * 100) / 100);
						return lastyBtm;
					} else {
						return -_this.height * 2; //hide if unclassified
					}
				}

			})
			.attr("width", barWidth)
			.style("fill", (d) => {
				return colors[d.type];
			})
			// eslint-disable-next-line
			.on("mouseover", function (this: SVGRectElement, d) {
				_this.mouseOver(_this.shadow.current, this, d);
			})
			.on("mouseout", _this.mouseOut)
			.style("cursor", "pointer")
			// eslint-disable-next-line
			.on("click", function (this: SVGRectElement, d) {
				_this.mouseClick(this, d)
			})
			.attr("height", (d) => {
				if (d.value !== undefined && Math.round(d.value * 100) / 100 !== 0) {
					let barHeight = Math.abs(y(d.value) - y(0));
					if (segment !== "all") {
						barHeight = barHeight > 5 ? barHeight : 5;
					}
					return barHeight;
				}
				return 0;
			});

		//Add labels
		stack.append("text")
			.attr("class", "bar_label")
			// eslint-disable-next-line
			.text(function (this: SVGTextElement) {
				let total = d3.select(this.parentNode).attr("bar-total");
				total = isNaN(total) ? 0 : total;
				return _format(Math.round(total * 100) / 100, 2)
			})
			.attr("font-size", "10")
			.style("opacity", 0)
			// eslint-disable-next-line
			.attr("y", function (this: SVGTextElement) {
				const total = d3.select(this.parentNode).attr("bar-total");
				if (Math.round(total * 100) / 100 === 0) {
					return -_this.height * 2; //hide if total is zero
				} else {
					return d3.select(this.parentNode).attr("lasty") - 5;
				}
			})
			// eslint-disable-next-line
			.attr("x", function (this: SVGTextElement) {
				return parseInt(d3.select(this.parentNode).attr("lastx")) + barWidth;
			})
			.attr("text-anchor", "end");

		if (includeLastYear) { //Draw Last Year if required
			this.createDataLastYear(y, x, processedDataLastYear, segment);
		}
		else {
			const lastYear = d3.select(this.dataLastYear.current);
			lastYear.selectAll("*").remove();
		}
		stack.append("rect")
			.attr("class", "data__stack__bar overlay_bar")
			.attr("x", (bandwidth - barWidth) / 2)
			.attr("y", 0)
			.attr("width", barWidth)
			.style("fill", () => {
				return "#ffffff";
			})
			.attr("height", (d) => {
				return _this.height;
			})
		this.makeTransition(showby, processedData);
		return;
	}

	createDataLastYear(y, x, processedDataLastYear, segment) {
		const lastYear = d3.select(this.dataLastYear.current);
		lastYear.selectAll("*").remove();
		if (!y || isNullOrUndefined(processedDataLastYear)) {
			return;
		}
		const _this = this;
		let lasty = this.height - y(0);
		let lastyBtm = this.height - y(0);
		let lastBtmHeight = 0;
		let barTotal = 0;
		const stackLY = lastYear.selectAll(".data__stackLY")
			.data(processedDataLastYear)
			.enter()
			.append("g")
			.attr("class", (val) => {
				return `data__stack lastYear_"${(_this.isQuarterly ? val.date : getMonth(val.date))}`;
			})
			.attr("data-period", (d) => {
				return _this.isQuarterly ? d.date : d.date.toLocaleString("default", { month: "long" })
			})
			.attr("data-breakdown-type", () => {
				return "lastYear"
			})
			.attr("transform", (val) => {
				const selector = _this.isQuarterly ? val.date : getMonth(val.date);
				if (!d3.select(`.actual_${selector}`).empty()) {
					d3.select(`.actual_${selector}`).attr("transform", () => {
						return `translate(${(x(val.date) - (barWidth / 2))}, 0)`;
					});
					return `translate(${(x(val.date) + (barWidth / 2))}, 0)`;
				}
				return `translate(${(x(val.date) + (barWidth))}, 0)`;
			});

		const barEnterLY = stackLY.selectAll(".data__stack__barLY")
			.data((val) => {
				return val.data;
			})
			.enter();

		barEnterLY
			.append("rect")
			.attr("class", "data__stack__bar prev-year-bar")
			// eslint-disable-next-line
			.attr("x", function (this: SVGRectElement) {
				const barX = ((bandwidth - barWidth) / 2) + 2;
				d3.select(this.parentNode).attr("lastx", barX);
				return barX;
			})
			// eslint-disable-next-line
			.attr("y", function (this: SVGRectElement, d, i) {
				if (d.value !== undefined) {
					if (i === 0) {
						lasty = _this.height - y(0);
						lastyBtm = _this.height - y(0);
						lastBtmHeight = 0;
						barTotal = 0;
						d3.select(this.parentNode).attr("lasty", Math.round(lasty));
						d3.select(this.parentNode).attr("bar-total", Math.round(barTotal * 100) / 100);
					}
					if (d.value > 0) {
						let itemheight = y(d.value) - y(0);
						itemheight = segment === d.type && itemheight < 5 ? 5 : itemheight;
						if (segment === "all" || segment === d.type) {
							lasty -= itemheight;
							d3.select(this.parentNode).attr("lasty", Math.round(lasty));
							barTotal += d.value;
							d3.select(this.parentNode).attr("bar-total", Math.round(barTotal * 100) / 100);
							return lasty;
						} else {
							return -_this.height * 2; //hide if unclassified
						}
					} else {
						let itemheight = y(0) - y(d.value);
						itemheight = segment === d.type && itemheight > -5 ? -5 : itemheight;
						if (segment === "all" || segment === d.type) {
							lastyBtm += lastBtmHeight;
							lastBtmHeight = itemheight;
							barTotal += d.value;
							d3.select(this.parentNode).attr("bar-total", Math.round(barTotal * 100) / 100);
							return lastyBtm;
						} else {
							return -_this.height * 2; //hide if unclassified
						}
					}
				}
				else {
					return (y(0));
				}
			})
			.attr("width", barWidth)
			.style("fill", (d) => {
				return colors2[d.type];
			})
			// eslint-disable-next-line
			.on("mouseover", function (this: SVGRectElement, d) {
				_this.mouseOver(_this.shadow.current, this, d);
			})
			.style("cursor", "pointer")
			// eslint-disable-next-line
			.on("click", function (this: SVGRectElement, d) {
				_this.mouseClick(this, d)
			})
			.on("mouseout", _this.mouseOut)
			.attr("height", (d) => {
				if (d.value !== undefined && Math.round(d.value * 100) / 100 !== 0) {
					let barHeight = Math.abs(y(d.value) - y(0));
					if (segment !== "all") {
						barHeight = barHeight > 5 ? barHeight : 5;
					}
					return barHeight;
				}
				return 0;
			});
		//Add labels
		stackLY.append("text")
			.attr("class", "bar_label")
			// eslint-disable-next-line
			.text(function (this: SVGTextElement) {
				let total = d3.select(this.parentNode).attr("bar-total");
				total = isNaN(total) ? 0 : total;
				return Math.round(total * 100) / 100
			})
			.attr("font-size", "10")
			.style("opacity", 0)
			// eslint-disable-next-line
			.attr("y", function (this: SVGTextElement) {
				const total = d3.select(this.parentNode).attr("bar-total");
				if (Math.round(total * 100) / 100 === 0) {
					return -_this.height * 2; //hide if total is zero
				} else {
					return d3.select(this.parentNode).attr("lasty") - 5;
				}
			})
			// eslint-disable-next-line
			.attr("x", function (this: SVGTextElement) {
				return parseInt(d3.select(this.parentNode).attr("lastx")) - barWidth;
			})
			.attr("text-anchor", "start")
			.style("transform", `translate(${barWidth * 1.3}px, 0)`);

		stackLY.append("rect")
			.attr("class", "data__stack__bar overlay_bar")
			.attr("x", ((bandwidth - barWidth) / 2) - 1)
			.attr("y", -2) //Added a -2 starting point for Y for each side to properly mask the bar. this is /2 of barwidth additional buffer
			.attr("width", barWidth + 4) //Added a +4 px buffer on each side to properly mask the bar
			.style("fill", () => {
				return "#ffffff";
			})
			.attr("height", () => {
				return _this.height;
			})
		const gridNode = d3.select(this.gridNode.current);
		d3.selectAll(".overlay_bar")
			.transition().duration(720)
			.delay((d, i) => {
				return 100 * i;
			})
			.attr("height", 0)
			.on("end", () => {
				gridNode.select(".zero_indicator").classed("hidden", false);
			});
		return;
	}

	createGridNode(y) {
		if (!y) {
			return;
		}
		const gridNode = d3.select(this.gridNode.current);
		const height = this.height;
		gridNode.select(".domain").remove();
		gridNode.selectAll(".tick text").remove();
		gridNode.selectAll(".tick line")
			.attr("stroke", "#f0f0f0");
		gridNode.append("line")
			.attr("class", "zero_indicator")
			.attr("x1", () => {
				return 0;
			})
			.attr("y1", () => {
				return height - y(0);
			})
			.attr("x2", () => {
				return width;
			})
			.attr("y2", () => {
				return height - y(0);
			})
			.attr("stroke", "#A5A6A8");
		return;
	}
	createNegativeTileNode(y) {
		if (!y) {
			return;
		}
		const negativeTileNode = d3.select(this.negativeTileNode.current);
		const _this = this;
		if (y(0) !== 0) {
			negativeTileNode
				.attr("height", `${y(0) + negativeAreaGap} `)
				.style("opacity", 1)
				.attr("x", "0px")
				.attr("y", `${(_this.height - y(0) + 1)} `)
				.style("fill", "#ecedef");
		}
		else {
			negativeTileNode
				.attr("height", `${y(0)} `)
		}
		const xAxisNode = d3.select(this.xAxisNode.current);
		if (this.props.period === "quarterly") {
			xAxisNode.attr("transform", `translate(${yAxisSize - 100}, ${this.height + margin.top + negativeAreaGap})`);
		} else {
			xAxisNode.attr("transform", `translate( ${yAxisSize} , ${this.height + margin.top + negativeAreaGap})`);
		}
		return;
	}

	createYAxisNode(stackedBarChart) {
		if (!stackedBarChart) {
			return
		}
		const yAxisNode = d3.select(this.yAxisNode.current);
		const gridNode = d3.select(this.gridNode.current);

		yAxisNode.selectAll("*").remove();
		gridNode.selectAll("*").remove();

		const y = d3.scaleLinear();
		const y2 = d3.scaleLinear();
		const yAxis = d3.axisLeft(y2);

		y.domain([stackedBarChart.lowestY, stackedBarChart.highestY])
			.range([0, this.height]);

		// Set _this.highestY to 100 when there"s no data.
		const hasData = (stackedBarChart?.processedData?.length > 0 || stackedBarChart?.processedDataLastYear?.length > 0);
		y2.domain([stackedBarChart.lowestY, hasData ? stackedBarChart.highestY : 100])
			.range([this.height, 0]);

		yAxis.ticks(4);

		if ((stackedBarChart.lowestY >= 0 && stackedBarChart.highestY > 0 && stackedBarChart.highestY < 5) || (stackedBarChart.lowestY > -5 && stackedBarChart.lowestY < 0 && stackedBarChart.highestY <= 0)) {
			yAxisNode.call(yAxis.tickFormat(d3.format(".2"))).select(".domain").remove();
		} else {
			yAxisNode.call(yAxis.tickFormat(d3.format(".2s"))).select(".domain").remove();
		}
		yAxisNode.select(".domain").remove();
		yAxisNode.selectAll(".tick line").remove();

		gridNode.call(yAxis.tickSize(-width));
		stackedBarChart.y = y;
		return stackedBarChart;

	}

	createXAxisNode(stackedBarChart) {
		if (!stackedBarChart) {
			return
		}
		const xAxisNode = d3.select(this.xAxisNode.current);
		xAxisNode.selectAll("*").remove();
		const x = d3.scaleBand();
		const xAxis = d3.axisBottom(x);

		if (this.isQuarterly) {
			x.domain(["Q1", "Q2", "Q3", "Q4"])
				.range([0, width]);
		}
		else {
			x.domain([...Array(12)].map((_, i) => new Date(parseInt(this.props.year), i, 1)))
				.range([0, width]);
			xAxis.tickFormat(d3.timeFormat("%b"));
		}
		stackedBarChart.x = x;
		xAxisNode.call(xAxis)
			.style("text-transform", "uppercase");

		this.isQuarterly && xAxisNode.attr("transform", `translate(${yAxisSize - 100},${this.height + margin.top})`);
		!this.isQuarterly && xAxisNode.attr("transform", `translate(${yAxisSize},${this.height + margin.top})`);

		xAxisNode.select(".domain").remove();
		xAxisNode.selectAll(".tick line").remove();
		return stackedBarChart;
	}

	makeTransition(showby, data) {
		d3.selectAll(".overlay_bar")
			.transition().duration(720)
			.delay((d, i) => {
				return 100 * i;
			})
			.attr("height", 0)
			.on("end", () => {
				d3.select(".data__current-month")
					.transition().duration(200)
					.delay(500)
					.style("opacity", 1);
			});
		d3.selectAll(".bar_label")
			.transition().duration(360)
			.delay(() => {
				return 1000;
			})
			.style("opacity", 1);

		// y-axis label drawing here
		if (d3.select(".stackedbarchart-ivcr").selectAll("y-label")) {
			d3.select(".stackedbarchart-ivcr").selectAll(".y-label").remove();
		}
		d3.select(".stackedbarchart-ivcr").append("b")
			.text(`${showby} `)
			.classed("y-label", true)
			.classed("--has-overlay", data.length === 0);
	}
	toggleResponsive() {
		const element = document.querySelector(".stacked-bar-chart");

		if (document.querySelector(".stacked-bar-chart") !== null && element instanceof HTMLElement &&
			element.offsetWidth >= svgWidth) {
			this.makeResponsive();
		} else {
			this.disableResponsive();
		}
		window.removeEventListener("resize", this.toggleResponsive);
	}

	makeResponsive() {
		d3
			.select(".stackedbarchart-ivcr svg")
			.attr("width", "100%")
			.attr("height", "100%")
			.attr("preserveAspectRatio", "none")
			.attr("viewBox", `0 0 ${svgWidth} ${this.totalHeight + (margin.top / 2)} `);
	}

	disableResponsive() {
		d3
			.select(".stackedbarchart-ivcr svg")
			.attr("width", svgWidth)
			.attr("height", this.totalHeight + (margin.top / 2))
	}

	mouseOver(node, rect, data) {
		const roundedValue = Math.round(data.value * 100) / 100;
		const position = rect.getBoundingClientRect();
		const comparative = this.props.comparative;
		const isPrev = d3.select(rect).classed("prev-year-bar");
		let yearLabel = `Actual ${this.props.year}`;
		let segmentLabel = data.type.charAt(0).toUpperCase() + data.type.slice(1);
		let tooltipText = "";

		if (comparative === "plan" && isPrev) {
			yearLabel = `Plan ${this.props.year}`;
		}

		if (comparative === "yoy" && isPrev) {
			yearLabel = `Actual ${(parseInt(this.props.year) - 1)}`;
		}

		if (data.type === "lmt") {
			segmentLabel = data.type.toUpperCase();
		}

		tooltipText = `${yearLabel}, ${segmentLabel}: ${roundedValue}`;
		const popup = document.createElement("div");
		popup.className = "chart__popup";
		popup.appendChild(document.createTextNode(tooltipText));
		popup.style.position = "fixed";
		popup.style.left = `${(position.left + position.width / 2)}px`;
		popup.style.top = `${position.top - 40}px`;
		popup.style.backgroundColor = colors[data.type];
		node.appendChild(popup);
	}

	mouseOut() {
		d3.select(".chart__popup").remove();
	}

	mouseClick(ele, data) {
		const trailEventListener = new CustomEvent("onLineBarClick", {
			detail: {
				type: data.type,
				period: ele.parentNode.getAttribute("data-period"),
				breakdownType: ele.parentNode.getAttribute("data-breakdown-type")
			}
		});
		document.dispatchEvent(trailEventListener);
	}

	handleScroll() {
		if (this.scroller.current) {
			const clientRect = this.scroller.current.getBoundingClientRect();
			const shadowDiv = d3.select(this.shadow.current);
			if (clientRect && clientRect.width >= svgWidth) {
				shadowDiv.classed("chart__shadow--disable", true);
				shadowDiv.selectAll(".chart__scroll__indicator").remove();
				return;
			}
			shadowDiv.classed("chart__shadow--disable", false);
			if (clientRect.width + this.scroller.current.scrollLeft >= svgWidth) {
				shadowDiv.classed("chart__shadow--end", true);
				shadowDiv.classed("chart__shadow--start", false);
				if (!shadowDiv.select(".chart__scroll__indicator--start").node()) {
					shadowDiv.append("div")
						.attr("class", "chart__scroll__indicator chart__scroll__indicator--start")
						.node().addEventListener("click", this.scrollLeft);
				}
				shadowDiv.select(".chart__scroll__indicator--end").remove();
			} else if (this.scroller.current.scrollLeft === 0) {
				shadowDiv.classed("chart__shadow--end", false);
				shadowDiv.classed("chart__shadow--start", true);
				if (!shadowDiv.select(".chart__scroll__indicator--end").node()) {
					shadowDiv.append("div")
						.attr("class", "chart__scroll__indicator chart__scroll__indicator--end")
						.node().addEventListener("click", this.scrollRight);
				}
				shadowDiv.select(".chart__scroll__indicator--start").remove();
			} else {
				shadowDiv.classed("chart__shadow--end", false);
				shadowDiv.classed("chart__shadow--start", false);
				if (!shadowDiv.select(".chart__scroll__indicator--start").node()) {
					shadowDiv.append("div")
						.attr("class", "chart__scroll__indicator chart__scroll__indicator--start")
						.node().addEventListener("click", this.scrollLeft);
				}
				if (!shadowDiv.select(".chart__scroll__indicator--end").node()) {
					shadowDiv.append("div")
						.attr("class", "chart__scroll__indicator chart__scroll__indicator--end")
						.node().addEventListener("click", this.scrollRight);
				}
			}
			shadowDiv.selectAll(".chart__scroll__indicator")
				.style("top", `${(this.height + margin.top + 8)}px`);
		}
	}

	scrollLeft() {
		if (this.scroller.current) {
			const target = this.scroller.current.scrollLeft - 100;
			smoothScrollLeft(this.scroller.current, target, 300);
		}
	}

	scrollRight() {
		if (this.scroller.current) {
			const target = this.scroller.current.scrollLeft + 100;
			smoothScrollLeft(this.scroller.current, target, 300);
		}
	}

	createScroller() {
		if (this.props.hasData) {
			const scroller = d3.select(this.scroller.current);
			smoothScrollLeft(scroller.node(), null, 300);
			scroller.node().addEventListener("scroll", this.handleScroll);
			window.addEventListener("resize", this.handleScroll);
			this.handleScroll();
			this.toggleResponsive();
			window.addEventListener("resize", this.toggleResponsive.bind(this));
		}
	}
}

function processData(stackedBarChart, isQuarterly) {
	if (!isNullOrUndefined(stackedBarChart.data)) {
		const thisYear = stackedBarChart.data["year"];
		stackedBarChart.highestY = 0;
		stackedBarChart.lowestY = 0;
		if (stackedBarChart?.data?.actual?.length) {
			stackedBarChart = processDataStream("actual", thisYear, stackedBarChart, isQuarterly, "processedData");
		}
		if (stackedBarChart?.data?.previous?.length) {
			stackedBarChart = processDataStream("previous", thisYear, stackedBarChart, isQuarterly, "processedDataLastYear");
		}

	}
	return stackedBarChart;
}

function processDataStream(stream, thisYear, stackedBarChart, isQuarterly, name) {
	const _this = stackedBarChart;
	const year = thisYear;
	const sortedData: any[] = [];
	let newLowestY = stackedBarChart.lowestY;
	let newHighestY = stackedBarChart.highestY;
	_this.data[stream].forEach((d) => {
		const date = isQuarterly ? d.period : new Date(`${d.period} 1 ${year} `);
		let segmentHeight = 0;
		let segmentHeightLow = 0;
		const data: any[] = [];
		_this.segments.forEach((segment) => {
			if (typeof d[segment] === "undefined") return; // check if the segment is in data
			if (typeof d[segment][_this.type] === "undefined" && _this.valuechain !== "CCNG") return; // check if the value needed is in the segment data
			if (_this.selectedSegment === "all" || _this.selectedSegment === segment) {
				//zero negative
				const value = d[segment][_this.type];
				if (value < 0) {
					segmentHeightLow += value;
				} else {
					segmentHeight += value;
				}
				data.push({
					value: value,
					type: segment
				});
			}
		});
		sortedData.push({ date: date, data: data })
		if (segmentHeightLow < newLowestY) { //Find the lowest value
			newLowestY = segmentHeightLow;
		}
		if (segmentHeight > newHighestY) { //Find the highest value
			newHighestY = segmentHeight;
		}
	});
	_this[name] = sortedData;
	_this.lowestY = newLowestY;
	_this.highestY = newHighestY;
	return _this;
}
function getMonth(key) {
	return new Date(key).getMonth();
}
function _format(num, decimalPlace) {
	num = num ? num : 0;
	return (new Intl.NumberFormat("en-US", {
		maximumFractionDigits: decimalPlace !== undefined ? decimalPlace : 2,
		minimumFractionDigits: decimalPlace !== undefined ? decimalPlace : 2
	}).format(num))
}
function smoothScrollLeft(node, target, duration) {
	if (!window.requestAnimationFrame) {
		node.scrollLeft = target;
		return;
	}
	const time = duration || 300;
	const currentPosition = node.scrollLeft;
	const distance = target - currentPosition;
	const start = new Date().getTime();
	const end = start + time;
	const animateScroll = function () {
		const timestamp = new Date().getTime();
		const progress = Math.min((time - (end - timestamp)) / time, 1);
		const move = distance * progress;
		node.scrollLeft = currentPosition + move;
		if (progress >= 1) {
			return;
		}
		requestAnimationFrame(animateScroll);
	};
	animateScroll();
}
