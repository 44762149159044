import { AppStore } from "store"
import { AppStoreProps } from "store/app/app.store"
import { BoxAndLabel } from "components/molecules/Legend/BoxAndLabel/BoxAndLabel";
import { CCNGTrendAnalysisFilter } from "./components/CCNGTrendAnalysisFilter";
import { ComparativeType } from "types/ComparativeType";
import { DropdownItemType } from "components/organisms/Dropdown/Dropdown";
import { isNullOrUndefined } from "util";
import { Legend } from "components/organisms/LegendContainer/Legend/Legend";
import { LegendContainer } from "components/organisms/LegendContainer/LegendContainer";
import { LoadProductType } from "../ValueLevers/CCNGValueLevers.service";
import { ProductListType } from "types/ProductListType";
import { Properties } from "helpers/properties";
import React from "react"
import { TrendAnalysisBarType } from "types/TrendAnalysisType";
import { TrendAnalysisType } from "../../../types/CCNGtype";
import { useEffect } from "react"
import { CCNGStore, CCNGStoreProps } from "store/ccng/ccng.store";
import { CCNGUtil, Util } from "helpers/util/util";
import { GetCCNGTrendAnalysisStackedBarChart, LoadRegion, LoadYears } from "./CCNGTrendAnalysis.service";
import { HeaderSubtitle, OverlayPopUp, StickyElement } from "components/molecules";
import "./CCNGTrendAnalysis.scss";
import { StackedbarChart } from "components/molecules/StackedbarChart/StackedbarChart";

type Props = AppStoreProps & CCNGStoreProps;
type State = {
	type: TrendAnalysisBarType;
	productTypes: ProductListType[];
	productType: string;
	region: string;
	regionList: Array<DropdownItemType>;
	yearList: Array<DropdownItemType>;
	data: TrendAnalysisType;
	loading: boolean;
	segment: string;
	informationPopupType?: {
		type: "carigaliGroup" | "mpm" | "marketing" | "period",
		period: string,
		breakdownType: string
	};
}

/**
 * This should always return new state.
 * **/
function reducer(state: State, action: { type: string, data?: any, key?: string, [key: string]: any }) {
	switch (action.type) {
		case "UPDATE_FILTER":
			if (!action["key"]) {
				throw new Error("You must pass 'key' in action for 'UPDATE_FILTER' to work");
			}
			if (!action["data"]) {
				throw new Error("You must pass 'data' in action for 'UPDATE_FILTER' to work");
			}
			return { ...state, [action.key]: action.data };

		case "UPDATE_LOADING":
			return { ...state, loading: action.data };

		case "UPDATE_TREND_ANALYSIS_DATA":
			if (!action["data"]) {
				throw new Error("You must pass 'data' in action for 'UPDATE_TREND_ANALYSIS_DATA' to work");
			}
			return { ...state, data: action.data };

		case "UPDATE_INFORMATION_POPUP":
			return {
				...state, informationPopupType: action.data as {
					type: "carigaliGroup" | "mpm" | "marketing" | "period",
					period: string,
					breakdownType: string
				}
			};
		default:
			throw new Error();
	}
}

/*
	FUNCTION NAME: CCNGTrendAnalysis
	DESCRIPTION: The function that renders the CCNG Trend Analysis screen
*/
function CCNGTrendAnalysis({ filters, dictionary, ccng }: Props) {
	const [state, dispatch] = React.useReducer(reducer, {
		type: "margin",
		productType: "all",
		data: {},
		productTypes: [],
		loading: false,
		region: "all",
		segment: "all",
		regionList: [{ label: "All", id: "all" }],
		yearList: [],
		informationPopupType: undefined
	});

	let currentProductTypeList = [{ label: "All", id: "all" }];

	const showBy = filters.get("showBy");
	const currency = filters.get("currency");
	const year = filters.get("year");
	let period = filters.get("period");

	// CustomLogic: Custom logic here.
	switch (period) {
		case "monthly":
		case "quarterly":
			break;
		default:
			period = "monthly"
	}

	const dictionaryList = dictionary.get("dictionary");
	const ESTIMATED_APP_HEADER_HEIGHT = 2.9125;
	const product = ccng.get("product");
	const segment = ccng.get("segment");
	const profitability = ccng.get("profitability");

	// CustomLogic: Custom logic here.
	const comparative: ComparativeType = ccng.get("comparative") === "plan" ? "plan" : "yoy";

	// CustomLogic: Custom logic here.
	switch (period) {
		case "monthly":
		case "quarterly":
			break;
		default:
			period = "monthly"
	}
	const handleFilterChange = (key: string, value: any) => {
		switch (key) {
			case "showBy":
			case "year":
			case "period":
				// this will trigger re-render too but the values are global to component.
				filters.set(key)(value.id);
				break;

			case "currency":
				filters.set("currency")({ name: value.label, currencySymbol: Properties.getCurrencySymbol(value.id) })
				break;
			case "product":
			case "segment":
			case "profitability":
			case "comparative":
				ccng.set(key)(value.id);
				break;
			default:
				// this will trigger re-render too but the values are local to component.
				dispatch({ type: "UPDATE_FILTER", key: key, data: value.id });
		}
	};
	const handleData = (value: any) => {
		dispatch({ type: "UPDATE_TREND_ANALYSIS_DATA", data: value });
		toggleLoadingChange(false);
	}
	const toggleLoadingChange = (value: boolean) => dispatch({ type: "UPDATE_LOADING", data: value });

	const getApiParams = () => {
		return {
			showBy: showBy,
			currency: currency.name,
			comparative: comparative,
			periodType: period,
			year: year,
			product: product,
			productType: state.productType,
			profitability: profitability,
			region: state.region,
			type: state.type
		}

	}



	const _legendLabel = () => {
		if (Object.keys(state.data).length) {
			if (comparative === "plan") {
				return {
					left: `Actual ${year}`,
					right: `Plan ${year}`
				};
			} else {
				// eslint-disable-next-line
				if (state.data["previous"].length) {
					return {
						left: `Actual ${year}`,
						right: `Actual ${parseInt(year) - 1}`
					};
				} else {
					return { left: "", right: `Actual ${year}` };
				}
			}
		}
		return "";
	};

	const renderPopupPortal = () => {
		const typeName: string = state.informationPopupType?.type || "";
		let monthValue;

		if (state.informationPopupType?.breakdownType === "lastYear") {
			monthValue = state.data?.previous.find((x) => x.period === state.informationPopupType?.period);
		} else {
			monthValue = state.data?.actual.find((x) => x.period === state.informationPopupType?.period);
		}

		if (!monthValue) return; //This is for error checks

		let popupHeaderName = typeName;
		switch (state.informationPopupType?.type) {
			case "carigaliGroup":
				if (state.informationPopupType?.breakdownType === "lastYear") {
					popupHeaderName = `Plan  ${state.informationPopupType?.period}  ${year} , Carigali Group`;
				} else {
					popupHeaderName = `${((state.informationPopupType?.breakdownType).charAt(0).toUpperCase() + (state.informationPopupType?.breakdownType).slice(1))}  ${state.informationPopupType?.period}  ${year} , Carigali Group`;
				}
				break
			case "mpm":
				if (state.informationPopupType?.breakdownType === "lastYear") {
					popupHeaderName = `Plan  ${state.informationPopupType?.period}  ${year} , MPM`;
				} else {
					popupHeaderName = `${((state.informationPopupType?.breakdownType).charAt(0).toUpperCase() + (state.informationPopupType?.breakdownType).slice(1))}  ${state.informationPopupType?.period}  ${year} , MPM`;
				}
				break
			case "period":
				popupHeaderName = "Period";
				break
			case "marketing":
				if (state.informationPopupType?.breakdownType === "lastYear") {
					popupHeaderName = `Plan  ${state.informationPopupType?.period}  ${year} , Marketing`;
				} else {
					popupHeaderName = `${((state.informationPopupType?.breakdownType).charAt(0).toUpperCase() + (state.informationPopupType?.breakdownType).slice(1))}  ${state.informationPopupType?.period}  ${year} , Marketing`;
				}
				break
			default:
				break;
		}
		monthValue[typeName]?.subSegment && Util.sortArray(monthValue[typeName]?.subSegment, "margin", "DSC");

		return <OverlayPopUp headerTitle={popupHeaderName} onPopup={handleBarPopupOpenClick}>
			<table cellSpacing={0}
				cellPadding={0} >
				<tbody>
					{monthValue[typeName]?.subSegment?.map((item, i) => <tr key={i}>
						<td className="table__body" >
							{item.name}
						</td>
						<td className="table__body" >
							{Util.formatDigits(item[state.type], showBy)}
						</td>
					</tr>)}
				</tbody>
			</table>
		</OverlayPopUp>
	}

	useEffect(() => {
		LoadProductType().then((data) => {
			handleFilterChange("productTypes", { id: data });
		});

		LoadRegion().then((data) => {
			handleFilterChange("regionList", { id: CCNGUtil.loadRegion(data) });
		});

		LoadYears().then((data) => {
			handleFilterChange("yearList", { id: CCNGUtil.loadYears(data) });
		});
		// eslint-disable-next-line
	}, [])


	useEffect(() => {
		toggleLoadingChange(true);
		GetCCNGTrendAnalysisStackedBarChart(
			getApiParams()
		).then((result) => {
			handleData(result);
		});
		// eslint-disable-next-line
	}, [currency, showBy, year, comparative, state.type, period, product, state.region, state.productType, profitability]);

	useEffect(() => {
		dispatch({ type: "UPDATE_FILTER", key: "segment", data: segment });
		// eslint-disable-next-line
	}, [segment])

	const handleBarPopupOpenClick = (event: CustomEventInit | null) => { dispatch({ type: "UPDATE_INFORMATION_POPUP", data: event?.detail || null }) }
	React.useEffect(() => {
		// If there"s no data, just ignore this effect.
		if (isNullOrUndefined(state.data)) {
			return;
		}
		document.addEventListener("onLineBarClick", handleBarPopupOpenClick);
		return () => {
			document.removeEventListener("onLineBarClick", handleBarPopupOpenClick);
		}
		// eslint-disable-next-line
	}, [state.data])

	currentProductTypeList = CCNGUtil.loadCurrentProductType(product, state.productTypes, currentProductTypeList);
	const isDataAvailable = (state.data?.actual && state.data?.actual.length > 0) || (state.data?.previous && state.data?.previous.length > 0) || false;
	return (
		<section className="CCNGTrendAnalysis">
			<HeaderSubtitle heading="Trend Analysis" subtitle={Properties.getDictionaryText(dictionaryList, `CCNG_trend_analysis_subheader`, "en")} className="CCNGTrendAnalysis-header-with-subtitles" />
			<StickyElement to="top" floatBy={ESTIMATED_APP_HEADER_HEIGHT} className="CCNGTrendAnalysis-filters" stickyClassName="sticky">
				<CCNGTrendAnalysisFilter
					onEvent={handleFilterChange}
					showBy={showBy}
					currency={currency}
					type={state.type}
					year={year}
					yearList={state.yearList}
					period={period}
					comparative={comparative}
					segment={segment}
					product={product}
					productType={state.productType}
					region={state.region}
					regionList={state.regionList}
					productTypeList={currentProductTypeList}
					profitability={profitability}
					hideProfitability={state.type !== "margin"}
					stickyClassName="CCNGTrendAnalysis-filters" //This stickyClassName is meant for the JS for scrolling
				/>
			</StickyElement>

			<div className="CCNGTrendAnalysis__chart">
				{segment === "all" && isDataAvailable && <p className="label-text">*All the figures in the Trend Analysis are pre-consolidation</p>}
				<StackedbarChart
					loading={state.loading}
					hasData={isDataAvailable}
					data={state.data}
					height={600}
					valuechain="CCNG"
					currency={currency.name}
					showBySelectedValue={showBy}
					comparative={comparative || "plan"}
					type={state.type}
					previous="previous"
					period={period}
					year={year}
					segment={state.segment}
				></StackedbarChart>
				{isDataAvailable &&
					<div className="legend-container-wrap">
						<LegendContainer className="CCNGTrendAnalysis-legend">
							{_legendLabel()["left"] && (
								<Legend year={_legendLabel()["left"]}>
									<BoxAndLabel label="Carigali Group" boxColor="indigo" box="box" />
									<BoxAndLabel label="Marketing" boxColor="sand" box="box" />
									<BoxAndLabel label="MPM" boxColor="teal" box="box" />
								</Legend>
							)}
							{_legendLabel()["right"] && (
								<Legend year={_legendLabel()["right"]}>
									<BoxAndLabel label="Carigali Group" boxColor="twilight" box="box" />
									<BoxAndLabel label="Marketing" boxColor="lightsand" box="box" />
									<BoxAndLabel label="MPM" boxColor="lightaqua" box="box" />
								</Legend>
							)}
						</LegendContainer>
					</div>
				}
			</div>
			{state.informationPopupType && renderPopupPortal()}


		</section>
	)
}

export default AppStore.withStores(CCNGStore.withStores(CCNGTrendAnalysis));
