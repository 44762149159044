import "./Checkbox.scss";
import React from "react";
import classNames from "classnames";

interface Props {
	htmlFor: string;
	label?: string;
	className?: string;
	checked?: boolean;
	disabled?: boolean;
	onChange?: (htmlFor: string, newState: boolean) => void;
	size?: "small" | "medium" | "large" | "extra-large";
}

export function Checkbox({ htmlFor, label, className, checked, disabled, onChange, size = "medium" }: Props) {
	return (
		<div className={classNames("label-checkbox", className, `checkbox-size-${size}`, { "checked": checked, "disabled": disabled })}>
			<input
				type="checkbox"
				className={"checkbox"}
				id={htmlFor}
				name={htmlFor}
				checked={checked}
				onChange={(e) => {
					onChange && onChange(htmlFor, e.target.checked);
				}} />
			<label className={classNames({ "checked": checked })} htmlFor={htmlFor}>{label}</label>
		</div>
	);
}
