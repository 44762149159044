import { RouteType } from "types/RouteType";
import ContributionTrends from "./ContributionTrends/ContributionTrends";
import CostMarginPage from "./CostMargin/CostMargin";
import LNGHome from "./LNGHome";
import Overview from "./Overview/Overview";
import TrendAnalysis from "./TrendAnalysis/TrendAnalysis";
import ValueLevers from "./ValueLevers/ValueLevers";
import SRMC from "./SRMC/SRMC";

export default {
	label: "LNG",
	header: "Liquified Natural Gas",
	icon: "lng-ship",
	path: "/lng",
	component: LNGHome,
	subpages: [
		{
			label: "LNG Overview",
			path: "overview",
			component: Overview,
			icon: "lng-ship"
		},
		{
			label: "Trend Analysis",
			path: "trend-analysis",
			component: TrendAnalysis,
			icon: "trend"
		},
		{
			label: "Cost/Margin Buildup",
			path: "cost-margin-buildup",
			component: CostMarginPage,
			icon: "buildup"
		},
		{
			label: "Value Levers",
			path: "value-levers",
			component: ValueLevers,
			icon: "value-lever"
		},
		{
			label: "Contribution Trends",
			path: "contribution-trends",
			component: ContributionTrends,
			icon: "contribution"
		},
		{
			label: "SRMC",
			path: "srmc",
			component: SRMC,
			icon: "buildup"
		},

	]
} as RouteType;
