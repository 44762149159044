import Maintenance from "./Maintenance/Maintenance";
import React from "react";
import Unauthorised from "./Unauthorised/Unauthorised";
import AppRoutes from "./route";
import { AppStore } from "store";
import { AppStoreProps } from "store/app/app.store";
import { createBrowserHistory } from "history";
import { isNullOrUndefined } from "util";
import {
	GetApplicationSettings,
	GetLastUpdated,
	LoadDictionary,
} from "./app.service";
import { LoadingScreen } from "./LoadingScreen/LoadingScreen";
import { Redirect, Route, Router, Switch } from "react-router";
import { useEffect, useState } from "react";
import { Permission } from "types/UserPermissions";

import TagManager from "react-gtm-module";
import { CSATForm } from "./CSAT/CSATForm";

export const dataLayer = (window as any).dataLayer || [];
export const AppHistory = createBrowserHistory();
let userPermissions: Permission[] = [{ id: 1, permissionName: "" }];
export default function App() {
	const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);
	const [isMaintenanceMode, setIsMaintenanceMode] = useState<boolean | null>(
		null
	);

	const handleInit = (result: 200 | 401 | 503) => {
		switch (result) {
			case 401:
				setIsAuthorized(false);
				return;
			case 503:
				setIsMaintenanceMode(true);
				return;
			case 200:
				setIsAuthorized(true);
				setIsMaintenanceMode(false);
				return;
			default:
				return;
		}
	};

	const crypto = require("crypto");
	const nonce = crypto.randomBytes(16).toString("base64");

	const metas = document.getElementsByTagName("meta");
	for (let i = 0; i < metas.length; i++) {
		const srcPath = metas[i].getAttribute("http-equiv");
		if (srcPath === "Content-Security-Policy") {
			metas[i].setAttribute(
				"content",
				`script-src 'nonce-${nonce}' 'strict-dynamic' 'self' https://www.google-analytics.com https://analytics.google.com https://www.googletagmanager.com;
									style-src 'self' 'unsafe-inline' https://www.googletagmanager.com https://fonts.googleapis.com;
									frame-src 'self' https://login.microsoftonline.com ;
									object-src 'none'`
			);
		}
	}

	const scripts = document.getElementsByTagName("script");
	for (let i = 0; i < scripts.length; i++) {
		const srcPath = scripts[i].getAttribute("src");
		if (srcPath?.includes("/dist/js/") || srcPath?.includes("/static/js/")) {
			scripts[i].setAttribute("nonce", nonce);
		}
	}

	return (
		<AppStore.Container>
			{/* If loading... */}
			{isNullOrUndefined(isAuthorized) &&
				isNullOrUndefined(isMaintenanceMode) && (
					<>
						<AppInit onInit={handleInit} />
						<LoadingScreen />
					</>
				)}

			{/* If loaded and no access */}
			{!isNullOrUndefined(isAuthorized) && !isAuthorized && <Unauthorised />}
			{!isNullOrUndefined(isMaintenanceMode) && isMaintenanceMode && (
				<Maintenance />
			)}

			{/* If loaded and has access */}
			{!isNullOrUndefined(isAuthorized) && isAuthorized && (
				<>
					<YearFilterUpdate></YearFilterUpdate>
					<CSATForm></CSATForm>
					<Router history={AppHistory}>
						<Switch>
							{AppRoutes.map((route) => {
								if (userPermissions !== null) {
									const isAllowed = userPermissions.find(
										(ele) => ele?.permissionName === route.path
									);
									if (
										isAllowed !== undefined ||
										route.path === "/401" ||
										route.path === "/503"
									) {
										return (
											<Route
												key={route.path}
												path={route.path}
												render={route.component}
											/>
										);
									}
								}
								return null;
							})}
							{/* If nothing exists goto /lng */}
							{userPermissions !== null && userPermissions.length > 0 && (
								<Redirect exact from="/" to={userPermissions[0].permissionName} />
							)}
							<Redirect from="**" to="/401" />
						</Switch>
					</Router>
				</>
			)}
		</AppStore.Container>
	);
}

const AppInit = AppStore.withStores(
	({
		dictionary,
		global,
		onInit,
		permissions,
	}: AppStoreProps & { onInit?: (state: 200 | 401 | 503) => void }) => {
		useEffect(() => {
			const tagManagerArgs = {
				gtmId: process.env["REACT_APP_GTM_ID"],
				auth: process.env["REACT_APP_GTM_AUTH"],
				preview: process.env["REACT_APP_GTM_PREVIEW"],
			};
			TagManager.initialize(tagManagerArgs);
			GetApplicationSettings()
				.then((result) => {
					userPermissions = result.data.permissions;
					permissions.set("permissions")(userPermissions);
					onInit && onInit(200);
					try {
						TagManager.dataLayer({
							dataLayer: { event: "Login", uid: result.data.userId }
						});
						console.log(`Login event: ${result.data.userId}`);
					} catch (error) {
						console.log("error GA4 push data layer");
					}
				})
				.catch((e) => {
					switch (e && e["response"] && e["response"]["status"]) {
						case 401:
							onInit && onInit(401);
							break;
						case 503:
							onInit && onInit(503);
							break;
						default:
							break;
					}
				});

			GetLastUpdated().then((result) => {
				global.set("lastUpdatedDate")(result);
			});
			LoadDictionary().then((newDictionary) =>
				dictionary.set("dictionary")(newDictionary)
			);
			// eslint-disable-next-line
		}, []);

		return <></>;
	}
);

const YearFilterUpdate = AppStore.withStores(({ filters }: AppStoreProps) => {

	const [currentLocation, setCurrentLocation] = useState(window.location.href);

	useEffect(() => {

		const interval = setInterval(() => {
			if (currentLocation !== window.location.href) {
				setCurrentLocation(window.location.href);

				if (window.location.href.includes("lng")) {
					if (window.location.href.includes("srmc")) {
						filters.set("year")(filters.get('srmcYear'));
						filters.set("month")(filters.get('srmcMonth'));
					} else {
						filters.set("year")(filters.get('lngYear'));
						filters.set("month")(filters.get('lngMonth'));
					}
				} else {
					filters.set("year")(filters.get('ccngYear'));
					filters.set("month")(filters.get('ccngMonth'));
				}
			}
		}, 1);

		return () => clearInterval(interval);
	}, [currentLocation]);

	return <></>
});