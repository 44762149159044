import React from "react";
import classNames from "classnames";
import { TypographyProps } from "./Typography";

type Props = TypographyProps;

export function TextNormal({ children, className, color = "inherit", weight = 300, uppercase = false, wrap = false }: Props) {
	return (
		<p className={classNames("text-normal", className, { [`color-${color}`]: color }, { [`weight-${weight}`]: weight }, { "text-uppercase": uppercase }, { "text-wrap": wrap })}>
			{children}
		</p>
	);
}
