import { DropdownItemType } from "components/organisms/Dropdown/Dropdown";
import { isNullOrUndefined } from "util";
import moment from "moment";
import { Properties } from "helpers/properties";

class IVCI_Util {
	getActualOrPlanText = (planText, comparative, month, quarter, year, periodType, previous = false) => {
		let label = planText;

		if (comparative === "plan") {
			return label;
		}
		if (previous) {
			label = `Actual ${(parseInt(year) - 1)}`;
			if (comparative === "actual_preceding" && periodType === "monthly") {
				label = `Actual  ${Properties.getPreviousMonth(month, year).month.substring(0, 3)}`
			}
			else if (comparative === "actual_preceding" && periodType === "quarterly") {
				label = `Actual  ${Properties.getPreviousQuarter(quarter, year).quarter}`
			}
		}
		else {
			label = `Actual ${parseInt(year)}`;
			if (comparative === "actual_preceding" && periodType === "monthly") {
				label = `Actual  ${month.substring(0, 3)}`
			}
			else if (comparative === "actual_preceding" && periodType === "quarterly") {
				label = `Actual  ${quarter}`
			}
		}
		return label;
	}

	capitalize = (name: string) => {
		return name.charAt(0).toUpperCase() + name.slice(1);
	};

	formatDigits = (num: number, showBy: string, decimalPlace?: number) => {
		if (isNullOrUndefined(num)) {
			return "-";
		}
		num = num ? num : 0;
		return new Intl.NumberFormat("en-US", {
			maximumFractionDigits:
				decimalPlace !== undefined ? decimalPlace : this.getDecimalPlace(showBy),
			minimumFractionDigits:
				decimalPlace !== undefined ? decimalPlace : this.getDecimalPlace(showBy)
		}).format(num);
	};

	getDecimalPlace = (showBy: string) => {
		return showBy === "mmbtu" ? 2 : 0;
	};

	getFormattedLastUpdated = (dateStr: string) => {
		const formattedDate = moment(dateStr).toDate();
		//const day = formattedDate.getDay()
		const month = formattedDate.toLocaleString("default", { month: "long" });
		const year = formattedDate.getFullYear()
		return `${month} ${year}`;
	}

	sortArray = (arr, sortBy, type: "ASC" | "DSC") => {
		if (type === "DSC") {
			return arr.sort((a, b) => {
				if (a?.[sortBy] > b?.[sortBy]) {
					return -1;
				}
				if (a?.[sortBy] < b?.[sortBy]) {
					return 1;
				}
				return 0;
			});
		} else {
			return arr.sort((a, b) => {
				if (a?.[sortBy] > b?.[sortBy]) {
					return 1;
				}
				if (a?.[sortBy] < b?.[sortBy]) {
					return -1;
				}
				return 0;
			});
		}

	}

	areObjectsEqual = (objectA, objectB) => {
		// Create arrays of property names
		const aProps = Object.getOwnPropertyNames(objectA);
		const bProps = Object.getOwnPropertyNames(objectB);

		// If number of properties is different,
		// objects are not equivalent
		if (aProps.length !== bProps.length) {
			return false;
		}

		for (let i = 0; i < aProps.length; i++) {
			const propName = aProps[i];

			// If values of same property are not equal,
			// objects are not equivalent
			if (objectA[propName] !== objectB[propName]) {
				return false;
			}
		}

		// If we made it this far, objects
		// are considered equivalent
		return true;
	}

	compareBy = (key: any, type: string) => {
		if (type === "asc") {
			return (a, b) => {
				if (a[key] < b[key]) return -1;
				if (a[key] > b[key]) return 1;
				return 0;
			};
		}
		else {
			return (a, b) => {
				if (a[key] > b[key]) return -1;
				if (a[key] < b[key]) return 1;
				return 0;
			};
		}

	}
	compareByAbsolute = (key: any, type: string) => {
		if (type === "asc") {
			return (a, b) => {
				if (Math.abs(a[key]) < Math.abs(b[key])) return -1;
				if (Math.abs(a[key]) > Math.abs(b[key])) return 1;
				return 0;
			};
		} else {
			return (a, b) => {
				if (Math.abs(a[key]) > Math.abs(b[key])) return -1;
				if (Math.abs(a[key]) < Math.abs(b[key])) return 1;
				return 0;
			};
		}
	}


}


class IVCI_CCNG_Util {
	capitalize = (name: string) => {
		return name.charAt(0).toUpperCase() + name.slice(1);
	};
	loadCurrentProductType = (key: string, productTypeData, currentProductTypeList) => {
		let data = currentProductTypeList;
		if (productTypeData.length) {
			const currentProductType: Array<DropdownItemType> = [];
			currentProductType.push({ label: "All", id: "all" });
			for (let i = 0; i < productTypeData.length; i++) {
				const product: {} = productTypeData[i];
				if (product["product"] === key) {
					for (let j = 0; j < product["productTypes"].length; j++) {
						if (product["productTypes"][j] !== "all") {
							currentProductType.push({
								label: this.capitalize(product["productTypes"][j]),
								id: product["productTypes"][j]
							});
						}
					}
				}
			}
			data = currentProductType;
		}
		return data;
	}

	loadCurrentSubSegment = (key: string, subsegmentsData, currentSubsegmentTypeList) => {
		let data = currentSubsegmentTypeList;
		if (subsegmentsData.length) {
			const segments = subsegmentsData;
			const currentSubsegmentList: Array<DropdownItemType> = [];
			currentSubsegmentList.push({ label: "All", id: "all" });
			for (let i = 0; i < segments.length; i++) {
				if (segments[i]["segment"].trim().toLowerCase() === key.trim().toLowerCase()) {
					for (let j = 0; j < segments[i]["subSegments"].length; j++) {
						currentSubsegmentList.push({
							label: Util.capitalize(segments[i]["subSegments"][j]),
							id: segments[i]["subSegments"][j]
						});
					}
				}
			}
			data = currentSubsegmentList;
		}
		return data;
	}

	loadRegion = (data) => {
		const regionList: Array<DropdownItemType> = [];
		regionList.push({ label: "All", id: "all" });
		for (let i = 0; i < data.length; i++) {
			if (data[i] !== "all") {
				regionList.push({
					label: Util.capitalize(data[i]),
					id: data[i]
				});
			}
		}
		return regionList;
	}

	loadYears = (data) => {
		const yearList: Array<DropdownItemType> = [];
		for (let i = 0; i < data.length; i++) {
			yearList.push({
				label: data[i],
				id: data[i]
			});
		}
		return yearList;
	}
}

class IVCI_LNG_Util {
	loadCurrentSubSegment = (key: string, subsegmentsData) => {
		let subSegmentList: Array<DropdownItemType> = [];
		// Dynamically construct dropdown list for subsegments.
		const currentSegmentItem = subsegmentsData.find((s) => s.name === key);
		if (!isNullOrUndefined(currentSegmentItem)) {
			subSegmentList = currentSegmentItem.subSegments.map((sub) => {
				return { id: sub, label: Util.capitalize(sub) }
			});
		}
		return subSegmentList;
	}

	loadYears = (data) => {
		const yearList: Array<DropdownItemType> = [];
		for (let i = 0; i < data.length; i++) {
			yearList.push({
				label: data[i],
				id: data[i]
			});
		}
		return yearList;
	}
}

export const Util = new IVCI_Util();
export const CCNGUtil = new IVCI_CCNG_Util();
export const LNGUtil = new IVCI_LNG_Util();
