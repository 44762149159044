import { AppStoreEffects } from "../app.store";

export const appStoreEffects: AppStoreEffects = ({ filters, global, dictionary, permissions }) => {
	if (process.env.NODE_ENV !== "production") {
		filters.onAll().subscribe(({ key, previousValue, value }) => {
			console.info(
				`%c Filters ⥁ ${key}`,
				"background-color: rgb(96, 125, 139); color: #fff; padding: 2px 8px 2px 0;",
				previousValue,
				"→",
				value
			);
		});

		global.onAll().subscribe(({ key, previousValue, value }) => {
			console.info(
				`%c Global ⥁ ${key}`,
				"background-color: rgb(96, 125, 139); color: #fff; padding: 2px 8px 2px 0;",
				previousValue,
				"→",
				value
			);
		});

		dictionary.onAll().subscribe(({ key, previousValue, value }) => {
			console.info(
				`%c Dictionary ⥁ ${key}`,
				"background-color: rgb(96, 125, 139); color: #fff; padding: 2px 8px 2px 0;",
				previousValue,
				"→",
				value
			);
		});
		permissions.onAll().subscribe(({ key, previousValue, value }) => {
			console.info(
				`%c Permissions ⥁ ${key}`,
				"background-color: rgb(96, 125, 139); color: #fff; padding: 2px 8px 2px 0;",
				previousValue,
				"→",
				value
			);
		});
	}

	return { filters, global, dictionary, permissions };
};

