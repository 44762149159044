import { API_ROUTE } from "config/PageApiConfig";
import { ComparativeType } from "types/ComparativeType";
import { FilterStateType } from "store/app/filters.store";
import { HttpRequest } from "helpers/request-helper";
import { LNGValueChainType } from "types/LNGValueChainType";
import { PeriodType } from "types/PeriodType";
import { SubSegmentType } from "./types/SubSegmentType";
import { VLSegmentType } from "types/VLSegmentType";

export function GetValueLeverSummary(
	segment: string,
	subsegments: string,
	params: Pick<FilterStateType, "showBy"> & {
		comparative: ComparativeType;
		currency: string;
		period: string;
		periodType: PeriodType;
		year: string;
		valueChain: LNGValueChainType;
	}
): Promise<VLSegmentType> {
	return HttpRequest.get(`${API_ROUTE.LNG.valueLeversChart}/${segment}/${subsegments}/costs/detail`, params).then(
		(data) => data.data as VLSegmentType
	);
}


export function GetSubSegments(valueChain: string): Promise<SubSegmentType> {
	const params = {
		country: valueChain
	}
	return HttpRequest.get(API_ROUTE.LNG.valueLeversSubsegment, params).then(
		(data) => data.data as SubSegmentType
	);
}

export function LoadYears() {
	return HttpRequest.get(API_ROUTE.LNG.years).then(
		(data) => data.data)
}
