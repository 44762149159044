export const API_ROUTE = {
	authenticateUrl: "authenticate/v2",
	dataDictionary: "dictionary/v2",
	LNG: {
		dashboardSummary: "LNG/v2/summary",
		dashboardDetail: "LNG/v2",
		trendAnalysisChart: "LNG/v2/trends",
		costMarginSubsegment: "LNG/v2/subsegments",
		costMarginChart: "LNG/v2",
		valueLeversSubsegment: "LNG/v2/subsegments",
		valueLeversChart: "LNG/v2",
		contributionTrendsChart: "LNG/v2/contribution/trends",
		applicationSetting: "applicationSetting/v2",
		projection: "LNG/v2/projection",
		years: "LNG/v2/years",
	},
	CCNG: {
		dashboardSummary: "ccng/v1/summary",
		dashboardDetail: "ccng/v1",
		trendAnalysisChart: "ccng/v1/trends",
		costMarginChart: "ccng/v1",
		valueLeversChart: "ccng/v1",
		productType: "ccng/v1/products",
		subsegments: "ccng/v1/subSegments",
		region: "ccng/v1/regions",
		years: "ccng/v1/years",
		entities: "ccng/v1/entities",
	},
	CSAT: {
		userFeedback: "feedback",
	}
};
