import "./AppHeader.scss";
import classNames from "classnames";
import React from "react"
import { ReactComponent as Logo } from "svgs/petronas-logo.svg";
import { Typography } from "components/molecules";
import { useScrollInfo } from "helpers/hooks/useScrollInfo";

type Props = {
	title?: string;
	Actions?: any
	children?: React.ReactNode;
	className?: string;
	sticky?: number;
	onHomeClick?: () => void
};

export function AppHeader({ className, title, Actions, children, sticky = -0.0625, onHomeClick }: Props) {
	const convertREMtoPX = sticky * 16; //This is * 16 due to convertion of rem to px
	const headerRef = React.useRef<HTMLElement>(null);
	const { scrollInfo } = useScrollInfo("major", convertREMtoPX, 33); // 33 is the number which is the difference between min and expanded header height that would cause the bounce effect.
	return <header ref={headerRef} className={classNames("appHeader", className, { "stickyBar": sticky > 0 }, { "minimized": scrollInfo.hasScrolledPastPoint })}>

		<Logo onClick={onHomeClick} className={classNames("logo", { "pointer": onHomeClick })} />
		<div className={"pageContent"}>
			{title && <Typography.Heading2 className="pageTitle" color={"Grey"} weight={700}>{title}</Typography.Heading2>}
			{children && <div className={"pageMetaContent"}>{children}</div>}
		</div>
		{Actions && <Actions />}
	</header>
}

