import { LastUpdatedType } from "types/LastUpdatedType";
export type CurrencyType = { name: string; currencySymbol: string };

export type GlobalStateType = {
	lastUpdatedDate: LastUpdatedType
};

export const initialGlobalState: GlobalStateType = {
	lastUpdatedDate: {
		lngLastUpdate: "",
		ccngLastUpdate: "",
		lastUpdate: "",
		srmcActualLastUpdate: "",
		srmcDataAsOfLastUpdate: "",
		srmcProjectedLastUpdate: ""
	}
};
