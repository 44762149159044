import { LNGStore } from "store/lng/lng.store";
import { AppStoreProps, AppStore } from "store/app/app.store";
import { SubNavigationWrapper } from "components/molecules";
import { Util } from "helpers/util/util";
import { Page_Util } from "helpers/util/pageUtil";
import AppRoutes from "pages/route";
import CCNGRoutes from "pages/CCNG/routes";
import LNGRoutes from "pages/LNG/routes";
import { AppHistory } from "pages/app";

type Props = AppStoreProps;

/*
	FUNCTION NAME: LNGHome
	DESCRIPTION: The function that contains all routes and variables needed for LNG value chain pages
*/
function LNGHome(appStore: Props) {
	const pageRoutes = Page_Util.getPermissionList(appStore, AppRoutes, CCNGRoutes, LNGRoutes);
	let lastUpdated = appStore.global.get("lastUpdatedDate")?.lngLastUpdate ? `Data Up To ${Util.getFormattedLastUpdated(appStore.global.get("lastUpdatedDate").lngLastUpdate)}` : "-";
	const currentSelectedRoute = AppHistory.location.pathname;
	if (currentSelectedRoute === "/lng/srmc") {
		lastUpdated = appStore.global.get("lastUpdatedDate")?.srmcDataAsOfLastUpdate ? `Data As At ${Util.getFormattedLastUpdated(appStore.global.get("lastUpdatedDate").srmcDataAsOfLastUpdate)}` : "-";
	}

	return SubNavigationWrapper(
		"IVCI",
		"LNG",
		pageRoutes.allowedLNGRoutes,
		LNGStore,
		lastUpdated,
		pageRoutes.allowedAppRoute,
		pageRoutes.allowedLNGRoutes,
		pageRoutes.allowedCCNGRoutes
	)
}

export default AppStore.withStores(LNGHome);