import { AppStore } from "store";
import { AppStoreProps } from "store/app/app.store"
import { CardWithIcon } from "components/organisms/CardWithIcon/CardWithIcon";
import { ComparativeType } from "types/ComparativeType";
import { MeasureUnit } from "../types/ValueChainSummary";
import { MonthType } from "types/MonthType";
import { Properties } from "helpers/properties";
import { QuarterType } from "types/QuarterType";
import React from "react"
import { ShowByType } from "types/ShowByType";
import { Util } from "helpers/util/util";
import { SkeletonContext } from "helpers/hooks/Loader";
import { Page_Util } from "helpers/util/pageUtil";

type Props = {
	onTabSwitch?: (key: string) => void;
	segments?: { [key: string]: MeasureUnit };
	comparative: ComparativeType;
	year: string;
	showBy: ShowByType | string;
	currencySymbol: string;
	selectedValueChain: string;
	month?: MonthType;
	quarter?: QuarterType;
	periodType: string;
	product: string;
} & AppStoreProps

/*
	FUNCTION NAME: LNGSegments
	DESCRIPTION: The function that renders all cards used in Segment section of LNG Overview page
*/
function LNGSegments({ segments, comparative, month, quarter, year, periodType, showBy, currencySymbol, selectedValueChain, onTabSwitch, dictionary }: Props) {
	const skeletonState = React.useContext(SkeletonContext);
	const loading = skeletonState?.loading;

	if (!loading && segments && Object.keys(segments).length) {
		const footerLabel = showBy !== "absolute" ? ["/MMBtu"] : [""]
		return (
			<>
				{Object.keys(segments).map((key, i) => {
					const tab = segments[key];
					return (
						<CardWithIcon
							id={`CCNG_segment_${i}`}
							key={i}
							cardName={key}
							iconName={Properties.getIcons(key)}
							className="homepage__article__segment-tabs__tab"
							mainheaderLabel={Properties.getDictionary(key)}
							headerLabel={Util.getActualOrPlanText("Actual", comparative, month, quarter, year, periodType)}
							footerLabel={footerLabel}
							trendLabel={Util.formatDigits(tab.deviation, showBy)}
							unitLabel={Properties.getCurrencySymbol(currencySymbol)}
							valueLabel={Util.formatDigits(tab.actual, showBy)}
							indicatorLabel={Util.formatDigits(tab.deviationPercentage, "", 0)}
							indicatorEnclosed
							bordered
							trendUp={tab.deviation > 0 ? "up" : tab.deviation < 0 ? "down" : "neutral"}
							headerLabel1={Util.getActualOrPlanText("Plan", comparative, month, quarter, year, periodType, true)}
							unitLabel1={Properties.getCurrencySymbol(currencySymbol)}
							valueLabel1={Util.formatDigits(tab.compare, showBy)}
							selected={key === selectedValueChain}
							onSelection={onTabSwitch}
							infotiptool
							info={Properties.getDictionaryText(dictionary.get("dictionary"), `LNG_segment_${key}`, "en")}
						/>
					)
				})}
			</>
		)
	} else {
		return Page_Util.getOverviewSegmentLoadingCard();
	}
}

export default AppStore.withStores(LNGSegments);
