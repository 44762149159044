import { ComparativeType } from "types/ComparativeType";
import { CurrencyType } from "types/CurrencyType";
import { DropdownItemType } from "components/organisms/Dropdown/Dropdown";
import { ShowByType } from "types/ShowByType";
import { PeriodType } from "types/PeriodType";
import { ProfitabilityType } from "types/ProfitabilityType";
import React from "react"
import { Properties } from "helpers/properties";
import { TrendAnalysisBarType } from "types/TrendAnalysisType";
import { CCNGSegmentType, ProductType } from "types/CCNGtype";
import { Dropdown, WrapContainer } from "components/molecules";

type Props = {
	onEvent?: (type: string, value: any) => void;
	comparative: ComparativeType;
	showBy?: ShowByType | string;
	currency: CurrencyType;
	product?: ProductType;
	productType?: string;
	profitability: ProfitabilityType;
	segment?: CCNGSegmentType;
	type?: TrendAnalysisBarType;
	year?: string;
	yearList: Array<DropdownItemType>;
	period: PeriodType;
	region: string;
	regionList: Array<DropdownItemType>;
	productTypeList: DropdownItemType[];
	hideProfitability?: boolean;
	stickyClassName: string;
}

/*
	FUNCTION NAME: CCNGTrendAnalysisFilter
	DESCRIPTION: The function that renders all filter dropdowns used in Trend Analysis page
*/
export function CCNGTrendAnalysisFilter({ onEvent, showBy, currency, product, productType, profitability, type, year, yearList, region, regionList, productTypeList, comparative, segment, period, hideProfitability, stickyClassName }: Props) {
	const handleOnEvent = (eventType: string, value: any) => {
		if (!onEvent) return;
		onEvent(eventType, value)
	}
	const unit = product === "Natural Gas" ? "/MMBtu" : "/BOE";

	return (
		<WrapContainer className="CCNGTrendAnalysis-filter-component" stickyClassName={stickyClassName}>
			<Dropdown
				className="spaced"
				selected={currency.name}
				border
				title="Currency"
				onChange={(value) => handleOnEvent("currency", value)}
				list={[
					{ label: "USD", id: "USD" },
					{ label: "MYR", id: "MYR" }
				]}
			/>
			<Dropdown
				selected={showBy}
				border
				className="spaced"
				title="Show By"
				onChange={(value) => handleOnEvent("showBy", value)}
				list={[
					{
						label: `${currency.currencySymbol}${unit}`,
						id: "mmbtu",
					},
					{
						label: `Absolute(${currency.currencySymbol})`,
						id: "absolute",
					}
				]}
			/>
			<Dropdown
				border
				title="Comparative"
				onChange={(value) => handleOnEvent("comparative", value)}
				className="spaced"
				selected={comparative}
				list={[
					{ label: "Plan", id: "plan" },
					{ label: "Actual", id: "yoy" }
				]}
			/>

			<Dropdown
				selected={period}
				border
				className="spaced"
				title="Period"
				onChange={(value) => handleOnEvent("period", value)}
				list={[
					{
						label: "Monthly",
						id: "monthly"
					},
					{
						label: "Quarterly",
						id: "quarterly"
					}
				]}
			/>
			<Dropdown
				border
				title="Year"
				onChange={(value) => handleOnEvent("year", value)}
				className="spaced"
				selected={year}
				list={yearList}
			/>

			<Dropdown
				className="spaced"
				selected={product}
				border
				title="Product"
				onChange={(value) => handleOnEvent("product", value)}
				list={[
					{ label: "All", id: "all" },
					{ label: "Crude", id: "Crude" },
					{ label: "Condensate", id: "Condensate" },
					{ label: "Natural Gas", id: "Natural Gas" }
				]}
			/>
			<Dropdown
				className="spaced"
				selected={productType}
				border
				title="Product Type"
				onChange={(value) => handleOnEvent("productType", value)}
				list={productTypeList}
				disabled={productTypeList.length === 1}
			/>
			<Dropdown
				border
				title="Type"
				onChange={(value) => handleOnEvent("type", value)}
				className="spaced"
				selected={type}
				list={[
					{ label: "Margin", id: "margin" },
					{ label: "Cost", id: "cost" },
					{ label: "Revenue", id: "revenue" }
				]}
			/>

			{!hideProfitability && <Dropdown
				border
				title="Profitability"
				onChange={(value) => handleOnEvent("profitability", value)}
				className="spaced"
				selected={profitability}
				list={Properties.get("CCNG_profitablity_filter")}
			/>}
			<Dropdown
				border
				selected={region}
				className="spaced"
				title="Region"
				onChange={(value) => handleOnEvent("region", value)}
				list={regionList}
			/>
			<Dropdown
				border
				title="Segment"
				onChange={(value) => handleOnEvent("segment", value)}
				className="spaced"
				selected={segment}
				list={[
					{ label: "All", id: "all" },
					{ label: "Carigali Group", id: "carigaliGroup" },
					{ label: "MPM", id: "mpm" },
					{ label: "Marketing", id: "marketing" }
				]}
			/>
		</WrapContainer>
	)
}