import React, { useState, useEffect, useRef } from "react"
import { AppStore } from "store"
import { AppStoreProps } from "store/app/app.store"
import { TableAccordion } from "components/molecules/TableAccordion/TableAccordion"
import { SRMCType } from "./types/SRMCType"
import { SkeletonContext } from "helpers/hooks/Loader"
import { Util } from "helpers/util/util"
import { isNullOrUndefined } from "util"

type Props = {
	currencySymbol: string;
	subSegments: SRMCType[];
	headerLabel: string;
} & AppStoreProps

/*
	FUNCTION NAME: SRMCTable
	DESCRIPTION: The function that renders the expandable table within the tabs of the SRMC page
*/
const usePreviousValue = (value) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};
function SRMCTable({ currencySymbol, subSegments, headerLabel }: Props) {
	const skeletonState = React.useContext(SkeletonContext);
	const loading = skeletonState?.loading;
	const [key, setKey] = useState<boolean>(true);
	const prevDataValue = usePreviousValue(subSegments);
	// eslint-disable-next-line
	useEffect(() => {
		if (subSegments !== prevDataValue) {
			setKey(!key);
		}
	})

	if (!loading) {
		return <TableAccordion<SRMCType>
			key={key ? "srmc_table_key" : "srmc_table_key_updated"}
			id={`LNG_srmc_table`}
			expandActionType="single"
			sortType="all"
			className="srmc__table"
			defaultSortKey={{ key: "value", sortBy: "DSC" }}
			data={subSegments?.map((v) => {
				if (!v["subSegment"])
					v["children"] = [];
				else v["children"] = v["subSegment"];
				return v;
			})}
			showOnly="all"
			header={[
				{
					key: "name",
					label: headerLabel,
					align: "left",
					sortable: true,
					className: "header-width-large",
					renderRow: (item, val) => (
						// Data column
						<span className="name-attr">
							{val.name}
						</span>
					)
				},
				{
					key: "value",
					label: "amount",
					align: "right",
					sortable: true,
					renderRow: (item, val, level) => !isNullOrUndefined(val.value) ? <>{(val.value < 0 ? "-" : "")}
						<span className="super-currency">{val["currency"] || currencySymbol}</span>
						<span className="value">{
							Util.formatDigits((val.value < 0 ? -1 * val.value : val.value), "", 2)}</span>
					</> : <>-</>
				}
			]}
		/>
	} else return <></>
}


export default AppStore.withStores(SRMCTable);
