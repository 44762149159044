import { API_ROUTE } from "config/PageApiConfig";
import { FilterStateType } from "store/app/filters.store";
import { HttpRequest } from "helpers/request-helper";
import { PeriodType } from "types/PeriodType";

export function LoadProjection(
	params: Pick<FilterStateType, "showBy"> & {
		currency: string;
		year: string;
		period: string;
		periodType: PeriodType;
		valueChain: string;
		enterpriseLng: string;
	}
) {
	return HttpRequest.get(API_ROUTE.LNG.projection, params).then(
		(data) => data.data
	);
}

export function LoadYears() {
	return HttpRequest.get(API_ROUTE.LNG.years).then(
		(data) => data.data)
}

