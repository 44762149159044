import { createConnectedStoreAs } from "undux";
import { lngEffects } from "./effects/lng.effects";
import { lngFiltersState, LNGStateType } from "./filters.store";
import { Store, EffectsAs } from "undux";

export const LNGStore = createConnectedStoreAs(
	{
		lng: lngFiltersState,
	},
	lngEffects
);

export type LNGStoreProps = {
	lng: Store<LNGStateType>;
};

export type LNGEffects = EffectsAs<{
	lng: LNGStateType;
}>;