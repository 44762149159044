import { AppStore } from "store";
import { AppStoreProps } from "store/app/app.store"
import { CardWithIcon } from "components/organisms/CardWithIcon/CardWithIcon";
import { MeasureUnit } from "../types/ValueChainSummary";
import { ProductType } from "types/CCNGtype";
import { Properties } from "helpers/properties";
import React from "react"
import { ShowByType } from "types/ShowByType";
import { Util } from "helpers/util/util";
import { SkeletonContext } from "helpers/hooks/Loader";
import { Page_Util } from "helpers/util/pageUtil";

type Props = {
	onTabSwitch?: (key: string) => void;
	segments?: { [key: string]: MeasureUnit };
	showBy: ShowByType | string;
	currencySymbol: string;
	selectedValueChain: string;
	product: ProductType;
	headerLabels: {
		left: string,
		right: string
	}
} & AppStoreProps

/*
	FUNCTION NAME: CCNGSegments
	DESCRIPTION: The function that renders all cards used in Segment section of CCNG Overview page
*/
function CCNGSegments({ segments, showBy, currencySymbol, selectedValueChain, product, onTabSwitch, dictionary, headerLabels }: Props) {
	const skeletonState = React.useContext(SkeletonContext);
	const loading = skeletonState?.loading;

	if (!loading && segments && Object.keys(segments).length) {
		const footerLabel = showBy !== "absolute" ? (product !== "Natural Gas" ? ["/BOE"] : ["/MMBtu"]) : [""]
		return (
			<>
				{Object.keys(segments).map((key, i) => {
					const tab = segments[key];
					return (
						<CardWithIcon
							id={`CCNG_segment_${i}`}
							key={i}
							cardName={key}
							iconName={Properties.getIcons(key)}
							className="homepage__article__segment-tabs__tab"
							mainheaderLabel={Properties.getDictionary(key)}
							headerLabel={headerLabels.left}
							footerLabel={footerLabel}
							trendLabel={Util.formatDigits(tab.deviation, showBy)}
							unitLabel={Properties.getCurrencySymbol(currencySymbol)}
							valueLabel={Util.formatDigits(tab.actual, showBy)}
							indicatorLabel={Util.formatDigits(tab.deviationPercentage, "", 0)}
							indicatorEnclosed
							bordered
							trendUp={tab.deviation > 0 ? "up" : tab.deviation < 0 ? "down" : "neutral"}
							headerLabel1={headerLabels.right}
							unitLabel1={Properties.getCurrencySymbol(currencySymbol)}
							valueLabel1={Util.formatDigits(tab.compare, showBy)}
							selected={key === selectedValueChain}
							onSelection={onTabSwitch}
							infotiptool
							info={Properties.getDictionaryText(dictionary.get("dictionary"), `CCNG_segment_${key}`, "en")}
						/>
					)
				})}
			</>
		)
	} else {
		return Page_Util.getOverviewSegmentLoadingCard();
	}
}


export default AppStore.withStores(CCNGSegments);
