import { ComparativeType } from "types/ComparativeType";
import { ProfitabilityType } from "types/ProfitabilityType";
import { CCNGSegmentType, ProductType } from "types/CCNGtype";

export const ccngFiltersState: CCNGStateType = {
	product: "all",
	segment: "all",
	profitability: "PBT",
	comparative: "plan",
};

export type CCNGStateType = {
	product: ProductType;
	segment: CCNGSegmentType;
	profitability: ProfitabilityType;
	comparative: ComparativeType;
};


