import { CCNGStore } from "store/ccng/ccng.store";
import { AppStoreProps, AppStore } from "store/app/app.store";
import { SubNavigationWrapper } from "components/molecules";
import { Util } from "helpers/util/util";
import { Page_Util } from "helpers/util/pageUtil";
import AppRoutes from "pages/route";
import CCNGRoutes from "pages/CCNG/routes";
import LNGRoutes from "pages/LNG/routes";

type Props = AppStoreProps;

/*
	FUNCTION NAME: CCNGHome
	DESCRIPTION: The function that contains all routes and variables needed for CCNG value chain pages
*/
function CCNGHome(appStore: Props) {
	const pageRoutes = Page_Util.getPermissionList(appStore, AppRoutes, CCNGRoutes, LNGRoutes);
	const lastUpdated = appStore.global.get("lastUpdatedDate")?.ccngLastUpdate ? `Data Up To ${Util.getFormattedLastUpdated(appStore.global.get("lastUpdatedDate").ccngLastUpdate)}` : "-";

	return SubNavigationWrapper(
		"IVCI",
		"CCNG",
		pageRoutes.allowedCCNGRoutes,
		CCNGStore,
		lastUpdated,
		pageRoutes.allowedAppRoute,
		pageRoutes.allowedLNGRoutes,
		pageRoutes.allowedCCNGRoutes
	)
}

export default AppStore.withStores(CCNGHome);