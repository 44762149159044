import classNames from "classnames";
import { Icon } from "components/molecules";
import React from "react"
import { TrendAmt } from "components/atoms/TrendAmt/TrendAmt";


type Props = {
	oppositeTrend?: boolean;
	spinner?: boolean;
	trendLabel: string;
	trendUp?: "up" | "down" | "neutral";
	indicatorLabel?: string;
	indicatorEnclosed?: boolean;
	className?: string;
};


export default function CardVarianceTrendMolecule({ trendUp, oppositeTrend, spinner, trendLabel, indicatorLabel, indicatorEnclosed, className }: Props) {

	return (
		<article
			className={classNames(
				"unit-and-trend",
				"cmp-card",
				"item",
				trendUp,
				oppositeTrend && "opposite",
				spinner && "spinner"
			)}
		>
			<h2 className="card-title--header">{trendLabel && "Variance"}</h2>
			<div className={classNames(spinner && "spinner-height", "variance-body")}>
				<span className={classNames("indicator", "overview-chevron-icon", trendUp, oppositeTrend && "opp")} >
					{trendLabel && <Icon name="chevron" />}
				</span>
				<div className="variance-body-indicator-positions">
					<TrendAmt label={trendLabel} up={trendUp} />
					{(indicatorLabel || trendUp || indicatorEnclosed) && (
						<span
							className={classNames(
								"indicator",
								className,
								trendUp,
								oppositeTrend && "opp"
							)}
						>
							<span className={classNames("variance", "indicator", indicatorEnclosed && "enclosed")}>
								{indicatorLabel}
							</span>
						</span>
					)}
				</div>
			</div>
		</article>
	)
}
