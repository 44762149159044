import classNames from "classnames";
import { isNullOrUndefined } from "util";
import { Indicator } from "components/atoms/Indicator/Indicator";
import { Properties } from "helpers/properties";
import { ShowByType } from "types/ShowByType";
import { SubSegment } from "../types/ValueChainDetailResult";
import { Util } from "helpers/util/util";
import { CCNGSegmentType, ProductType } from "types/CCNGtype";
import { DynamicAccordionTable, Icon } from "components/molecules";
import React, { useState } from "react"

type Props = {
	onEvent?: (type: string, value: any) => void;
	subSegments: Array<SubSegment>;
	currencySymbol: string;
	onRowIndexClick: any;
	subSegmentsDetail: {};
	showOnly: number;
	selectedValueChain: CCNGSegmentType;
	product: ProductType;
	entity: string;
	showBy: ShowByType | string;
	headerLabels: {
		left: string,
		right: string
	}
	expandedChildId?: string;
}

/*
	FUNCTION NAME: SubSegmentsDetails
	DESCRIPTION: The function that renders all cards used in SubSegment Detail section of CCNG Overview page
*/
export function SubSegmentsDetails({ currencySymbol, showBy, subSegments, showOnly, onRowIndexClick, subSegmentsDetail, selectedValueChain, entity, headerLabels, product, expandedChildId }: Props) {
	const [sortBy, setSortBy] = useState<{ key: string | null, type: "asc" | "desc" }>({ key: "deviation", type: "desc" })
	const [reset, setreset] = useState("changed");

	let subSegmentArray: SubSegment[] = [];
	subSegmentArray = [...subSegments];
	subSegmentArray.sort(Util.compareBy(sortBy.key, sortBy.type))

	const sortBySubSegment = (key: any, e: any) => {
		const type = e["target"]["dataset"]["sort"];
		e["target"]["dataset"]["sort"] = type === "asc" ? "desc" : "asc";
		setSortBy({ key, type });
	}

	React.useEffect(() => {
		reset === "changed" && setreset("changedAgain");
		reset === "changedAgain" && setreset("changed");
		// eslint-disable-next-line
	}, [sortBy])

	const getCurrency = (key) => {
		return Properties.showCurrency(key)
			? Properties.getCurrencySymbol(currencySymbol)
			: ""
	}
	const _getSubsegmentName = (key) => {
		if (selectedValueChain === "carigaliGroup" && key === "productionVol" && entity === "all") {
			return Properties.getDictionary(`${key}Gross`, `CCNG_ ${selectedValueChain}_tab`);
		}
		return Properties.getDictionary(key, `CCNG_${selectedValueChain}_tab`);
	}

	const _getSubsegmentUnit = (contributionKey) => {
		const footerLabel = Array<string>();
		const labelIndex = product === "Natural Gas" ? 1 : 0;
		const footerLabelText = Properties.getUnitForSubSegmentDetails(`CCNG_${contributionKey}`, showBy, `CCNG_${selectedValueChain}`, currencySymbol);

		footerLabelText && footerLabel.push(
			String(footerLabelText === "" ? "" : `(${footerLabelText[labelIndex]})`)
		);
		return footerLabel;
	};

	const getChildDetail = () => {
		const subSegmentsDetailList: any[] = [];
		Object.keys(subSegmentsDetail).forEach((key, ind) => {
			subSegmentsDetailList.push(subSegmentsDetail[key]);
			const isNeutralTrend = selectedValueChain === "mpm" && key === "purchaseVol";
			subSegmentsDetailList[ind]["isNeutral"] = isNeutralTrend;
			subSegmentsDetailList[ind]["opposite"] = Properties.showOppositeTrend(key);
			subSegmentsDetailList[ind]["name"] = `${_getSubsegmentName(key)} ${_getSubsegmentUnit(key)}`;
			subSegmentsDetailList[ind]["currency"] = getCurrency(key);
		});
		return subSegmentsDetailList;
	}

	return (
		<DynamicAccordionTable
			id={`CCNG_sub_segment_details`}
			className="subsegment__details"
			fixedColumnId="actual"
			header={[
				{
					label: "PSC / RSC",
					id: "name",
					render: (val) => (
						// Data column
						<span className="name-attr">
							{val.name}
						</span>
					),

					renderHeader: (val) => (
						<>
							{val.label}
							<Icon
								name="sort_updown"
								data-sort="asc"
								size="medium"
								onClick={(e) => sortBySubSegment(val.id, e)}
							/>
						</>
					)
				},
				{
					label: headerLabels.left,
					id: "actual",
					renderParent: (val) => (
						!isNullOrUndefined(val.actual) ? <>
							{(val.actual < 0 ? "-" : "")} <span className="super-currency">{
								Properties.getCurrencySymbol(currencySymbol)}</span><span className="value">{Util.formatDigits((val.actual < 0 ? -1 * val.actual : val.actual), "mmbtu", 2)}</span>
						</> : <>-</>
					),
					render: (val) => (
						!isNullOrUndefined(val.actual) ? <>
							{(val.actual < 0 ? "-" : "")}
							{val.currency ? (<span className="super-currency">{val.currency}</span>) : ""}
							<span className="value">{
								Util.formatDigits((val.actual < 0 ? -1 * val.actual : val.actual), "mmbtu", 2)}</span>
						</> : <>-</>
					),
					renderHeader: (val) => (
						<>
							{val.label}
							<Icon
								name="sort_updown"
								data-sort="asc"
								size="medium"
								onClick={(e) => sortBySubSegment(val.id, e)}
							/>
						</>
					)
				},
				{
					label: headerLabels.right,
					id: "compare",
					renderParent: (val) => (
						!isNullOrUndefined(val.compare) ? <>
							{(val.compare < 0 ? "-" : "")} <span className="super-currency">
								{Properties.getCurrencySymbol(currencySymbol)}</span><span className="value">{Util.formatDigits((val.compare < 0 ? -1 * val.compare : val.compare), "mmbtu", 2)}</span>
						</> : <>-</>
					),
					render: (val) => (
						!isNullOrUndefined(val.compare) ? <>
							{(val.compare < 0 ? "-" : "")}
							{val.currency ? (<span className="super-currency">{val.currency}</span>) : ""}
							<span className="value">{
								Util.formatDigits((val.compare < 0 ? -1 * val.compare : val.compare), "mmbtu", 2)}</span>
						</> : <>-</>
					),
					renderHeader: (val) => (
						<>
							{val.label}
							<Icon
								name="sort_updown"
								data-sort="asc"
								size="medium"
								onClick={(e) => sortBySubSegment(val.id, e)}
							/>
						</>
					)
				},
				{
					label: "Variance",
					id: "deviation",
					render: (val) => (
						!isNullOrUndefined(val.deviation) ? <p
							className={classNames(
								"right-align", val.isNeutral ? "disabled" : val.deviation === 0 ? "neutral" : (val.deviation < 0 ? "minus" : "plus"))}
						>
							{(val.deviation === 0 ? "" : (val.deviation < 0 ? "-" : "+"))}
							{val.currency ? (<span className="super-currency">{val.currency}</span>) : ""}
							<span className="value">
								{Util.formatDigits((val.deviation < 0 ? -1 * val.deviation : val.deviation), "mmbtu", 2)}</span>
							<Indicator
								label={Util.formatDigits(val.deviationPercentage, "", 0)}
								down={val.isNeutral ? "disabled" : val.deviation === 0 ? "neutral" : (val.deviation < 0 ? "down" : "up")}
								enclosed
							/>
						</p> : <>-</>
					),
					renderParent: (val) => (
						!isNullOrUndefined(val.deviation) ? <p
							className={classNames(
								"right-align", val.isNeutral ? "disabled" : val.deviation === 0 ? "neutral" : (val.deviation < 0 ? "minus" : "plus"))}
						>
							{(val.deviation === 0 ? "" : (val.deviation < 0 ? "-" : "+"))}<span className="super-currency">
								{Properties.getCurrencySymbol(currencySymbol)} </span><span className="value">
								{Util.formatDigits((val.deviation < 0 ? -1 * val.deviation : val.deviation), "mmbtu", 2)}</span>
							<Indicator
								label={Util.formatDigits(val.deviationPercentage, "", 0)}
								down={val.opposite ? (val.isNeutral ? "disabled" : val.deviation === 0 ? "neutral" : val.deviation < 0 ? "up" : "down") : val.isNeutral ? "disabled" : val.deviation === 0 ? "neutral" : (val.deviation < 0 ? "down" : "up")}
								enclosed
							/>
						</p> : <>-</>
					),
					renderHeader: (val) => (
						<p className="right-align">
							{val.label}
							<Icon
								name="sort_updown"
								data-sort="asc"
								size="medium"
								onClick={(e) => sortBySubSegment(val.id, e)}
							/>
						</p>
					)
				}
			]}
			data={subSegmentArray.slice(0, showOnly)}
			onRowIndexClick={onRowIndexClick}
			childDetail={getChildDetail()}
			expandCoumnNumber={0}
			expandedChildId={expandedChildId}
			reset={reset}
		/>
	);
}