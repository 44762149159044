import { CCNGEffects } from "../ccng.store";

export const ccngEffects: CCNGEffects = ({ ccng }) => {
	if (process.env.NODE_ENV !== "production") {
		ccng.onAll().subscribe(({ key, previousValue, value }) => {
			console.info(
				`%c CCNG ⥁ ${key}`,
				"background-color: rgb(96, 125, 139); color: #fff; padding: 2px 8px 2px 0;",
				previousValue,
				"→",
				value
			);
		});


	}

	return { ccng };
};
