import React from "react";
import { Typography } from "components/atoms/Typography/Typography";
import { Icon } from "components/atoms/Icon/Icon";
import "./PopupBox.scss";

interface Props {
	id: string
	className?: string
}
export function PopupBox(props: Props) {
	return (
		<div id={props.id} className={props.className}>
			<div className="selected-bar-details">
				<div className="selected-bar-details__details">
					<Typography.SmallText>Loreum Ipsum</Typography.SmallText>
					<Typography.Heading4>
						<span className="sign">Loreum Ipsum</span>
						<Typography.ExtraSmallText className="currency super-currency">Loreum Ipsum</Typography.ExtraSmallText>
						<span className="value">Loreum Ipsum</span></Typography.Heading4>
				</div>
				<Icon
					className="selected-bar-details__icon icon-close"
					name="close"
					size="medium"
				/>
			</div>
		</div>
	)
}
