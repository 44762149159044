import React from "react"
import classNames from "classnames";
import ReactDOM from "react-dom";
import { ReactElement } from "react"
import { Icon, Typography } from "components/molecules";
import "./OverlayPopUp.scss"


type Props = {
	className?: string;
	children: ReactElement;
	headerTitle: string;
	onPopup: (event: Event | null) => void;
}

export function OverlayPopUp({ onPopup, headerTitle, children, className = "" }: Props) {
	const renderLocation = document.getElementById("root")!;
	const popup = <div className={classNames("overlay-popup", "show-popup", className)}>
		<div className="overlay-popup__mask" onClick={(e) => onPopup(null)} />
		<div className="overlay-popup__content" >
			<div className="overlay-popup__content__header" >
				<Typography.LargeText weight={700}> {headerTitle} </Typography.LargeText>
				<Icon
					name="close"
					size="medium"
					onClick={(e) => onPopup(null)}
				/>
			</div>
			<div className="overlay-popup__content__section" >
				{children}
			</div>
		</div>
	</div>;

	return ReactDOM.createPortal(popup, renderLocation);
}
