import { TypographyProps } from "./Typography";
import classNames from "classnames";
import React from "react";

type Props = TypographyProps;

export function Heading6({ children, className, color = "inherit", weight = 900, uppercase = false, wrap = false }: Props) {
	return (
		<h6
			className={classNames("heading6", className, { [`color-${color}`]: color }, { [`weight-${weight}`]: weight }, { "text-uppercase": uppercase }, { "text-wrap": wrap })}>
			{children}
		</h6>
	);
}
