import React from "react";
import classNames from "classnames";
import { ClickOutsideBoundary, Placeholders } from "components/molecules";
import { ReactElement, useRef } from "react";
import { Typography } from "components/atoms/Typography/Typography";
import "./BuildupChart.scss";

interface Props {
	className?: string;
	children?: Array<ReactElement>;
	loading: boolean;
	hasData: boolean;
	errorMessage?: string;
}

export function BuildupChart(props: Props) {
	const buildUpChart = useRef(null);
	const closeChartPopUp = () => {
		window["closeBuildUpChartPopUp"]();
	};

	return (
		<div className={classNames("buildupchart-ivcr", props.className, { "--no-data-overlay": !props.loading && !props.hasData })}>
			{props.loading && <Placeholders.Loader className={classNames("buildupchart-ivcr__loader")}></Placeholders.Loader>}
			{!props.loading && !props.hasData && <Placeholders.NoData className={classNames("buildupchart-ivcr__no-data")}></Placeholders.NoData>}
			{props.errorMessage && <Typography.Heading4 color="Grey" weight={300} className="error_message_chart">{props.errorMessage}</Typography.Heading4>}
			<ClickOutsideBoundary connectTo={buildUpChart} listen={true} onOutClick={(e) => closeChartPopUp()} eventType="mouseout">
				<div ref={buildUpChart} className={classNames("chart build-up-chart", { "--has-overlay": props.loading || !props.hasData })} />
			</ClickOutsideBoundary>
			{props.children}
		</div>
	);
}
