import { API_ROUTE } from "config/PageApiConfig";
import { ContributionTrendResult } from "./types/ContributionTrendResult";
import { FilterStateType } from "store/app/filters.store";
import { HttpRequest } from "helpers/request-helper";
import { PeriodType } from "types/PeriodType";

export function getTrendsData(
	params: Pick<FilterStateType, "showBy"> & {
		currency: string;
		year: string;
		periodType: PeriodType,
		period: string;
	}
): Promise<ContributionTrendResult> {
	return HttpRequest.get(API_ROUTE.LNG.contributionTrendsChart, params).then(
		(data) => data.data as ContributionTrendResult
	);
}

export function LoadYears() {
	return HttpRequest.get(API_ROUTE.LNG.years).then(
		(data) => data.data)
}

