import { useEffect } from "react";

function useOutsideAlerter(ref, listen: boolean, onOutClick: (e) => void, eventType) {
	/**
   * Alert if clicked on outside of element
   */
	function handleClickOutside(event) {
		if (ref && ref.current && !ref.current.contains(event.target)) {
			onOutClick(event);
		}
	}

	useEffect(() => {
		if (listen)
			// Bind the event listener
			document.addEventListener(eventType, handleClickOutside);
		else document.removeEventListener(eventType, handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener(eventType, handleClickOutside);
		};
	});
}

type Props = {
	children: any;
	listen?: boolean;
	onOutClick: (e) => void;
	connectTo: any;
	eventType?: "mousedown" | "mouseout"
};

export function ClickOutsideBoundary({ children, listen, onOutClick, connectTo, eventType = "mousedown" }: Props) {
	useOutsideAlerter(connectTo, listen || false, onOutClick, eventType);
	return children
}
