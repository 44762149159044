import { ccngEffects } from "./effects/ccng.effects";
import { createConnectedStoreAs } from "undux";
import { ccngFiltersState, CCNGStateType } from "./filters.store";
import { Store, EffectsAs } from "undux";

export const CCNGStore = createConnectedStoreAs(
	{
		ccng: ccngFiltersState,
	},
	ccngEffects
);

export type CCNGStoreProps = {
	ccng: Store<CCNGStateType>;
};

export type CCNGEffects = EffectsAs<{
	ccng: CCNGStateType;
}>;