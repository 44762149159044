import CCNGCostMargin from "./CostMargin/CostMargin";
import CCNGHome from "./CCNGHome";
import CCNGOverview from "./Overview/Overview";
import CCNGTrendAnalysis from "./TrendAnalysis/CCNGTrendAnalysis";
import CCNGValueLevers from "./ValueLevers/CCNGValueLevers";
import { RouteType } from "types/RouteType";

export default {
	label: "CCNG",
	header: "Crude Condensate Natural Gas",
	icon: "psc",
	path: "/ccng",
	component: CCNGHome,
	subpages: [
		{
			label: "CCNG Overview",
			path: "overview",
			component: CCNGOverview,
			icon: "psc"
		},
		{
			label: "Trend Analysis",
			path: "trend-analysis",
			component: CCNGTrendAnalysis,
			icon: "trend"
		},
		{
			label: "Cost/Margin Buildup",
			path: "costmargin",
			component: CCNGCostMargin,
			icon: "buildup"
		},
		{
			label: "Value Levers",
			path: "value-levers",
			component: CCNGValueLevers,
			icon: "value-lever"
		}
	]
} as RouteType;
