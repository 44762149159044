import React from "react"
import { CurrencyType } from "store/app/global.store";
import { MonthType } from "types/MonthType";
import { QuarterType } from "types/QuarterType";
import { Properties } from "helpers/properties";
import { ShowByType } from "types/ShowByType";
import { DropdownItemType } from "components/organisms/Dropdown/Dropdown";
import { Dropdown, ItemGroup, WrapContainer } from "components/molecules";
import { PeriodType } from "types/PeriodType";

type Props = {
	showBy?: ShowByType | string;
	viewType?: PeriodType | string;
	month?: MonthType;
	quarter?: QuarterType;
	year: string;
	yearList?: Array<DropdownItemType>;
	valueChain: string;
	EntLng?: string
	currency: CurrencyType;
	onEvent?: (type: string, value: any) => void;
	// hide
	hideMonth?: boolean;
	hideQuarter?: boolean;
	hideYear?: boolean;
	stickyClassName: string;
}

/*
	FUNCTION NAME: LNGSRMCFilters
	DESCRIPTION: The function that renders all filter dropdowns used in SRMC page
*/
export function SRMCFilters({ showBy, viewType, month, quarter, year, yearList = [], valueChain, EntLng, currency, onEvent, hideMonth = false, hideQuarter = false, hideYear = false, stickyClassName }: Props) {



	const handleOnEvent = (eventType: string, value: any) => {
		if (!onEvent) return;
		onEvent(eventType, value)
	}

	return (
		<WrapContainer className="SRMC-filters-container" stickyClassName={stickyClassName}>
			<Dropdown
				selected={showBy}
				border
				className="spaced"
				title="Show By"
				key="showBy"
				onChange={(value) => handleOnEvent("showBy", value)}
				list={[
					{
						label: `${currency.currencySymbol}/MMBtu`,
						id: "mmbtu",
					},
					{
						label: `Absolute(${currency.currencySymbol})`,
						id: "absolute",
					}
				]}
			/>



			<Dropdown
				selected={viewType}
				border
				className="spaced"
				title="View"
				key="viewType"
				onChange={(value) => handleOnEvent("viewType", value)}
				list={[
					{ label: "Monthly", id: "monthly" },
					{ label: "YTD", id: "ytd" },
					{ label: "Full Year", id: "yep" },
				]}
			/>

			<ItemGroup className="spaced" key="month-quarter-year">
				{!hideMonth && <Dropdown
					selected={month}
					border
					title="Month"
					key="month"
					onChange={(value) => handleOnEvent("month", value)}
					list={Properties.getAllMonths().map((v) => {
						return { ...v, id: v.label }
					})}
				/>}


				{!hideQuarter && <Dropdown
					selected={quarter}
					border
					title="Quarter"
					key="quarter"
					onChange={(value) => handleOnEvent("quarter", value)}
					list={Properties.getAllQuarters().map((v) => {
						return { ...v, id: v.label }
					})}
				/>}

				{!hideYear && <Dropdown
					selected={year}
					border
					title="Year"
					key="year"
					onChange={(value) => handleOnEvent("year", value)}
					list={yearList}
				/>}
			</ItemGroup>

			<Dropdown
				selected={valueChain}
				border
				className="spaced"
				title="Value Chain"
				key="valueChain"
				onChange={(value) => handleOnEvent("valueChain", value)}
				list={[
					{ label: "MLNG1", id: "malaysia-mlng1" },
					{ label: "MLNG2", id: "malaysia-mlng2" },
					{ label: "MLNG3", id: "malaysia-mlng3" },
					{ label: "T9", id: "malaysia-t9" },
					{ label: "MLNG 1,2 & 3", id: "malaysia-mlng123" },
					{ label: "PLC (MLNG1,2,3 & T9)", id: "malaysia-plc" },
				]}
			/>

			<Dropdown
				selected={EntLng}
				border
				className="spaced"
				title="ENT/LNG"
				key="EntLng"
				onChange={(value) => handleOnEvent("EntLng", value)}
				list={[
					{ label: "Enterprise SRMC", id: "enterprise" },
					{ label: "LNG SRMC", id: "lng" }
				]}
			/>
		</WrapContainer>
	)
}
