import { AppStore } from "store";
import { AppStoreProps } from "store/app/app.store"
import CCNGContributors from "./components/Contributors";
import CCNGCorporateCost from "./components/CorporateCost";
import { CCNGFilters } from "./components/Filters";
import CCNGPriceBenchmark from "./components/PriceBenchmark";
import CCNGSegments from "./components/Segments";
import CCNGSubSegments from "./components/CCNGSubSegments";
import { ComparativeType } from "types/ComparativeType";
import { DropdownItemType } from "components/organisms/Dropdown/Dropdown";
import { ProductListType } from "types/ProductListType";
import { Properties } from "helpers/properties";
import React from "react"
import { ValueChainDetailResult } from "./types/ValueChainDetailResult";
import { ValueChainSummary } from "./types/ValueChainSummary";
import { ValueChainType } from "types/ValueChainType";
import { CCNGStore, CCNGStoreProps } from "store/ccng/ccng.store";
import { CCNGUtil, Util } from "helpers/util/util";
import { Dropdown, HeaderInfoPopup, HeaderSubtitle, StickyElement } from "components/molecules";
import { LoadEnities, LoadProductType, LoadRegion, LoadYears, getCCNGValueChainsDetails, getCCNGValueChainsSubSegmentDetails, getCCNGValueChainsSummary } from "./Overview.service";
import { Skeleton } from "helpers/hooks/Loader";
import "./Overview.scss";

type Props = AppStoreProps & CCNGStoreProps;

type State = {
	productType: string;
	productTypes: ProductListType[];
	yearList: Array<DropdownItemType>;
	regionList: Array<DropdownItemType>;
	region: string;
	entityList: Array<DropdownItemType>;
	entity: string;
	// year: string;
	selectedValueChain: ValueChainType;
	summary?: ValueChainSummary;
	details?: {
		upstream?: ValueChainDetailResult,
		asset?: ValueChainDetailResult,
		lmt?: ValueChainDetailResult,
		integrated?: ValueChainDetailResult,
	};
	loading: {
		summary: boolean,
		details: boolean,
	}
	subSegmentsDetail: {};
	expandedChildId?: string;
}

/**
 * This should always return new state.
 * **/
function reducer(state: State, action: { type: string, data?: any, key?: string, [key: string]: any }) {
	switch (action.type) {
		case "UPDATE_SUMMARY":
			return {
				...state, summary: (action.data)
			};
		case "UPDATE_DETAILS":
			if (!action["key"]) {
				throw new Error("You must pass 'key' in action for 'UPDATE_DETAILS' to work");
			}

			if (!action["data"]) {
				throw new Error("You must pass 'data' in action for 'UPDATE_DETAILS' to work");
			}

			return { ...state, details: { ...state.details, [action.key]: action.data } };
		case "UPDATE_FILTER":
			if (!action["key"]) {
				throw new Error("You must pass 'key' in action for 'UPDATE_FILTER' to work");
			}

			if (!action["data"]) {
				throw new Error("You must pass 'data' in action for 'UPDATE_FILTER' to work");
			}

			return { ...state, [action.key]: action.data };

		case "SELECT_VALUECHAIN":
			return { ...state, selectedValueChain: action.data, entity: "all" };

		case "UPDATE_LOADING":
			if (!action["key"]) {
				throw new Error("You must pass 'key' in action for 'UPDATE_LOADING' to work");
			}

			if (action.data === undefined) {
				throw new Error("You must pass 'data' in action for 'UPDATE_LOADING' to work");
			}

			return { ...state, loading: { ...state.loading, [action.key]: action.data } };

		case "UPDATE_SUBSEGMENT_DETAILS":
			return { ...state, subSegmentsDetail: action.data };

		case "UPDATE_SUBSEGMENT_EXPANDED_ROW":
			return { ...state, expandedChildId: action.data };
		default:
			throw new Error();
	}
}

/*
	FUNCTION NAME: CCNGOverview
	DESCRIPTION: The function that renders the CCNG Overview screen
*/
function CCNGOverview({ filters, dictionary, ccng }: Props) {
	const [state, dispatch] = React.useReducer(reducer, {
		selectedValueChain: "carigaliGroup",
		productType: "all",
		productTypes: [],
		yearList: [],
		regionList: [{ label: "All", id: "all" }],
		entityList: [{ label: "All", id: "all" }],
		region: "all",
		entity: "all",
		loading: {
			summary: true,
			details: true,
		},
		subSegmentsDetail: {},
		expandedChildId: ""
	});

	const showBy = filters.get("showBy");
	const currency = filters.get("currency");
	const month = filters.get("month");
	const quarter = filters.get("quarter");
	const year = filters.get("year");
	const period = filters.get("period");
	const dictionaryList = dictionary.get("dictionary");
	const product = ccng.get("product");
	const profitability = ccng.get("profitability");
	// CustomLogic: Custom logic here.
	const comparative = ccng.get("comparative") === "yoy" ? "actual_preceding" : ccng.get("comparative");
	const segments = state.summary && state.summary.segments;
	const currentValueChainDetails = state.details && state.details[state.selectedValueChain];
	const contributions = currentValueChainDetails && currentValueChainDetails.segmentDetails;
	const corporateCosts = currentValueChainDetails && currentValueChainDetails.corporateCosts;
	const subSegments = currentValueChainDetails && currentValueChainDetails.subSegments;
	const priceBenchmark = state.summary && state.summary.priceBenchmark;
	const priceDifference = state.summary && state.summary.priceDifference;
	const ESTIMATED_APP_HEADER_HEIGHT = 2.9125;

	let comparative_param: ComparativeType = "plan";
	if (comparative === "actual_preceding") {
		comparative_param = "actual_preceding";
	}
	else if (comparative === "actual_corresponding") {
		comparative_param = "yoy";
	}

	const handleFilterChange = (key: string, value: any) => {
		switch (key) {
			case "showBy":
			case "month":
			case "quarter":
			case "year":
			case "period":
				filters.set(key)(value.id);
				break;
			case "currency":
				filters.set("currency")({ name: value.label, currencySymbol: Properties.getCurrencySymbol(value.id) })
				break;
			case "comparative":
				// Do this special logic provided by clients before you update local state.
				if (value.id === "actual_preceding" && period === "ytd") {
					filters.set("period")("monthly");
				}
			// eslint-disable-next-line
			case "product":
			case "profitability":
				ccng.set(key)(value.id);
				break;
			default:
				dispatch({ type: "UPDATE_FILTER", key: key, data: value.id });
		}
	};
	const handleTabChange = (key: string) => dispatch({ type: "SELECT_VALUECHAIN", data: key });
	const handleDetailsChange = (details: ValueChainDetailResult) => dispatch({ type: "UPDATE_DETAILS", data: details, key: state.selectedValueChain })
	const handleSummaryChange = (summary: ValueChainSummary) => dispatch({ type: "UPDATE_SUMMARY", data: summary })
	const handleLoading = (key: string, loading: boolean = true) => dispatch({ type: "UPDATE_LOADING", data: loading, key: key })
	const handleSubSegment = (subsegmentDetails: {}) => dispatch({ type: "UPDATE_SUBSEGMENT_DETAILS", data: subsegmentDetails });
	const handleExpandedRowId = (expandedRowId) => dispatch({ type: "UPDATE_SUBSEGMENT_EXPANDED_ROW", data: expandedRowId })
	let typeList = [
		{
			label: "Monthly",
			id: "monthly"
		},
		{
			label: "Quarterly",
			id: "quarterly"
		},
		{
			label: "YTD",
			id: "ytd"
		}
	];
	if (comparative === "actual_preceding") {
		typeList = [
			{
				label: "Monthly",
				id: "monthly"
			},
			{
				label: "Quarterly",
				id: "quarterly"
			}
		];
	}
	const getAPIParams = () => {
		const isQuarterly = period === "quarterly";
		const isMonthly = period === "monthly";
		return {
			showBy: showBy,
			currency: currency.name,
			comparative: comparative_param,
			periodType: period,
			period: (isQuarterly && quarter) || (isMonthly && month) || `ytd-${month}`,
			year: year,
			segment: state.selectedValueChain,
			product: product,
			productType: state.productType,
			region: state.region,
			entity: state.entity,
			profitability: profitability
		};
	}
	let currentProductTypeList = [{ label: "All", id: "all" }];

	const onRowIndexClick = (key, expandedChildId) => {
		if (key !== null && expandedChildId !== state.expandedChildId) {
			getCCNGValueChainsSubSegmentDetails(state.selectedValueChain, key, getAPIParams()).then((data) => {
				handleExpandedRowId(expandedChildId)
				handleSubSegment(data["subSegmentDetails"])
			})
		} else {
			handleExpandedRowId("");
		}
	}

	const showCorporateCost = () => {
		return corporateCosts && corporateCosts.length > 0 && product === "all" && state.productType === "all" && state.region === "all" ? true : false
	}
	React.useEffect(() => {
		LoadProductType().then((data) => {
			handleFilterChange("productTypes", { id: data });
		});


		LoadRegion().then((data) => {
			handleFilterChange("regionList", { id: CCNGUtil.loadRegion(data) });
		});

		LoadYears().then((data) => {
			handleFilterChange("yearList", { id: CCNGUtil.loadYears(data) });
		});

		LoadEnities().then((data) => {
			const entityList: Array<DropdownItemType> = [];
			entityList.push({ label: "All", id: "all" });
			for (let i = 0; i < data.length; i++) {
				entityList.push({
					label: Util.capitalize(data[i]),
					id: data[i]
				});
			}
			handleFilterChange("entityList", { id: entityList });
		});
		// eslint-disable-next-line
	}, [])


	React.useEffect(() => {
		handleLoading("summary", true);
		handleLoading("details", true);
		const params = getAPIParams();
		Promise.all([
			getCCNGValueChainsSummary(params),
			getCCNGValueChainsDetails(state.selectedValueChain, params)
		])
			.then((result) => {
				return {
					summary: result[0] as ValueChainSummary,
					details: result[1]
				}
			})
			.then((values) => {
				handleSummaryChange(values.summary)
				return values;
			})
			.then((values) => handleDetailsChange(values.details))
			.then(() => {
				handleLoading("summary", false);
				handleLoading("details", false);
			});
		// eslint-disable-next-line
	}, [currency, showBy, comparative, period, month, quarter, year, product, state.productType, state.region, profitability])

	React.useEffect(() => {
		if (!state.summary && !state.details) {
			return;
		}
		handleLoading("details", true);
		const params = getAPIParams();
		getCCNGValueChainsDetails(state.selectedValueChain, params)
			.then(handleDetailsChange)
			.then(() => {
				handleLoading("details", false);
			});
		// eslint-disable-next-line
	}, [state.selectedValueChain, state.entity])

	currentProductTypeList = CCNGUtil.loadCurrentProductType(product, state.productTypes, currentProductTypeList);


	const headerLabels = {
		left: Util.getActualOrPlanText("Actual", comparative, month, quarter, year, period),
		right: Util.getActualOrPlanText("Plan", comparative, month, quarter, year, period, true)
	}
	return (
		<> <section className="homepage__article" >
			<HeaderSubtitle heading="CCNG Value Chain Margin" subtitle={showBy === "absolute" && (currency.name).toString() === "MYR" ? Properties.getDictionaryText(dictionaryList, `CCNG_page_subheader_absolute_myr`, "en") : (showBy === "absolute" && (currency.name).toString() === "USD" ? Properties.getDictionaryText(dictionaryList, `CCNG_page_subheader_absolute`, "en") : Properties.getDictionaryText(dictionaryList, `CCNG_page_subheader`, "en"))} className="ccng-Overview-header-with-subtitles" />
			<StickyElement to="top" floatBy={ESTIMATED_APP_HEADER_HEIGHT} className="ccng-filters" stickyClassName="sticky">
				<CCNGFilters
					onEvent={handleFilterChange}
					comparative={comparative}
					profitability={profitability}
					period={period}
					showBy={showBy}
					currency={currency}
					periodType={typeList}
					month={month}
					quarter={quarter}
					yearList={state.yearList}
					year={year}
					hideMonth={period === "quarterly"}
					hideQuarter={period !== "quarterly"}
					product={product}
					productType={state.productType}
					productTypeList={currentProductTypeList}
					regionList={state.regionList}
					region={state.region}
					stickyClassName="ccng-filters" //This stickyClassName is meant for the JS for scrolling
				/>
			</StickyElement>
			<div className="homepage__article__segment">
				<div className="homepage__article__segment-tabs">
					<Skeleton loading={state.loading.summary}>
						<CCNGSegments
							segments={segments}
							showBy={showBy}
							currencySymbol={currency.currencySymbol}
							selectedValueChain={state.selectedValueChain}
							onTabSwitch={handleTabChange}
							product={product}
							headerLabels={headerLabels}
						></CCNGSegments>
					</Skeleton>
				</div>
				<Skeleton loading={state.loading.details}>
					<div className="homepage__article__tab-content">
						<div className="homepage__article__tab-content__contributors">
							<div className="ccng-contributors-header-section">
								<HeaderInfoPopup type="subheader"
									info={Properties.getDictionaryText(dictionaryList, `CCNG_contributer_subheader_${state.selectedValueChain}`, "en")}
								>
									<h2>Top Margin Variance Contributors</h2>
								</HeaderInfoPopup>
								{state.selectedValueChain === "carigaliGroup" &&
									<Dropdown
										border
										onChange={(value) => handleFilterChange("entity", value)}
										selected={state.entity}
										className="spaced ccng-entity-dropdwn"
										title="Entity"
										list={state.entityList}
									/>}
							</div>
							<div className="homepage__article__tab-content__container">
								<CCNGContributors
									contributions={contributions}
									showBy={showBy}
									currencySymbol={currency.currencySymbol}
									selectedValueChain={state.selectedValueChain}
									product={product}
									entity={state.entity}
									headerLabels={headerLabels}
								></CCNGContributors>
							</div>
						</div>
						{subSegments && subSegments.length > 0 && <CCNGSubSegments
							subSegments={subSegments}
							currencySymbol={currency.currencySymbol}
							onRowIndexClick={onRowIndexClick}
							subSegmentsDetail={state.subSegmentsDetail}
							subHeader={`CCNG_${state.selectedValueChain}_sub_segment`}
							selectedValueChain={state.selectedValueChain}
							entity={state.entity}
							headerLabels={headerLabels}
							product={product}
							expandedChildId={state.expandedChildId}
						></CCNGSubSegments>}
						{showCorporateCost() &&
							<div className="homepage__article__tab-content__sub-segment">
								<HeaderInfoPopup type="subheader" info={Properties.getDictionaryText(dictionary.get("dictionary"), `CCNG_corporate_cost_subheader_${state.selectedValueChain}`, "en")}>
									<h2 className="homepage__footer__header">Other Costs</h2>
								</HeaderInfoPopup>
								<div className="homepage__article__tab-content__container ccng__corporate-cost">
									<CCNGCorporateCost
										corporateCost={corporateCosts}
										showBy={showBy}
										currencySymbol={currency.currencySymbol}
										selectedValueChain={state.selectedValueChain}
										product={product}
										headerLabels={headerLabels}
									></CCNGCorporateCost>
								</div>
							</div>}
					</div>
				</Skeleton>
			</div>
			<CCNGPriceBenchmark
				benchmark={priceBenchmark}
				headerLabels={headerLabels}
				priceDifference={priceDifference}>
			</CCNGPriceBenchmark>
		</section>

		</>
	)
}
/**
 * This codebase is using 'Undux' for global state management.
 * @see https://undux.org/ for more information
 *
 * Also @see https://undux.org/#examples/derived-state for more information on why 'withStore' was used.
 **/

export default AppStore.withStores(CCNGStore.withStores(CCNGOverview));
