import { AppHeader } from "components/molecules";
import { Properties } from "helpers/properties";
import { processLogout } from "helpers/auth";
import React from "react"
import "./Unauthorised.scss";
import { AppStoreProps, AppStore } from "store/app/app.store";

type Props = AppStoreProps

/*
	FUNCTION NAME: Unauthorised
	DESCRIPTION: The function that renders the screen which appears to users when they reach a page
    that they do not have sufficient authorisation to access 
*/
function Unauthorised({ dictionary }: Props) {
	return (
		<>
			<AppHeader Actions={LogoutActions} sticky={1200} />
			<p></p>
			<section className={"unauthorised"}>
				<img alt="logo" className={"logo"} src="/assets/images/401.svg" ></img>
				<h1 className={"page-title"}>{Properties.getDictionaryText(dictionary.get("dictionary"), `unauthorised_header`, "en")}</h1>
				<p className={"information"}>{Properties.getDictionaryText(dictionary.get("dictionary"), `unauthorised_subheader`, "en")}</p>
				{/* commented translation

        <p className={"informationMY"}>{Properties.getDictionaryText(dictionary.get("dictionary"), `unauthorised_subheader`, "my")}</p>

        */}
			</section>
		</>)
}


function LogoutActions() {
	return (
		// eslint-disable-next-line
		<a
			onClick={(e) => processLogout()}
			style={{
				color: "Teal",
				marginRight: "0.5rem",
				marginLeft: "auto",
				alignSelf: "center"
			}}
		>
			<i className="icon-exit sidebar-tab__icon"></i>
			<span className="">Logout</span>
		</a >
	)
}


export default AppStore.withStores(Unauthorised)