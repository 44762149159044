import React from "react";
import { isNullOrUndefined } from "util";

/**
 * You can attach this React hook to listen to scroll height changes.
 *
 * @param {addBreakpointAfter} [addBreakpointAfter] Pass the ref of item you want to use as reference of height
 * @param {"always" | "pre" | "exact" | "post"} [listenOnly] You can use this param to ensure how often you want to listen to state changes.
 * @arg {always} [always] Use this to get state updates everytime user scrolls.
 * @arg {pre} [pre] Use this to get state updates everytime scroll position is before the 'scrollBeyondPoint'
 * @arg {major} [major] Use this to get state update everytime scroll position hits a major breakpoint. i.e: pre, post or exact.
 * @arg {post} [post] Use this to get state updates everytime scroll position is after the 'scrollBeyondPoint'
 * @return {scrollState} You can use this to extract the current scrollY position, direction of scroll & a boolean flag if scroll is more than scroll point.
 */
export function useScrollInfo(listenOnly: "always" | "pre" | "major" | "post" = "major", addBreakpointAfter: number, threshold: number = 0,) {
	const [scrollState, setScrollState] = React.useState({
		scrollY: window.scrollY,
		scrollDirection: "none",
		lastScrollTop: 0,
		bodyOffset: document.body.getBoundingClientRect(),
		hasScrolledPastPoint: isNullOrUndefined(addBreakpointAfter) ? false : window.scrollY > addBreakpointAfter
	})


	const listener = (e: Event) => {
		const newUpdate = {
			scrollY: window.scrollY,
			scrollDirection: scrollState.lastScrollTop > -scrollState.bodyOffset.top ? "up" : "down",
			lastScrollTop: -scrollState.bodyOffset.top,
			bodyOffset: document.body.getBoundingClientRect(),
			hasScrolledPastPoint: isNullOrUndefined(addBreakpointAfter) ? false : window.scrollY > addBreakpointAfter
		}
		const currentScroll = window.scrollY;
		const breakpoint = addBreakpointAfter;


		if (isNullOrUndefined(addBreakpointAfter))
			return;

		switch (listenOnly) {
			case "major":
				if ((currentScroll > breakpoint) || (currentScroll <= breakpoint - threshold)) {
					setScrollState(newUpdate);
				}
				break;

			case "post":
				if (newUpdate.scrollY > addBreakpointAfter)
					setScrollState(newUpdate);
				break;

			case "pre":
				if (newUpdate.scrollY < addBreakpointAfter)
					setScrollState(newUpdate);
				break;

			case "always":
			default:
				setScrollState(newUpdate);
		}
	};


	React.useEffect(() => {
		window.addEventListener("scroll", listener);
		return () => {
			window.removeEventListener("scroll", listener);
		};
	});

	return {
		scrollInfo: {
			scrollY: scrollState.scrollY,
			scrollDirection: scrollState.scrollDirection,
			hasScrolledPastPoint: scrollState.hasScrolledPastPoint
		}
	};
}

