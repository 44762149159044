import React from "react";
import classNames from "classnames";
import { Typography } from "components/atoms/Typography/Typography";
import "./DropdownListItem.scss";

export type DropdownItemType = {
	label: string;
	id: string;
	children?: Array<DropdownItemType>;
};

type Props = {
	item: DropdownItemType;
	onSelect?: (item: DropdownItemType) => void;
	rowClassName?: string;
	selected: string;
	level?: number
};

export function DropDownListItem({ onSelect, item, rowClassName, selected, level = 1 }: Props) {
	const MAX_CHARACTERS_TO_SHOW = 25;
	if (item.children) {
		return <>
			<li
				style={{
					paddingLeft: `calc(${level} * .8rem)`,
					whiteSpace: (item.label.length <= MAX_CHARACTERS_TO_SHOW && "pre") || "initial"
				}}
				key={item.id}
				data-key={item.label}
				data-value={item.id}
				onClick={(e) => onSelect && onSelect(item)}
				className={classNames("dropdown__options__menuParent__subOption", rowClassName)}
			>
				<Typography.Heading5 color={selected === item.id ? "Teal" : undefined} weight={selected === item.id ? 500 : undefined}>{item.label}</Typography.Heading5>
			</li>
			<ul className="dropdown__options__menuParent">
				{item.children.map((child, index) =>
					<DropDownListItem
						item={child}
						key={index}
						selected={selected}
						onSelect={onSelect}
						level={level + 1} />
				)}
			</ul>
		</>
	}

	return (
		<li
			key={item.id}
			data-key={item.label}
			data-value={item.id}
			onClick={(e) => onSelect && onSelect(item)}
			style={{
				paddingLeft: `calc(${level} * .8rem)`,
				whiteSpace: (item.label.length <= MAX_CHARACTERS_TO_SHOW && "pre") || "initial"
			}}
			className={classNames("dropdown__options__menuParent__subOption", rowClassName, { "selected": selected === item.id })}
		>
			<Typography.Heading5 color={selected === item.id ? "Teal" : undefined} weight={selected === item.id ? 500 : undefined}>{item.label}</Typography.Heading5>
		</li>
	);
}