import "./CardHeader.scss";
import classNames from "classnames";
import React from "react";

interface Props {
	label: string;
	className?: string;
}

export function Header(props: Props) {
	return (
		<h2 className={classNames("card-title--header", props.className)}>
			{props.label}
		</h2>
	);
}
