import { AppStore } from "store";
import { AppStoreProps } from "store/app/app.store";
import { CardSplitNoIcon } from "components/organisms/CardSplitNoIcon/CardSplitNoIcon";
import { PriceBenchMark } from "../types/ValueChainSummary";
import { Properties } from "helpers/properties";
import React from "react"
import { ShowByType } from "types/ShowByType";
import { Util } from "helpers/util/util";
import { HeaderInfoPopup, Table } from "components/molecules";

type Props = {
	benchmark?: PriceBenchMark
	showBy: ShowByType | string;
	currencySymbol: string;
} & AppStoreProps

/*
	FUNCTION NAME: LNGPriceBenchmark
	DESCRIPTION: The function that renders all cards used in Price Benchmark section of LNG Overview page
*/
function LNGPriceBenchmark({ benchmark, showBy, currencySymbol, dictionary }: Props) {
	if (benchmark) {
		return (
			<section className="homepage__footer">
				<HeaderInfoPopup type="subheader"
					info={Properties.getDictionaryText(dictionary.get("dictionary"), "LNG_price_benchmark", "en")}
				>
					<h2 className="homepage__footer__header">LNG Price Benchmark</h2>
				</HeaderInfoPopup>
				<div className="homepage__footer__cards">
					<div className="homepage__footer__cards__cards-and-table">
						{benchmark.floorPrice ? (
							<CardSplitNoIcon
								id={`LNG_benchmark_floor_price`}
								withTrend1={false}
								withTrend={false}
								mainheaderLabel="Floor Price"
								headerLabel="Price"
								footerLabel={["/MMBtu"]}
								unitLabel={Properties.getCurrencySymbol(
									currencySymbol
								)}
								valueLabel={Util.formatDigits(benchmark.floorPrice.price, showBy, 2)}
								bordered
								headerLabel1="Deemed FOB"
								unitLabel1={Properties.getCurrencySymbol(
									currencySymbol
								)}
								valueLabel1={Util.formatDigits(benchmark.floorPrice.priceDeemedFOB, showBy, 2)}
								className="homepage__footer__cards__spot-term"
							/>
						) :
							(Properties.getErrorMessage("Api: /summary, Field: PriceBenchMark, SubField: floorPrice")
							)}
						{benchmark.spot ? (
							<CardSplitNoIcon
								id={`LNG_benchmark_spot`}
								withTrend1={false}
								withTrend={false}
								mainheaderLabel="Spot"
								headerLabel="Price"
								footerLabel={["/MMBtu"]}
								footerLabel1={["TBtu"]}
								unitLabel={Properties.getCurrencySymbol(
									currencySymbol
								)}
								valueLabel={Util.formatDigits(benchmark.spot.price, showBy, 2)}
								bordered
								headerLabel1="Volume"
								unitLabel1=""
								valueLabel1={Util.formatDigits(benchmark.spot.volume, showBy, 2)}
								className="homepage__footer__cards__spot-term"
							/>
						) :
							(Properties.getErrorMessage("Api: /summary, Field: PriceBenchMark, SubField: spot")
							)}
						{benchmark.term ? (
							<CardSplitNoIcon
								id={`LNG_benchmark_term`}
								className="homepage__footer__cards__spot-term term"
								mainheaderLabel="Term"
								headerLabel="Price"
								footerLabel={["/MMBtu"]}
								footerLabel1={["TBtu"]}
								unitLabel={Properties.getCurrencySymbol(
									currencySymbol
								)}
								valueLabel={Util.formatDigits(benchmark.term.price, showBy, 2)}
								bordered
								headerLabel1="Volume"
								unitLabel1=""
								valueLabel1={Util.formatDigits(benchmark.term.volume, showBy, 2)}
							/>) :
							(Properties.getErrorMessage("Api: /summary, Field: PriceBenchMark, SubField: term"))}
						{benchmark.markets && (
							<Table
								id={`LNG_price_benchmark_market_price`}
								className="homepage__footer__cards__market"
								header={[
									{ label: "Market", id: "name" },
									{
										label: "Value",
										id: "value",
										render: (val) => <> <span className="super-currency">{currencySymbol} </span><span className="value">{Util.formatDigits(val.value, showBy, 2)} </span></>
									},
									{ label: "Unit", id: "unit" }
								]}
								data={_filterMarketTable(benchmark.markets)}
							/>
						)}
					</div>
				</div>
			</section>
		);
	} else {
		const spinnerDivs: any = [];
		for (let k = 0; k < 4; k++) {
			spinnerDivs.push(
				<CardSplitNoIcon
					key={k}
					mainheaderLabel=""
					headerLabel=""
					footerLabel={[""]}
					unitLabel=""
					valueLabel=""
					bordered
					headerLabel1=""
					unitLabel1=""
					valueLabel1=""
					className="homepage__footer__cards__spot-term"
					spinner
				/>
			);
		}
		return (
			<section className="homepage__footer">
				<div className="homepage__footer__cards">
					<div className="homepage__footer__cards__cards-and-table">
						{spinnerDivs}
					</div>
				</div>
			</section>
		);
	}
}

function _filterMarketTable(dataArray) {
	const footerMarketArray: Array<any> = [];
	const footerMarket = Properties.get("footerMarket");
	for (let i = 0; i < dataArray.length; i++) {
		for (let j = 0; j < footerMarket.length; j++) {
			if (dataArray[i].name === footerMarket[j]) {
				footerMarketArray.push(dataArray[i]);
			}
		}
	}
	return footerMarketArray;
}

export default AppStore.withStores(LNGPriceBenchmark);
