import React from "react";
import classNames from "classnames";
import "./CardFooter.scss";

interface Props {
	label: Array<string>;
	className?: string;
}

export function Footer(props: Props) {
	return (
		<div className={classNames("card-title--footer", props.className)}>
			{props.label.map((key, i) => {
				return (
					<p className="unit" key={i}>
						<span>{key}</span>
					</p>);
			})}
		</div>
	);
}
