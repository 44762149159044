import { AppStore } from "store";
import { AppStoreProps } from "store/app/app.store"
import { Properties } from "helpers/properties";
import React from "react"
import { useState } from "react"
import { SubSegment } from "../types/ValueChainDetailResult";
import { SubSegmentsDetails } from "./SubSegmentsDetails";
import { Buttons, HeaderInfoPopup, Typography } from "components/molecules";
import { CCNGSegmentType, ProductType } from "types/CCNGtype";
import { SkeletonContext } from "helpers/hooks/Loader";



type Props = {
	onEvent?: (type: string, value: any) => void;
	subSegments: SubSegment[];
	currencySymbol: string;
	onRowIndexClick: any;
	subSegmentsDetail: {};
	subHeader: string;
	selectedValueChain: CCNGSegmentType;
	entity: string;
	product: ProductType
	headerLabels: {
		left: string,
		right: string
	},
	expandedChildId?: string;
} & AppStoreProps

/*
	FUNCTION NAME: CCNGSubSegments
	DESCRIPTION: The function that renders all cards used in SubSegment section of CCNG Overview page
*/
function CCNGSubSegments({ filters, subSegments, currencySymbol, dictionary, onRowIndexClick, subSegmentsDetail, subHeader, selectedValueChain,
	entity, headerLabels, product, expandedChildId }: Props) {
	const numOfRowsToAdd = Properties.get("numOfSubsegmentRows");
	const [showOnly, setShowOnly] = useState(numOfRowsToAdd);
	const showBy = filters.get("showBy");
	const skeletonState = React.useContext(SkeletonContext);
	const loading = skeletonState?.loading;

	const handleViewless = () => {
		let newValue = numOfRowsToAdd;
		if (newValue < numOfRowsToAdd)
			newValue = numOfRowsToAdd;
		setShowOnly(newValue);
	}

	const handleViewMore = () => {
		let newValue = showOnly + numOfRowsToAdd;

		if (newValue > subSegments.length)
			newValue = subSegments.length;

		setShowOnly(newValue);
	};
	const handleViewAll = () => {
		const newValue = subSegments.length;
		setShowOnly(newValue);
	}

	if (showOnly > subSegments.length && showOnly > numOfRowsToAdd) {
		handleViewAll();
	}

	if (!loading) {
		return <div className="homepage__article__tab-content__sub-segment">
			<HeaderInfoPopup type="subheader"
				info={Properties.getDictionaryText(dictionary.get("dictionary"), subHeader, "en")}
			>
				<h2>Sub-segment Details</h2>
			</HeaderInfoPopup>
			<div className="homepage__article__tab-content__container">
				<SubSegmentsDetails
					currencySymbol={currencySymbol}
					subSegments={subSegments}
					onRowIndexClick={onRowIndexClick}
					subSegmentsDetail={subSegmentsDetail}
					showOnly={showOnly}
					selectedValueChain={selectedValueChain}
					product={product}
					entity={entity}
					headerLabels={headerLabels}
					showBy={showBy}
					expandedChildId={expandedChildId}
				/>
			</div>
			{subSegments.length > numOfRowsToAdd && (
				<>
					<h2 className="sub">
						Viewing {showOnly} Sub-Segments of{" "}
						{subSegments.length}
					</h2>
					<div className="homepage__article__tab-content__sub-segment__buttons">
						{showOnly <
							subSegments.length &&
							(<Buttons.NormalButton
								onClick={handleViewMore}
								background={"Teal"}
								color={"White"}
								rounded>View More</Buttons.NormalButton>
							)}
						{showOnly > numOfRowsToAdd && (
							<Buttons.NormalButton
								onClick={handleViewless}
								background={"Teal"}
								color={"White"}
								rounded>View less</Buttons.NormalButton>
						)}
					</div>
					<div className="homepage__article__tab-content__sub-segment__buttons--view-less">
						{showOnly <
							subSegments.length &&
							<Buttons.TextButton
								onClick={handleViewAll}
								renderText={(text) => <Typography.ExtraLargeText uppercase>{text}</Typography.ExtraLargeText>}
								color={"Teal"}>View All</Buttons.TextButton>
						}
					</div>
				</>
			)}
		</div>
	} else return <></>
}

export default AppStore.withStores(CCNGSubSegments);

