import React from "react";
import classNames from "classnames";
import "./Loader.scss";

interface Props {
	className?: string
}

export function Loader({ className }: Props) {
	return (
		<div className={classNames(className, "loader")}></div>
	);
}
