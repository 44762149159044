import React from "react";
import classNames from "classnames";
import { TypographyProps } from "./Typography";

type Props = TypographyProps;

export function Heading5({ children, className, color = "inherit", weight = 300, uppercase = false, wrap = false }: Props) {
	return (
		<h5
			className={classNames("heading5", className, { [`color-${color}`]: color }, { [`weight-${weight}`]: weight }, { "text-uppercase": uppercase }, { "text-wrap": wrap })}>
			{children}
		</h5>
	);
}