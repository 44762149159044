import { CurrencyType } from "store/app/global.store";
import { DropdownItemType } from "components/organisms/Dropdown/Dropdown";
import { LNGSegmentType } from "types/LNGSegmentType";
import { LNGValueChainType } from "types/LNGValueChainType";
import { MonthType } from "types/MonthType";
import { PeriodType } from "types/PeriodType";
import { Properties } from "helpers/properties";
import { QuarterType } from "types/QuarterType";
import React from "react"
import { ShowByType } from "types/ShowByType";
import { Dropdown, ItemGroup, WrapContainer } from "components/molecules";

type Props = {
	onEvent?: (type: string, value: any) => void;
	period?: PeriodType;
	comparative?: string;
	month?: MonthType;
	quarter?: QuarterType;
	year?: string;
	yearList?: Array<DropdownItemType>;
	valueChain?: LNGValueChainType;
	segment?: LNGSegmentType;
	subsegment?: string;
	subSegmentList: DropdownItemType[];
	periodType: DropdownItemType[];
	hideMonth?: boolean;
	hideQuarterly?: boolean;
	showBy?: ShowByType | string;
	currency: CurrencyType;
	disableSubsegment?: boolean
	stickyClassName: string;
}

/*
	FUNCTION NAME: VLFilters
	DESCRIPTION: The function that renders all filter dropdowns used in LNG Value Levers page
*/
export function VLFilters({ onEvent, disableSubsegment, valueChain, showBy, currency, hideMonth = false, comparative, period, month, quarter, year, yearList = [], segment, subsegment, subSegmentList, periodType, hideQuarterly = false, stickyClassName }: Props) {
	const handleOnEvent = (key: string, value: any) => {
		if (!onEvent) return;
		onEvent(key, value)
	}

	return (
		<WrapContainer className="vl-filters-container" stickyClassName={stickyClassName}>
			<Dropdown
				selected={showBy}
				border
				className="spaced"
				title="Show By"
				key="showBy"
				onChange={(value) => handleOnEvent("showBy", value)}
				list={[
					{
						label: `${currency.currencySymbol}/MMBtu`,
						id: "mmbtu",
					},
					{
						label: `Absolute(${currency.currencySymbol})`,
						id: "absolute",
					}
				]}
			/>
			<Dropdown
				selected={comparative}
				border
				className="spaced"
				title="Comparative"
				key="comparative"
				onChange={(value) => handleOnEvent("comparative", value)}
				list={[
					{ label: "Plan", id: "plan" },
					{ label: "Actual - Preceding", id: "actual_preceding" },
					{ label: "Actual - Corresponding", id: "actual_corresponding" }
				]}
			/>
			<Dropdown
				selected={period}
				border
				className="spaced"
				title="Period"
				key="period"
				onChange={(value) => handleOnEvent("period", value)}
				list={periodType}
			/>

			<ItemGroup className="spaced" key="month-quarter-year">
				{!hideMonth && <Dropdown
					selected={month}
					border
					title="Month"
					onChange={(value) => handleOnEvent("month", value)}
					list={Properties.getAllMonths().map((v) => {
						return { ...v, id: v.label }
					})}
				/>}


				{!hideQuarterly && <Dropdown
					selected={quarter}
					border
					title="Quarter"
					onChange={(value) => handleOnEvent("quarter", value)}
					list={Properties.getAllQuarters().map((v) => {
						return { ...v, id: v.label }
					})}
				/>}

				{<Dropdown
					selected={year}
					border
					title="Year"
					onChange={(value) => handleOnEvent("year", value)}
					list={yearList}
				/>}
			</ItemGroup>

			<Dropdown
				key="valueChain"
				selected={valueChain}
				border
				className="spaced"
				title="Value Chain"
				onChange={(value) => handleOnEvent("valueChain", value)}
				list={[
					{ label: "All", id: "all" },
					{
						label: "Malaysia", id: "malaysia-all", children: [
							{ label: "MLNG1", id: "malaysia-mlng1" },
							{ label: "MLNG2", id: "malaysia-mlng2" },
							{ label: "MLNG3", id: "malaysia-mlng3" },
							{ label: "T9", id: "malaysia-t9" },
							{ label: "PFLNG", id: "malaysia-pflng" },
							{ label: "PFLNG1", id: "malaysia-pflng1" },
							{ label: "PFLNG2", id: "malaysia-pflng2" },
							{ label: "PLC (MLNG1,2,3 & T9)", id: "malaysia-plc" },]
					},
					{ label: "Egypt", id: "egypt-all" },
					{ label: "Australia", id: "australia-all" },
				]}
			/>

			<Dropdown
				className="spaced"
				selected={segment}
				border
				title="Segment"
				key="segment"
				onChange={(value) => handleOnEvent("segment", value)}
				list={[
					{ label: "All", id: "all" },
					{ label: "Upstream", id: "upstream" },
					{ label: "Asset", id: "asset" },
					{ label: "LMT", id: "lmt" }
				]}
			/>
			<Dropdown
				className="spaced"
				selected={subsegment}
				border
				title="Sub-Segment"
				key="subsegments"
				onChange={(value) => handleOnEvent("subsegments", value)}
				list={subSegmentList}
				disabled={disableSubsegment}
			/>
		</WrapContainer>
	)
}