import React from "react";
import classNames from "classnames";
import { TypographyProps } from "./Typography";

type Props = TypographyProps;

export function Heading1({ children, className, color = "inherit", weight = 500, uppercase = false, wrap = false }: Props) {
	return (
		<h1 className={classNames("heading1", className, { [`color-${color}`]: color }, { [`weight-${weight}`]: weight }, { "text-uppercase": uppercase }, { "text-wrap": wrap })}>
			{children}
		</h1>
	);
}
