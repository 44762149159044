import React, { useState } from "react"
import classNames from "classnames";
import { Properties } from "helpers/properties";
import { SubSegment } from "../types/ValueChainDetailResult";
import { AppStoreProps } from "store/app/app.store"
import { AppStore } from "store";
import { ValueChainType } from "types/ValueChainType";
import { Typography, Buttons, HeaderInfoPopup } from "components/molecules";
import { WithChild, TableAccordion } from "components/molecules/TableAccordion/TableAccordion";
import { isNullOrUndefined } from "util";
import { Util } from "helpers/util/util";
import { ComparativeType } from "types/ComparativeType";
import { MonthType } from "types/MonthType";
import { QuarterType } from "types/QuarterType";
import { Indicator } from "components/atoms/Indicator/Indicator";
import { SkeletonContext } from "helpers/hooks/Loader";

type Props = {
	onEvent?: (type: string, value: any) => void;
	subSegments: SubSegment[];
	comparative: ComparativeType;
	month?: MonthType;
	quarter?: QuarterType;
	periodType: string;
	year: string;
	currencySymbol: string;
	onRowClicked: (item: WithChild<SubSegment>) => void;
	subHeader: string;
	selectedValueChain: ValueChainType;
} & AppStoreProps

/*
	FUNCTION NAME: LNGSubSegments
	DESCRIPTION: The function that renders all cards used in SubSegment section of LNG Overview page
*/
function LNGSubSegments({ subSegments, dictionary, onRowClicked, subHeader, comparative, month, quarter, year, periodType, currencySymbol }: Props) {
	const numOfRowsToAdd = Properties.get("numOfSubsegmentRows");
	const [showOnly, setShowOnly] = useState<number>(numOfRowsToAdd);
	const skeletonState = React.useContext(SkeletonContext);
	const loading = skeletonState?.loading;

	const handleViewless = () => {
		let newValue = numOfRowsToAdd;
		if (newValue < numOfRowsToAdd)
			newValue = numOfRowsToAdd;
		setShowOnly(newValue);
	}

	const handleViewMore = () => {
		let newValue = showOnly + numOfRowsToAdd;

		if (newValue > subSegments.length)
			newValue = subSegments.length;

		setShowOnly(newValue);
	};
	const handleViewAll = () => {
		const newValue = subSegments.length;
		setShowOnly(newValue);
	}
	if (showOnly > subSegments.length && showOnly > numOfRowsToAdd) {
		handleViewAll();
	}
	if (!loading) {
		return <div className="homepage__article__tab-content__sub-segment">
			<HeaderInfoPopup type="subheader"
				info={Properties.getDictionaryText(dictionary.get("dictionary"), subHeader, "en")}
			>
				<h2>Sub-segment Details</h2>
			</HeaderInfoPopup>
			<div className="homepage__article__tab-content__container">
				<TableAccordion<SubSegment>
					id={`LNG_sub_segment_details`}
					expandActionType="single"
					sortType="all"
					className="subsegment__details"
					sortBackToDefault={true}
					data={subSegments?.map((v) => {
						if (!v["children"])
							v["children"] = [];

						return v;
					})}
					showOnly={showOnly}
					onRowExpand={onRowClicked}
					defaultSortKey={{
						key: "deviation",
						sortBy: "DSC"
					}}
					header={[
						{
							key: "name",
							label: "PSC / RSC",
							align: "left",
							sortable: true,
							className: "header-width-large",
							renderRow: (data, val) => (
								// Data column
								<span className="name-attr">
									{val["functionalArea"] || val.name}
								</span>
							)
						},
						{
							key: "actual",
							label: Util.getActualOrPlanText("Actual", comparative, month, quarter, year, periodType),
							align: "right",
							sortable: true,
							renderRow: (data, val, level) => !isNullOrUndefined(val.actual) ? <>{(val.actual < 0 ? "-" : "")}
								{val["currency"] || level === 0 ? (<span className="super-currency">{val["currency"] || currencySymbol}</span>) : ""}
								<span className="value">{
									Util.formatDigits((val.actual < 0 ? -1 * val.actual : val.actual), "", 2)}</span>
							</> : <>-</>
						},
						{
							key: "compare",
							label: Util.getActualOrPlanText("Plan", comparative, month, quarter, year, periodType, true),
							align: "right",
							sortable: true,
							renderRow: (data, val, level) => !isNullOrUndefined(val.compare) ? <>{(val.compare < 0 ? "-" : "")}
								{val["currency"] || level === 0 ? (<span className="super-currency">{val["currency"] || currencySymbol}</span>) : ""}
								<span className="value">{
									Util.formatDigits((val.compare < 0 ? -1 * val.compare : val.compare), "", 2)}</span>
							</> : <>-</>
						},
						{
							key: "deviation",
							label: "Variance",
							align: "right",
							sortable: true,
							renderRow: (data, val, level) => !isNullOrUndefined(val.deviation) ? <span
								className={classNames(val.deviation > 0 && "plus", val.deviation < 0 && "minus")}>
								{val.deviation === 0 ? "" : (val.deviation < 0 ? "-" : "+")}
								{val["currency"] || level === 0 ? (<span className="super-currency">{val["currency"] || currencySymbol}</span>) : ""}
								<span className="value">
									{Util.formatDigits((val.deviation < 0 ? -1 * val.deviation : val.deviation), "", 2)}</span>
								{level === 0 ? <Indicator
									label={Util.formatDigits(val.deviationPercentage, "", 0)}
									size="large"
									down={val["opposite"] ? (val.deviation === 0 ? "neutral" : val.deviation < 0 ? "up" : "down") : val.deviation === 0 ? "neutral" : (val.deviation < 0 ? "down" : "up")}
									enclosed
								/> :
									<Indicator
										label={Util.formatDigits(val.deviationPercentage, "", 0)}
										down={val.deviation === 0 ? "neutral" : (val.deviation < 0 ? "down" : "up")}
										size="large"
										enclosed
									/>}
							</span> : <>-</>
						}
					]}
				/>
			</div>
			{subSegments.length > numOfRowsToAdd && (
				<>
					<h2 className="sub">
						Viewing {showOnly} Sub-Segments of{" "}
						{subSegments.length}
					</h2>
					<div className="homepage__article__tab-content__sub-segment__buttons">
						{showOnly <
							subSegments.length &&
							(<Buttons.NormalButton
								onClick={handleViewMore}
								background={"Teal"}
								color={"White"}
								rounded>View More</Buttons.NormalButton>
							)
						}
						{showOnly > numOfRowsToAdd && (
							<Buttons.NormalButton
								onClick={handleViewless}
								background={"Teal"}
								color={"White"}
								rounded>View less</Buttons.NormalButton>
						)}


					</div>
					<div className="homepage__article__tab-content__sub-segment__buttons--view-less">
						{showOnly <
							subSegments.length &&
							<Buttons.TextButton
								onClick={handleViewAll}
								renderText={(text) => <Typography.ExtraLargeText uppercase>{text}</Typography.ExtraLargeText>}
								color={"Teal"}>View All</Buttons.TextButton>
						}
					</div>
				</>
			)}
		</div>
	} else return <></>
}

export default AppStore.withStores(LNGSubSegments);

