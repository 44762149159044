import React from "react";
import classNames from "classnames";
import { Typography } from "components/molecules";
import "../../organisms/Breadcrumbs/Breadcrumbs.scss";


export type BreadcrumbItemProps = {
	className?: string;
	children?: any;
	selected?: boolean;
	disabled?: boolean;
	path?: boolean;
	onClick?: () => void;
}

export function BreadcrumbItem({ className, children, selected, disabled, onClick }: BreadcrumbItemProps) {
	return <>
		<li
			className={classNames("breadcrumbChild", { "selected": selected }, { "disabled": disabled }, className)}
			onClick={onClick}
		>
			<Typography.TextNormal color={!selected ? "Grey" : "Teal"}>{children}</Typography.TextNormal>
		</li>
		<Typography.TextNormal className={classNames("breadcrumbs__children", "nextIcon")} color={"Grey"}> &gt; </Typography.TextNormal>
	</>
}