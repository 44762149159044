import { ComparativeType } from "types/ComparativeType";
import { CurrencyType } from "store/app/global.store";
import { LNGSegmentType } from "types/LNGSegmentType";
import { DropdownItemType } from "components/organisms/Dropdown/Dropdown";
import { LNGValueChainType } from "types/LNGValueChainType";
import { PeriodType } from "types/PeriodType";
import { ProfitabilityType } from "types/ProfitabilityType";
import React from "react"
import { ShowByType } from "types/ShowByType";
import { TrendAnalysisBarType } from "types/TrendAnalysisType";
import { Dropdown, WrapContainer } from "components/molecules";

type Props = {

	onEvent?: (type: string, value: any) => void;
	comparative: ComparativeType;
	showBy?: ShowByType | string;
	currency: CurrencyType;
	segment?: LNGSegmentType;
	type?: TrendAnalysisBarType;
	year?: string;
	yearList?: Array<DropdownItemType>;
	valueChain?: LNGValueChainType,
	period?: PeriodType;
	profitability?: ProfitabilityType;
	stickyClassName: string;
}

/*
	FUNCTION NAME: TrendAnalysisFilter
	DESCRIPTION: The function that renders all filter dropdowns used in LNG Trend Analysis page
*/
export function TrendAnalysisFilter({ onEvent, valueChain, showBy, currency, type, year, yearList = [], comparative, segment, period, profitability, stickyClassName }: Props) {
	const handleOnEvent = (eventType: string, value: any) => {
		if (!onEvent) return;
		onEvent(eventType, value)
	}

	const isProfitabilityDisabled = (type === "cost") || (type === "revenue")

	return (
		<WrapContainer className="TrendAnalysis-filter-component" stickyClassName={stickyClassName}>
			<Dropdown
				selected={showBy}
				border
				className="spaced"
				title="Show By"
				key="showBy"
				onChange={(value) => handleOnEvent("showBy", value)}
				list={[
					{
						label: `${currency.currencySymbol}/MMBtu`,
						id: "mmbtu",
					},
					{
						label: `Absolute(${currency.currencySymbol})`,
						id: "absolute",
					}
				]}
			/>
			<Dropdown
				border
				title="Comparative"
				key="comparative"
				onChange={(value) => handleOnEvent("comparative", value)}
				className="spaced"
				selected={comparative}
				list={[
					{ label: "Plan", id: "plan" },
					{ label: "Actual", id: "yoy" }
				]}
			/>

			<Dropdown
				selected={period}
				border
				className="spaced"
				title="Period"
				key="period"
				onChange={(value) => handleOnEvent("period", value)}
				list={[
					{
						label: "Monthly",
						id: "monthly"
					},
					{
						label: "Quarterly",
						id: "quarterly"
					}
				]}
			/>
			<Dropdown
				border
				title="Year"
				key="year"
				onChange={(value) => handleOnEvent("year", value)}
				className="spaced"
				selected={year}
				list={yearList}
			/>

			<Dropdown
				key="valueChain"
				selected={valueChain}
				border
				className="spaced"
				title="Value Chain"
				onChange={(value) => handleOnEvent("valueChain", value)}
				list={[
					{ label: "All", id: "all" },
					{
						label: "Malaysia", id: "malaysia-all", children: [
							{ label: "MLNG1", id: "malaysia-mlng1" },
							{ label: "MLNG2", id: "malaysia-mlng2" },
							{ label: "MLNG3", id: "malaysia-mlng3" },
							{ label: "T9", id: "malaysia-t9" },
							{ label: "PFLNG", id: "malaysia-pflng" },
							{ label: "PFLNG1", id: "malaysia-pflng1" },
							{ label: "PFLNG2", id: "malaysia-pflng2" },
							{ label: "PLC (MLNG1,2,3 & T9)", id: "malaysia-plc" },]
					},
					{ label: "Egypt", id: "egypt-all" },
					{ label: "Australia", id: "australia-all" },
				]}
			/>

			<Dropdown
				border
				title="Type"
				key="type"
				onChange={(value) => handleOnEvent("type", value)}
				className="spaced"
				selected={type}
				list={[
					{ label: "Margin", id: "margin" },
					{ label: "Cost", id: "cost" },
					{ label: "Revenue", id: "revenue" }
				]}
			/>

			<Dropdown
				border
				disabled={isProfitabilityDisabled}
				title="Profitability"
				key="profitability"
				onChange={(value) => handleOnEvent("profitability", value)}
				className="spaced"
				selected={profitability}
				list={[
					{ label: "PBT", id: "PBT" },
					{ label: "EBITDA", id: "EBITDA" },
					{ label: "Gross Profit", id: "GP" }
				]}
			/>

			<Dropdown
				border
				title="Segment"
				key="segment"
				onChange={(value) => handleOnEvent("segment", value)}
				className="spaced"
				selected={segment}
				list={[
					{ label: "All", id: "all" },
					{ label: "Upstream", id: "upstream" },
					{ label: "Asset", id: "asset" },
					{ label: "LMT", id: "lmt" }
				]}
			/>
		</WrapContainer>
	)
}