import React from "react";
import classNames from "classnames";
import { ClickOutsideBoundary, Placeholders, PopupBox } from "components/molecules";
import { useRef } from "react";
import "./WaterfallChart.scss";

interface Props {
	loading: boolean;
	hasData: boolean;
}

export function WaterfallChart(props: Props) {
	const waterfallChart = useRef(null);
	const closeChartPopUp = () => {
		window["closeVLChartPopUp"]();
	};

	return (
		<div className={classNames("waterfallchart-ivcr-chart", { "--no-data-overlay": !props.loading && !props.hasData })}>
			{props.loading && <Placeholders.Loader className={classNames("waterfallchart-ivcr__loader")}></Placeholders.Loader>}
			{!props.loading && !props.hasData && <Placeholders.NoData className={classNames("waterfallchart-ivcr__no-data")}></Placeholders.NoData>}
			<ClickOutsideBoundary connectTo={waterfallChart} listen={true} onOutClick={(e) => closeChartPopUp()} eventType="mouseout">
				<div className={classNames("waterfallchart-ivcr", { "--has-overlay": props.loading || !props.hasData })} ref={waterfallChart}>
					{/* Do Not remove this. it's a template for popup boxes that will show on mouseover */}
				</div>
			</ClickOutsideBoundary>
			<PopupBox id="waterfallchart_selected-details" className="--hidden" />
		</div>
	);
}