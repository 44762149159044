import React from "react";
import classNames from "classnames";
import { Typography } from "components/molecules";
import "./SquareTab.scss";

type Props = {
	id: number;
	label: string;
	selected?: boolean;
	className?: string;
	onClick?: (id: number) => void;
	children?: any;
}

export function SquareTab({ className, label, id, onClick, selected }: Props) {
	return (
		<>
			<div
				className={classNames("squareTab", { active: selected }, className)}
				key={id}
				id={label + id}
				onClick={(event) => onClick && onClick(id)}
			>
				<Typography.LargeText>{label}</Typography.LargeText>
				<i className="icon-arrow button__icon" />
			</div>
		</>
	);
}
