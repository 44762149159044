export default {
	clientId: "d8e028f2-93b4-4a60-bc9b-92d26d9bd56b", //PROD
	//clientId: "d8a6ff6d-9804-4ea0-8527-f8fa14277d4e", //STAG
	endpoints: {
	// Necessary for CORS requests, for more info see https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/CORS-usage
	//	api: "d8a6ff6d-9804-4ea0-8527-f8fa14277d4e" 
		api: "d8e028f2-93b4-4a60-bc9b-92d26d9bd56b" // Necessary for CORS requests, for more info see https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/CORS-usage
	},
	// 'tenant' is the Azure AD instance.
	tenant: "3b2e8941-7948-4131-978a-b2dfc7295091", //PROD
	//tenant: "fa6434db-4921-4ae3-8a0c-60977ad6c33c", //STAG
	// 'cacheLocation' is set to 'sessionStorage' by default, for more info see https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/Config-authentication-context#configurable-options
	// We change it to'localStorage' because 'sessionStorage' does not work when our app is served on 'localhost' in development.
	cacheLocation: "localStorage"
}