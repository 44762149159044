import { API_ROUTE } from "config/PageApiConfig";
import { DictionaryType } from "store/app/dictionary.store";
import { HttpRequest } from "helpers/request-helper";
import { LastUpdatedType } from "types/LastUpdatedType";

export function LoadDictionary(): Promise<DictionaryType> {
	return HttpRequest.get(API_ROUTE.dataDictionary).then((data) => data.data);
}

export function GetApplicationSettings(): Promise<any> {
	return HttpRequest.get(API_ROUTE.authenticateUrl);
}

export function GetLastUpdated(): Promise<LastUpdatedType> {
	return HttpRequest.get(API_ROUTE.LNG.applicationSetting)
		.then((res) => res.data as LastUpdatedType)
}