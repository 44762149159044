import React from "react";
import classNames from "classnames";
import { TrendAmt } from "../../atoms/TrendAmt/TrendAmt";
import { Indicator } from "../../atoms/Indicator/Indicator";

interface Props {
	trend: "up" | "down" | "neutral";
	trendAmountLabel?: string;
	indicatorLabel?: string;
	className?: string;
	oppositeTrend?: boolean;
}

function getTrend(props: Props) {
	if (props.trendAmountLabel && props.indicatorLabel) {
		return (
			<p
				className={classNames(
					"trend",
					props.className,
					props.trend,
					props.oppositeTrend && "opposite"
				)}
			>
				<TrendAmt label={props.trendAmountLabel} up={props.trend} />
				<Indicator label={props.indicatorLabel} enclosed down={props.trend} />
			</p>
		);
	}
	return <></>;
}
export function Trend(props: Props) {
	return getTrend(props);
}
