import { API_ROUTE } from "config/PageApiConfig";
import { ComparativeType } from "types/ComparativeType";
import { HttpRequest } from "helpers/request-helper";
import { FilterStateType } from "store/app/filters.store";
import { LNGValueChainType } from "types/LNGValueChainType";
import { PeriodType } from "types/PeriodType";
import { ProfitabilityType } from "types/ProfitabilityType";
import { TrendAnalysisResult } from "./types/TrendAnalysisResult";


export function GetLNGTrendAnalysisStackedBarChart(
	params: Pick<FilterStateType, "showBy"> & {
		currency: string;
		comparative: ComparativeType;
		periodType: PeriodType,
		year: string;
		profitability: ProfitabilityType
		valueChain: LNGValueChainType;

	}
): Promise<TrendAnalysisResult> {
	return HttpRequest.get(API_ROUTE.LNG.trendAnalysisChart, params).then(
		(data) => data.data as TrendAnalysisResult
	);
}

export function LoadYears() {
	return HttpRequest.get(API_ROUTE.LNG.years).then(
		(data) => data.data)
}

