import { ReactComponent as Logo } from "svgs/petronas-logo.svg";
import React from "react"
import "./LoadingScreen.scss";

/*
	FUNCTION NAME: LoadingScreen
	DESCRIPTION: The function that renders the screen which appears as IVCI loads 
*/
export function LoadingScreen() {
	return (
		<section className={"loading-screen"}>
			<Logo className={"logo"} />
			<p><b>Please wait</b></p>
		</section>
	)
}

