import AdalConfig from "../config/AdalConfig"
import ApiConfig from "../config/ApiConfig"
import AuthContext from "./Auth"
import axios from "axios"
const Instance = axios.create(ApiConfig)
// Add a request interceptor
Instance.interceptors.request.use((config) => {
	// Check and acquire a token before the request is sent
	return new Promise((resolve, reject) => {
		AuthContext.acquireToken(AdalConfig.endpoints.api, (message, token, msg) => {
			if (!!token) {
				config.headers.Authorization = `Bearer ${token}`;
				config.headers["Ocp-Apim-Subscription-Key"] = process.env["REACT_APP_SUBSCRIPTION_KEY"];
				resolve(config)
			} else {
				// Do something with error of acquiring the token
				reject(config)
			}
		})
	})
}, (error) => {
	// Do something with error of the request
	return Promise.reject(error)
})
export default Instance