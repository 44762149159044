import React, { useEffect } from "react";
import { AppStore } from "store"
import { AppStoreProps } from "store/app/app.store"
import { CCNGVLFilters } from "./components/CCNGVLFilters";
import { ComparativeType } from "types/ComparativeType";
import { DropdownItemType } from "components/organisms/Dropdown/Dropdown";
import { isNullOrUndefined } from "util";
import { PeriodType } from "types/PeriodType";
import { ProductListType } from "types/ProductListType";
import { Properties } from "helpers/properties";
import { SubSegmentType } from "./types/SubSegmentType";
import TopValueLeversTable from "./components/TopValueLeversTable";
import { CCNGStore, CCNGStoreProps } from "store/ccng/ccng.store";
import { CCNGUtil, Util } from "helpers/util/util";
import { Dropdown, HeaderInfoPopup, HeaderSubtitle, StickyElement, WaterfallChart } from "components/molecules";
import { GetValueLeverSummary, LoadProductType, LoadRegion, LoadYears, LoadSubSegment } from "./CCNGValueLevers.service";
import TopVLTableAccordion from "components/organisms/TopVLTableAccordion/TopVLTableAccordion";
import { VLSegmentType } from "types/VLSegmentType";
import { CostDetailTable } from "components/organisms/CostDetailTable/CostDetailTable";
import "./CCNGValueLevers.scss";

type Props = AppStoreProps & CCNGStoreProps;

type State = {
	type: PeriodType;
	subsegments: string;
	subsegmentsList: SubSegmentType[];
	productType: string;
	productTypesList: ProductListType[];
	loading: boolean;
	includeCashPayment: boolean;
	includePSCCosts: boolean;
	includeAccountingCosts: boolean;
	data: {
		summary: VLSegmentType | null;
		subsegment: SubSegmentType | null;
	}
	region: string;
	regionList: DropdownItemType[];
	yearList: DropdownItemType[];
}

/**
 * This should always return new state.
 * **/
function reducer(state: State, action: { type: string, data?: any, key?: string, [key: string]: any }) {
	switch (action.type) {
		case "UPDATE_CPCHECKBOX_STATE":
			return { ...state, includeCashPayment: action.data };
		case "UPDATE_PSCCHECKBOX_STATE":
			return { ...state, includePSCCosts: action.data };
		case "UPDATE_ACCCHECKBOX_STATE":
			return { ...state, includeAccountingCosts: action.data };
		case "UPDATE_FILTER":
			if (!action["key"]) {
				throw new Error("You must pass 'key' in action for 'UPDATE_FILTER' to work");
			}
			if (!action["data"]) {
				throw new Error("You must pass 'data' in action for 'UPDATE_FILTER' to work");
			}
			return { ...state, [action.key]: action.data };

		case "UPDATE_SUMMARY_DATA":
			if (!action["data"]) {
				throw new Error("You must pass 'data' in action for 'UPDATE_SUMMARY_DATA' to work");
			}
			return {
				...state,
				data: {
					...state.data,
					summary: action.data as VLSegmentType
				}
			}

		case "UPDATE_LOADING":
			return { ...state, loading: action.data };

		case "UPDATE_SUBSEGMENTS_DATA":
			return {
				...state,
				data: {
					...state.data,
					subsegment: action.data as SubSegmentType
				}
			}

		default:
			throw new Error();
	}
}

/*
	FUNCTION NAME: CCNGValueLevers
	DESCRIPTION: The function that renders the CCNG Value Levers screen
*/
let waterfallChart;
function CCNGValueLevers({ filters, dictionary, ccng }: Props) {
	const [state, dispatch] = React.useReducer(reducer, {
		type: "monthly",
		subsegments: "all",
		productType: "all",
		loading: true,
		productTypesList: [],
		subsegmentsList: [],
		includeCashPayment: true,
		includePSCCosts: true,
		includeAccountingCosts: true,
		data: {
			summary: null,
			subsegment: null
		},
		region: "all",
		regionList: [{ label: "All", id: "all" }],
		yearList: []
	});

	const showBy = filters.get("showBy");
	const currency = filters.get("currency");
	const month = filters.get("month");
	const quarter = filters.get("quarter");
	const year = filters.get("year");
	const period = filters.get("period");
	const ESTIMATED_APP_HEADER_HEIGHT = 2.9125;
	const deviationThreshold = 100;
	const dictionaryList = dictionary.get("dictionary");
	const product = ccng.get("product");
	const segment = ccng.get("segment");
	// CustomLogic: Custom logic here.
	const comparative = ccng.get("comparative") === "yoy" ? "actual_preceding" : ccng.get("comparative");

	const tableHeaderLabels = {
		left: Util.getActualOrPlanText("Actual", comparative, month, quarter, year, period),
		right: Util.getActualOrPlanText("Plan", comparative, month, quarter, year, period, true)
	}
	let comparative_param: ComparativeType = "plan";
	if (comparative === "actual_preceding") {
		comparative_param = "actual_preceding";
	}
	else if (comparative === "actual_corresponding") {
		comparative_param = "yoy";
	}

	// const handleCPCheckboxClick = (id: string, value: any) => dispatch({
	// 	type: "UPDATE_CPCHECKBOX_STATE",
	// 	key: id, data: value
	// });

	// const handlePSCCheckboxClick = (id: string, value: any) => dispatch({
	// 	type: "UPDATE_PSCCHECKBOX_STATE",
	// 	key: id, data: value
	// });

	// const handleACCheckboxClick = (id: string, value: any) => dispatch({
	// 	type: "UPDATE_ACCCHECKBOX_STATE",
	// 	key: id, data: value
	// });

	const handleFilterChange = (key: string, value: any) => {
		switch (key) {
			case "showBy":
			case "month":
			case "quarter":
			case "year":
			case "period":
				// this will trigger re-render too but the values are global to component.
				filters.set(key)(value.id);
				break;
			case "currency":
				filters.set("currency")({ name: value.label, currencySymbol: Properties.getCurrencySymbol(value.id) })
				break;
			case "comparative":
				// Do this special logic provided by clients before you update local state.
				// intentionally skipped break statement. NOT A MISTAKE.
				if (value.id === "actual_preceding" && period === "ytd") {
					filters.set("period")("monthly");
				}
			// eslint-disable-next-line
			case "product":
			case "segment":
				ccng.set(key)(value.id);
				break;
			default:
				// this will trigger re-render too but the values are local to component.
				dispatch({ type: "UPDATE_FILTER", key: key, data: value.id });
		}
	};
	let typeList = [
		{
			label: "Monthly",
			id: "monthly"
		},
		{
			label: "Quarterly",
			id: "quarterly"
		},
		{
			label: "YTD",
			id: "ytd"
		}
	];
	if (comparative === "actual_preceding") {
		typeList = [
			{
				label: "Monthly",
				id: "monthly"
			},
			{
				label: "Quarterly",
				id: "quarterly"
			}
		];
	}
	const handleSummaryDataChange = (value: VLSegmentType) => {
		dispatch({ type: "UPDATE_SUMMARY_DATA", data: value });
		toggleLoadingChange(false);
	}
	const toggleLoadingChange = (value: boolean) => dispatch({ type: "UPDATE_LOADING", data: value });

	const getAPIParams = () => {
		const isQuarterly = period === "quarterly";
		const isMonthly = period === "monthly";

		return {
			currency: currency.name,
			showBy: showBy,
			comparative: comparative_param,
			periodType: period,
			period: (isQuarterly && quarter) || (isMonthly && month) || `ytd-${month}`,
			year: year,
			region: state.region,
			product: product,
			productType: state.productType,
			includeCashPayment: state.includeCashPayment,
			includePSCCosts: state.includePSCCosts,
			includeAccountingCosts: state.includeAccountingCosts
		};
	}

	let currentProductTypeList = [{ label: "All", id: "all" }];

	let currentSubsegment = [{ label: "All", id: "all" }];

	const _initChart = (data: VLSegmentType) => {
		waterfallChart = new window["WaterfallChart"](
			".waterfallchart-ivcr",
			{ data: data },
			500,
			1170,
			"ccng",
			showBy,
			currency,
			tableHeaderLabels,
			product
		);

		waterfallChart.init();
	}

	const _updateChart = (data: VLSegmentType) => {
		waterfallChart.update({ data: data }, showBy, currency, tableHeaderLabels, product);
	}

	useEffect(() => {
		LoadProductType().then((data) => {
			handleFilterChange("productTypesList", { id: data });
		});

		LoadSubSegment().then((data) => {
			handleFilterChange("subsegmentsList", { id: data });
		});

		LoadRegion().then((data) => {
			handleFilterChange("regionList", { id: CCNGUtil.loadRegion(data) });
		});

		LoadYears().then((data) => {
			handleFilterChange("yearList", { id: CCNGUtil.loadYears(data) });
		});
		// eslint-disable-next-line
	}, [])

	currentProductTypeList = CCNGUtil.loadCurrentProductType(product, state.productTypesList, currentProductTypeList);

	currentSubsegment = CCNGUtil.loadCurrentSubSegment(segment, state.subsegmentsList, currentSubsegment);

	useEffect(() => {
		const params = getAPIParams();
		toggleLoadingChange(true);

		Promise.all([
			GetValueLeverSummary(segment, state.subsegments, params)
		]).then((values) => {
			handleSummaryDataChange(values[0]);
			toggleLoadingChange(false);
		})
		// eslint-disable-next-line
	}, [currency, showBy, comparative, period, month, quarter, year, product, state.productType, state.region, segment, state.subsegments, state.includeAccountingCosts, state.includeCashPayment, state.includePSCCosts]);


	useEffect(() => {
		if (isNullOrUndefined(state.data.summary)) {
			return;
		}

		if (isNullOrUndefined(waterfallChart)) {
			_initChart(state.data.summary);
		} else {
			_updateChart(state.data.summary);
		}
		// eslint-disable-next-line
	}, [state.data.summary])

	const [cost, setCost] = React.useState<{ id: string, label: "Fixed & Variable" | "Cash & Non-cash" | "Controllable & Non-controllable" }>({ id: "fnv", label: "Fixed & Variable" })

	const handleTables = (id: string, value: any) => {
		setCost({ id: value.id, label: value.label })
	}

	const valueLeverTables = () => {
		if (state?.data?.summary?.costs)
			if (cost.id === "fnv") {

				return (
					(state.data.summary.costs.fixedCosts?.costs?.length > 0 && state.data.summary.costs.variableCosts?.costs?.length > 0) &&
					<>
						<CostDetailTable
							id={"CCNG_value_levers_fixed_cost"}
							category={"Fixed Costs"}
							valueLevelTable={state.data.summary.costs.fixedCosts}
							tableCurrency={currency.name}
							actual={state.data.summary["actual"]}
							plan={state.data.summary["compare"]}
							childNodeName="details"
							tableHeaderLabel={tableHeaderLabels}
							showBy="mmbtu"
						/>
						<CostDetailTable
							id={"CCNG_value_levers_variable_cost"}
							category={"Variable Costs"}
							valueLevelTable={state.data.summary.costs.variableCosts}
							tableCurrency={currency.name}
							actual={state.data.summary["actual"]}
							plan={state.data.summary["compare"]}
							childNodeName="details"
							headerLabel="Variable Costs"
							tableHeaderLabel={tableHeaderLabels}
							showBy="mmbtu"
						/>
					</>
				)
			}
			else if (cost.id === "cnnc") {
				return (
					(state.data.summary.costs.cashCosts?.costs?.length > 0 && state.data.summary.costs.nonCashCosts?.costs?.length > 0) &&
					<>
						<CostDetailTable
							id={"CCNG_value_levers_cash_cost"}
							category={"Cash Costs"}
							valueLevelTable={state.data.summary.costs.cashCosts}
							tableCurrency={currency.name} actual={state.data.summary["actual"]}
							plan={state.data.summary["compare"]}
							childNodeName="details"
							tableHeaderLabel={tableHeaderLabels}
							showBy="mmbtu"
						/>
						<CostDetailTable
							id={"CCNG_value_levers_non_cash_cost"}
							category={"Non-Cash Costs"}
							valueLevelTable={state.data.summary.costs.nonCashCosts}
							tableCurrency={currency.name} actual={state.data.summary["actual"]}
							plan={state.data.summary["compare"]}
							childNodeName="details"
							headerLabel="Non-Cash Costs"
							tableHeaderLabel={tableHeaderLabels}
							showBy="mmbtu"
						/>
					</>
				)

			}
			else if (cost.id === "cnc") {
				return (
					(state.data.summary.costs.controllableCosts?.costs?.length > 0 && state.data.summary.costs.nonControllableCosts?.costs?.length > 0) &&
					<>
						<CostDetailTable
							id={"CCNG_value_levers_controllable_cost"}
							category={"Controllable Costs"}
							valueLevelTable={state.data.summary.costs.controllableCosts}
							tableCurrency={currency.name}
							actual={state.data.summary["actual"]}
							plan={state.data.summary["compare"]}
							childNodeName="details"
							tableHeaderLabel={tableHeaderLabels}
							showBy="mmbtu"
						/>
						<CostDetailTable
							id={"CCNG_value_levers_non_controllable_cost"}
							category={"Non-Controllable Costs"}
							valueLevelTable={state.data.summary.costs.nonControllableCosts}
							tableCurrency={currency.name}
							actual={state.data.summary["actual"]}
							plan={state.data.summary["compare"]}
							childNodeName="details"
							headerLabel="Non-Controllable Costs"
							tableHeaderLabel={tableHeaderLabels}
							showBy="mmbtu"
						/>
					</>
				)

			}
		return [];
	}
	return (
		<section className="homepage__article">
			<HeaderSubtitle heading="Key Value Levers" subtitle={Properties.getDictionaryText(dictionaryList, `CCNG_value_lever_subheader`, "en")} className="ccng-vl-header-with-subtitles" />
			<StickyElement to="top" floatBy={ESTIMATED_APP_HEADER_HEIGHT} className="value-levers-filters" stickyClassName="sticky">
				<>
					<CCNGVLFilters
						period={period}
						comparative={comparative}
						month={month}
						quarter={quarter}
						year={year}
						yearList={state.yearList}
						segment={segment}
						product={product}
						productType={state.productType}
						productTypeList={currentProductTypeList}
						subsegment={state.subsegments}
						onEvent={handleFilterChange}
						showBy={showBy}
						currency={currency}
						subSegmentList={currentSubsegment}
						hideMonth={period === "quarterly"}
						hideQuarterly={period === "monthly" || period === "ytd"}
						region={state.region}
						regionList={state.regionList}
						periodType={typeList}
						stickyClassName="value-levers-filters" //This stickyClassName is meant for the JS for scrolling
					>
						{/* <div className="vl-checkboxes">
							<HeaderInfoPopup type="popup"
								info={Properties.getDictionaryText(dictionary.get("dictionary"), "CCNG-vl-cashPayments", "en")} className="with-margin"
							>
								<Checkbox htmlFor="cashPayments" label={"Include Cash Payments"} checked={state.includeCashPayment} onChange={(id, value) => handleCPCheckboxClick(id, value)} />
							</HeaderInfoPopup>
							<HeaderInfoPopup type="popup"
								info={Properties.getDictionaryText(dictionary.get("dictionary"), "CCNG-vl-pscCosts", "en")} className="with-margin"
							>
								<Checkbox htmlFor="pscCosts" label={"Include PSC Costs"} checked={state.includePSCCosts} onChange={(id, value) => handlePSCCheckboxClick(id, value)} />
							</HeaderInfoPopup>
							<HeaderInfoPopup type="popup"
								info={Properties.getDictionaryText(dictionary.get("dictionary"), "CCNG-vl-accountingCosts", "en")} className="with-margin"
							>
								<Checkbox htmlFor="accountingCosts" label={"Include Accounting Costs"} checked={state.includeAccountingCosts} onChange={(id, value) => handleACCheckboxClick(id, value)} />
							</HeaderInfoPopup>
						</div> */}
					</CCNGVLFilters>

				</>
			</StickyElement>

			<div className="homepage__article__segment">
				<div className="ValueLevers__chart">
					<WaterfallChart loading={state.loading} hasData={(state.data?.summary?.valueLevers && state.data?.summary?.valueLevers?.length > 0) || false} />
				</div>
				{!state.loading && state.data.summary && state.data.summary.valueLevers?.length > 0 &&

					<TopValueLeversTable>
						<TopVLTableAccordion
							tableCurrency={currency.name}
							valueLevers={state.data.summary.valueLevers}
							tableHeaderLabel={tableHeaderLabels}
							deviationThreshold={deviationThreshold}>
						</TopVLTableAccordion>
					</TopValueLeversTable>}
			</div>
			{!state.loading && state.data.summary && state.data.summary.costs.fixedCosts?.costs?.length > 0 && state.data.summary.costs.variableCosts?.costs?.length > 0 &&
				<>
					<div className="CCNGValueLevers__tables">
						<div className="CCNGValueLevers__tables__heading">
							<HeaderInfoPopup info={Properties.getDictionaryText(dictionary.get("dictionary"), `CCNG_value_lever_cost_breakdown`, "en")} type="subheader">
								<h2>Cost Breakdown</h2>
							</HeaderInfoPopup>
							<Dropdown
								className="cost-dropdwn"
								selected={cost.id}
								border
								title="Cost"
								onChange={(value) => handleTables("cost", value)}
								list={[
									{ label: "Fixed & Variable", id: "fnv" },
									{ label: "Cash & Non-cash", id: "cnnc" },
									{ label: "Controllable & Non-controllable", id: "cnc" },
								]}
							/>
						</div>
						{valueLeverTables()}
					</div>


				</>
			}
		</section>
	)
}

export default AppStore.withStores(CCNGStore.withStores(CCNGValueLevers));
