import "./FixedColumnTable.scss";
import classNames from "classnames";
import { Icon } from "components/molecules";
import React from "react";

interface Props {
	className?: string;
	header: Array<HeaderType>;
	data: Array<DataType>;
	childNodeName?: string;
	fixedColumnId?: string;
	expandCoumnNumber?: number;
	heading?: string;

}

interface DataType {
	[key: string]: any;
}

interface HeaderType {
	id: string;
	label?: string;
	render?: (value: DataType) => React.ReactElement;
	renderHeader?: (value: DataType) => React.ReactElement;

}

interface State {
	showChildren: string
	dataTable: Array<DataType>
}
export class FixedColumnTable extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = { showChildren: "0", dataTable: this.props.data }
	}

	componentDidUpdate(prevProps: Props) {
		const index = this.props.data.findIndex((ele) => {
			if (this.props.childNodeName && ele[this.props.childNodeName]) {
				return true;
			}
			return 0;
		})

		if (this.props.data !== prevProps.data) {
			this.setState({ dataTable: this.props.data, showChildren: index.toString() });
		}
	}


	_showChildren = (id: string) => {
		const tableId = id;
		if (tableId) {
			this.setState((prevState) => ({
				showChildren: prevState.showChildren === tableId ? "" : tableId
			}))
		}
	}

	_getTable = (isClone) => {
		return (
			<table className={classNames("table", isClone && "clone", this.props.fixedColumnId && "fixed-table")}>
				<thead>
					<tr>
						{this.props.header.map((head, i) => {
							return (
								<th scope={isClone ? "col" : "row"} className={classNames("table__header", head.id === this.props.fixedColumnId && "fixed-column")}
									key={i} >
									{head.renderHeader && head.renderHeader(head)}
									{!head.renderHeader && head.label}
								</th>
							);
						})}
					</tr>
				</thead>
				<tbody>
					{this.state.dataTable.map((row, ind) => {
						return (<React.Fragment key={ind}>
							{this.state.dataTable.length > 1 && <tr className={classNames((this.state.showChildren === ind.toString()) && "expanded")}>
								{this.props.header.map((head, k) => {
									return (<td className={classNames("table__body", head.id === this.props.fixedColumnId && "fixed-column")} key={k} id={ind.toString()} onClick={(e) => this.props.childNodeName && row[this.props.childNodeName] && this._showChildren(ind.toString())}>
										{(k === this.props.expandCoumnNumber && (this.props.childNodeName && row[this.props.childNodeName])) && <Icon name={this.state.showChildren === ind.toString() ? "table-arrow_down" : "table-arrow_right"} color="#cccdcf" ></Icon>}
										{head.render && head.render(row)}
										{!head.render && row[head.id]}
									</td>)
								})}
							</tr>
							}

							{(this.props.childNodeName && row[this.props.childNodeName]) && row[this.props.childNodeName].map((val, j) =>
								<tr key={j} className={classNames("child-row", j === 0 && "first-child", (this.state.showChildren === ind.toString()) && "expanded")}>
									{this.props.header.map((head, k) => {
										return (<td className={classNames("table__body", head.id === this.props.fixedColumnId && "fixed-column")} key={k}>
											{head.render && head.render(val)}
											{!head.render && val[head.id]}
										</td>)
									})}
								</tr>
							)}
						</React.Fragment>
						);
					})}
				</tbody>
			</table>

		);
	}
	render() {
		return (
			<div className={classNames("fixed-accordion-table", this.props.className)}>
				<div className="table-holder">
					{this._getTable(false)}
				</div>
				{this._getTable(true)}
			</div>

		);
	}
}