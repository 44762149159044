import "./WrapContainer.scss";
import classNames from "classnames";
import { isNullOrUndefined } from "util";
import React from "react";
import { useComponentSize } from "react-use-size";
import { useScrollInfo } from "helpers/hooks/useScrollInfo";
import { Buttons, MemoizedDimensionsWrapper, Typography } from "components/molecules";
import { useEffect, useState } from "react";

interface Props {
	children: React.ReactNode | React.ReactNodeArray;
	stickyClassName: string;
	className?: string;
}


function wrapComponentIntoDimensionListener(childs: Array<React.ReactElement>, handleOnDimensionChange: (cWidth: any, cKey: any) => void) {
	return React.Children.map(childs, (child: React.ReactElement) => {
		// Every child passed inside 'WrapContainer' must have a key prop.
		const hasKey = !isNullOrUndefined(child.key);
		// Here you check...
		if (!hasKey) {
			throw new Error("Each child in WrapContainer MUST have a UNIQUE & NON-INDEX-NUMBER'key'");
		}
		// Just wrap and attach a 'onDimensionChange' listener.
		// You need to pass 'id' too which you can use in 'onDimensionChange' handler function to store to height.
		// Messy solution I know but can't find any better way in 1 day. Sorry!
		return <MemoizedDimensionsWrapper onDimensionChange={handleOnDimensionChange} id={child.key}>
			{child}
		</MemoizedDimensionsWrapper>;
	});
}

export function WrapContainer({ children, className, stickyClassName }: Props) {
	// beyond this threshold to enable wrapping/expand button
	const WRAP_ENABLING_HEIGHT_THRESHOLD = 100;

	// Use this to get the parent div's width
	const { ref, width } = useComponentSize();
	const { scrollInfo } = useScrollInfo("always", WRAP_ENABLING_HEIGHT_THRESHOLD);

	// This state will manage the state for expand/collapse button
	const [expanded, setExpanded] = useState(true);
	const [forceExpand, setForceExpand] = useState(false);

	// This state will hold the state for all the child component's width
	const [measuredItems, setMeasuredItems] = useState<{
		[key: string]: {
			width: number,
		}
	}>({});

	// Use this to toggle from 'collapse' to 'expand' when user scrolls down.
	useEffect(() => {
		const filterTop = document?.querySelector(`.${stickyClassName}`)?.getBoundingClientRect().top || 0;
		const headerBottom = document?.querySelector("header")?.getBoundingClientRect().bottom || 0;
		const hasUserScrolledPastPoint = (filterTop < headerBottom ? true : false);

		if (scrollInfo.scrollY === 0) {
			setExpanded(true);
			setForceExpand(false);
		}

		// If scrolled past the threshold, collapse menu
		if (hasUserScrolledPastPoint) {
			// If the menu has been expanded by the user, set both expanded and force expand to true.
			if (forceExpand) {
				setExpanded(true);
			} else {
				// If not, set it all to false to collapse the menu
				setExpanded(false);
			}
		}
		// eslint-disable-next-line
	}, [scrollInfo.scrollY])

	// Initial load to set the menu to be expanded
	useEffect(() => {
		setExpanded(true);
		setForceExpand(false);
	}, [])

	const handleOnDimensionChange = React.useCallback((cWidth, cKey) => {
		setMeasuredItems({
			...measuredItems, [cKey]: {
				width: cWidth,
			}
		})
	}, [measuredItems]);

	const childs: Array<React.ReactElement> = React.Children.toArray(children) as Array<React.ReactElement>;

	// This const will wrap each child passed by parent into a 'MemoizedDimensionsWrapper' div, so to speak.
	const WrappedChilds = wrapComponentIntoDimensionListener(childs, handleOnDimensionChange)

	// This array will hold items that will eventually get rendered.
	const renderOnlyChilds: Array<React.ReactElement> = [];
	// Dynamically calculate the width of shown childs.
	let visibleComponentsCalculatedWidth = 0;
	// Fixed width of all childs.
	// You need this to hide expand/collapse when all items are shown.
	let componentsFixedWidth = 0;
	// Number of hidden items.
	let hiddenItems = 0;


	// calculate how many childs get rendered depending on parent's available width.
	// We loop... because earth is round and life isn't fair...
	for (let childIndex = 0; childIndex < WrappedChilds.length; childIndex++) {
		const element = WrappedChilds[childIndex];
		// Is element rendered and has passed width to parent?
		const isElementCalculated = measuredItems && measuredItems[element.props.id] && measuredItems[element.props.id].width;
		const elementWidth = isElementCalculated ? measuredItems[element.props.id].width : -1;

		// Just to be safe because safety is our number one priority...
		if (isNullOrUndefined(element)) continue;


		// Checking if the parent rendered the child and got it's width in state or not...
		if (!isElementCalculated) {
			// If not... just render it as is.
			renderOnlyChilds.push(element);
			continue;
		}

		// Calculate fixed width of all childs one by one.
		componentsFixedWidth = componentsFixedWidth + elementWidth;

		// check if parent's width allow the current child to render.
		const shouldAddItem = (visibleComponentsCalculatedWidth + elementWidth) < width;
		/**
		 * ~~Why do you need this?~~
		 * Just check if in previous run of for loop any components were hidden
		 * This trick is used to make sure ensure inline rendering of elements.
		 * (1) (2) (3) (4)
		 * If (1) and (2) are visible but (3) = hidden
		 *
		 * then (4) should not render if their width satisfy 'shouldAddItem'
		 **/
		const areAnyComponentsHidden = hiddenItems > 0;

		if ((shouldAddItem && !areAnyComponentsHidden) || expanded) {
			visibleComponentsCalculatedWidth += elementWidth
			renderOnlyChilds.push(element)
		} else {
			hiddenItems++;
			continue;
		}
	}

	// Check if filters need to wrap or not.
	const shouldWrap = componentsFixedWidth > width;

	function clickOnButton() {
		if (scrollInfo.scrollY === 0) {
			setExpanded(!expanded);
		}
		else {
			setExpanded(!expanded);
			setForceExpand(!forceExpand);
		}
	}

	return (
		<div className={classNames("wrap-container", className)}>
			<div ref={ref} className={"childs"}>
				{renderOnlyChilds}
			</div>
			{/* Just check if there's hidden items or forceExpand is true*/}
			{shouldWrap && (hiddenItems > 0 || expanded) && (
				<Buttons.TextButton
					className={classNames("expand-button")}
					iconClassName={classNames({ "inverse-arrow": expanded })}
					onClick={clickOnButton}
					icon="table-arrow_down"
					renderText={(text) => <Typography.SmallText weight={900} uppercase>{text}</Typography.SmallText>}
				>{expanded ? "Collapse" : "Expand"}</Buttons.TextButton>
			)}
		</div >
	)
}
