import React from "react";

export function useActivatingStickyEvent(stickNavHeaderClass: string, activatedStickyNavHeaderClass: string) {
	//threshold is 0 ~ 1 where 0 is out of view and 1 is totally in view
	const inViewThreshold = 0.5;
	const queryElement = (document as any).querySelector(`.${stickNavHeaderClass}`);
	const observer = new IntersectionObserver((entries) => {
		// The intersection that will trigger when it's almost out of view
		if (entries[0].intersectionRatio <= inViewThreshold) {
			queryElement?.classList?.add(`${activatedStickyNavHeaderClass}`);
		} else {
			queryElement?.classList?.remove(`${activatedStickyNavHeaderClass}`);
		}
	}, {
		threshold: [inViewThreshold, 1]
	});

	React.useEffect(() => {
		// Use the previous sibling of the sticky nav to be the one that will trigger the additional sticky class
		observer.observe((document as any).querySelector(`.${stickNavHeaderClass}`).previousSibling);
		return () => {
			observer.unobserve((document as any).querySelector(`.${stickNavHeaderClass}`).previousSibling);
		};
	});

	return null
}