import "./CardWithIcon.scss";
import classNames from "classnames";
import { CardHeadingTrendMolecule } from "../../molecules/CardHeadingTrendMolecule/CardHeadingTrendMolecule";
import CardMainHeadingTrendMolecule from "../../molecules/CardMainHeadingTrendMolecule/CardMainHeadingTrendMolecule";
import CardVarianceTrendMolecule from "../../molecules/CardVarianceTrendMolecule/CardVarianceTrendMolecule";
import React from "react";
import { HeaderInfoPopup, Icon } from "components/molecules";

interface Props {
	mainheaderLabel: string;
	iconName: string;
	headerLabel: string;
	footerLabel: Array<string>;
	trendLabel: string;
	indicatorLabel: string;
	unitLabel: string;
	valueLabel: string;
	bordered?: boolean;
	className?: string;
	trendUp?: "up" | "down" | "neutral";
	headerLabel1: string;
	unitLabel1: string;
	valueLabel1: string;
	selected?: boolean;
	indicatorEnclosed?: boolean;
	onSelection?: (key: string) => void;
	oppositeTrend?: boolean;
	spinner?: boolean;
	cardName?: string;
	infotiptool?: boolean;
	info?: string;
	id?: string;
}

/*
	FUNCTION NAME: CardWithIcon
	DESCRIPTION: The method that creates the card UI with a split segment as 
	well as an icon on the top center that is used on the four main tabs on the
	overview page
*/
export class CardWithIcon extends React.Component<Props> {
	_onTabSelection = (e) => {
		if (!e.target.className.includes("info-icon")) {
			this.props.onSelection &&
				this.props.onSelection(this.props.cardName || this.props.mainheaderLabel);
		}
	}
	render() {
		const {
			mainheaderLabel,
			iconName,
			headerLabel,
			footerLabel,
			trendLabel,
			indicatorLabel,
			unitLabel,
			valueLabel,
			trendUp,
			bordered,
			headerLabel1,
			unitLabel1,
			valueLabel1,
			className,
			selected,
			indicatorEnclosed,
			oppositeTrend,
			spinner,
			infotiptool,
			info,
			id
		} = this.props;
		return (
			<article
				className={classNames(
					"cmp-card",
					className,
					selected && "selected",
					bordered && "bordered",
					"with-icon"
				)}
				onClick={this._onTabSelection} id={id}
			>
				{infotiptool && <HeaderInfoPopup info={info} className="header-info-pop-up">
					<h1 className={classNames("card-title--header", spinner && "spinner")}>
						<Icon name={iconName} className="heading-icon" />
						<span>{mainheaderLabel}</span>
					</h1>
				</HeaderInfoPopup>
				}
				{!infotiptool &&
					<h1 className={classNames("card-title--header", spinner && "spinner")}>
						<Icon name={iconName} className="heading-icon" />
						<span>{mainheaderLabel}</span>
					</h1>
				}
				<div className="card-body">
					<CardMainHeadingTrendMolecule
						spinner={spinner}
						headerLabel={headerLabel}
						valueLabel={valueLabel}
						unitLabel={unitLabel}
						footerLabel={footerLabel}
					/>

					<div className="card-group border-top">
						<CardHeadingTrendMolecule
							headerLabel={headerLabel1}
							footerLabel={footerLabel}
							unitLabel={unitLabel1}
							valueLabel={valueLabel1}
							className="item"
							oppositeTrend={oppositeTrend}
							spinner={spinner}
						/>

						<CardVarianceTrendMolecule
							trendUp={trendUp}
							oppositeTrend={oppositeTrend}
							spinner={spinner}
							trendLabel={trendLabel}
							indicatorLabel={indicatorLabel}
							indicatorEnclosed={indicatorEnclosed}
							className={className}
						/>

					</div>
				</div>
				<div className="arrow-down" />
				<div className="arrow-down drop" />
			</article>
		);
	}
}
