import "./NavItem.scss";
import classNames from "classnames";
import { Icon } from "components/atoms/Icon/Icon";
import { Link } from "react-router-dom"
import React from "react";
import { Typography } from "components/atoms/Typography/Typography";

interface Props {
	className?: string;
	label?: string;
	icon?: string;
	path?: string;
	selected?: boolean;
	hovered?: boolean;
	onClick?: () => void;
	onTouchStart?: () => void;
	onHover?: () => void;
}

export function NavItem({
	icon,
	label,
	selected,
	hovered,
	path,
	className,
	onHover,
	onClick,
	onTouchStart
}: Props) {
	if (path) {
		return <li>
			<Link
				to={path}
				className={classNames("nav-item", { "selected": selected || hovered }, className)}
				onClick={(e) => {
					onClick && onClick()
				}}
				onMouseEnter={(e) => { onHover && onHover() }}
				onTouchStart={(e) => { onTouchStart && onTouchStart() }}
			>
				{icon && (
					<Icon name={icon} className={classNames("nav-item-icon", `icon-${icon}`)} />
				)}
				<Typography.TextNormal className={"link-text"}>{label}</Typography.TextNormal>
			</Link >
		</li>
	} else {
		return <li className={classNames("nav-item", { "selected": selected || hovered }, className)}
			onMouseEnter={(e) => { onHover && onHover() }}
			onTouchStart={(e) => { onTouchStart && onTouchStart() }}
			onClick={(e) => {
				onClick && onClick()
			}}>
			{icon && (
				<Icon name={icon} className={classNames("nav-item-icon", `icon-${icon}`)} />
			)}
			<Typography.TextNormal className={"link-text"}>{label}</Typography.TextNormal>
		</li>
	}
}
