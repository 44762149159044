import "./Icon.scss";
import classNames from "classnames";
import globalStyles from "styles/_colors.module.scss";
import React from "react";

const ThemeColors = {
	...globalStyles,
	Transparent: "transparent"
};

interface Props {
	name: string;
	className?: string;
	color?: typeof ThemeColors | string;
	background?: typeof ThemeColors | string;
	borderColor?: typeof ThemeColors | string;
	size?: "small" | "medium" | "large" | "extra-large";
	borderWidth?: string;
	round?: boolean;
	bordered?: boolean;
	onClick?: (e: any) => void;
	[key: string]: any
}

export function Icon(props: Props) {
	const {
		name,
		color,
		borderColor = "black",
		background,
		bordered = false,
		round = false,
		className,
		size = "large",
		borderWidth = "1px",
		onClick
	} = props;

	return (
		<>
			{name && (
				<i
					className={classNames(
						`icon-${name}`,
						"icon",
						"icon-component",
						{
							"icon-component__is-rounded":
								round || (background && background !== "transparent")
						},
						`icon-font-size-${size}`,
						className
						, { [`color-${color}`]: color })}
					data-sort="asc"
					style={{
						backgroundColor:
							(background &&
								background !== ThemeColors.Transparent &&
								globalStyles[background.toString()]) ||
							String(background),
						borderColor: bordered && borderColor !== ThemeColors.Transparent ? globalStyles[borderColor.toString()] : ThemeColors.Transparent,
						borderStyle: (bordered && "solid") || "none",
						borderWidth: (bordered && borderWidth) || "0",
					}}
					onClick={(e) => onClick && onClick(e)}
				/>
			)}
		</>
	);
}
