import "./Table.scss";
import classNames from "classnames";
import React from "react";

interface Props {
	header: Array<HeaderType>;
	// data array takes any type of string
	data: Array<DataType>;
	className?: string;
	id?: string;
}

interface HeaderType {
	id: string;
	label?: string;
	render?: (value: DataType) => React.ReactElement;
}

interface DataType {
	[key: string]: any;
}

export function Table(props: Props) {
	return (
		<article className={classNames(props.className)} id={props.id}>
			<div className={classNames("table-holder")}>
				<table className={classNames("table")} cellSpacing={0}>
					<thead>
						<tr>
							{props.header.map((head, i) => {
								return (
									<th className="table__header" key={i}>
										{head.label}
									</th>
								);
							})}
						</tr>
					</thead>
					<tbody>
						{props.data?.length > 0 ? (
							props.data.map((row, i) => {
								return (
									<tr key={i}>
										{props.header.map((head) => {
											// Only render row for which the heading ID is provided
											return (
												<td className="table__body" key={head.id}>
													{head.render && head.render(row)}
													{!head.render && row[head.id]}
												</td>
											);
										})}
									</tr>
								);
							})
						) :
							(
								<>
									<tr>
										{props.header.map((head, i) => {
											return (
												<td className="table__body" key={i}>-</td>
											);
										})}
									</tr>
									<tr>
										{props.header.map((head, i) => {
											return (
												<td className="table__body" key={i}>-</td>
											);
										})}
									</tr>
								</>
							)}
					</tbody>
				</table>
			</div>
		</article>
	);
}
