import { API_ROUTE } from "config/PageApiConfig";
import { CostMarginResult } from "./type/CostMarginResult";
import { CostMarginSubSegmentResult, SegmentBreakdown } from "./type/CostMarginSubSegmentResult";
import { FilterStateType } from "store/app/filters.store";
import { HttpRequest } from "helpers/request-helper";
import { LNGValueChainType } from "types/LNGValueChainType";
import { PeriodType } from "types/PeriodType";
import { ProfitabilityType } from "types/ProfitabilityType";

export function LoadSubSegment(valueChain: string): Promise<Array<SegmentBreakdown>> {
	const params = {
		country: valueChain
	}
	return HttpRequest.get(API_ROUTE.LNG.costMarginSubsegment, params).then(
		(data) => (data.data as CostMarginSubSegmentResult).segments)
}

export function LoadChartData(
	segment: string,
	subsegment: string,
	profitability: ProfitabilityType,
	params: Pick<FilterStateType, "showBy"> & {
		currency: string;
		year: string;
		periodType: PeriodType,
		period: string;
		cost?: string;
		cts?: string;
		valueChain: LNGValueChainType;
	},
): Promise<CostMarginResult> {
	return HttpRequest.get(`${API_ROUTE.LNG.costMarginChart}/${segment}/${subsegment}/${profitability}/costs`, params)
		.then((data) => data.data as CostMarginResult);
}

export function LoadYears() {
	return HttpRequest.get(API_ROUTE.LNG.years).then(
		(data) => data.data)
}