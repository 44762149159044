import { ReactComponent as NoDataSvg } from "svgs/no-data.svg";
import classNames from "classnames";
import React from "react";

interface Props {
	className?: string
}

export function NoData({ className }: Props) {
	return (
		<NoDataSvg className={classNames(className)}></NoDataSvg>
	);
}
